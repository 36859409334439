<!---------------------组合课包----------------------->
<!--------------------------------------------------->
<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="6">课程包名称: <el-input type="text" v-model="search.course_bag_name"
            prefix-icon="el-icon-search"></el-input>
        </el-col>
        <el-button type="primary" icon="el-icon-search" @click="btnClick"
          style="margin-top:14px;margin-left:10px">搜索</el-button>
          <el-button type="warning" icon="el-icon-refresh" @click="resetClick"
          style="margin-top:14px;margin-left:10px">重置</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-button type="primary" v-if="checkPermission('admin.course.bag.add')" @click="addCP">添加课程包</el-button>
        <el-button type="success" @click="excelExport">Excel导出</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="cpList" border stripe>
          <el-table-column label="课程包编号" prop="course_bag_no" width="120px"></el-table-column>
          <el-table-column label="课程包ID" prop="id" width="100px"></el-table-column>
          <el-table-column label="课程包名称" prop="course_bag_name"></el-table-column>
          <el-table-column label="课程包封面" width="120px">
            <template slot-scope="scope">
              <el-image :src="imgDomain + scope.row.course_bag_thumb" style="width: 30px;height:30px;"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="课程包简介" prop="course_bag_desc"></el-table-column>
          <el-table-column label="课包类型" prop="course_bag_cate_string" width="100px"></el-table-column>
          <el-table-column label="课程包标签" prop="tag_name_string" width="100px"></el-table-column>
          <el-table-column label="课程包阶段" prop="course_bag_phase_string" width="100px"></el-table-column>
          <el-table-column label="售卖价格(元)" prop="course_bag_price_string" width="120px"></el-table-column>
          <el-table-column label="课包所属" width="120px">
            <template slot-scope="scope">
              <span v-if="scope.row.belong_type == 1">普通用户</span>
              <span v-if="scope.row.belong_type == 2">学校</span>
              <span v-if="scope.row.belong_type == 3">学校，普通用户</span>
            </template>
          </el-table-column>
          <el-table-column label="首页分类" prop="course_bag_homepage_type_string" width="100px"></el-table-column>
          <el-table-column label="课包主题数" prop="course_bag_subject_count" width="100px"></el-table-column>
          <el-table-column label="音频会员" prop="gift_audio_member" width="100px"></el-table-column>
          <el-table-column label="状态" width="80px">
            <template slot-scope="scope">
              <span v-if="scope.row.course_bag_status == 1">启用</span>
              <span v-else>禁用</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="edit(scope.row.id)"
                v-if="checkPermission('admin.course.bag.edit')">编辑</el-button>
              <el-button type="text" size="mini" v-if="checkPermission('admin.bag.theme')"
                @click="jump(scope.row.id)">管理主题</el-button>

              <el-button type="text" size="mini" v-if="scope.row.belong_type == 2 || scope.row.belong_type == 3"
                @click="jumpBindSchool(scope.row.id)">绑定学校</el-button>
              <el-button type="text" size="mini"
                v-if="scope.row.web_display == 2 && checkPermission('admin.bag.frontShow')"
                @click="frontShow(scope.row.id)">前端显示</el-button>
              <el-button type="text" size="mini" v-else
                @click="frontShow(scope.row.id) && checkPermission('admin.bag.frontShow')">前端隐藏</el-button>
              <template v-if="checkPermission('admin.bag.tuijian')">
                <el-button type="text" size="mini" @click="recommend(scope.row.id, scope.row.is_index_recommend)"
                  v-if="scope.row.is_index_recommend == 2">推荐课程</el-button>
                <el-button type="text" size="mini" style="color:red"
                  @click="recommend(scope.row.id, scope.row.is_index_recommend)" v-else>取消推荐</el-button>
              </template>
              <el-button type="text" size="mini" v-if="checkPermission('admin.bag.buyUrl')"
                @click="jumpCreateUrl(scope.row.id, scope.row.course_bag_price, scope.row.course_bag_name, scope.row.course_bag_pay_type)">生成购买链接</el-button>
              <template v-if="checkPermission('admin.bag.show')">
                <el-button type="text" v-if="scope.row.course_bag_status == 2" size="mini"
                  @click="changeStatusClick(scope.row.id)">启用</el-button>
                <el-button type="text" v-else-if="scope.row.course_bag_status == 1" size="mini"
                  @click="changeStatusClick(scope.row.id)">禁用</el-button>
              </template>
              <!-- <el-popconfirm title="确定要删除嘛?"  @onConfirm="delCourseBag(scope.row.id)" @confirm="delCourseBag(scope.row.id)">           -->
              <el-button type="text" slot="reference" size="mini" v-if="scope.row.order_list_count < 1"
                @click="delCourseBag(scope.row.id)">删除</el-button>
              <!-- </el-popconfirm> -->
            </template>
          </el-table-column>
        </el-table>

        <!--分页start-->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <!--分页end-->
      </el-row>
    </el-card>

    <!---------------------添加&修改---------------------------->
    <!---------------------添加&修改---------------------------->
    <el-dialog title="课程包" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="60%"
      @close="addDialogClosed">
      <el-form ref="addFormRef" :model="addForm" label-width="130px">
        <el-form-item label="课程包类型:">
          <el-select v-model="addForm.course_bags_cate" :disabled="(addForm.course_bag_id > 0) ? true : false">
            <el-option v-for="item in courseTypeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>

        </el-form-item>
        <el-form-item label="课程包名称:">
          <el-input maxlength="24" v-model="addForm.course_bag_name"></el-input>
        </el-form-item>
        <el-form-item label="课程包简介:">
          <el-input maxlength="26" v-model="addForm.course_bag_desc"></el-input>
        </el-form-item>
        <div style="width:178px;">
          <el-form-item label="课程包封面:" v-loading="progress">
            <el-upload class="avatar-uploader" :action="action" :show-file-list="false" :headers="token"
              :on-success="handleAvatarSuccess" :data="{ dirname: 'interactcourse', index: 1 }"
              :http-request="(file) => { uploadFile(file, 1, 1) }">
              <img v-if="addForm.course_bag_thumb" :src="imgDomain + addForm.course_bag_thumb" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-progress type="circle" v-if="show[0]" :percentage="percentage[0]" class="progress"></el-progress>
          </el-form-item>
        </div>
        <el-form-item label="课程包标签:">
          <template>
            <el-checkbox-group v-model="course_bag_tag_selected" :max="2">
              <el-checkbox :label="item.id" v-for="item in tagList" :key="item.id">{{ item.name }}</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-form-item>
        <el-form-item label="课程包所属:">
          <template>
            <el-checkbox-group v-model="course_bag_belong_type_selected" :max="2">
              <el-checkbox :disabled="item.disable" :label="item.id" v-for="item in course_bag_belong_type_list"
                :key="item.id">{{ item.name
                }}</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-form-item>
        <el-form-item label="课程包阶段:">
          <el-radio label="1" v-model="addForm.course_bag_phase">小阶课程</el-radio>
          <el-radio label="2" v-model="addForm.course_bag_phase">中阶课程</el-radio>
          <el-radio label="3" v-model="addForm.course_bag_phase">大阶课程</el-radio>
        </el-form-item>
        <el-form-item label="售卖价格:">
          <div>
            <div>
              <el-radio label="1" v-model="addForm.course_bag_pay_type">免费课程</el-radio>
            </div>
            <div>
              <el-radio label="2" v-model="addForm.course_bag_pay_type">付费课程 <el-input v-model="addForm.course_bag_price"
                  type="number" style="margin-left:40px;"></el-input>
              </el-radio>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="首页分类:">
          <template>
            <el-radio label="1" :disabled="courseBagHomepageRadio1Disabled"
              v-model="addForm.course_bag_homepage_type">体验课程</el-radio>
            <el-radio label="2" :disabled="courseBagHomepageRadio2Disabled"
              v-model="addForm.course_bag_homepage_type">阅读培养</el-radio>
            <el-radio label="3" :disabled="courseBagHomepageRadio3Disabled"
              v-model="addForm.course_bag_homepage_type">兴趣养成</el-radio>
            <el-radio label="4" :disabled="courseBagHomepageRadio4Disabled"
              v-model="addForm.course_bag_homepage_type">主题课程</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="是否赠送音频会员:">
          <el-radio label="1" v-model="addForm.is_gift_audio_member">赠送</el-radio>
          <el-radio label="2" v-model="addForm.is_gift_audio_member">不赠送</el-radio>
        </el-form-item>
        <div style="width:178px;">
          <el-form-item label="详情图:" v-loading="progress">
            <el-upload class="avatar-uploader" :action="action" :show-file-list="false" :headers="token"
              :on-success="handleAvatarSuccess" :data="{ dirname: 'interactcourse', index: 2 }"
              :http-request="(file) => { uploadFile(file, 2, 2) }">
              <img v-if="addForm.course_bag_detail" :src="imgDomain + addForm.course_bag_detail" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-progress type="circle" v-if="show[1]" :percentage="percentage[1]" class="progress"></el-progress>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addCPSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!--添加-->

  </div>
</template>

<script>
import { getCourseTag } from '@/api/course'
import { addCp, list, show, update, changeStatus, frontShowToggle, delCourseBag, checkRecommend, recommend } from '@/api/coursePackage'
import axios from 'axios'
import { getQiniuToken } from '@/api/upload'
import * as qiniu from 'qiniu-js'
import global from '@/utils/global'
export default {
  data() {
    return {
      action: this.uploadUrl + '/admin/upload/upload',
      token: { token: window.sessionStorage.getItem("token") },
      cpList: [],
      tagList: [], //标签
      addForm: {
        course_bag_name: '',
        course_bag_desc: '',
        course_bag_thumb: '',
        course_bag_tag: '',    // 标签
        course_bag_phase: '1',  //阶段
        course_bag_pay_type: '1',  // 付费类型
        course_bag_price: '0', // 价格
        course_bag_homepage_type: '1', // 首页分类
        course_bag_type: 2,
        course_bag_id: '',
        course_bag_detail: '', // 详情图
        course_bags_cate: 1,
        is_gift_audio_member: '2',
        belong_type: '0'
      },
      course_bag_belong_type_list: [
        { id: 2, name: "学校", disable: false },
        { id: 1, name: "普通用户", disable: false },
      ],
      course_bag_belong_type_selected: [],
      course_bag_tag_selected: [], //标签选中
      search: {
        pagenum: 1,
        pagesize: 10,
        course_bag_name: '',
        course_bag_type: 2,
      },
      total: 0,
      addDialogVisible: false,
      progress: false,
      formData: '',
      is_edit: 0,
      percentage: [0, 0],
      show: [false, false],
      courseTypeList: [{ id: 1, name: "阅读课" },], // { id: 2, name: "美术课" }
      addCTypeSelect: 1,
      courseBagHomepageRadio1Disabled: false,
      courseBagHomepageRadio2Disabled: false,
      courseBagHomepageRadio3Disabled: false,
      courseBagHomepageRadio4Disabled: false,
    }
  },
  created() {
    if (this.$route.query.pagenum) {
      this.search.pagenum = parseInt(this.$route.query.pagenum)
    }
    if (this.$route.query.pagesize) {
      this.search.pagesize = parseInt(this.$route.query.pagesize)
    }
    this.getCpList()
  },
  methods: {
    //--------添加------------------------------
    addCP() {
      this.getgetCourseTag()
      this.addDialogVisible = true
      this.getCpList()
      this.courseBagHomepageRadio1Disabled = false;
      this.courseBagHomepageRadio2Disabled = false;
      this.courseBagHomepageRadio3Disabled = false;
      this.courseBagHomepageRadio4Disabled = false;
    },
    addDialogClosed() { // 关闭添加弹框
      this.clear()
      this.is_edit = 0
    },
    clear() {
      this.addForm.course_bag_name = '';
      this.addForm.course_bag_desc = '';
      this.addForm.course_bag_thumb = '';
      this.addForm.course_bag_tag = '';
      this.addForm.course_bag_phase = '1';
      this.addForm.course_bag_pay_type = '1';
      this.addForm.course_bag_price = '0';
      this.addForm.course_bag_homepage_type = '1';
      // this.addForm.course_bag_type = 2
      this.course_bag_tag_selected = []
      this.addForm.course_bag_detail = "";
      this.addForm.course_bags_cate = 1;
      this.addForm.course_bag_id = ""
      this.addForm.is_gift_audio_member = "2"
      this.addForm.belong_type = '0'
      this.course_bag_belong_type_selected = []
      this.course_bag_belong_type_list = [
        { id: 2, name: "学校", disable: false },
        { id: 1, name: "普通用户", disable: false },
      ]
    },
    async addCPSubmit() {  //添加课包提交
      if (this.checkForm() == -1) {
        return
      }
      this.addForm.course_bag_tag = this.course_bag_tag_selected.join(',')

      let selectBelongTypeUser = 0
      let selectBelongTypeSchool = 0
      this.course_bag_belong_type_selected.forEach((item, index) => {
        if (item == 1) selectBelongTypeUser = 1
        if (item == 2) selectBelongTypeSchool = 1
      })

      if (selectBelongTypeUser == 1 && selectBelongTypeSchool == 1) {
        this.addForm.belong_type = 3
      } else if (selectBelongTypeUser == 1 && selectBelongTypeSchool == 0) {
        this.addForm.belong_type = 1
      } else if (selectBelongTypeUser == 0 && selectBelongTypeSchool == 1) {
        this.addForm.belong_type = 2
      }

      if (selectBelongTypeUser == 0 && selectBelongTypeSchool == 0) {
        this.$message.error('请选择课包所属')
        return
      }

      if (this.is_edit == 1) {
        const { data: res } = await update(this.addForm)
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
      } else {
        const { data: res } = await addCp(this.addForm)
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
      }

      this.getCpList()
      this.addDialogVisible = false
      this.is_edit = 0
    },
    checkForm() {
      if (this.addForm.course_bag_name == "") {
        this.$message.error('填写课程包名称')
        return -1
      }
      if (this.addForm.course_bag_desc == "") {
        this.$message.error('填写课包简介')
        return -1
      }
      if (this.addForm.course_bag_thumb == "") {
        this.$message.error('请上传课包封面')
        return -1
      }
      if (this.addForm.course_bag_detail == "") {
        this.$message.error('请上传详情图')
        return -1
      }
      if (this.course_bag_tag_selected.length < 1) {
        this.$message.error('请选课包标签')
        return -1
      }
      if (this.addForm.course_bag_pay_type == 2) {
        if (this.addForm.course_bag_price == "") {
          this.$message.error('请填写付费课程价格')
          return -1
        }
      }
      return 1
    },
    //------------------------编辑-------------------------
    async edit(id) {
      const { data: res } = await show({ course_bag_id: id })
      this.is_edit = 1
      this.addForm.course_bag_id = id
      this.addForm.course_bag_name = res.data.course_bag_name
      this.addForm.course_bag_desc = res.data.course_bag_desc
      this.addForm.course_bag_thumb = res.data.course_bag_thumb
      this.addForm.course_bag_detail = res.data.course_bag_detail
      this.addForm.course_bag_phase = res.data.course_bag_phase.toString()
      this.addForm.course_bag_pay_type = res.data.course_bag_pay_type.toString()
      this.addForm.course_bag_price = res.data.course_bag_price
      this.addForm.course_bag_homepage_type = res.data.course_bag_homepage_type.toString()
      this.addForm.course_bags_cate = res.data.course_bags_cate
      this.addForm.is_gift_audio_member = res.data.is_gift_audio_member.toString();
      this.course_bag_tag_selected = res.data.course_bag_tag.split(",").map(element => {
        return parseInt(element)
      })
      if (res.data.course_bags_time_id != "") {
        if (res.data.course_bag_homepage_type == 4) {
          this.courseBagHomepageRadio1Disabled = true;
          this.courseBagHomepageRadio2Disabled = true;
          this.courseBagHomepageRadio3Disabled = true;
          this.courseBagHomepageRadio4Disabled = false;
        } else {
          this.courseBagHomepageRadio1Disabled = false;
          this.courseBagHomepageRadio2Disabled = false;
          this.courseBagHomepageRadio3Disabled = false;
          this.courseBagHomepageRadio4Disabled = true;
        }
      }
      if (res.data.belong_type == 1) {
        this.course_bag_belong_type_selected.push(1)
        this.addForm.belong_type = 1
      } else if (res.data.belong_type == 2) {
        this.course_bag_belong_type_selected.push(2)
        this.addForm.belong_type = 2
      } else if (res.data.belong_type == 3) {
        this.course_bag_belong_type_selected.push(1)
        this.course_bag_belong_type_selected.push(2)
        this.addForm.belong_type = 3
      }
      if (res.data.bind_school_count > 0) {
        this.course_bag_belong_type_list.forEach((value, index) => {
          if (value.id == 2) {
            value.disable = true;
          }
        })
      }
      console.log(this.course_bag_belong_type_selected)
      this.addDialogVisible = true
      this.getgetCourseTag()
    },
    //-------------------课包 删除-------------------------
    confirm() {
      this.visible = false;
      this.$emit('onConfirm');
    },
    delCourseBag(id) {
      this.$confirm("是否要删除", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await delCourseBag({ course_bag_id: id })
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.getCpList()
        this.$message.success('删除课包成功!')
      }).catch(() => {
        console.log("课包取消删除")
      })

    },
    //------------------------课包列表--------------------
    async getCpList() {
      const { data: res } = await list(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.cpList = res.data.data
      this.total = res.data.total
    },
    //----------------------------Excel 导出--------------------------
    excelExport() {
      const headers = {
        '课程包编号': 'course_bag_no',
        '课程包名称': 'course_bag_name',
        '课程包封面': 'course_bag_thumb',
        '课程包简介': 'course_bag_desc',
        '课包类型': 'course_bag_cate_string',
        '课程包标签': 'course_bag_type_string',
        '课程包阶段': 'course_bag_phase',
        '售卖价格(元)': 'course_bag_price',
        '课包所属': 'belong_type',
        '首页分类': 'course_bag_homepage_type_string',
        '课包主题数': 'course_bag_subject_count',
        '音频会员': 'gift_audio_member',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await list(this.search)
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '课包信息',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          switch (headers[key]) {
            case "belong_type":
              if (item[headers[key]] == 1) {
                return '普通用户';
              } else if (item[headers[key]] == 2) {
                return '学校'
              } else if (item[headers[key]] == 3) {
                return '普通用户,学校'
              }
              break;

            default:
              return item[headers[key]]
              break;
          }

        })
      })
    },
    // -------------------------分页----------------------------------
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getCpList(this.search)
      // this.$forceUpdate()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getCpList(this.search)
      // this.$forceUpdate()
    },
    resetClick(){
       this.search.course_bag_name = "";
       this.btnClick();
    },
    //----------------------------搜索（筛选)-------------------------
    btnClick() {
      this.search.pagenum = 1
      this.search.pagesize = 10
      this.getCpList()
    },
    //---------------------前端显示/隐藏-----------------
    async frontShow(id) {
      const { data: res } = await frontShowToggle({ course_bag_id: id })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.getCpList()
    },
    //------------------------启用/禁用--------------------------------
    async changeStatusClick(id) {
      const { data: res } = await changeStatus({ course_bag_id: id })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.getCpList()
    },
    //----------------------推荐课程---------------------
    async recommend(id, is_recommend) {
      const { data: res } = await checkRecommend({ course_bag_id: id })
      if (res.status != 0) { //已有推荐
        this.$confirm(res.msg, '提示', {
          confirmButtonText: '更换推荐',
          cancelButtonText: '暂不更换',
          type: 'warning'
        }).then(async () => {
          const { data: res } = await recommend({ course_bag_id: id, is_recommend: is_recommend, cover: 2 })
          if (res.status != 0) {
            return this.$message.error(res.msg)
          }
          this.getCpList()

        }).catch(() => {
          console.log("暂不更换")
        })
      } else {

        const { data: res } = await recommend({ course_bag_id: id, is_recommend: is_recommend, cover: 1 })
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.getCpList()
      }

    },
    jumpBindSchool(id) {
      this.$router.push({ path: '/admin.courseBagsBindSchool.courseBagsBindSchoolList', query: { course_bags_id: id } })
    },
    //---------------- 跳转到 管理主题----------------------
    jump(id) {
      this.$router.push({ path: '/admin.course.themeManage', query: { cpId: id, pagenum: this.search.pagenum, pagesize: this.search.pagesize } })
    },
    jumpCreateUrl(id, price, bag_name, type) {
      // 生成购买链接
      this.$router.push({ path: 'admin.course.buyUrl', query: { course_bag_id: id, price: price, bag_name: bag_name, pay_type: type } })
    },
    //----------------------------------课程标签---------------
    async getgetCourseTag() {
      const { data: res } = await getCourseTag()
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.tagList = res.data
    },
    //-------------------------------文件上传--------------------
    handleAvatarSuccess(res, file) {  // 图片上传成功
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }

      if (res.data.index == 1) {
        this.addForm.course_bag_thumb = res.data.url
      } else if (res.data.index == 2) {
        this.addForm.course_bag_detail = res.data.url
      }

    },
    async uploadFile(uploadInfo, index, type) {

      if (this.img_limit(uploadInfo, type) == -1) {
        if (type == 1) {
          return this.$message.error("上传图片请小于2M")
        } else {
          return this.$message.error("上传详情图请小于2M")
        }

      }

      const { data: token } = await getQiniuToken()
      const that = this
      const observer = {
        next(res) {
          // console.log('next:', res)
          const pp = parseInt(res.total.percent.toString().split(".")[0])
          if (index == 1) {
            that.$set(that.percentage, 0, pp)
          } else if (index == 2) {
            that.$set(that.percentage, 1, pp)
          }
        },
        error(err) {
          // console.log(err)
        },
        complete(res) {
          // console.log('complete:....')
          if (index == 1) {
            that.addForm.course_bag_thumb = res.key
            that.$set(that.show, 0, false)
          } else if (index == 2) {
            that.addForm.course_bag_detail = res.key
            that.$set(that.show, 1, false)
          }
        }
      }
      if (index == 1) {
        this.$set(this.show, 0, true)
      } else if (index == 2) {
        this.$set(this.show, 1, true)
      }
      let name = uploadInfo.file.name
      let fileName = global.getFileName(name)
      if (parseInt(fileName) != NaN) {
        name = fileName + "-" + global.randStr(10) + "." + global.getExt(name)
      }
      const observable = qiniu.upload(uploadInfo.file, "interactcourse/" + name, token, {}, {})
      const subscription = observable.subscribe(observer)
    },
    img_limit(val, t) {
      let type = val.file.type
      let size = val.file.size
      let type_arr = type.split("/")
      let limit = 2   // 1M
      if (t == 2) {
        limit = 2
      }
      if (type_arr[0] == "image") {
        if (size > 1024 * 1024 * limit) {
          return -1
        }
      }
      return 1
    },
    //---------------------------------------------------

  }
}
</script>


<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.loadingModal {
  width: 100%;
  margin: 0 auto;
  background: rgba(22, 21, 21, 0.3);
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
}

.progress {
  position: absolute;
  margin-left: 26px;
  margin-top: -147px;
}
</style>