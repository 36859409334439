<template>
  <div>
    <el-card>
        <el-row class="nav-">
          <el-col :span="6">视频名称: <el-input type="text"  v-model="search.title" prefix-icon="el-icon-search"></el-input></el-col>
          <el-col :span="3" style="margin-left:10px">状态: 
            <el-select v-model="search.type" placeholder="请选择" @change="selectBag(cp_select)" filterable
                style="width:100%">
                <el-option value="">全部</el-option>
                <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id">{{
                  item.name
                }}
                </el-option>
              </el-select>
          </el-col>
          <el-button  type="primary" icon="el-icon-search" @click="searchClick" style="margin-top:14px;margin-left:10px">搜索</el-button>
          <el-button  type="warning" icon="el-icon-refresh" @click="btnClick" style="margin-top:14px;margin-left:10px">重置</el-button>
        </el-row>
        <el-row style="margin-top:10px">
          <videoAdd v-if="create"  ref="video_add" :editObj="editObj" @getVideoList="changeList"></videoAdd>
        </el-row>
        <el-row style="margin-top:10px">
          <h2>课程数量:{{total}}</h2>
        </el-row>
        <el-row style="margin-top:10px;">
            <el-table :data="list" border stripe>
            <el-table-column label="ID"  prop="id"></el-table-column>
            <el-table-column label="视频名称" prop="video_name"></el-table-column>
            <el-table-column label="权重" prop="video_weight"></el-table-column>
            <el-table-column label="播放量" prop="video_play_number"></el-table-column>
            <el-table-column label="状态"  prop="status">
              <template slot-scope="scope" >
                  <span  v-if="scope.row.status ==1">启用</span>
                  <span v-else>禁用</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="240px">
                <template slot-scope="scope" >
                  <el-button type="text" v-if="scope.row.status == 2" size="mini" @click="changeStatus(scope.row)">启用</el-button>
                  <el-button type="text" v-else-if="scope.row.status == 1" size="mini" @click="changeStatus(scope.row)">禁用</el-button>
                  <el-button type="text"  size="mini" @click="del(scope.row.id)">删除</el-button>
                  <el-button type="text"  size="mini" @click="edit(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
          </el-table>

          <!--分页start-->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.pagenum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="search.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          <!--分页end--> 

        </el-row>
      </el-card> 
  </div>
</template>


<script>
import videoAdd from "@/components/Video/videoAdd"
import {videoList,changeVideoStatus,videoDel} from '@/api/video'
export default {
  components:{
    videoAdd,
  },
  data(){
    return {
      list:[],
      create:true,
      search:{
        title:"",
        type:"",
        pagenum:1,
        pagesize:10,
      },
      total:0,
      typeList:[{"id":1,"name":"正常"},{"id":2,"name":"禁用"}],
      editObj:{},
    }
  },
  created(){
    this.getList()
  },
  methods:{
    changeList(){
      this.getList()
    },

    async getList(){
      this.search.id = this.$route.query.id
      const {data:res} = await videoList(this.search)
       if (res.status != 0) {
         return this.$message.error(res.msg)
       }
       this.total = res.data.total
       this.list = res.data.data
    },

    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList(this.search)
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList(this.search)
    },

    searchClick(){
      this.getList()
    },
    
    btnClick() {
      this.search.title = ""
      this.search.type = ""
      this.search.pagenum = 1
      this.search.pagesize = 10
      this.getList()
    },

    edit(data){
      this.$refs.video_add.addDialogVisible = true
      this.$refs.video_add.is_edit = 1
      this.editObj = data
    },
    
    async del(id){
      this.$confirm("是否要删除", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {data:res} = await videoDel({"id":id})
        if (res.status != 0) {
          return this.$message.error(res.msg)
        } 
        this.$message.success("删除成功!")
        this.getList()
      }).catch(() => {
        
      })  
    },

    async changeStatus(row){
      const {data:res} =  await changeVideoStatus({id:row.id,status:row.status})
       if(res.status!=0){
        return this.$message.error(res.msg)
      } 
      this.$message.success(res.msg)
      this.getList()
    },

  }
}
</script>