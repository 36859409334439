<template>
  <div>
    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" @click="addDialogVisible = true">添加角色</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-top:20px;">
        <el-table border :data="roleList">
          <el-table-column type="index"></el-table-column>
          <el-table-column label="角色名称" prop="name"></el-table-column>
          <el-table-column label="角色ID" prop="id"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" v-if="scope.row.id != 1 && checkPermission('admin.auth.edit')" icon="el-icon-edit" @click="editRoleDialog(scope.row)">编辑</el-button>  
                <el-popconfirm title="确定要删除这个角色嘛?" @onConfirm="delRole(scope.row.id)" @confirm="delRole(scope.row.id)">
                  <el-button slot="reference" v-if="scope.row.id != 1 && checkPermission('admin.auth.del')" size="mini" type="danger" icon="el-icon-delete">删除</el-button>
                </el-popconfirm>
              <el-button size="mini" type="warning" icon="el-icon-setting" v-if='scope.row.name!="admin" && scope.row.name!=`${username}` ' @click="showSetAuthDialog(scope.row)">分配权限</el-button>
            </template>
          </el-table-column>
      </el-table>
      </el-row>
    </el-card>

    <!--分配权限start-->
    <el-dialog title="分配权限" :visible.sync="serAuthDialogVisible" width="50%">
      <el-tree :data="authList" :props="treeProps" show-checkbox default-expand-all node-key="id" :default-checked-keys="defaultKeys" ref="treeRef" :check-strictly="false">
        
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="serAuthDialogVisible = false"> 取消</el-button>
        <el-button type="primary" @click="allotAuths"> 确定</el-button>
      </span>
    </el-dialog>
    <!--分配权限end-->

    <!--添加角色start-->  
    <el-dialog title="添加角色"  :visible.sync="addDialogVisible" width="28%" @close="addDialogClosed" >
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="70px">
        <el-form-item label="角色名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>     
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addRule">确定</el-button>
      </span>
    </el-dialog>
    <!--添加角色end-->

    <!--修改角色start-->  
    <el-dialog title="添加角色"  :visible.sync="editDialogVisible" width="28%" @close="editDialogClosed" >
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="70px">
        <el-form-item label="角色名" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>     
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editRole">确定</el-button>
      </span>
    </el-dialog>
    <!--修改角色end-->
  </div>
</template>

<script>
import { list } from '@/api/role'
import { subTreeList } from '@/api/auth'
import { addRole, editRole, delRole ,allotAuth } from '@/api/role'
export default {
data() {
  return {
    roleList: [],
    authList: [],
    defaultKeys: [],  // 默认选择
    treeProps: {
      label: 'name',
      children: 'children'
    },
    username:window.sessionStorage.getItem("username"),
    serAuthDialogVisible: false, //分配权限
    roleId: '',
    addDialogVisible: false,  // 添加角色对话框
    editDialogVisible: false, //修改角色对话框
    // 添加
    addForm: {
      name: ''
    },
    addFormRules: {
       name: [{ required: true, message: '请输入权限名', trigger: 'blur' }],
    },
    editForm: {
      id: '',
      name: ''
    },
    editFormRules: {
       name: [{ required: true, message: '请输入权限名', trigger: 'blur' }],
    },
  }
},
created() {
  this.getRoleList()
},
methods: {
  async getRoleList() {
    const {data:res} = await list()
    if (res.status ==0 ){
      this.roleList = res.data
    }
  },// 分配权限
  async showSetAuthDialog(roleInfo) {
    this.roleId = roleInfo.id
    const { data:res } = await subTreeList({'roleId':this.roleId})
    if (res.status != 0 ){
      return this.$message.error(res.msg)
    }
    this.authList = res.data.allAuth
    this.defaultKeys = res.data.selectedAuth
    this.serAuthDialogVisible = true
  },
  addDialogClosed() {
    this.$refs.addFormRef.resetFields()
  },
  async addRule() {
    this.$refs.addFormRef.validate(async valid => {
      if(!valid) return
      const {data:res} = await addRole(this.addForm)
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.addDialogVisible = false
      this.getRoleList()
    })  
  },
  // 编辑
  editRoleDialog(row) {
    console.log(row)
    this.editForm.name = row.name
    this.editForm.id = row.id
    this.editDialogVisible = true
  },
  editDialogClosed() {
    this.$refs.editFormRef.resetFields()
  },
  editRole() {
    this.$refs.editFormRef.validate(async valid => {
      if(!valid) return
      const {data:res} = await editRole(this.editForm)
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.editDialogVisible = false
      this.getRoleList()
    })
  },
  // 删除
  confirm() {
    this.visible = false;
    this.$emit('onConfirm');
  },
  async delRole(id) {
    const {data:res} = await delRole({"id":id})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.$message.success(res.msg)
    this.getRoleList()
  },
  // 分配权限
  async allotAuths() {
    const keys = [
      ...this.$refs.treeRef.getCheckedKeys(),
      ...this.$refs.treeRef.getHalfCheckedKeys()
    ]
    const idStr = keys.join(',')
    const {data:res} = await allotAuth({"roleId":this.roleId,"idStr":idStr})
    if( res.status != 0 ){
      return this.$message.error(res.msg)
    }
    this.$message.success(res.msg)
    this.serAuthDialogVisible = false
    this.getRoleList()
  }
}
}
</script>

<style lang="sass">

</style>