import Vue from 'vue'

Vue.filter('removeDir', function (value) {
  if(Object.keys(value).length==0) return value
  let site = value.lastIndexOf("\/");
  return value.substring(site+1, value.length)
})

Vue.filter('hidden', function (value) {
  if(Object.keys(value).length==0) return value
  let end = value.lastIndexOf("-")
  if(end>20){
    end = 20
  }
  return value.substring(0, end)
})

Vue.filter('tranNum', function (value) {
  if(Object.keys(value).length==0) return value
  if (value == 1){
    return 'A'
  }else if(value ==2){
    return 'B'
  }else if(value ==3){
    return 'C'
  }else if(value ==4){
    return 'D'
  }
})

// function removeDir(value) {
//   let site = value.lastIndexOf("\/");
//     return value.substring(site+1, value.length)
// }
// function hidden(value){
//   let end = value.lastIndexOf("-")
//   if(end>20){
//     end = 20
//   }
//   return value.substring(0, end)
// }
// function tranNum(value) {
//   if (value == 1){
//     return 'A'
//   }else if(value ==2){
//     return 'B'
//   }else if(value ==3){
//     return 'C'
//   }else if(value ==4){
//     return 'D'
//   }
// }

// export default {
//   removeDir,
//   hidden,
//   tranNum
// }