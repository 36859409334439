<template>
  <div>
    <!--面包屑start-->
    <el-card>
      <el-row class="nav-">
        <el-col :span="3" style="margin-left:10px">状态:
          <el-select v-model="search.status" placeholder="请选择"  filterable
                     style="width:100%">
            <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id">{{
                item.name
              }}
            </el-option>
          </el-select>
        </el-col>

        <el-button  type="primary" icon="el-icon-search" @click="btnClick" style="margin-top:14px;margin-left:10px">搜索</el-button>
        <el-button  type="warning" icon="el-icon-refresh" @click="reset" style="margin-top:14px;margin-left:10px">重置</el-button>
      </el-row>
      <el-row  style="margin-top:10px">
        <el-col>
          <el-button type="success" @click="excelExport" >导出</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-table :data="list" border stripe>
          <el-table-column label="学校ID" prop="id"></el-table-column>
          <el-table-column label="学校名称" prop="name"></el-table-column>
          <el-table-column label="关联学生数" prop="user_num"></el-table-column>
          <el-table-column label="关联课包数" prop="course_num"></el-table-column>
          <el-table-column label="状态" prop="status_msg"></el-table-column>
          <el-table-column label="操作" width="180px">
            <template slot-scope="scope">
<!--              <el-button type="text" size="mini" @click="edit(scope.row)">编辑</el-button>-->
              <el-button type="text" v-if="scope.row.status == 2 && scope.row.is_operator_status" size="mini" @click="changeStatusClick(scope.row)">启用</el-button>
              <el-button type="text" v-else-if="scope.row.status == 1 && scope.row.is_operator_status" size="mini" @click="changeStatusClick(scope.row)">禁用</el-button>
              <el-button type="text" size="mini" @click="bindingClick(scope.row.id)">绑定记录</el-button>
              <el-button type="text" size="mini" @click="relatedCourseBags(scope.row.id)">关联课包</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页start-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="search.pagenum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="search.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <!--分页end-->
      </el-row>
    </el-card>
    <!--面包屑end-->
  </div>
</template>
<script>
import {changeStatus} from "@/api/school";
import {list} from "@/api/schoolCourseBags";

export default {
  data(){
    return{
      list:[],
      search:{
        status:'',
        pagenum:1,
        pagesize:10,
      },
      typeList:[{"id": '', "name": "全部"},{"id":1,"name":"启用"},{"id":2,"name":"禁用"}],
      total:0,
    }
  },
  created(){
    this.getList()
  },
  methods: {

    //--------------------------------------获取学校列表--------------------------------------------------
    async getList(){
      const {data:res} = await list(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.total = res.data.total
      this.list = res.data.data
    },
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },
    //--------------------------------------搜索--------------------------------------------------
    btnClick() {
      this.search.pagenum = 1;
      this.getList();
    },
    reset() {
      this.search.pagenum = 1;
      this.search.pagesize = 10;
      this.search.status = '';
      this.getList();
    },
    //--------------------------------------修改状态--------------------------------------------------
    async changeStatusClick(row){
      const {data:res} =  await changeStatus({id:row.id,status:row.status})
      if(res.status!=0){
        return this.$message.error(res.msg);
      }
      this.$message.success(res.msg);
      this.getList();
    },
//--------------------------------------导出学校--------------------------------------------------
    excelExport() {
      const headers = {
        'ID':"id",
        '学校名称': 'name',
        '关联学生数': 'user_num',
        '关联课包数': 'course_num',
        '状态': 'status_msg',
      }
      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await list(this.search)
        if (!res.data.data) {
          return this.$message.error('暂无数据导出')
        }
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '学校信息',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.search.pagesize = 10
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },

//--------------------------------------绑定记录--------------------------------------------------
    bindingClick(id){
      this.$router.push({ path: '/admin.school.schoolBindingList', query: { id: id } })
    },
//--------------------------------------关联课包--------------------------------------------------
    relatedCourseBags(id){
      this.$router.push({ path: '/admin.schoolCourse.schoolCourseBags', query: {school_id: id } })
    },

  }
}
</script>
