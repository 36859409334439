<template>
    <span>
        <div style="clear:both"></div>
        <el-row style="margin-top:10px;">
            <el-table :data="list" border stripe :row-style="{ height: '50px' }">
                <el-table-column label="学校id" prop="school_id" width="190px"></el-table-column>
                <el-table-column label="学校名称" prop="school_name" width="240px"></el-table-column>
                <el-table-column label="绑定时间" prop="created_at" width="180px"></el-table-column>
                <el-table-column label="是否被关联" width="100px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.relation_school_count > 0">已被关联</span>
                        <span v-if="scope.row.relation_school_count == 0">未被关联</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="90px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">正常</span>
                        <span v-if="scope.row.status == 2">禁用</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="190px">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" @click="callChangeStatusBindSchool(scope.row.id)"
                            v-if="scope.row.status == 1">禁用</el-button>
                        <el-button type="text" size="mini" @click="callChangeStatusBindSchool(scope.row.id)"
                            v-if="scope.row.status == 2">启用</el-button>
                        <el-button type="text" size="mini" @click="callDeleteBindSchool(scope.row.id)"
                            v-if="scope.row.relation_school_count == 0">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-row>
    </span>
</template>

<script>
import { list, deleteBindSchool, changeStatus } from '@/api/bindSchool'
import excelImportOperator from '@/utils/excelImport'
export default {
    data() {
        return {
            list: [],
            total: 0,
            search: {
                pagenum: 1,
                pagesize: 10,
                keyword: "",
                bags_id: this.$route.query.course_bags_id,
            },
        }
    },
    created() {
    },
    methods: {
        async getBindSchoolList() {
            const { data: res } = await list(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.list = res.data.data
            this.total = res.data.total
        },
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
            this.getBindSchoolList(this.search)
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
            this.getBindSchoolList(this.search)
        },
        async excelExport() {
            const headers = {
                '学校id': 'school_id',
                '学校名称': 'school_name',
                '绑定时间': 'created_at',
                '是否被关联': 'relation_school_count',
                '状态': 'status',
            }
            const { data: res } = await list(this.formatExcelSearch())
            const data = this.formatJson(headers, res.data.data)
            excelImportOperator.exportExcel(headers, data, '绑定学校信息')
        },
        formatJson(headers, rows) {
            return rows.map(item => {
                return Object.keys(headers).map(key => {
                    console.log(headers[key])
                    switch (headers[key]) {
                        case 'relation_school_count':
                            if (item[headers[key]] > 0) {
                                return "已被关联";
                            } else {
                                return '未被关联';
                            }
                            break;
                        case 'status':
                            if (item[headers[key]] == 1) {
                                return "正常";
                            } else {
                                return '禁用';
                            }
                            break;
                        default:
                            return item[headers[key]]
                    }

                })
            })
        },
        formatExcelSearch() {
            let search = {}
            search.pagenum = 1;
            search.pagesize = this.total;
            search.school_name = this.search.school_name;
            search.bags_id = this.search.bags_id;
            return search;
        },
        async callChangeStatusBindSchool(id) {
            const { data: res } = await changeStatus({ id: id })
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.getBindSchoolList()
        },
        async callDeleteBindSchool(id) {
            this.$confirm('确认删除学校信息吗？', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data: res } = await deleteBindSchool({ id: id })
                if (res.status != 0) {
                    return this.$message.error(res.msg)
                }
                this.getBindSchoolList()
                this.$emit('callAddSchoolList')

            }).catch(() => {
                console.log("暂不更换")
            })

        }
    }
}
</script>