import request from '@/utils/request'

//---------------------------音频------------------------
export function list(data) {
  return request({
    url: '/admin/Audio/list',
    method: 'post',
    data
  })
}

export function add(data) {
  return request({
    url: '/admin/Audio/add',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: '/admin/Audio/edit',
    method: 'post',
    data
  })
}
export function changeStatus(data) {
  return request({
    url: '/admin/Audio/changeStatus',
    method: 'post',
    data
  })
}
export function del(data) {
  return request({
    url: '/admin/Audio/del',
    method: 'post',
    data
  })
}
//    ---------音频记录
export function audioPlayRecords(data) {
  return request({
    url: '/admin/Audio/audioPlayRecords',
    method: 'post',
    data
  })
}
export function audioCollectRecords(data) {
  return request({
    url: '/admin/Audio/audioCollectRecords',
    method: 'post',
    data
  })
}


//------------------合集------------------------------

export function conllectionList(data) {
  return request({
    url: '/admin/audioGroup/list',
    method: 'post',
    data
  })
}

export function collectionAdd(data) {
  return request({
    url: '/admin/audioGroup/add',
    method: 'post',
    data
  })
}
export function collectionEdit(data) {
  return request({
    url: '/admin/audioGroup/edit',
    method: 'post',
    data
  })
}
export function collectionDel(data) {
  return request({
    url: '/admin/audioGroup/del',
    method: 'post',
    data
  })
}

export function collectionChangeStatus(data) {
  return request({
    url: '/admin/audioGroup/changeStatus',
    method: 'post',
    data
  })
}


export function audioGroupSubscribeRecords(data) {
  return request({
    url: '/admin/AudioGroup/audioGroupSubscribeRecords',
    method: 'post',
    data
  })
}
export function audioGroupCollectRecords(data) {
  return request({
    url: '/admin/AudioGroup/audioGroupCollectRecords',
    method: 'post',
    data
  })
}

//------------------- 关联------------
export function couldBeRelaList(data) {
  return request({
    url: '/admin/AudioRelation/couldBeRelaList',
    method: 'post',
    data
  })
}

export function audioRelationList(data) {
  return request({
    url: '/admin/AudioRelation/list',
    method: 'post',
    data
  })
}
export function relationAddAudio(data) {
  return request({
    url: '/admin/AudioRelation/addAudio',
    method: 'post',
    data
  })
}
export function editSort(data) {
  return request({
    url: '/admin/AudioRelation/editSort',
    method: 'post',
    data
  })
}
export function audioRelationDel(data) {
  return request({
    url: '/admin/AudioRelation/del',
    method: 'post',
    data
  })
}

