import { render, staticRenderFns } from "./videoAdd.vue?vue&type=template&id=82a0a378&scoped=true&"
import script from "./videoAdd.vue?vue&type=script&lang=js&"
export * from "./videoAdd.vue?vue&type=script&lang=js&"
import style0 from "./videoAdd.vue?vue&type=style&index=0&id=82a0a378&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82a0a378",
  null
  
)

export default component.exports