import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,    //schema:domain   http://test666.hafeikids.com  'http://tp6'
  timeout: 5000 * 6,
})

// @x 请求拦截
service.interceptors.request.use(config=>{
  config.headers.Token = window.sessionStorage.getItem('token')
  return config
})


// @x 响应拦截
service.interceptors.response.use(config => {

  if(config.headers.refreshtoken){
    window.sessionStorage.setItem('token',config.headers.refreshtoken)
  }

  if(config.data.msg == "Expired token"|| config.data.msg =="Wrong number of segments"){
    window.sessionStorage.clear()
    location.href='/login'
  }
  
  return config
})

export default service
