<!--主题管理-->
<template>
  <div>
    <el-card>
      <el-row style="margin-top:10px;">
        <el-button type="danger" @click="back">返回课包</el-button>
        <el-button type="primary" @click="add">添加主题</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="subList" border stripe>
          <el-table-column label="主题编号" prop="id"></el-table-column>
          <el-table-column label="课程包主题" prop="course_bags_subject_name"></el-table-column>
          <el-table-column label="已关联课程" prop="be_course_count"></el-table-column>
          <el-table-column label="操作" width="340px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="update(scope.row)"
                v-if="scope.row.push_status == 1">编辑</el-button>
              <el-button type="text" size="mini" @click="jump(scope.row.id, scope.row.course_bag_id)"
                v-if="scope.row.push_status == 1">关联课程</el-button>
              <el-button type="text" size="mini" @click="jump2(scope.row.id, scope.row.course_bag_id)"
                v-else>查看关联课程</el-button>
              <el-popconfirm title="确定要删除嘛?" style="margin-left:5PX;" @confirm="delSubject(scope.row.id)">
                <el-button type="text" slot="reference" size="mini" v-if="scope.row.push_status == 1">删除</el-button>
              </el-popconfirm>
              <el-button type="text" size="mini" style="margin-left:5PX;" v-if="scope.row.allow_push == 1"
                @click="syncSunmit(scope.row.id)">确认同步</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>

        <!--添加-->
        <el-dialog title="添加主题" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="60%"
          @close="addDialogClosed">
          <el-form ref="addFormRef" label-width="100px">
            <el-form-item label="主题名称:">
              <el-input v-model="addForm.subject_name"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="addSubmit">确定</el-button>
          </span>
        </el-dialog>
        <!--添加-->

        <!--添加-->
        <el-dialog title="编辑主题" :close-on-click-modal="false" :visible.sync="editDialogVisible" width="60%">
          <el-form label-width="100px">
            <el-form-item label="主题名称:">
              <el-input v-model="editForm.subject_name"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="updateSubmit">确定</el-button>
          </span>
        </el-dialog>
        <!--添加-->

      </el-row>
    </el-card>
  </div>
</template>

<script>
import { addSubject, subList, updateSub, syncSubject, delSubject } from '@/api/coursePackage'
export default {
  data() {
    return {
      addForm: {
        course_bag_id: this.$route.query.cpId, // 课包id
        subject_name: '',
      },
      subList: [],
      addDialogVisible: false,
      editDialogVisible: false,
      editForm: {
        course_bag_id: '',
        subject_name: '',
        subject_id: ''
      },
      search: {
        pagenum: 1,
        pagesize: 10,
        course_bag_id: this.$route.query.cpId
      },
      total: 0,
    }
  },
  created() {
    this.getSubjectList()
  },
  methods: {
    //----------------------------跳转: 关联课程-----------------------------
    // 主题id
    jump(subjectId, course_bag_id) {
      this.$router.push({ path: '/admin.course.relatedcourse', query: { cpId: course_bag_id, subjectId: subjectId, show: 0 } })
    },
    jump2(subjectId, course_bag_id) {
      this.$router.push({ path: '/admin.course.relatedcourse', query: { cpId: course_bag_id, subjectId: subjectId, show: 1 } })
    },
    //----------------------------主题列表-----------------------------------
    async getSubjectList() {
      // console.log("课包id:"+ this.addForm.course_bag_id)
      const { data: res } = await subList(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.subList = res.data.data
      this.total = res.data.total
    },
    //-------------------------------- 添加主题--------------------------------
    add() {
      this.addDialogVisible = true
    },
    addDialogClosed() {
      this.addForm.subject_name = ''
    },
    async addSubmit() {
      if (this.addForm.course_bag_id == undefined) {
        return this.$message.error('没有课程id,访问方式有误')
      }
      if (this.addForm.subject_name == "") {
        return this.$message.error('请填写主题')
      }
      const { data: res } = await addSubject(this.addForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.addDialogVisible = false
      this.getSubjectList()
    },
    //---------------------------更新主题--------------------
    update(row) {
      this.editForm.subject_id = row.id
      this.editForm.course_bag_id = row.course_bag_id
      this.editForm.subject_name = row.course_bags_subject_name
      this.editDialogVisible = true
    },
    async updateSubmit() {
      if (this.editForm.course_bag_id == undefined) {
        return this.$message.error('没有课程id,访问方式有误')
      }
      if (this.editForm.subject_name == "") {
        return this.$message.error('请填写主题')
      }
      const { data: res } = await updateSub(this.editForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.editDialogVisible = false
      this.getSubjectList()
    },
    //------------------------主题同步---------------------
    syncSunmit(id) {
      this.$confirm('确认要同步课程吗，同步后将无法进行修改', '系统提示', {
        confirmButtonText: '确认同步',
        cancelButtonText: '暂不同步',
        type: 'warning'
      }).then(async () => {
        const obj = {}
        obj.course_bag_subject_id = id
        const { data: res } = await syncSubject(obj)
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success('主题同步成功!')
        this.getSubjectList()
      }).catch(() => {

      })

    },
    //-------------------主题删除-------------------------
    confirm() {
      this.visible = false;
      this.$emit('onConfirm');
    },
    async delSubject(id) {
      console.log(id)
      const { data: res } = await delSubject({ course_bag_subject_id: id })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.getSubjectList()
      this.$message.success('主题删除成功!')
    },
    //---------------- 跳转到 课包----------------------
    back() {
      this.$router.push({ path: '/admin.course.bag', query: { pagenum: this.$route.query.pagenum, pagesize: this.$route.query.pagesize } })
    },
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getSubjectList()
      // this.$forceUpdate()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getSubjectList()
      // this.$forceUpdate()
    },
  }
}
</script>