import request from '@/utils/request'

//------------------------------互动课程-用户----------------
export function list (data) {
  return request({
    url: '/admin/InteractUser/list',
    method: 'post',
    data
  })
}
export function userExport (data) {
  return request({
    url: '/admin/InteractUser/userExport',
    method: 'post',
    responseType:"blob",
    data
  })
}

// 购买记录
export function buyList (data) {
  return request({
    url: '/admin/InteractUser/buyList',
    method: 'post',
    data
  })
}

// 禁用启用
export function changeStatus (data) {
  return request({
    url: '/admin/InteractUser/changeStatus',
    method: 'post',
    data
  })
}

export function getUserDetail(data) {
  return request({
    url: '/admin/InteractUser/userDetail',
    method: 'post',
    data
  })
}



export function getUserMedal(data) {
  return request({
    url: '/admin/InteractUser/myMedal',
    method: 'post',
    data
  })
}

export function getUserDevice(data){
  return request({
    url: '/admin/InteractUser/deviceList',
    method: 'post',
    data
  })
}