<!--------------------用户管理---------------------->
<template>
  <div>
    <!--面包屑start-->
    <!--面包屑end-->
    <el-card>
       <el-row class="nav-">
          <el-col :span="6">用户名: <el-input type="text" @blur="course_search" v-model="search_content" prefix-icon="el-icon-search"></el-input></el-col>
        </el-row>
      <el-row>
        <el-col>
          <el-button type="primary" v-if="checkPermission('admin.user.add')" @click="addDialogVisible = true">添加用户</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-top:20px">
        <el-table :data="userList" border stripe>
          <el-table-column type="index"></el-table-column>
          <el-table-column label="用户名" prop="username"></el-table-column>
          <el-table-column label="用户ID" prop="id"></el-table-column>          
          <el-table-column label="角色" prop="roleName"></el-table-column>
          <el-table-column label="操作" width="180px">
            <template slot-scope="scope" >
              <el-button type="primary" v-if="checkPermission('admin.user.edit') && scope.row.username!='admin'" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row)"></el-button>
              <el-popconfirm title="确定要删除这个用户嘛?" @onConfirm="delUser(scope.row.id)" @confirm="delUser(scope.row.id)">
              <el-button slot="reference" v-if="scope.row.id != 1" type="danger" icon="el-icon-delete" size="mini"></el-button>
              </el-popconfirm>
              <el-tooltip class="item" effect="dark" content="分配角色" placement="top" :enterable="false">
                <el-button type="warning" icon="el-icon-setting" size='mini' v-if="scope.row.username!='admin'" @click="setRole(scope.row)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>

        <!--分页start-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        <!--分页end-->  
      </el-row>
    </el-card>

    <!--添加用户start-->
    <el-dialog title="添加用户"  :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="70px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addUser">确定</el-button>
      </span>
    </el-dialog>
    <!--添加用户end-->
    <!--修改用户start-->
    <el-dialog title="修改用户" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="70px">
        <el-form-item label="用户名">
          <el-input v-model="editForm.username" ></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="editForm.password" ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>
    <!--修改用户end-->

    <!-- 分配角色的对话框start -->
    <el-dialog title="分配角色" :visible.sync="setRoleDialogVisible" width="50%" @close="setRoleDialogClosed">
      <div>
        <p>当前的用户：{{userInfo.username}}</p>
        <p>当前的角色：{{userInfo.roleName}}</p>
        <p>分配新角色：
          <el-select v-model="selectedRoleId" placeholder="请选择">
            <el-option v-for="item in rolesList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRoleInfo">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分配角色的对话框end -->
  </div>
</template>

<script>
import { userList, userAdd, userById, allotRole, userDel, userEdit } from '@/api/user'
import { list } from '@/api/role'
export default {
  data() {
    return {
       search_content: '',
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 10
      },
      userList: [],
      total: 0,
      addDialogVisible: false, //添加用户对话框
      editDialogVisible: false, //修改用户对话框
      setRoleDialogVisible: false, //分配角色对话框
      addForm: {
        username: '',
        password: ''
      },
      editForm: {
        username: '',
        password:''
      },
      userInfo: {},
      rolesList: [],
      selectedRoleId: '',

      // 添加表单验证
      addFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '用户名的长度在3~10个字符之间',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '用户名的长度在6~15个字符之间',
            trigger: 'blur'
          }
        ],
      },
      // 修改表单验证
      editFormRules: {

      }
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    // 用户搜索
    course_search(){ 
      this.getUserList()
    },
  
    async getUserList() {
      const {data:res} = await userList({search_content:this.search_content,pagenum:this.queryInfo.pagenum,pagesize:this.queryInfo.pagesize})
      if(res.status!=0){
        return this.$message.error('获取用户列表失败')
      }
      this.userList = res.data.data
      this.total = res.data.total
    },// 分页
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getUserList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage
      this.getUserList()
    },
    // 添加用户
    addUser() {
      this.$refs.addFormRef.validate(async valid => {
        if(!valid) return
        const {data:res} = await userAdd(this.addForm)
        if(res.status != 0){
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.addDialogVisible = false
        this.getUserList()
      })
    },
    addDialogClosed() { // 关闭对话框，重置表单
      this.$refs.addFormRef.resetFields()
    },
    // 修改用户 
    async showEditDialog(val) {
      const {data:res} = await userById({"id":val.id})
      if(res.status!=0){
        return this.$message.error('查询用户信息失败')
      }
      this.editForm.username = res.data.username
      this.editForm.id = val.id
      this.editDialogVisible = true
    },
    async editUserInfo() {
      // 修改 todo
      const {data:res} = await userEdit(this.editForm)
      if(res.status != 0){
        return this.$message.error(res.msg)
      }
      this.editDialogVisible = false
      this.$message.success("更新成功!")
      this.getUserList()
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    // 删除 todo
    confirm() {
      this.visible = false;
      this.$emit('onConfirm');
    },
    async delUser(id) {
      const {data:res} = await userDel({"id":id})
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.getUserList()
    },
    // 分配角色
    async setRole(userInfo) {
      this.userInfo = userInfo
      const {data:res} = await list()
      if (res.status != 0 ){
        return this.$message.error('获取角色列表失败!')
      }
      this.rolesList = res.data
      this.setRoleDialogVisible = true
    },
    setRoleDialogClosed() {  // 分配关键对话框关闭
      this.selectedRoleId = ''
      this.userInfo = {}
    },
    async saveRoleInfo() {
      this.userInfo.roleId =  this.selectedRoleId
      const { data:res} = await allotRole(this.userInfo)
      if(res.status == -1){
        return this.$message.error(res.msg)
      }  
      this.getUserList()
      this.setRoleDialogVisible = false
    }
  }
} 
</script>