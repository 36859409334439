<template>
    <el-dialog title="添加账号" :close-on-click-modal="false" :visible.sync="schoolAccountDialogVisible" width="40%"
        @close="closeBindSchoolDialog">
        <el-form ref="addFormRef" label-width="100px">
            <el-form-item label="后台账号:">
                <el-input v-model="addForm.admin_name"></el-input>
            </el-form-item>
            <el-form-item label="用户名:">
                <el-input v-model="addForm.nickname"></el-input>
            </el-form-item>
            <el-form-item label="密码:">
                <el-input v-model="addForm.admin_password"></el-input>
            </el-form-item>
            <el-form-item label="选择学校:">
                <el-select filterable v-model="addForm.school_id" style="" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择角色:">
                <template>
                    <el-checkbox-group v-model="optionsSelect" :max="2">
                        <el-checkbox :label="item.id" v-for="item in school_admin_list" :key="item.id">{{
                            item.name
                        }}</el-checkbox>
                    </el-checkbox-group>
                </template>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeBindSchoolDialog">取消</el-button>
            <el-button type="primary" @click="addSubmit()">确认</el-button>
        </span>
    </el-dialog>
</template>


<script>
import global_ from '@/utils/global.js'
import { getSchoolListAll } from '@/api/bindSchool'
import { addAccountBySchoolAccount } from '@/api/schoolAccount'
export default {
    data() {
        return {
            schoolAccountDialogVisible: false,
            addForm: {
                admin_name: '',
                school_id: '',
                admin_password: "",
                role_string:'',
                nickname:""
            },
            options: [],
            optionsSelect: [],
            school_admin_list: [
                { id: 1, name: "总账号" },
                { id: 2, name: "教师账号" },
            ],
        }
    },
    created() {
        this.callSchoolListAll();
    },
    methods: {
        async callSchoolListAll() {
            const { data: res } = await getSchoolListAll({ status: 1 })
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.options = res.data;
        },
        async addSubmit() {
            try {
                await this.validateFormSubmit();
                await this.callSchoolAccountCreate();
            } catch (error) {
                console.log(error)
                let error_result = JSON.parse(error.message);
                if (error_result.code == 1000) this.$message.error(error_result.msg)
            }
        },
        closeBindSchoolDialog() {
            this.schoolAccountDialogVisible = false;
            this.clearData();
        },
        async validateFormSubmit() {
            if (this.optionsSelect.length <= 0) throw new Error(JSON.stringify({ code: 1000, msg: '请选择角色' }))
            if (global_.isEmpty(this.addForm.admin_name)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请输入后台账号' }))
            }
            if (global_.isEmpty(this.addForm.admin_password)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请输入密码' }))
            }
            if (global_.isEmpty(this.addForm.school_id)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请选择学校' }))
            }
            if (global_.isEmpty(this.addForm.nickname)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请输入用户名' }))
            }
        },
        clearData() {
            this.optionsSelect = []
            this.addForm.admin_name = "";
            this.addForm.admin_password = "";
            this.addForm.school_id = "";
            this.addForm.nickname ='';
        },
        async callSchoolAccountCreate() {
            this.addForm.role_string = this.optionsSelect.join(",")
            const { data: res } = await addAccountBySchoolAccount(this.addForm)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.closeBindSchoolDialog();
            this.$emit("callSchoolAccountList")
        }
    }
}
</script>