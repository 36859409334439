import request from '@/utils/request'


export function list(data) {
  return request({
    url: '/admin/bindSchool/list',
    method: 'post',
    data
  })
}

export function changeStatus(data) {
  return request({
    url: '/admin/bindSchool/changeStatus',
    method: 'post',
    data
  })
}

export function deleteBindSchool(data) {
  return request({
    url: "/admin/bindSchool/delete",
    method: "post",
    params: data,
  });
}


export function getSchoolListAll(data) {
  return request({
    url: "/admin/bindSchool/getSchoolList",
    method: "post",
    data
  });
}

export function createBindSchoolBatch(data) {
  return request({
    url: "/admin/bindSchool/createBindSchoolBatch",
    method: "post",
    data,
  });
}