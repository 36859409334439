<template>
    <el-dialog title="退款日志" :close-on-click-modal="false" :visible.sync="addRefundRecordDialog" width="40%"
        @close="closeAudioDialog">
        <el-row class="grid-content">
            <el-col :span="6">处理内容：</el-col>
            <el-col :span="18">{{ orderRefundRecord.content }}</el-col>
        </el-row>
        <el-row class="grid-content">
            <el-col :span="6">理由/备注：</el-col>
            <el-col :span="18">{{ orderRefundRecord.reason }}</el-col>
        </el-row>
        <el-row class="grid-content">
            <el-col :span="6">操作时间：</el-col>
            <el-col :span="18">{{ orderRefundRecord.created_at }}</el-col>
        </el-row>
        <el-row class="grid-content">
            <el-col :span="6">操作人：</el-col>
            <el-col :span="18">{{ orderRefundRecord.account_name }}</el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="closeAudioDialog">确认</el-button>
        </span>
    </el-dialog>
</template>


<script>
import { refundRecord } from '@/api/courseOrder'
export default {
    data() {
        return {
            addRefundRecordDialog: false,
            order_id: "",
            orderRefundRecord: []
        }
    },
    created() {
    },
    methods: {
        async getRefundRecordApi() {
            const { data: res } = await refundRecord({ order_id: this.order_id })
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.orderRefundRecord = res.data.list[0]
            console.log("record", this.orderRefundRecord)
        },
        closeAudioDialog() {
            this.addRefundRecordDialog = false;
        }
    }
}
</script>

<style>
.grid-content {
    min-height: 36px;
}
</style>
