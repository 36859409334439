<template>
    <el-dialog title="关联账号" :close-on-click-modal="false" :visible.sync="schoolAccountBindPhoneDialogVisible" width="40%"
        @close="closeBindSchoolPhoneDialog">
        <el-form ref="addFormRef" label-width="100px">
            <el-form-item label="用户端手机号:">
                <el-input v-model="addForm.mobile_phone"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeBindSchoolPhoneDialog">取消</el-button>
            <el-button type="primary" @click="addSubmit()">确认</el-button>
        </span>
    </el-dialog>
</template>


<script>
import global_ from '@/utils/global.js'
import { bindPhoneBySchoolAccount } from '@/api/schoolAccount'
export default {
    data() {
        return {
            schoolAccountBindPhoneDialogVisible: false,
            addForm: {
                mobile_phone: '',
                id: ''
            },
        }
    },
    created() {

    },
    methods: {

        async addSubmit() {
            try {
                await this.validateFormSubmit();
                await this.callSchoolAccountPhone();
            } catch (error) {
                console.log(error)
                let error_result = JSON.parse(error.message);
                if (error_result.code == 1000) this.$message.error(error_result.msg)
            }
        },
        closeBindSchoolPhoneDialog() {
            this.schoolAccountBindPhoneDialogVisible = false;
            this.clearData();
        },
        async validateFormSubmit() {
            if (global_.isEmpty(this.addForm.mobile_phone)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请输入用户端手机号' }))
            }
        },
        clearData() {
            this.addForm.mobile_phone = "";
            this.addForm.id = ""
        },
        async callSchoolAccountPhone() {
            const { data: res } = await bindPhoneBySchoolAccount(this.addForm)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.closeBindSchoolPhoneDialog();
            this.$emit("callSchoolAccountList")
        }
    }
}
</script>