<template>
  <div>
    <!--面包屑start-->
    <el-card>
      <el-row class="nav-">
        <el-col :span="3" style="margin-left:10px">选择学校:
          <el-select v-model="search.search_school_id" placeholder="请选择"  filterable
                     style="width:100%">
            <el-option label="全部" value="">全部</el-option>
            <el-option v-for="(item, index) in allSchool" :key="index" :label="item.name" :value="item.id">{{
                item.name
              }}
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3" style="margin-left:10px">状态:
          <el-select v-model="search.status" placeholder="请选择"  filterable
                     style="width:100%">
            <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id">{{
                item.name
              }}
            </el-option>
          </el-select>
        </el-col>

        <el-button  type="primary" icon="el-icon-search" @click="btnClick" style="margin-top:14px;margin-left:10px">搜索</el-button>
        <el-button  type="warning" icon="el-icon-refresh" @click="reset" style="margin-top:14px;margin-left:10px">重置</el-button>
      </el-row>
      <el-row  style="margin-top:10px">
        <el-col>
          <el-button type="primary" @click="addSchool"
          >添加</el-button>
          <el-button type="success" @click="excelExport" >导出</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-table :data="list" border stripe>
          <el-table-column label="学校ID" prop="id"></el-table-column>
          <el-table-column label="学校名称" prop="name"></el-table-column>
          <el-table-column label="关联学生数" prop="user_num"></el-table-column>
          <el-table-column label="关联课包数" prop="course_num"></el-table-column>
          <el-table-column label="状态" prop="status_msg"></el-table-column>
          <el-table-column label="操作" width="180px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="edit(scope.row)">编辑</el-button>
              <el-button type="text" v-if="scope.row.status == 2" size="mini" @click="changeStatusClick(scope.row)">启用</el-button>
              <el-button type="text" v-else-if="scope.row.status == 1" size="mini" @click="changeStatusClick(scope.row)">禁用</el-button>
              <el-button type="text" size="mini"  @click="del(scope.row.id)" v-if="scope.row.is_delete == 1">删除</el-button>
              <el-button type="text" size="mini" @click="bindingClick(scope.row.id)">绑定记录</el-button>
              <el-button type="text" size="mini" @click="relatedCourseBags(scope.row.id)">关联课包</el-button>

            </template>
          </el-table-column>
        </el-table>
        <!--分页start-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="search.pagenum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="search.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <!--分页end-->
      </el-row>
    </el-card>
    <!--面包屑end-->
    <!--添加-->
    <el-dialog title="添加学校" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="50%"
               @close="addDialogClosed">
      <el-form ref="addFormRef"  label-width="120px">
        <el-form-item label="学校名称:">
          <el-input v-model="addForm.name" type="text" maxlength="30"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="addSubmit">保存</el-button>
          </span>
    </el-dialog>
    <!--添加end-->
    <!--修改-->
    <el-dialog title="编辑学校" :close-on-click-modal="false" :visible.sync="editDialogVisible" width="50%"
               @close="editDialogClosed">
      <el-form ref="editFormRef"  label-width="120px">
        <el-form-item label="学校名称:">
          <el-input v-model="editForm.name" type="text" maxlength="30"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="editSubmit">保存</el-button>
          </span>
    </el-dialog>
    <!--修改end-->
  </div>

</template>
<script >

import {getAllSchool,list,changeStatus,editSchool,addSchool,delSchool} from "@/api/school";

export default {
  data(){
    return{
      list:[],
      search:{
        search_school_id:'',
        status:'',
        pagenum:1,
        pagesize:10,
      },
      allSchool:[],
      typeList:[{"id": '', "name": "全部"},{"id":1,"name":"启用"},{"id":2,"name":"禁用"}],
      total:0,
      addForm: {
        name: ''
      },
      editForm:{
        name:"",
        id:"",
      },
      addDialogVisible:false,
      editDialogVisible:false
    }
  },
  created(){
    this.getAllSchool()
    this.getList()

  },
  methods: {
    //--------------------------------------获取所有学校数据--------------------------------------------------
    async getAllSchool(){
      const {data:res} = await getAllSchool()
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.allSchool = res.data
    },
    //--------------------------------------获取学校列表--------------------------------------------------
    async getList(){
      const {data:res} = await list(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.total = res.data.total
      this.list = res.data.data
    },
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },
    //--------------------------------------搜索--------------------------------------------------
    btnClick() {
      this.search.pagenum = 1;
      this.getList();
    },
    reset() {
      this.search.pagenum = 1;
      this.search.pagesize = 10;
      this.search.search_school_id = '';
      this.search.status = '';
      this.getList();
    },
    //--------------------------------------修改状态--------------------------------------------------
    async changeStatusClick(row){
      const {data:res} =  await changeStatus({id:row.id,status:row.status})
      if(res.status!=0){
        return this.$message.error(res.msg);
      }
      this.$message.success(res.msg);
      this.getList();
    },
    //--------------------------------------添加学校-------------------------------------------------
    addSchool(){
      this.addDialogVisible = true
    },
    async addSubmit(){
      const {data:res} = await addSchool(this.addForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.getList()
      this.getAllSchool()
      this.addDialogVisible = false
    },
    addDialogClosed(){
       this.addDialogVisible = false
      this.clear()
    },
    //--------------------------------------修改学校--------------------------------------------------
    edit(row){
      this.editDialogVisible = true
      this.editForm.name = row.name
      this.editForm.id = row.id
    },
    async editSubmit(){
      const {data:res} = await editSchool(this.editForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.getList()
      this.getAllSchool()
      this.editDialogVisible = false
    },
    editDialogClosed(){
      this.editDialogVisible = false
      this.clear()
    },
    //--------------------------------------删除学校--------------------------------------------------
    async del(id){
      this.$confirm("是否要删除", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {data:res} = await delSchool({"id":id})
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success("删除成功!")
        this.getList()
        this.getAllSchool()
      }).catch(() => {
      })
    },
    clear(){
      this.addForm.name=""
      this.editForm.name=""
    },

//--------------------------------------导出学校--------------------------------------------------
    excelExport() {
      const headers = {
        'ID':"id",
        '学校名称': 'name',
        '关联学生数': 'user_num',
        '关联课包数': 'course_num',
        '状态': 'status_msg',
      }
      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await list(this.search)
        if (!res.data.data) {
          return this.$message.error('暂无数据导出')
        }
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '学校信息',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.search.pagesize = 10
      })
    },

//--------------------------------------绑定记录--------------------------------------------------
    bindingClick(id){
      this.$router.push({ path: '/admin.school.schoolBindingList', query: { id: id } })
    },
//--------------------------------------关联课包--------------------------------------------------
    relatedCourseBags(id){
      this.$router.push({ path: '/admin.schoolCourse.schoolCourseBags', query: {school_id: id } })
    },

    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },
  }
}
</script>
