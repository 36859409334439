import request from '@/utils/request'

export function list(data) {
  return request({
    url: '/admin/device/list',
    method: 'post',
    data
  })
}

export function excelImport(data) {
  return request({
    url: '/admin/device/excelImport',
    method: 'post',
    params: data,
    responseType:"blob",
    timeout: 60* 3 * 1000,
  })
}