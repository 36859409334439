<template>
  <div>
    <el-card>
        <el-row >
          <el-col :span="5">操作时间:
            <el-date-picker  v-model="search.time" value-format="yyyy-MM-dd HH:mm:ss" type="daterange"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker> 
          </el-col>
          <el-col :span="3" style="margin-left:20px;">搜索用户: <el-input type="text"  v-model="search.keyword" prefix-icon="el-icon-search"></el-input></el-col>
          <el-col :span="3" style="margin-left:10px" v-if="show">操作内容: 
            <el-select v-model="search.status" placeholder="请选择"  filterable
                style="width:100%">
                <el-option value="">全部</el-option>
                <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id">{{
                  item.name
                }}
                </el-option>
              </el-select>
          </el-col>
          <el-col :span="3" style="margin-left:10px" v-if="!show">操作内容: 
            <el-select v-model="search.status" placeholder="请选择"  filterable
                style="width:100%">
                <el-option value="">全部</el-option>
                <el-option v-for="(item, index) in typeList1" :key="index" :label="item.name" :value="item.id">{{
                  item.name
                }}
                </el-option>
              </el-select>
          </el-col>
          <el-col :span="1"><el-button  type="primary" icon="el-icon-search" @click="searchClick" style="margin-top:14px;margin-left:10px;">搜索</el-button></el-col>
          <el-col :span="1"><el-button  type="warning" icon="el-icon-refresh" @click="btnClick" style="margin-top:14px;margin-left:40px">重置</el-button></el-col>
        </el-row>
        <el-row style="margin-top:10px">
          <el-button type="success"  @click="excelExport" >导出</el-button>
        </el-row>
        <el-row style="margin-top:10px;">
          <el-tabs  v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="收藏记录" name="active1">
              <el-table :data="list" border stripe>
                <el-table-column label="用户名"  prop="nickname"></el-table-column>
                <el-table-column label="手机号" prop="mobile_phone"></el-table-column>
                <el-table-column label="操作时间" prop="created_at"></el-table-column>
                <el-table-column label="操作内容" prop="status_str"></el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="订阅记录" name="active2">
              <el-table :data="list" border stripe>
                <el-table-column label="用户名"  prop="nickname"></el-table-column>
                <el-table-column label="手机号" prop="mobile_phone"></el-table-column>
                <el-table-column label="操作时间" prop="created_at"></el-table-column>
                <el-table-column label="操作内容" prop="status_str">
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
          
           <!--分页start-->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.pagenum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="search.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          <!--分页end--> 
        </el-row>

    </el-card> 
  </div>
</template>


<script>

import {audioGroupSubscribeRecords,audioGroupCollectRecords} from '@/api/audio'
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'
import global from '@/utils/global'
export default {
  components:{
  },
  data(){
    return {
      list:[],
      audioCollectionList:[],
      activeName:"active1",
      time:"",
      show:true,
      type:1,
      search:{
        keyword:"",
        time:"",
        status:"",
        id: this.$route.query.id,
        pagenum:1,
        pagesize:10,
      },
      total:0,
      typeList:[{"id":1,"name":"收藏"},{"id":2,"name":"取消收藏"}],
      typeList1:[{"id":1,"name":"订阅"},{"id":2,"name":"取消订阅"}],
      addDialogVisible:false,
      editDialogVisible:false
    }
  },
  watch:{
     "activeName":{
      handler(newVal,oldVal){
        if(newVal == "active1"){
          this.type = 1
          this.show = true
        }else if(newVal == "active2"){
          this.show = false
          this.type = 2
        }
        this.getList()
      },
      // immediate:true
    }
  },
  created(){
    this.getList()
  },
  methods:{
    handleClick(tab, event) {
      // console.log(tab, event);
      console.log(this.activeName)
    },
    async getList(){
      if(this.type == 1){
        const {data:res} = await audioGroupCollectRecords(this.search)
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.total = res.data.total
        this.list = res.data.data
      }else if(this.type == 2){
        const {data:res} = await audioGroupSubscribeRecords(this.search)
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.total = res.data.total
        this.list = res.data.data
      } 
    },

    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },

    searchClick(){
      this.pagenum = 1
      this.getList()
    },
    
    btnClick() {
      this.search.keyword = ""
      this.search.time = ""
      this.search.status = ""
      this.search.pagenum = 1
      this.search.pagesize = 10
      this.getList()
    },

    excelExport() {
      if(this.type == 1){
        this.delExport1()
      }else{
        this.delExport2()
      }
    },

    delExport1(){
      const headers = {
        '用户名':"nickname",
        '手机号': 'mobile_phone',
        '操作时间': 'created_at',
        '操作内容': 'status_str',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await audioGroupCollectRecords(this.search)
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '收藏记录',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.search.pagesize = 10
      })
    },
    delExport2(){
      const headers = {
        '用户名':"nickname",
        '手机号': 'mobile_phone',
        '操作时间': 'created_at',
        '操作内容': 'status_str',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await audioGroupSubscribeRecords(this.search)
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '订阅记录',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.search.pagesize = 10
      })
    },

    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },


  }
}
</script>