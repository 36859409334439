<template>
    <div>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="用户信息" name="first">
                <h1>基本信息</h1>
                <userData ref="userData"></userData>
                <h1>课程订单</h1>
                <buyRecord ref="buyRecord" style="margin-top: 10px;"></buyRecord>
            </el-tab-pane>
            <el-tab-pane label="用户勋章" name="second">
                <userMedal ref="userMedal"></userMedal>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>
  
<script>
import BuyRecord from './BuyRecords.vue'
import UserData from './UserData.vue'
import UserMedal from './UserMedal.vue'
export default {
    components: {
        buyRecord: BuyRecord,
        userData: UserData,
        userMedal:UserMedal
    },
    data() {
        return {
            user_id: this.$route.query.user_id,
            activeName: 'first',
        }
    },
    created() {
    },
    methods: {
        getUserMedalData(){
            this.$refs.userMedal.getUserMedalApi();
        },
        getUserData() {
            this.$refs.userData.search.user_id = this.user_id;
            this.$refs.userData.getUserInfo();
        },
        getUserBuyOrder() {
            this.$refs.buyRecord.search.user_id = this.user_id
            this.$refs.buyRecord.getList();
        },
        handleClick(tab, event) {
            if (tab.name == "first") {
                this.getUserData();
                this.getUserBuyOrder();
            } else{
                this.getUserMedalData();
            }
        }
    }
}
</script>