<!--课程卡片-->
<template>
<div>
  <el-card>
    <div class="nav_1">
      <div class="div-left">
        <div class="d-order-no">课程名称: </div>
        <div  class="div-left">
          <el-input class="input"  v-model="search.keyword"></el-input>
        </div>
      </div>
      <div class="div-left">
        <div class="d-order-no">卡片状态: </div>
        <div  class="div-left">
         <el-select v-model="search.status" placeholder="请选择" >
            <el-option v-for="(item,index) in statusList" :label="item.name" :key="index" :value="item.id">{{item.name}}</el-option>    
          </el-select>
        </div>
      </div>
      <div class="div-left">
        <el-button  type="primary" icon="el-icon-search" @click="btnClick" >搜索</el-button>
        <el-button  type="warning" icon="el-icon-refresh" @click="reset" >重置</el-button>
      </div>
    </div>
    <!---->
    <div class="clear"></div>
    <el-row style="margin-top:10px;">
      <el-table :data="list" 
        border stripe 
        :row-style="{height:'50px'}" 
        :cell-style='cellStyle' 
        :header-cell-style='rowClass'
        >
        <el-table-column label="课程名称" prop="title"></el-table-column>
        <el-table-column label="卡片图片">
          <template slot-scope="scope">           
            <el-image  v-if="scope.row.thumb" :src="imgDomain + scope.row.thumb" style="width: 30px;height:30px;" @click="changeImg(scope.row.id)"></el-image>
            <el-button type="text" size="mini" v-else  @click="changeImg(scope.row.id)"> 点击添加</el-button>
          </template>
        </el-table-column>
        <el-table-column label="权重" >
          <template slot-scope="scope">
              <el-button type="text"  @click="editSort(scope.row)" v-if="scope.row.weight">{{scope.row.weight}}</el-button>
              <el-button type="text"  @click="editSort(scope.row)" v-else>100</el-button>
          </template>
        </el-table-column>
        <el-table-column label="卡片状态" prop="card_status_str"></el-table-column>
        <el-table-column label="课程状态" prop="course_status_str" width="100px"></el-table-column>
         <el-table-column label="操作" width="240px">
            <template slot-scope="scope">
              <template>
                <el-button type="text"  size="mini" v-if="scope.row.card_status == 2" @click="changeSta(scope.row.id,scope.row.card_status)">启用</el-button>
                <el-button type="text"  size="mini" v-else-if="scope.row.card_status == 1" @click="changeSta(scope.row.id,scope.row.card_status)">禁用</el-button>
              </template>
            </template>
          </el-table-column>
      </el-table>
    </el-row>

    <!--分页start-->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="search.pagenum"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="search.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <!--分页end-->
  </el-card>
  <el-dialog
    :visible.sync="dialogVisible"
    title="修改权重" width="25%" @close="addDialogClosed" >
    <el-form ref="addFormRef"  label-width="100px">
      <el-form-item style="margin:0 auto" label="权重值" >
        <el-input v-model="addForm.weight" type="number" ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sortSubmit()">确 定</el-button>
    </span>
  </el-dialog>
  <el-dialog
    :visible.sync="imgDialogVisible"
    title="修改卡片" width="25%" @close="addDialogClosed" >
    <el-form ref="addFormRef"  label-width="100px">
      <el-form-item >
        <el-upload class="avatar-uploader" 
            action="#"
            :show-file-list="false"
            :http-request="(file)=> {uploadFile(file,1)}"
        >
          <img v-if="addForm.thumb" :src="imgDomain + addForm.thumb" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="imgDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sortSubmit()">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
import {list,edit,changeStatus} from '@/api/card'
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'
import global from '@/utils/global'
export default {
 data (){
  return {
    list:[],
    addForm: {
      id:"",
      thumb:"",
      weight:100,
    },
    total:0,
    statusList:[{id:"",name:"全部"},{id:1,name:"正常"},{id:2,name:"禁用"}],
    search: {
      keyword:"",
      status:"",
      pagenum:1,
      pagesize:10,
    },
    dialogVisible:false,
    imgDialogVisible: false,
  }
 },
 created(){
    this.getList()
 },
 methods: {
  //-----------------------------tabel 文字居中-------------------------------
  rowClass({row, rowIndex}){
    return 'text-align:center'
  },
  cellStyle({row, column, rowIndex, columnIndex}){
    return 'text-align:center;padding:0px' 
  },
   //----------------添加-----------
  editSort(row){
      this.dialogVisible= true
      this.addForm.id = row.id
      this.addForm.weight = row.weight
  },
  async sortSubmit(){
     const {data:res} = await edit(this.addForm)
     if(res.status!=0){
        return this.$message.error(res.msg)
      }
     this.$message.success('编辑成功');
     this.imgDialogVisible = false
     this.dialogVisible = false
     this.getList()
  },
  changeImg(id){
    this.imgDialogVisible = true
    this.addForm.id = id
  },
 addDialogClosed(){
  this.clear()
 },
  // -------------------------分页-------------------------------------------
  handleSizeChange(newSize) {
    this.search.pagesize = newSize
    this.getList()
  },
  handleCurrentChange(newPage) {
    this.search.pagenum = newPage
    this.getList()
  },
  //-------------------------------列表-----------------------------------
  async getList(){
    const {data:res} = await list(this.search)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.list = res.data.data
    this.total = res.data.total 
  },
  //---------禁用/启用-------
  async changeSta(id,status){
    const {data:res} = await changeStatus({id:id,status:status})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.getList()
  },
  //-------编辑-------
  clear(){
    this.addForm.id = ""
    this.addForm.sort = ""
    this.addForm.thumb = ""
  },
  //----------------上传-------------
   handleAvatarSuccess(res, file) {  
      if(res.status != 0){
        this.progressFlag = false
        return this.$message.error(res.msg)
      }
      this.addForm.img =  res.data.url    
    },
    async uploadFile(uploadInfo,index){
    const {data:token} =  await getQiniuToken()
    const that = this
    const observer = {
        next(res){
          console.log('next:', res)
          that.percentage = parseInt(res.total.percent.toString().split(".")[0])
        },
        error(err){
          console.log(err)
        },
        complete(res){
          console.log('complete:....')
          that.addForm.thumb = res.key 
          that.show = false  
        }
      }
      this.show = true
      let name = uploadInfo.file.name
      let fileName = global.getFileName(name)
      if(parseInt(fileName) !=NaN){
        name = fileName+"-"+global.randStr(10)+"."+global.getExt(name)
      }
      const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+ name,token,{},{})
      const subscription =  observable.subscribe(observer)
    },
  //------------------------------筛选---------------------------------------
  btnClick(){
    this.search.pagenum = 1
    this.getList()
  },
  reset(){
    this.search.keyword = ""
    this.search.status = ""
    this.search.pagenum = 1
    this.search.pagesize = 10
    this.getList()
  },
 }
}
</script>

<style scoped>
.nav_1{
  margin-bottom: 10px;
  float:left;
}
.div-left{
  float:left;
  margin-right: 10px;
}

.input{
  width: 220px;
}

.d-order-no{
  float:left;
  margin-right:15px;
  height:35px;
  line-height:35px;
}

.progress{
 position: absolute;
 margin-left:26px;
 margin-top:-147px;
}
</style>