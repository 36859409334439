<!--问答-->
<template>
  <div>
    <el-form-item label="题目类型:">
      <el-select v-model="addForm.title_type" placeholder="请选择" @change="selectTitleType(addForm.title_type)">
        <!-- @change="selectAnswerNum(num)" -->
        <el-option v-for="item in title_type_list" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="题目:" prop="title">
      <el-input v-model="addForm.title" placeholder="* 题目字数最多45个字" style="margin-left:28px;width:80%"
        maxlength="45"></el-input>
    </el-form-item>
    <el-form-item label="题目语音:">
      <el-upload class="avatar-uploader" action="#" :show-file-list="false"
        :http-request="(file) => { uploadFile(file, 0, 0) }">
        <div v-if="addForm.audio" style="width:178px;height:35px">{{ addForm.audio | removeDir | hidden }}</div>
        <div v-else style="width:178px;height:35px">上传</div>
      </el-upload>
      <el-progress v-if="show[0]" :percentage="percentage[0]" class="line"></el-progress>
    </el-form-item>
    <el-form-item label="题目图片:" v-if="addForm.title_type == 2">
      <el-upload class="avatar-uploader" action="#" :show-file-list="false"
        :http-request="(file) => { uploadFile(file, 3, 0) }">
        <div>
          <img v-if="addForm.title_thumb" :src="imgDomain + addForm.title_thumb" class="avatar"
            style="width:130px;height:130px" />
          <i v-else class="el-icon-plus avatar-uploader-icon" style="width:130px;height:130px"></i>
          <!-- <i  class="el-icon-plus avatar-uploader-icon" ></i> -->
        </div>
      </el-upload>
    </el-form-item>
    <el-form-item label="题目顺序:">
      <el-input v-model="addForm.sort" type="number" style="width:80%"></el-input>
    </el-form-item>
    <el-form-item label="题目类型:">
      <template>
        <el-radio v-model="addForm.ex_type" label="1">单选题</el-radio>
        <el-radio v-model="addForm.ex_type" label="2">多选题</el-radio>
      </template>
    </el-form-item>
    <el-form-item label="答案数量:">
      <el-select v-model="addForm.answerNum" placeholder="请选择" @change="selectAnswerNum(addForm.answerNum)">
        <!-- @change="selectAnswerNum(num)" -->
        <el-option value="2">2</el-option>
        <el-option value="3">3</el-option>
        <el-option value="4">4</el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="答案类型:">
      <el-select v-if='refresh_answer_type' v-model="addForm.answerType" placeholder="请选择"
        @change="selectAnswerType(addForm.answerType)">
        <el-option v-for="item in answer_select_list" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <div>答案设置:</div>
      <div v-if="addForm.title_type == 1">说明：需配置并勾选正确答案，<span style="color:red">图文结合文字</span>答案最多12个字，<span
          style="color:red">纯文字</span>答案最多20个字，<span style="color:red">图片</span>答案最佳尺寸为138x94px</div>
      <div v-if="addForm.title_type == 2">说明：需配置并勾选正确答案，<span style="color:red">纯文字</span>答案最多10个字</div>

      <!--单选-->
      <div v-if="addForm.ex_type == 1">
        <div v-if="addForm.answerType == 1">
          <div v-for="index of num1" :key="index">
            <el-row>
              <el-col :span="2">
                <el-radio v-model="addForm.answerRadio" :label="index">答案{{ index | tranNum }}</el-radio>
              </el-col>
              <el-col :span="12">
                <el-input v-model="addForm.answerRadioText[index - 1]" type="text" style="width:96%"
                  :maxlength="text_word"></el-input>
              </el-col>
              <el-col :span="5">
                <el-form-item>
                  <el-upload class="avatar-uploader" action="#" :show-file-list="false"
                    :http-request="(file) => { uploadFile(file, 1, index) }">
                    <div v-if="addForm.answerRadioAudio[index - 1]">
                      <div v-if="addForm.answerRadioAudio[index - 1].url" style="width:178px;height:35px">{{
                        addForm.answerRadioAudio[index - 1].url | removeDir | hidden
                      }}</div>
                      <div v-else style="width:178px;height:35px">上传</div>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-else-if="addForm.answerType == 2">
          <!-- 图片 -->
          <div v-for="index of num1" :key="index">
            <el-row>
              <el-col :span="2">
                <el-radio :label="index" v-model="addForm.answerRadio">答案{{ index | tranNum }}</el-radio>
              </el-col>
              <el-col :span="8" style="margin:3px 0;">
                <el-form-item>
                  <el-upload class="avatar-uploader" action="#" :show-file-list="false" style="width:130px;height:130px"
                    :http-request="(file) => { uploadFile(file, 2, index) }">
                    <div v-if="addForm.answerRadioImg[index - 1]">
                      <img v-if="addForm.answerRadioImg[index - 1].url"
                        :src="imgDomain + addForm.answerRadioImg[index - 1].url" class="avatar"
                        style="width:130px;height:130px" />
                      <i v-else class="el-icon-plus avatar-uploader-icon" style="width:130px;height:130px"></i>
                      <!-- <i  class="el-icon-plus avatar-uploader-icon" ></i> -->
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-else-if="addForm.answerType == 3">
          <!-- 图文结合 -->
          <div v-for="index of num1" :key="index" style="margin:5px 0">
            <el-row style="line-height:136px;">
              <el-col :span="2">
                <el-radio :label="index" v-model="addForm.answerRadio">答案{{ index | tranNum }}</el-radio>
              </el-col>
              <el-col :span="8">
                <el-input v-model="addForm.answerRadioText[index - 1]" type="text" style="width:96%"
                  :maxlength="text_word"></el-input>
              </el-col>
              <div style="line-height:136px;margin-top:48px;">
                <el-col :span="5">
                  <el-form-item>
                    <el-upload class="avatar-uploader" action="#" :show-file-list="false"
                      :http-request="(file) => { uploadFile(file, 1, index) }">
                      <div v-if="addForm.answerRadioAudio[index - 1]">
                        <div v-if="addForm.answerRadioAudio[index - 1].url" style="width:178px;height:35px">{{
                          addForm.answerRadioAudio[index - 1].url | removeDir | hidden
                        }}</div>
                        <div v-else style="width:178px;height:35px">上传</div>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </div>
              <el-col :span="5" style="margin-top:-30px;">
                <el-form-item>
                  <el-upload class="avatar-uploader" action="#" :show-file-list="false" style="width:130px;height:130px;"
                    :http-request="(file) => { uploadFile(file, 2, index) }">
                    <div v-if="addForm.answerRadioImg[index - 1]">
                      <img v-if="addForm.answerRadioImg[index - 1].url"
                        :src="imgDomain + addForm.answerRadioImg[index - 1].url" class="avatar"
                        style="width:130px;height:130px" />
                      <i v-else class="el-icon-plus avatar-uploader-icon" style="width:130px;height:130px"></i>
                      <!-- <i  class="el-icon-plus avatar-uploader-icon" ></i> -->
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <!--单选end-->
      <!--多选start-->
      <div v-else>
        <div v-if="addForm.answerType == 1">
          <div v-for="index of num1" :key="index">
            <el-row>
              <el-col :span="2">
                <el-checkbox :label="index" v-model="addForm.answerCheckbox[index - 1]">答案{{ index |
                  tranNum }}</el-checkbox>
              </el-col>
              <el-col :span="12">
                <el-input v-model="addForm.answerRadioText[index - 1]" type="text" style="width:96%"
                  :maxlength="text_word"></el-input>
              </el-col>
              <el-col :span="5">
                <el-form-item>
                  <el-upload class="avatar-uploader" action="#" :show-file-list="false"
                    :http-request="(file) => { uploadFile(file, 1, index) }">
                    <div v-if="addForm.answerRadioAudio[index - 1]">
                      <div v-if="addForm.answerRadioAudio[index - 1].url" style="width:178px;height:35px">{{
                        addForm.answerRadioAudio[index - 1].url | removeDir | hidden
                      }}</div>
                      <div v-else style="width:178px;height:35px">上传</div>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-else-if="addForm.answerType == 2">
          <!-- 图片 -->
          <div v-for="index of num1" :key="index">
            <el-row>
              <el-col :span="2">
                <el-checkbox :label="index" v-model="addForm.answerCheckbox[index - 1]">答案{{ index |
                  tranNum }}</el-checkbox>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <el-upload class="avatar-uploader" action="#" :show-file-list="false" style="width:130px;height:130px"
                    :http-request="(file) => { uploadFile(file, 2, index) }">
                    <div v-if="addForm.answerRadioImg[index - 1]">
                      <img v-if="addForm.answerRadioImg[index - 1].url"
                        :src="imgDomain + addForm.answerRadioImg[index - 1].url" class="avatar"
                        style="width:130px;height:130px" />
                      <i v-else class="el-icon-plus avatar-uploader-icon" style="width:130px;height:130px"></i>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <!--图文结合-->
        <div v-else-if="addForm.answerType == 3">
          <!-- 图片 -->
          <div v-for="index of num1" :key="index">
            <el-row style="line-height:136px;">
              <el-col :span="2">
                <el-checkbox :label="index" v-model="addForm.answerCheckbox[index - 1]">答案{{ index |
                  tranNum }}</el-checkbox>
              </el-col>
              <el-col :span="8">
                <el-input v-model="addForm.answerRadioText[index - 1]" type="text" style="width:96%"
                  maxlength="12"></el-input>
              </el-col>
              <div style="line-height:136px;margin-top:48px;">
                <el-col :span="5">
                  <el-form-item>
                    <el-upload class="avatar-uploader" action="#" :show-file-list="false"
                      :http-request="(file) => { uploadFile(file, 1, index) }">
                      <div v-if="addForm.answerRadioAudio[index - 1]">
                        <div v-if="addForm.answerRadioAudio[index - 1].url" style="width:178px;height:35px">{{
                          addForm.answerRadioAudio[index - 1].url | removeDir | hidden
                        }}</div>
                        <div v-else style="width:178px;height:35px">上传</div>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </div>
              <el-col :span="5" style="margin-top:-30px;">
                <el-form-item>
                  <el-upload class="avatar-uploader" action="#" :show-file-list="false" style="width:130px;height:130px"
                    :http-request="(file) => { uploadFile(file, 2, index) }">
                    <div v-if="addForm.answerRadioImg[index - 1]">
                      <img v-if="addForm.answerRadioImg[index - 1].url"
                        :src="imgDomain + addForm.answerRadioImg[index - 1].url" class="avatar"
                        style="width:130px;height:130px" />
                      <i v-else class="el-icon-plus avatar-uploader-icon" style="width:130px;height:130px"></i>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <!--多选end-->
    </el-form-item>

    <div style="footer-div">
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogHidden">取消</el-button>
        <el-button type="primary" @click="addSubmit">确定</el-button>
      </span>
    </div>
  </div>
</template>


<script>
import global_ from '@/utils/global.js'
import { getQiniuToken } from '@/api/upload'
import * as qiniu from 'qiniu-js'

import { contentAdd, contentDetail, changeExerciseStatus } from '@/api/course'

export default {
  props: {
    editObj: {},
    inter_type:{},
    // courseId:{},
  },
  created() {
    if (this.editObj.is_edit == 1) {
      this.editInit(this.editObj)
    }
  },
  watch:{
    addForm:{
      handler(newVal,oldVal){
        if(this.editObj.is_edit == 1){
          let flag = 0
          if(this.editTemp.title!=newVal.title){
            flag = 1
          }
          if(this.editTemp.title_type!=newVal.title_type){
            flag = 1
          }
          if(this.editTemp.title_thumb!=newVal.title_thumb){
            flag = 1
          }
          if(this.editTemp.audio != newVal.audio){ 
            flag = 1
          }
          if(this.editTemp.sort != newVal.sort){ 
            flag = 1
          }
          if(this.editTemp.ex_type != newVal.ex_type){ 
            flag = 1
          }
          if(this.editTemp.num!=newVal.answerNum){ 
            flag = 1
          }
          if(this.editTemp.option_type!=newVal.answerType){
            flag = 1
          }
          if(this.editTemp.answerRadio!=newVal.answerRadio){ // 单选选中项
            flag = 1
          }
          for(let i=0;i<this.editTemp.num;i++){
            if(this.editTemp.option_type == 1){ // 单选
              if(this.editTemp.answerType == 1){
                if(this.editTemp.answerRadioText[i]!=newVal.answerRadioText[i] || this.editTemp.answerRadioAudio[i]!=newVal.answerRadioAudio[i]){
                  flag = 1
                  break
                }
              }else if(this.editTemp.answerType == 2){
                if( this.editTemp.answerRadioImg[i] != newVal.answerRadioImg[i] ){
                  flag = 1
                  break
                }
              }else if(this.editTemp.answerType == 3){
                if( this.editTemp.answerRadioText[i]!=newVal.answerRadioText[i] || this.editTemp.answerRadioAudio[i]!=newVal.answerRadioAudio[i] || this.editTemp.answerRadioImg[i] != newVal.answerRadioImg[i] ){
                  flag = 1
                  break
                }
              }
            }else if(this.option_type == 2){ // 多选
              if(this.editTemp.answerType == 1){
                if(this.editTemp.answerRadioText[i]!=newVal.answerRadioText[i] || this.editTemp.answerRadioAudio[i]!=newVal.answerRadioAudio[i]){
                  flag = 1
                  break
                }
              }else if(this.editTemp.answerType == 2){
                if( this.editTemp.answerRadioImg[i] != newVal.answerRadioImg[i] ){
                  flag = 1
                  break
                }
              }else if(this.editTemp.answerType == 3){
                if( this.editTemp.answerRadioText[i]!=newVal.answerRadioText[i] || this.editTemp.answerRadioAudio[i]!=newVal.answerRadioAudio[i] || this.editTemp.answerRadioImg[i] != newVal.answerRadioImg[i] ){
                  flag = 1
                  break
                }
              }

              if(this.edit.answerCheckbox != newVal.answerCheckbox){
                flag = 1
                break
              }
            }
          } 
          // if(flag == 1){
          //   window.sessionStorage.setItem("interType",4)
          // }else{
          //   window.sessionStorage.setItem("interType",null)
          // }  
        }else{
          console.log('------------------2')
          if(newVal.title!="" ||newVal.title_type!="" || newVal.title_thumb!="" || newVal.audio!="" || newVal.sort!=100 || newVal.ex_type!="1" || newVal.answerNum !="" || newVal.answerType!="1" || newVal.answerRadio!="" ||newVal.answerRadioText.length!=0 || newVal.answerRadioAudio.length!=0 || newVal.answerRadioImg.length!=0 || newVal.answerCheckbox[0]!=false || newVal.answerCheckbox[1]!=false || newVal.answerCheckbox[2]!=false || newVal.answerCheckbox[3]!=false){
             window.sessionStorage.setItem("interType",4)
          }else{
            window.sessionStorage.setItem("interType",null)
          }
        }
      },
      deep:true,
    }
  },
  filters: {
    removeDir(value) {
      if(typeof(value) != "string"){
        return value
      }
      let site = value.lastIndexOf("\/");
      return value.substring(site + 1, value.length)
      
      
    },
    hidden(value) {
      if(typeof(value) != "string"){
        return value
      }
      let end = value.lastIndexOf("-")
      if (end > 20) {
        end = 20
      }
      return value.substring(0, end)
    },
    tranNum(value) {
      if(typeof(value) != 1){
        return value
      }
      if (value == 1) {
        return 'A'
      } else if (value == 2) {
        return 'B'
      } else if (value == 3) {
        return 'C'
      } else if (value == 4) {
        return 'D'
      }
    },

  },
  data() {
    return {
      addForm: {
        id: -1,
        inter_type: this.inter_type,
        courseId: this.$route.query.id,
        title: '',
        title_type: "",
        title_thumb: "",
        audio: '',
        sort: 100,
        ex_type: '1',
        answerNum: '',
        answerType: '1',
        answerRadio: '', // 单选选项
        answerRadioText: [],
        answerRadioAudio: [],
        answerRadioImg: [],
        answerCheckbox: [false, false, false, false], //[true,false,false,true]  false,false,false,false]
      },
      title_type_list: global_.course_game_q_w_list,
      answer_select_list: global_.course_game_q_w_answer_type_list_text,
      show: [false, false],
      refresh_answer_type: true,
      num1: '',
      is_edit: 0,
      gameType: 3,
      temp: '',
      selectType: "",
      text_word: 20,
      editTemp:{},
      courseId:this.$route.query.id
    }
  },
  methods: {
    editInit(row) {
      this.is_edit = 1
      this.addForm.title = row.title
      this.addForm.audio = row.audio
      this.addForm.sort = row.sort
      this.addForm.ex_type = row.type.toString()  //题目类型
      this.addForm.answerType = row.option_type.toString() //答案类型
      this.addForm.answerNum = row.num
      this.selectAnswerNum(row.num)
      this.addForm.id = row.id
      this.selectType = row.option_type.toString()
      this.addForm.title_thumb = row.title_thumb
      this.addForm.title_type = parseInt(row.title_type)
       this.selectTitleType(this.addForm.title_type)
      if (this.addForm.title_type == 2) {
        this.text_word = 10
      } else {
        this.text_word = 20
      }

      for (let i = 0; i < row.num; i++) {
        //文本
        if (row.text[i] == '') {
          this.addForm.answerRadioText[i] = ''
        } else {
          if (this.addForm.answerType == 1 || this.addForm.answerType == 3) {
            this.addForm.answerRadioText[i] = row.text[i]
          } else {
            this.addForm.answerRadioText[i] = ''
          }
        }

        if (row.type == 1) {
          if (row.check[i] == 'true') {
            this.addForm.answerRadio = i + 1
          }
        } else {
          if (row.check[i] == 'false') {
            this.addForm.answerCheckbox[i] = false
          } else {
            this.addForm.answerCheckbox[i] = true
          }
        }

        // 音频
        if (row.audioList[i] == '') {
          this.addForm.answerRadioAudio[i] = {}
        } else {
          if (this.addForm.answerType == 1 || this.addForm.answerType == 3) {
            this.addForm.answerRadioAudio[i] = { url: row.audioList[i] }
          } else {
            this.addForm.answerRadioAudio[i] = {}
          }

        }

        // 图片
        if (row.img[i] == '') {
          this.addForm.answerRadioImg[i] = {}
        } else {
          if (this.addForm.answerType == 2 || this.addForm.answerType == 3) {
            this.addForm.answerRadioImg[i] = { url: row.img[i] }
          } else {
            this.addForm.answerRadioImg[i] = {}
          }
        }

      }
      this.addForm.id = row.id
      this.addDialogVisible = true
      this.editTemp = JSON.parse(JSON.stringify(this.addForm))
    },
    selectTitleType(title_type) {
      console.log(this.is_edit)
      if(this.is_edit != 1){
        this.addForm.answerNum = ''
        this.addForm.answerRadio = ''
        this.addForm.ex_type = '1'
        this.num1 = ''
        this.addForm.answerRadioAudio = []
        this.addForm.answerRadioText = []
        this.addForm.answerRadioImg = []
        this.addForm.answerCheckbox = [false, false, false, false]
      }
      if (title_type == 2) {
        this.$nextTick(() => {
          this.answer_select_list = global_.course_game_q_w_answer_type_list_pic
          this.addForm.answerType = '1'
          this.refresh_answer_type = false;
          this.refresh_answer_type = true;
          this.text_word = 10
        })
      } else {
        this.answer_select_list = global_.course_game_q_w_answer_type_list_text
        this.text_word = 20;
      }
      this.is_edit=0
    },

    //------------文件上传------------------
    async uploadFile(uploadInfo, type, index) {
      const { data: token } = await getQiniuToken()
      const that = this
      const observer = {
        next(res) { },
        error(err) { console.log(err) },
        complete(res) {
          if (type == 0) { // 题目语音
            that.addForm.audio = res.key
          } else if (type == 1) { //1.单选音频
            that.$set(that.addForm.answerRadioAudio, index - 1, { url: res.key })
          } else if (type == 2) { // 2.单选图片
            that.$set(that.addForm.answerRadioImg, index - 1, { url: res.key })
          } else if (type == 3) { // 3.题目类型=图文题目时，需要上传题目图片
            that.addForm.title_thumb = res.key
          }
        }
      }

      let name = uploadInfo.file.name
      let fileName = global_.getFileName(name)
      if (parseInt(fileName) != NaN) {
        name = fileName + "-" + global_.randStr(10) + "." + global_.getExt(name)
      }
      const observable = qiniu.upload(uploadInfo.file, "interactcourse/" + name, token, {}, {})
      const subscription = observable.subscribe(observer)
    },
    //----------答案数量---------------
    selectAnswerNum(num) {
      this.num1 = parseInt(this.addForm.answerNum)
      if (this.addForm.ex_type == 1) {
        this.addForm.answerRadio = ''
      }

      let testLen = this.addForm.answerRadioText.length
      if (testLen == 0) {
        this.addForm.answerRadioText = []
        for (let i = 0; i < this.num1; i++) {
          this.addForm.answerRadioText.push('')
        }
      } else {
        if (num > testLen) {
          for (let i = 0; i < num - testLen; i++) {
            this.addForm.answerRadioText.push('')
          }
        }
      }

      let audioLen = this.addForm.answerRadioAudio.length
      if (testLen == 0) {
        this.addForm.answerRadioAudio = []
        for (let i = 0; i < this.num1; i++) {
          this.addForm.answerRadioAudio.push({})
        }
      } else {
        if (num > audioLen) {
          for (let i = 0; i < num - testLen; i++) {
            this.addForm.answerRadioAudio.push({})
          }
        }
      }


      let imgLen = this.addForm.answerRadioImg.length
      if (imgLen == 0) {
        this.addForm.answerRadioImg = []
        for (let i = 0; i < this.num1; i++) {
          this.addForm.answerRadioImg.push({})
        }
      } else {
        if (num > imgLen) {
          for (let i = 0; i < num - imgLen; i++) {
            this.addForm.answerRadioImg.push({})
          }
        }
      }
    },
    selectAnswerType(type) {
      console.log("type:", type)
      if (this.temp == '') {
        this.temp = this.addForm.answerRadio
        this.addForm.answerRadio = ''
      } else {
        this.addForm.answerRadio = this.temp
        this.temp = ''
      }
      this.addForm.answerRadioText = []
      this.addForm.answerRadioAudio = []
      this.addForm.answerRadioImg = []
      for (let i = 0; i < this.addForm.answerNum; i++) {
        this.addForm.answerRadioAudio.push({})
        this.addForm.answerRadioText.push('')
        this.addForm.answerRadioImg.push({})
      }
      this.addForm.answerRadio = ""
      this.addForm.answerCheckbox = [false, false, false, false]
      this.selectType = type
    },
    clear() {
      this.addForm.title = ''
      this.addForm.audio = ''
      this.addForm.sort = 100
      this.num1 = ''
      this.addForm.ex_type = '1'
      this.addForm.answerRadio = ''
      this.addForm.answerRadioAudio = []
      this.addForm.answerRadioText = []
      this.addForm.answerRadioImg = []
      this.addForm.answerCheckbox = [false, false, false, false]
      this.addForm.answerNum = ''
      this.addForm.id = -1
      this.is_edit = 0
    },
    //-------------------------
    async addSubmit() {
      if (this.checkForm() != 1) {
        return
      }
      this.addForm.courseId = this.courseId
      this.addForm.game_type = this.gameType
      // 答案数量切换  (大 减小  清除多余的)
      if (this.addForm.answerNum < this.addForm.answerRadioImg.length || this.addForm.answerNum < this.addForm.answerRadioText.length) {
        while (this.addForm.answerRadioImg.length - this.addForm.answerNum) {
          this.addForm.answerRadioImg.pop()
          this.addForm.answerRadioText.pop()
          this.addForm.answerRadioAudio.pop()
          this.addForm.answerCheckbox.pop()
        }
      }
      const { data: res } = await contentAdd(this.addForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.$emit("updateList", false)
      this.clear()
      window.sessionStorage.setItem("interType",null)
      window.sessionStorage.setItem("oldInterType",null)
      window.sessionStorage.setItem("oldEditObj",null)
    },
    checkForm() {
      if (this.addForm.title_type == "") {
        return this.$message.error({ 'message': '请选择(互动类型下)题目类型', duration: 1000 })
      }
      if (this.addForm.title == '') {
        return this.$message.error({ 'message': '请输入题目', duration: 1000 })
      }
      if (this.addForm.title_type == "") {
        return this.$message.error({ 'message': '请选择(互动类型下)题目类型', duration: 1000 })
      }
      if (this.addForm.audio == "") {
        return this.$message.error({ 'message': '请上传题目语音', duration: 1000 })
      }
      if (this.addForm.sort == "") {
        return this.$message.error({ 'message': '题目排序值不能为空', duration: 1000 })
      }
      if (this.addForm.answerNum == "") {
        return this.$message.error({ 'message': '答案数量必选', duration: 1000 })
      }
      if (this.addForm.answerType == "") {
        return this.$message.error({ 'message': '答案类型必选', duration: 1000 })
      }

      if (this.addForm.ex_type == 1) {
        if (this.addForm.answerRadio == "") {
          return this.$message.error({ 'message': '请勾选正确答案', duration: 1000 })
        }
      }

      if (this.addForm.ex_type == 2) {
        if (this.addForm.answerCheckbox.length < 1) {
          return this.$message.error({ 'message': '请勾选正确答案!!', duration: 1000 })
        }
      }

      if (this.addForm.answerType == 1) { //文本
        for (let i = 0; i < this.num1; i++) {
          // 答案文本
          if (this.addForm.answerRadioText[i] == "") {
            return this.$message.error({ 'message': '请填写答案选项内容', duration: 1000 })
          }

          // 音频
          if (!this.addForm.answerRadioAudio[i].url) {
            return this.$message.error({ 'message': '请上传音频', duration: 1000 })
          } else {
            if (this.addForm.answerRadioAudio[i].url == "") {
              return this.$message.error({ 'message': '请上传音频', duration: 1000 })
            }
          }
        }
      } else if (this.addForm.answerType == 2) {   // 图片
        for (let i = 0; i < this.num1; i++) {
          if (this.addForm.answerRadioImg[i].url == undefined || this.addForm.answerRadioImg[i].url == "") {
            return this.$message.error({ 'message': '请上传答案图片', duration: 1000 })
          }
        }
      } else if (this.addForm.answerType == 3) {
        for (let i = 0; i < this.num1; i++) {
          // 答案文本
          if (this.addForm.answerRadioText[i] == "") {
            return this.$message.error({ 'message': '请填写答案选项内容', duration: 1000 })
          }

          // 音频
          if (!this.addForm.answerRadioAudio[i].url) {
            return this.$message.error({ 'message': '请上传音频', duration: 1000 })
          } else {
            if (this.addForm.answerRadioAudio[i].url == "") {
              return this.$message.error({ 'message': '请上传音频', duration: 1000 })
            }
          }

          // 图片
          if (this.addForm.answerRadioImg[i].url == undefined || this.addForm.answerRadioImg[i].url == "") {
            return this.$message.error({ 'message': '请上传答案图片', duration: 1000 })
          }
        }
      }
      return 1
    },
    dialogHidden() {
      this.clear()
      this.$emit("dialogHidden")
    }
  }
}
</script>


<style scoped>
.footer-div {
  position: relative;
  height: 40px;
}

.dialog-footer {
  position: absolute;
  right: 5px;
  bottom: 10px;
}
</style>