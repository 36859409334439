import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'normalize.css/normalize.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/icon.css'
// import locale from 'element-ui/lib/locale/lang/en'
import './assets/fonts/iconfont.css'

import '@/styles/index.scss'

import '@/icons'
import '@/permission'
import checkPermission from '@/mixin/checkPermission'
import axios from 'axios'
import "./filters" // 过滤器
// 导入excel
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)


import VueViewer from 'v-viewer'
Vue.use(VueViewer)

Vue.prototype.baseURL = process.env.VUE_APP_BASE_API
// 文件上传指向服务器域名
Vue.prototype.uploadUrl = process.env.VUE_APP_UPLOAD 
Vue.prototype.uploadApi =  "/admin/upload/upload"
//七牛云 域名
Vue.prototype.imgDomain = process.env.VUE_APP_QINIU
Vue.prototype.imgLimit = process.env.VUE_APP_IMG_LIMIT

Vue.prototype.checkUpload = (msg)=> {
  if(msg == "Expired token"){
    window.sessionStorage.clear()
    location.href='/login'
  }
}

import FullCalendar from 'vue-fullcalendar'
Vue.use(FullCalendar)



// element-ui
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.mixin(checkPermission)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
