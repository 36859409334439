<template>
    <el-dialog title="修改账号" :close-on-click-modal="false" :visible.sync="schoolAccountUpdateDialogVisible" width="40%"
        @close="closeBindSchoolUpdateDialog">
        <el-form ref="addFormRef" label-width="100px">
            <el-form-item label="后台账号:">
                <el-input v-model="addForm.admin_name"></el-input>
            </el-form-item>
            <el-form-item label="密码:">
                <el-input v-model="addForm.admin_password"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeBindSchoolUpdateDialog">取消</el-button>
            <el-button type="primary" @click="addSubmit()">确认</el-button>
        </span>
    </el-dialog>
</template>


<script>
import global_ from '@/utils/global.js'
import { updateBySchoolAccount } from '@/api/schoolAccount'
export default {
    data() {
        return {
            schoolAccountUpdateDialogVisible: false,
            addForm: {
                admin_name: '',
                admin_password: '',
                admin_id: ''
            },
        }
    },
    created() {

    },
    methods: {

        async addSubmit() {
            try {
                //await this.validateFormSubmit();
                await this.callSchoolAccountCreate();
            } catch (error) {
                console.log(error)
                let error_result = JSON.parse(error.message);
                if (error_result.code == 1000) this.$message.error(error_result.msg)
            }
        },
        closeBindSchoolUpdateDialog() {
            this.schoolAccountUpdateDialogVisible = false;
            this.clearData();
        },
        async validateFormSubmit() {
            if (global_.isEmpty(this.addForm.admin_name)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请输入后台账号' }))
            }
            if (global_.isEmpty(this.addForm.admin_password)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请输入密码' }))
            }
        },
        clearData() {
            this.addForm.admin_name = "";
            this.addForm.admin_password = "";
            this.addForm.admin_id = ""
        },
        async callSchoolAccountCreate() {
            const { data: res } = await updateBySchoolAccount(this.addForm)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.closeBindSchoolUpdateDialog();
            this.$emit("callSchoolAccountList")
        }
    }
}
</script>