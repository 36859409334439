import request from '@/utils/request'

//----------------------------------弹框

export function list(data) {
  return request({
    url: '/admin/card/list',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: '/admin/card/edit',
    method: 'post',
    data
  })
}

export function changeStatus(data) {
  return request({
    url: '/admin/card/changeStatus',
    method: 'post',
    data
  })
}