<template>
  <div>
      <el-form-item label="题目:" prop="title">
        <el-input v-model="addForm.title" placeholder="* 题目字数最多45个字" style="margin-left:28px;width:80%" maxlength="45"></el-input>
      </el-form-item>
      <el-form-item label="题目语音:">
        <el-upload class="avatar-uploader" action="#" :show-file-list="false"
          :http-request="(file) => { uploadFile(file, 0, 0) }">
          <div v-if="addForm.audio" style="width:178px;height:35px">{{ addForm.audio | removeDir | hidden }}</div>
          <div v-else style="width:178px;height:35px">上传</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="内容数量:">
        <el-select v-model="addForm.contentNum" placeholder="请选择" @change="selectContentNum(addForm.contentNum)">
          <el-option v-for="(item,index) in content_num_arr" :key="index" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容类型:">
        <el-select  v-model="addForm.contentType" placeholder="请选择" @change="selectContentType(addForm.contentType)">
          <el-option v-for="item in content_type" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div style="color:red">*内容文字限制为10个字，图片尺寸比例为:</div>
        <div>
          <!--文字-->
          <div v-if="addForm.contentType == 1  ">
            <div v-for="index of addForm.contentNum" :key="index">
              <el-row>
                <el-col>内容{{index}}</el-col>  
                <el-col :span="10">
                  <el-input v-model="addForm.contentText[index - 1]" type="text" style="width:96%"  maxlength="10"></el-input>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-upload class="avatar-uploader" action="#" :show-file-list="false"
                      :http-request="(file) => { uploadFile(file, 1, index) }">
                      <div v-if="addForm.contentAudio[index - 1]">
                        <div v-if="addForm.contentAudio[index - 1].url" style="width:178px;height:35px">{{
                          addForm.contentAudio[index - 1].url | removeDir | hidden
                        }}</div>
                        <div v-else style="width:178px;height:35px">上传</div>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </div> 
          </div>
          <!--图片-->
          <div v-else-if="addForm.contentType == 2" style="float:left">
            <div v-for="index of addForm.contentNum" :key="index" style="float:left;width:200px;">
              <el-row >
                <el-col>内容{{index}}</el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-upload
                      class="avatar-uploader"
                      action="#"
                      :show-file-list="false"
                      style="width:130px;height:130px"
                      :http-request="(file)=> {uploadFile(file,2,index)}"              
                    >
                    <div v-if="addForm.contentImg[index-1]">
                      <img
                        v-if="addForm.contentImg[index - 1].url"
                        :src="imgDomain + addForm.contentImg[index - 1].url"
                        class="avatar"
                        style="width:130px;height:130px"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon" style="width:130px;height:130px"></i>
                    </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <!--图文结合-->
          <div v-else-if="addForm.contentType == 3  ">
            <div v-for="index of addForm.contentNum" :key="index">
              <el-row>
                <el-col>内容{{index}}</el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-upload
                      class="avatar-uploader"
                      action="#"
                      :show-file-list="false"
                      style="width:130px;height:130px"
                      :http-request="(file)=> {uploadFile(file,2,index)}"              
                    >
                    <div v-if="addForm.contentImg[index-1]">
                      <img
                        v-if="addForm.contentImg[index - 1].url"
                        :src="imgDomain + addForm.contentImg[index - 1].url"
                        class="avatar"
                        style="width:130px;height:130px"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon" style="width:130px;height:130px"></i>
                    </div>
                    </el-upload>
                  </el-form-item>
                </el-col>  
                <el-col :span="10">
                  <el-input v-model="addForm.contentText[index - 1]" type="text" style="width:96%"  maxlength="10"></el-input>
                </el-col>
                <el-col :span="5">
                  <el-form-item>
                    <el-upload class="avatar-uploader" action="#" :show-file-list="false"
                      :http-request="(file) => { uploadFile(file, 1, index) }">
                      <div v-if="addForm.contentAudio[index - 1]">
                        <div v-if="addForm.contentAudio[index - 1].url" style="width:178px;height:35px">{{
                          addForm.contentAudio[index - 1].url | removeDir | hidden
                        }}</div>
                        <div v-else style="width:178px;height:35px">上传</div>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </div> 
          </div>
        </div>
      </el-form-item>
      <div style="footer-div" >
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogHidden">取消</el-button>
          <el-button type="primary" @click="addSubmit">确定</el-button>
        </span>
      </div>
  </div>
</template>


<script>
import global from '@/utils/global.js'
import { getQiniuToken } from '@/api/upload'
import * as qiniu from 'qiniu-js'
import { contentAdd } from '@/api/course'

export default {  
  props:{
    inter_type:{},
    courseId:{},
    game_type:{},
    editObj:{}
  },
  created(){
    if(this.editObj.is_edit == 1){
      this.editInit()
      this.editTemp = this.editObj
    }else{
      this.selectContentNum(3)
    }
  },
   watch:{
    addForm:{
      handler(newVal,oldVal){
        if(this.editObj.is_edit == 1){
          let flag = 0
          if(this.editTemp.title!=newVal.title){
            flag = 1
          }
          if(this.editTemp.audio != newVal.audio){ 
            flag = 1
          }
          if(this.editTemp.num!=newVal.contentNum){ // 内容数量
            flag = 1
          }
          if(this.editTemp.option_type!=newVal.contentType){
            flag = 1
          }
          for(let i=0;i<this.editTemp.num;i++){
            if(this.editTemp.option_type == 1){
              if( this.editTemp.contentText[i] != newVal.contentText[i] ){
                flag = 1
                break
              }
              if( this.editTemp.contentAudio[i] != newVal.contentAudio[i] ){
                flag = 1
                break
              }
            }else if(this.editTemp.option_type == 2){
              if( this.editTemp.contentImg[i] != newVal.contentImg[i] ){
                flag = 1
                break
              }
            }else if(this.editTemp.option_type == 3){
              if( this.editTemp.contentText[i] != newVal.contentText[i] || this.editTemp.contentImg[i] != newVal.contentImg[i] || this.editTemp.contentAudio[i] != newVal.contentAudio[i] ){
                flag = 1
                break
              }
            }
          } 
          // if(flag == 1){
          //   window.sessionStorage.setItem("interType",1)
          // }else{
          //   window.sessionStorage.setItem("interType",null)
          // }
        }else{
          if(newVal.title!="" || newVal.audio!="" || newVal.contentType!= 1 || newVal.contentNum!=3 || newVal.contentText.length!=3  || newVal.contentImg.length!=3 ||newVal.contentAudio.length!=3){
             window.sessionStorage.setItem("interType",2)
          }else{
            let flag = 0
            for(let i=0;i<newVal.contentNum;i++){
              if(newVal.contentText[i]!=""){
                flag = 1
                break
              }
              if(Object.keys(newVal.contentImg[i]).length!=0){
                flag = 1
                break
              }
            }
            if(flag == 1){
              window.sessionStorage.setItem("interType",1)
            }else{
              window.sessionStorage.setItem("interType",null)
            }
          }
        }
      },
      deep:true,
    }
  },
  data(){
    return {
      addForm: {
        id: -1,
        title: '',
        audio: '',
        inter_type: this.inter_type,
        courseId: this.$route.query.id,
        game_type:3,
        contentNum: 3,
        contentType: 1,
        contentText:[],
        contentImg:[],
        contentAudio:[],
      },
      content_num_arr:[3,4,5,6],
      content_type:[{id:1,name:"文字"},{id:2,name:"图片"},{id:3,name:"图文结合"}],
      content:[],
      editTemp:{},
    }
  },
  filters: {
    
  },
  methods:{
    editInit(){
      // console.log("sort",this.editObj)
      this.addForm.inter_type = parseInt(this.editObj.inter_type)
      this.addForm.title = this.editObj.title
      this.addForm.audio = this.editObj.audio
      this.addForm.contentNum = this.editObj.num?this.editObj.num:this.addForm.contentNum
      this.addForm.contentType = this.editObj.option_type
      for(let i=0;i<this.editObj.num;i++){
        if(this.editObj.option_type == 1){
          this.addForm.contentText[i] = this.editObj.contentText[i]
          this.addForm.contentAudio[i] = {url:this.editObj.contentAudio[i]} // 2023-5-23
        }else if(this.editObj.option_type == 2){
          this.addForm.contentImg[i] = {url:this.editObj.contentImg[i]}
        } else {
          this.addForm.contentText[i] = this.editObj.contentText[i]
          this.addForm.contentImg[i] = {url:this.editObj.contentImg[i]}
          this.addForm.contentAudio[i] = {url:this.editObj.contentAudio[i]} // 2023-5-26
        }
      }
      this.addForm.id = this.editObj.id
    },
    async uploadFile(uploadInfo, type, index) {
      const { data: token } = await getQiniuToken()
      const that = this
      const observer = {
        next(res) {},
        error(err) {console.log(err)},
        complete(res) {
          if(type == 1){ // 文字-音频
            that.$set(that.addForm.contentAudio, index - 1, { url: res.key })
          }else if(type == 2){
            that.$set(that.addForm.contentImg, index - 1, { url: res.key })
          }else if(type == 0){
            that.addForm.audio = res.key
          }
        }
      }

      let name = uploadInfo.file.name
      let fileName = global.getFileName(name)
      if (parseInt(fileName) != NaN) {
        name = fileName + "-" + global.randStr(20) + "." + global.getExt(name)
      }
      const observable = qiniu.upload(uploadInfo.file, "interactcourse/" + name, token, {}, {})
      const subscription = observable.subscribe(observer)
    },
    // 内容数量
    selectContentNum(num) {
      this.addForm.contentNum = num
      this.addForm.contentCheckbox = []
      this.addForm.contentText = []
      this.addForm.contentImg = []
      this.addForm.contentAudio = [] 
      for(let i=0;i<num;i++){
        this.addForm.contentCheckbox.push(false)
        this.addForm.contentText.push("")
        this.addForm.contentImg.push({})
        this.addForm.contentAudio.push({})
      }
    },
    // 内容类型
    selectContentType(type) {
      this.addForm.contentType = type
    },
    //----------------
    addDialogClosed() {
      this.clear()
    },
    clear() {
      this.addForm.title = ''
      this.addForm.audio = ''
      this.addForm.contentText = []
      this.addForm.contentImg = []
      this.addForm.contentNum = 3
      this.addForm.contentAudio = []
      this.addForm.id = -1
      this.is_edit = 0
    },
    //-------------------------
    async addSubmit() {
      if (this.checkForm() != 1) {
        return
      }
      const { data: res } = await contentAdd(this.addForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)

      this.$emit("updateList",false)
      this.clear()
      window.sessionStorage.setItem("interType",null)
      window.sessionStorage.setItem("oldInterType",null)
      window.sessionStorage.setItem("oldEditObj",null)
    },
    checkForm() {
      if (this.addForm.title == '') {
        return this.$message.error({ 'message': '请输入题目', duration: 1000 })
      }
      if (this.addForm.audio == "") {
        return this.$message.error({ 'message': '请上传题目语音', duration: 1000 })
      }
     
      if (this.addForm.contentNum == "") {
        return this.$message.error({ 'message': '内容数量必选', duration: 1000 })
      }
      if (this.addForm.contentType == "") {
        return this.$message.error({ 'message': '内容类型必选', duration: 1000 })
      }

      if (this.addForm.contentType == 1) { //文本
        for (let i = 0; i < this.addForm.contentNum; i++) {
          if (this.addForm.contentText[i] == "") {
            return this.$message.error({ 'message': '请填写答案选项内容', duration: 1000 })
          }
          if (this.addForm.contentAudio[i].url == undefined || this.addForm.contentAudio[i].url == "") {  // 2023-5-23
            return this.$message.error({ 'message': '请上传语音', duration: 1000 })
          }
        }
      } else if (this.addForm.contentType == 2) {   // 图片
        for (let i = 0; i < this.addForm.contentNum; i++) {
          if (this.addForm.contentImg[i].url == undefined || this.addForm.contentImg[i].url == "") {
            return this.$message.error({ 'message': '请上传答案图片', duration: 1000 })
          }
        }
      } else if (this.addForm.contentType == 3) {   // 图文集合
        for (let i = 0; i < this.addForm.contentNum; i++) {
          if (this.addForm.contentText[i] == "") {
            return this.$message.error({ 'message': '请填写答案选项内容', duration: 1000 })
          }

          if (this.addForm.contentImg[i].url == undefined || this.addForm.contentImg[i].url == "") {
            return this.$message.error({ 'message': '请上传答案图片', duration: 1000 })
          }
          if (this.addForm.contentAudio[i].url == undefined || this.addForm.contentAudio[i].url == "") {  // 2023-5-26
            return this.$message.error({ 'message': '请上传语音', duration: 1000 })
          }
        }
      }
      return 1
    },
    dialogHidden(){
      this.clear()
      this.$emit("dialogHidden")
    }
  }
}
</script>

<style scoped>
.footer-div{
   position: relative;
   height:40px;
}
.dialog-footer {
  position:absolute;
  right:5px;
  bottom:10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-icon-plus:before {
  line-height: 178px;
}
</style>