<!--购课记录-->
<template>
    <div>
        <el-card>
            <el-row style="margin-top:0px;">
                <el-table :data="list" border stripe :row-style="{ height: '45px' }" :cell-style='cellStyle'
                    :header-cell-style='rowClass'>
                    <el-table-column label="编号" prop="id"></el-table-column>
                    <el-table-column label="头像名称" prop="name"></el-table-column>
                    <el-table-column label="头像图片">
                        <template slot-scope="scope">
                            <el-image :src="scope.row.thumb_url" style="width: 30px;height:30px;"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="获取条件" prop="exchange_range_type_name"></el-table-column>
                    <el-table-column label="兑换所需成长星" prop="exchange_cost"></el-table-column>
                    <el-table-column label="权重" prop="weight"></el-table-column>
                    <el-table-column label="状态" prop="status_name"></el-table-column>
                    <el-table-column label="操作" width="280px">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="changeStatusApi(scope.row)"
                                v-if="scope.row.status == 1">禁用</el-button>
                            <el-button type="text" size="mini" @click="changeStatusApi(scope.row)"
                                v-if="scope.row.status == 2">启用</el-button>

                            <el-button type="text" size="mini"  @click="edit(scope.row)">编辑</el-button>
                            <el-button type="text" size="mini" @click="toExchangeList(scope.row)">兑换列表</el-button>
                            <el-button type="text" size="mini" v-if="scope.row.user_header_count == 0 && scope.row.exchange_range_type != 1" @click="deleteHeader(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页start-->
                <el-pagination style="margin-top:20px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
                <!--分页end-->
            </el-row>
        </el-card>
    </div>
</template>
  
  
<script>
import { getAdminHeaderList, changeStatusHeader, deleteHeader } from '@/api/header'
export default {
    data() {
        return {
            search: {
                pagenum: 1,
                pagesize: 10,
                header_type: 1,
                name: ""
            },
            total: 0,
            list: []
        }
    },
    created() {
    },
    methods: {
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
            this.getHeaderAdminListApi();
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
            this.getHeaderAdminListApi();
        },
        rowClass({ row, rowIndex }) {
            return 'text-align:center'
        },
        cellStyle({ row, column, rowIndex, columnIndex }) {
            return 'text-align:center;padding:0px'
        },
        async getHeaderAdminListApi() {
            const { data: res } = await getAdminHeaderList(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.$nextTick(() => {
                this.list = res.data.data
                this.total = res.data.total
                sessionStorage.removeItem('header_list_pagenum')
                sessionStorage.removeItem('header_list_pagesize')
                sessionStorage.removeItem('header_list_header_type')
                sessionStorage.removeItem('header_list_name')
            })
        },
        async changeStatusApi(row) {
            const { data: res } = await changeStatusHeader({ id: row.id })
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.getHeaderAdminListApi();
        },
        edit(row) {
            this.$emit("editHeader", row)
        },
        deleteHeader(row) {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data: res } = await deleteHeader({ id: row.id })
                if (res.status != 0) {
                    return this.$message.error(res.msg)
                }
                this.getHeaderAdminListApi();
            }).catch(() => {
                
            });
        },
        toExchangeList(row){
            sessionStorage.setItem('header_list_pagenum',this.search.pagenum);
            sessionStorage.setItem('header_list_pagesize',this.search.pagesize);
            sessionStorage.setItem('header_list_header_type',this.search.header_type);
            sessionStorage.setItem('header_list_name',this.search.name);
            this.$emit("goToExchangeList", row.id)
        }
    }
}
</script>
  
<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}

.search {
    float: left;
    width: 300px;
    margin-left: 10px;
}
</style>