<template>
  <div>
    <el-card>
        <el-row style="margin-top:10px">
          <h2>报名人数:{{total}}</h2>
        </el-row>
        <el-row style="margin-top:10px;">
            <el-table :data="list" border stripe>
              <el-table-column label="用户名"  prop="user_name"></el-table-column>
              <el-table-column label="手机号" prop="user_mobile"></el-table-column>
              <el-table-column label="报名时间" prop="created_at"></el-table-column>
            </el-table>
        </el-row>
      </el-card> 
  </div>
</template>


<script>
import {buyDetailList} from '@/api/video'
export default {
  data(){
    return {
      list:[],
      total:0,
      search:{
        short_link_id:this.$route.query.short_link_id,
        video_id:this.$route.query.video_id,
        customer_id:this.$route.query.customer_id
      }
    }
  },
  created(){
    this.getList()
  },
  methods:{

    async getList(){
      const {data:res} = await buyDetailList(this.search)
       if (res.status != 0) {
         return this.$message.error(res.msg)
       }
       this.list = res.data
       this.total = res.data.length
    },

  }
}
</script>