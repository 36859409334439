<template>
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="HeaderDetailDislog" width="40%"
        @close="dislogClose">
        <el-form>
            <el-form-item label="类型" label-width="80px">
                <el-radio label='1' :disabled="header_type_disbale" v-model="addUserForm.header_type">头像</el-radio>
                <el-radio label='2' :disabled="header_type_disbale" v-model="addUserForm.header_type">头像框</el-radio>
            </el-form-item>
            <el-form-item label="名称" label-width="80px">
                <el-input v-model="addUserForm.name"></el-input>
                <p style="color:red;font-size:8px;height:30px;">最多6个字</p>
            </el-form-item>
            <el-form-item label="图片" label-width="80px">
                <el-upload class="avatar-uploader" action="#" :show-file-list="false"
                    :http-request="(file) => { uploadFile(file, 1) }">
                    <img v-if="addUserForm.thumb" :src="imgDomain + addUserForm.thumb" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="成长星" label-width="80px">
                <el-input type="number" :disabled="cost_disable" v-model="addUserForm.exchange_cost"
                    @input="addUserForm.exchange_cost = addUserForm.exchange_cost.replace(/^(0+)|[^\d]+/g, '')"></el-input>
                <p style="color:red;font-size:8px;height:30px;">仅支持输入整数</p>
            </el-form-item>
            <el-form-item label="权重" label-width="80px">
                <el-input v-model="addUserForm.weight"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dislogClose()">取消</el-button>
            <el-button type="primary" @click="submitMedal()">确定</el-button>
        </span>
    </el-dialog>
</template>


<script>
import { addHeader, updateHeader } from '@/api/header'
import global_ from '@/utils/global.js'
import { getQiniuToken } from '@/api/upload'
import * as qiniu from 'qiniu-js'
export default {
    data() {
        return {
            progress: false,
            action: this.uploadUrl + '/admin/upload/upload',
            token: { token: window.sessionStorage.getItem("token") },
            addUserForm: {
                id: 0,
                header_type: '1',
                name: "",
                thumb: "",
                exchange_cost: "",
                weight: '100'
            },
            HeaderDetailDislog: false,
            title: "编辑勋章",
            cost_disable: false,
            header_type_disbale: false,
            exchange_range_type:2
        }
    },
    created() {

    },
    methods: {
        clearinfo() {
            this.addUserForm.id = 0;
            this.addUserForm.name = "";
            this.addUserForm.header_type = "1";
            this.addUserForm.thumb = "";
            this.addUserForm.weight = "100";
            this.addUserForm.exchange_cost = "";
            this.cost_disable = false;
            this.header_type_disbale = false;
        },
        setInfo(row) {
            this.addUserForm.id = row.id;
            this.addUserForm.name = row.name;
            this.addUserForm.thumb = row.thumb;
            this.addUserForm.weight = row.weight;
            this.addUserForm.header_type = row.header_type.toString()
            this.addUserForm.exchange_cost = row.exchange_cost
            if (row.user_header_count > 0 || row.exchange_range_type == 1) {
                this.header_type_disbale = true;
                if (row.exchange_range_type == 1) {
                    this.cost_disable = true;
                }
                this.exchange_range_type = row.exchange_range_type;
            }
        },
        dislogOpen() {
            this.HeaderDetailDislog = true;
        },
        dislogClose() {
            this.clearinfo();
            this.HeaderDetailDislog = false;
        },
        async submitMedal() {
            if (global_.isEmpty(this.addUserForm.name)) {
                this.$message.error("请输入名称")
                return
            } else {
                if (global_.getStringLength(this.addUserForm.name) > 6) {
                    this.$message.error("名称最多6个字")
                    return
                }
            }
            if (this.addUserForm.weight == "" || this.addUserForm.weight == undefined) {
                this.$message.error("请输入权重")
                return
            }
            if (this.addUserForm.thumb == "" || this.addUserForm.thumb == undefined) {
                this.$message.error("请上传图片")
                return
            }
            if(this.addUserForm.exchange_cost == 0 && this.exchange_range_type == 2){
                this.$message.error('请输入成长星数量');
                return
            }
            if (this.addUserForm.id > 0) {
                const { data: res } = await updateHeader(this.addUserForm)
                if (res.status != 0) {
                    return this.$message.error(res.msg)
                }
            } else {
                const { data: res } = await addHeader(this.addUserForm)
                if (res.status != 0) {
                    return this.$message.error(res.msg)
                }
            }
            this.dislogClose();
            this.clearinfo();
            this.$emit("getHeaderList")
        },
        img_limit(val) {
            let type = val.file.type
            let size = val.file.size
            let type_arr = type.split("/")
            let limit = 1   // 1M
            if (type_arr[0] == "image") {
                if (size > 1024 * 1024 * limit) {
                    return -1
                }
            }
            return 1
        },
        async uploadFile(uploadInfo, index) {
            if (this.img_limit(uploadInfo) == -1) {
                return this.$message.error("上传图片请小于1M")
            }
            const { data: token } = await getQiniuToken()
            const that = this
            const observer = {
                next(res) {
                    console.log('next:', res)
                },
                error(err) {
                    console.log(err)
                },
                complete(res) {
                    console.log('complete:....')
                    that.addUserForm.thumb = res.key
                }
            }

            let name = uploadInfo.file.name
            let fileName = global_.getFileName(name)
            name = global_.randStr(15) + "." + global_.getExt(name)

            const observable = qiniu.upload(uploadInfo.file, "header/" + name, token, {}, {})
            const subscription = observable.subscribe(observer)
        },
    }
}
</script>
  
<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}
</style>