<template>
  <div style="float:left;margin-left:10px">
    <el-button type="primary"  @click="add">新增课程</el-button>
  
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <el-form label-width="100px">
        <el-form-item label="*课程标题">
          <el-input v-model="addForm.title" maxlength="20"></el-input>
        </el-form-item>
        <div style="width:178px;">
          <el-form-item label="*课程封面:">
            <el-upload class="avatar-uploader" 
              action="#" 
              :show-file-list="false" 
              :http-request="(file) => { uploadFile(file, 3, 1) }"
            >
              <img v-if="addForm.coverImg" :src="imgDomain + addForm.coverImg" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="课程头图:">
            <p>(最多5张)</p>
            <div style="float:left">
            <div style="float:left;" v-for="(item, index) of items1" :key="index" draggable="true"
              @dragstart="handleDragStart($event, item)" @dragover.prevent="handleDragOver($event, item)"
              @dragenter="handleDragEnter($event, item)" @dragend="handleDragEnd($event, item)">
              <div class="container">
                <img src="@/assets/common/delete.png" alt="" class="delete" @click="del(index,1)" />
                <el-image :src="imgDomain + item.img" 
                  style="width:178px;height:178px"></el-image>
                <div v-if="item.img" style="text-align:center;font-size:16px;">{{ item.img | removeDir | hidden }}</div>
              </div>
            </div>
            <div style="float:left;margin-top: 17px">
              <el-form-item>
                <el-upload class="avatar-uploader" 
                  action="#" 
                  :show-file-list="false"
                  :http-request="(file) => { uploadFile(file, 2 , 0) }" 
                  :multiple="multiVal"
                >
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </div>
          </div>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="*课程详情图:">
            <div>
              <el-button type="primary" size="mini"  @click="preview()">预览</el-button>
            </div>
            <div style="float:left">
              <div style="float:left;" v-for="(item, index) of items" :key="index" draggable="true"
                @dragstart="handleDragStart($event, item)" @dragover.prevent="handleDragOver($event, item)"
                @dragenter="handleDragEnter($event, item)" @dragend="handleDragEnd($event, item)">
                <div class="container">
                  <img src="@/assets/common/delete.png" alt="" class="delete" @click="del(index,2)" />
                  <el-image :src="imgDomain + item.img" 
                    style="width:178px;height:178px"></el-image>
                  <div v-if="item.img" style="text-align:center;font-size:16px;">{{ item.img | removeDir | hidden }}</div>
                </div>
              </div>
              <div style="float:left;margin-top: 17px">
                <el-form-item>
                  <el-upload class="avatar-uploader" 
                    action="#" 
                    :show-file-list="false"
                    :http-request="(file) => { uploadFile(file, 1, 0) }" 
                    multiple
                  >
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
        </div>
        <el-form-item label="课程原价:">
          <el-input v-model="addForm.origin_price" type="number"></el-input>
          <p>*非必填</p>
        </el-form-item>
        <el-form-item label="*课程售价:">
          <el-radio v-model="addForm.free" label="1">免费</el-radio>
          <el-radio v-model="addForm.free" label="2">自定义价格 <el-input v-model="addForm.sale_price" type="number"></el-input></el-radio>
        </el-form-item>
        <el-form-item label="*课程标签:">
          <template>
            <el-checkbox-group v-model="videoTagArr"  @change="tagChange">
              <el-checkbox :label="item.id" v-for="item in tagList" :key="item.id">{{ item.name }}</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-form-item>
        <el-form-item label="*主要标签:" v-if="show">
          <el-radio-group v-model="addForm.mainTag" size="small">
            <el-radio :label="item.id" v-for="item in mainTagArr" :key="item.id">{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="*所属客服">
          <el-select v-model="addForm.customer_id" placeholder="请选择">
            <el-option v-for="item in kefuList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit()">确定</el-button>
      </span>
    </el-dialog>

    <!--作品展示-->
    <el-dialog
      title="作品"
      :visible.sync="dialogVisible"
      width="50%"
      >
      <el-image v-for="(item,index) of items" :key="index" :src="imgDomain+item.img" ></el-image>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCourseTag } from '@/api/course'
import { videoCourseAdd } from '@/api/video'
import { getTimeCustomerList } from '@/api/customer'
import { getQiniuToken } from '@/api/upload'
import global from '@/utils/global'
import * as qiniu from 'qiniu-js'

export default {
  props:{
    editObj:{},
  },
  data(){
    return {
      title:"新增课程",
      addDialogVisible:false, //dialog
      addForm:{
        title:"",
        coverImg:"",
        headerImg:[],
        detailImg:[],
        videoTag:"",
        mainTag:"",
        customer_id:"",
        origin_price:"",
        sale_price:"",
        free:"1"
      },
      items:[],
      items1:[],
      //--拖动
      ending: null,
      dragging: null,
      gragFlag: 0,
      imgListSort: [],
      //
      tagList:[],
      videoTagArr:[],
      mainTagArr:[],
      show:false,
      kefuList: [],
      multiVal:true,
      limitNum:5,
      dialogVisible:false, // 作品展示
      is_edit:0,
    }
  },
  watch:{
    is_edit(newVal,oldVal){
      if(newVal == 1){
        if(Object.keys(this.editObj).length){
          this.initEdit()
          this.title="编辑课程"
        }
      }else{
        this.title = "新增课程"
      } 
    }
  },
  created(){
    this.getTag()
    this.getKefuList()
  },
  methods:{
    // 初始化
    initEdit(){
      this.addForm.title = this.editObj.video_course_name
      this.addForm.coverImg = this.editObj.video_course_thumb
      this.items = JSON.parse(this.editObj.video_course_detail_thumb)
      this.items1 = JSON.parse(this.editObj.video_course_header_thumb)
      this.videoTagArr = this.editObj.video_course_tag.split(",").map(Number)
      this.mainTagArr = this.tagList.filter((val)=>{
        if(this.videoTagArr.indexOf(val.id)!=-1){
          return val
        }
      })
      this.show = true
      this.addForm.mainTag = this.editObj.video_course_main_tag_id
      this.addForm.origin_price = this.editObj.video_course_origin_price
      this.addForm.free = this.editObj.free.toString()
      if(this.editObj.free == 2){
        this.addForm.sale_price = this.editObj.video_course_price
      }
      this.addForm.customer_id = parseInt(this.editObj.video_course_customer_id)
    },

    // 标签
    async getTag(){
      const { data: res } = await getCourseTag()
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.tagList = res.data
    },

    async getKefuList() {
      const { data: res } = await getTimeCustomerList({ customer_type: 1, status: 1 })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.kefuList = res.data
    },

    add(){
      this.addDialogVisible = true
    },
    addDialogClosed(){
      this.multiVal= true
      this.items=[]
      this.addForm.title = ""
      this.addForm.coverImg = ""
      this.addForm.headerImg = []
      this.addForm.videoTag = ""
      this.addForm.mainTag = ""
      this.addForm.customer_id = ""
      this.addForm.origin_price = ""
      this.addForm.sale_price = ""
      this.items1 = []
      this.mainTagArr = []
      this.videoTagArr = []
      this.show = false
      this.is_edit = 0
      this.addForm.id = ""
    },
    async submit(){
      // todo 验证
      
      this.addForm.detailImg = this.items
      this.addForm.headerImg = this.items1
      this.addForm.videoTag = this.videoTagArr.join(",")
      if(this.is_edit == 1){
        this.addForm.id = this.editObj.id
      }
      const {data:res} = await videoCourseAdd(this.addForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success("成功!");
      this.addDialogVisible = false
      this.$emit('getList')
    },
    tagChange(){
      this.mainTagArr = this.tagList.filter((val)=>{
        if(this.videoTagArr.indexOf(val.id) !=-1 ){
          return val
        }
      })
      if(this.videoTagArr.length>0){
        this.show = true
      }else{
        this.show = false
      }
    },
    preview(){
      this.dialogVisible = true
    },
    //---文件上传------
    img_limit(val) {
      let type = val.file.type
      let size = val.file.size
      let type_arr = type.split("/")
      let limit = 2   // 1M
      if (type_arr[0] == "image") {
        if (size > 1024 * 1024 * limit) {
          return -1
        }
      }
      return 1
    },
    async uploadFile(uploadInfo, type, index) {
      if (this.img_limit(uploadInfo) == -1) {
        return this.$message.error("上传图片请小于2M")
      }
      const { data: token } = await getQiniuToken()
      const that = this
      const observer = {
        next(res) {},
        error(err) {
          console.log(err)
        },
        complete(res) {
          if (type == 1) { // 图片
            if (that.items.length) {
              let max = 0
              let flag = 0
              let index = 0
              if (that.getIndex(res.key) != -1) {
                index = that.getIndex(res.key)
              }
              for (let i in that.items) {
                max = parseInt(i)
                if (!isNaN(parseInt(that.items[i].index)) && !isNaN(index)) {
                  if (parseInt(that.items[i].index) > parseInt(index)) {
                    flag = 1
                    break
                  }
                }
              }

              if (flag == 0) {
                max += 1
              }


              that.items.splice(max, 0, { key: that.key, "img": res.key, "audio": '', index: index })
              that.srcList.splice(max, 0, that.imgDomain + res.key)
              that.key++

            } else {
              that.getIndex(res.key)
              let index = 0
              if (that.getIndex(res.key) != -1) {
                index = that.getIndex(res.key)
              }
              console.log("index", index) 
              that.items.push({ key: that.key, "img": res.key, "audio": '', index: index })
              that.srcList.push(that.imgDomain + res.key)
              that.key++
            }
          }else if(type == 2){
            if(Object.keys(that.items1).length<that.limitNum){
              that.items1.push({ key: that.key, "img": res.key , index: index })
            } else{
              that.$message.error("最多上传5张")
            }
          }else if(type == 3){
            that.addForm.coverImg = res.key
          }

        }
      }

      let name = uploadInfo.file.name
      let fileName = global.getFileName(name)

      if (parseInt(fileName) != NaN) {
        name = fileName + "-" + global.randStr(10) + "." + global.getExt(name)
      }
      if(type == 4){
        this.show = 1
      } 
      const observable = qiniu.upload(uploadInfo.file, "interactcourse/" + name, token, {}, {})
      const subscription = observable.subscribe(observer)
    },
    getIndex(key) {
      let start = key.lastIndexOf("\/")
      let end = key.lastIndexOf("-")
      let str = key.substring(start + 1, end)
      if (parseInt(str) == NaN) {
        return -1
      } else {
        return parseInt(str)
      }
    },
    //---拖动-----
    handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd(e, item) {
      // if (this.ending.key === this.dragging.key) {
      //   return;
      // }
      let newItems = [...this.items];

      const src = newItems.indexOf(this.dragging);
      const dst = newItems.indexOf(this.ending);

      if (src > dst) {
        newItems.splice(dst, 0, newItems[src])
        newItems.splice(src + 1, 1)
      } else {
        item = newItems[src]
        newItems.splice(src, 1)
        newItems.splice(dst, 0, item)
      }


      // newItems.splice(src, 1, ...newItems.splice(dst, 1, newItems[src]));

      this.items = newItems;
      console.log("this.items",this.items)
      this.$nextTick(() => {
        this.dragging = null;
        this.ending = null;
      });
    },
    handleDragOver(e) {
      // 首先把div变成可以放置的元素，即重写dragenter/dragover
      // 在dragenter中针对放置目标来设置
      e.dataTransfer.dropEffect = "move";
    },
    handleDragEnter(e, item) {
      // 为需要移动的元素设置dragstart事件
      e.dataTransfer.effectAllowed = "move";
      this.ending = item;
    },
    del(index,type) {
      if(type == 1){
        this.items1.splice(index, 1)
      }else{
        this.items.splice(index, 1)
      }
    },
  }
}
</script>

<style scoped>
.clear {
  clear: both;
}
.container {
  margin:3px;
  position: relative;
}

.imgContent {
  width: 178px;
  height: 178px;
  border: 1px solid black;
}

.delete {
  width: 28px;
  height: 28px;
  color: red;
  position: absolute;
  right: 2px;
  z-index: 100;
}

.img {
  width: 178px;
  height: 178px;
}

</style>