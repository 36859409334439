<!-- 购买信息 -->
<template>
  <div>
    <el-card>
      <el-row>
        <!--日期start-->
        <div style="float:left;height:40px;line-height:40px;">购买时间: </div>
        <div style="float:left;margin-left:20px;">
          <div class="block">
            <el-date-picker v-model="search.buyTime" value-format="yyyy-MM-dd HH:mm:ss" type="daterange"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <!--日期end-->
        <div style="float:left;height:40px;line-height:40px;margin-left:20px;">开课时间: </div>
        <div style="float:left;margin-left:20px;">
          <div class="block">
            <el-date-picker v-model="search.startTime" value-format="yyyy-MM-dd HH:mm:ss" type="daterange"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <div  style="margin-left:10px">
          <el-button type="primary" icon="el-icon-search" @click="btnClick()">搜索</el-button>
          <el-button type="warning" icon="el-icon-refresh" @click="reset()">重置</el-button>
        </div>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-button type="success" style="width:120px;" @click="excelExport">导出统计数据</el-button>
        <el-button type="primary" style="width:120px;" @click="excelExport2">导出渠道数据</el-button>
        <el-button  style="width:100px;float:right;margin-right:10px" @click="back()">返回</el-button>
      </el-row>
      <el-row style="margin:8px 0;">
        <h2>课程包名称:{{course_bags_name}}</h2>  
      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="list" border stripe :row-style="{ height: '50px' }" :cell-style='cellStyle'
          :header-cell-style='rowClass'>
          <el-table-column label="用户id" prop="app_user_id" ></el-table-column>
          <el-table-column label="用户昵称" prop="user_name"></el-table-column>
          <el-table-column label="手机号" prop="user_mobile"></el-table-column>
          <el-table-column label="购买渠道参数" prop="source"></el-table-column>
          <el-table-column label="购买渠道课程包id" prop="chan_id"></el-table-column>
          <el-table-column label="购买渠道课程包名称" prop="chan_name"></el-table-column>
          <el-table-column label="是否退款" prop="refund_str"></el-table-column>
          <el-table-column label="购买时间" prop="buy_time"></el-table-column>
          <el-table-column label="开课时间" prop="start_time"></el-table-column>
          <el-table-column label="结课时间" prop="end_time"></el-table-column>
          <el-table-column label="参与课程数" prop="complete"></el-table-column>
          <el-table-column label="是否添加阅读指导老师" prop="add_customer_str"></el-table-column>
          <el-table-column label="指导老师名称" prop="customer_name"></el-table-column>
          <el-table-column label="添加指导老师时间" prop="add_customer_time"></el-table-column>
        </el-table>
      </el-row>
    </el-card>

    <!--分页start-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="search.pagenum"
        :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    <!--分页end-->

  </div>
</template>


<script>
import { buyList } from '@/api/channel'
import global_ from '@/utils/global';
export default {
  data() {
    return {
      // id: this.$route.query.id,  // 渠道课包id 
      course_bags_name: this.$route.query.course_bags_name, //渠道课包名称
      // relation_course_bags_id: this.$route.query.relation_course_bags_id, //关联课包id
      list: [],
      total: 0,
      search:{
        pagenum:1,
        pagesize:10,
        id:this.$route.query.id,
        course_bags_name: this.$route.query.course_bags_name,
        relation_course_bags_id: this.$route.query.relation_course_bags_id,
        buyTime:"",
        startTime:""
      },
      title:"",  
    }
  },
  created() {
    this.getList()
  },
  watch: {
  },
  methods: {
    //-----------------------------tabel 文字居中-------------------------------
    rowClass({ row, rowIndex }) {
      return 'text-align:center'
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;padding:0px'
    },
    //-------------------------------渠道列表-----------------------------------
    async getList() {
      const { data: res } = await buyList(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.list = res.data.data
      this.total = res.data.total
    },
    //-----------------------------导出--------------------------
    excelExport() {
      const headers = {
        "用户id":"app_user_id" ,
        "用户昵称":"user_name",
        "手机号":"user_mobile",
        "购买渠道参数":"source",
        "购买渠道课程包id":"chan_id",
        "购买渠道课程包名称":"chan_name",
        "是否退款":"refund_str",
        "购买时间":"buy_time",
        "开课时间":"start_time",
        "结课时间":"end_time",
        "参与课程数":"complete",
        "是否添加阅读指导老师":"add_customer_str",
        "指导老师名称":"customer_name",
        "添加指导老师时间":"add_customer_time"
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await buyList(this.search)
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '统计数据',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    excelExport2() {
      const headers = {
        "用户id":"app_user_id" ,
        "用户昵称":"user_name",
        "手机号":"user_mobile",
        "购买渠道参数":"source",
        "购买渠道课程包id":"chan_id",
        "购买渠道课程包名称":"chan_name",
        "是否退款":"refund_str",
        "购买时间":"buy_time",
        "开课时间":"start_time",
        "结课时间":"end_time",
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await buyList(this.search)
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '统计渠道数据',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },
    btnClick(){
      this.search.pagenum = 1
      this.search.pagesize = 10
      this.getList()
    },
    reset(){
      this.search.buyTime="",
      this.search.startTime=""
      this.search.pagenum = 1
      this.search.pagesize = 10
      this.getList()
    },
    // -------------------------分页-------------------------------------------
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },
    back(){
      this.$router.push({ path: '/admin.channel.bindCourseBag', query: { pagenum:this.$route.query.pagenum, pagesize:this.$route.query.pagesize ,channelId: this.$route.query.channelId} })
    }
  }
}
</script>