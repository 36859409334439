<!--购课记录-->
<template>
    <div>
        <el-row style="margin-top:10px;margin-bottom: 10px;">
            <el-button type="primary" size="medium" @click="excelExport">导出</el-button>
        </el-row>
        <el-card>
            <el-row style="margin-top:0px;">
                <el-table :data="list" border stripe :row-style="{ height: '45px' }" :cell-style='cellStyle'
                    :header-cell-style='rowClass'>
                    <el-table-column label="编号" prop="id"></el-table-column>
                    <el-table-column label="用户名" prop="nickname"></el-table-column>
                    <el-table-column label="手机号" prop="mobile_phone"></el-table-column>
                    <el-table-column label="兑换时间" prop="created_at"></el-table-column>
                </el-table>
                <!--分页start-->
                <el-pagination style="margin-top:20px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
                <!--分页end-->
            </el-row>
        </el-card>
    </div>
</template>
  
  
<script>
import { getAdminHeaderExchangeList } from '@/api/header'
export default {
    data() {
        return {
            search: {
                pagenum: 1,
                pagesize: 10,
                header_id: this.$route.query.header_id,
            },
            total: 0,
            list: []
        }
    },
    created() {
        this.getHeaderAdminExchangeListApi();
    },
    methods: {
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
            this.getHeaderAdminExchangeListApi();
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
            this.getHeaderAdminExchangeListApi();
        },
        rowClass({ row, rowIndex }) {
            return 'text-align:center'
        },
        cellStyle({ row, column, rowIndex, columnIndex }) {
            return 'text-align:center;padding:0px'
        },
        async getHeaderAdminExchangeListApi() {
            const { data: res } = await getAdminHeaderExchangeList(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.$nextTick(() => {
                this.list = res.data.data
                this.total = res.data.total
            })
        },
        excelExport() {
            let headers = {}
            headers = {
                '编号': 'id',
                '用户名': 'nickname',
                '手机号': 'mobile_phone',
                '兑换时间': 'created_at',
            }
            let query = {}
            query.header_id = this.search.header_id;
            query.pagesize = 1000000000;
            import('@/vendor/Export2Excel').then(async excel => {
                const { data: res } = await getAdminHeaderExchangeList(query)
                const data = this.formatJson(headers, res.data.data)
                excel.export_json_to_excel({
                    header: Object.keys(headers),
                    data,
                    filename: '兑换列表',
                    autoWidth: true,
                    bookType: 'xlsx'
                })
            })
        },
        formatJson(headers, rows) {
            return rows.map(item => {
                return Object.keys(headers).map(key => {
                    return item[headers[key]]
                })
            })
        },
    }
}
</script>
  
<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}

.search {
    float: left;
    width: 300px;
    margin-left: 10px;
}
</style>