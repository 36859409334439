<template>
    <el-dialog title="退款服务" :close-on-click-modal="false" :visible.sync="addRefundDialogVisible" width="40%"
        @close="closeOrderRefundDialog">
        <div style="margin-bottom:20px;margin-left:10px">
            <h4 style="color:red">*退款或退课后，用户购买音频合集将无法再收听，且无法恢复，请确认后再进行退款操作</h4>
        </div>
        <el-form ref="addFormRef" label-width="100px">
            <el-form-item label="理由/备注:">
                <el-input type="textarea" v-model="refund_form.reason" rows="10"></el-input>
            </el-form-item>
            <el-form-item label="*操作密码:">
                <el-input v-model="refund_form.refund_pass" type="password"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeOrderRefundDialog">取消</el-button>
            <el-button type="primary" @click="addSubmit()">确认退款</el-button>
        </span>
    </el-dialog>
</template>


<script>
import global_ from '@/utils/global.js'
import { refundApi } from '@/api/courseOrder'
export default {
    data() {
        return {
            addRefundDialogVisible: false,
            refund_form: {
                order_id: "",
                content_type: 4,
                reason: "",
                refund_pass: "",
                refund_price: ""
            },
        }
    },
    created() {
    },
    methods: {
        async getRefundApi() {
            const { data: res } = await refundApi(this.refund_form)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
            this.$emit('callOrderListByRefundSubmit');
        },
        async addSubmit() {
            try {
                await this.validateOrderRefundSubmit();
                await this.getRefundApi();
            } catch (error) {
                console.log(error)
                let error_result = JSON.parse(error.message);
                if (error_result.code == 1000) this.$message.error(error_result.msg)
            }
        },
        closeOrderRefundDialog() {
            this.addRefundDialogVisible = false;
            this.clearData();
        },
        validateOrderRefundSubmit() {
            if (global_.isEmpty(this.refund_form.reason)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请输入理由/备注' }))
            }
            if (global_.isEmpty(this.refund_form.refund_pass)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请输入退款密码' }))
            }
        },
        clearData(){
            this.refund_form.order_id = "";
            this.refund_form.content_type = 4;
            this.refund_form.reason = "";
            this.refund_form.refund_pass = "";
            this.refund_form.refund_price = "";
        }
    }
}
</script>