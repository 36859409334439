import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/Login.vue'
import Home from '@/views/home/Home.vue'
import Welcome from '@/views/home/Welcome.vue'
import Test from '@/views/test/TestUpload.vue'
// import Layout from '@/layout/index.vue'
import User from '@/views/user/User.vue'
import Auth from '@/views/auth/Auth.vue'
import Role from '@/views/role/Role.vue'
import Course from '@/views/course/Course.vue'
import CourseContent from '@/views/course/CourseContent.vue'
import CourseContentV2 from '@/views/course/CourseContentV2.vue'
import CourseCate from '@/views/course/CourseCate.vue'
import BagCate from '@/views/coursePackage/BagCate.vue'
import MixCP from '@/views/coursePackage/MixCP.vue'
import RelatedC from '@/views/coursePackage/RelatedCourse.vue'
import ThemeManage from '@/views/coursePackage/ThemeManage.vue'
import BuyUrl from '@/views/coursePackage/CreateUrl.vue'
import Schooltt from '@/views/coursePackage/SchoolTimeTable.vue'
import UserSignUp from '@/views/coursePackage/UserSignUp.vue'
import ClassRecord from '@/views/coursePackage/ClassRecords.vue'
import COrder from '@/views/coursePackage/CourseOrder.vue'
import CUser from '@/views/courseUser/User.vue'
import BuyRecords from '@/views/courseUser/BuyRecords.vue'
import LearnRecord from '@/views/courseUser/LearnRecords.vue'
//
import Banner from '@/views/market/Banner.vue'
import Alert from '@/views/market/Alert.vue'
import Advert from '@/views/market/IndexAdvert.vue'

import Calendar from '@/views/system/calendar/Calendar.vue'
import Message from '@/views/system/message/Message.vue'
import Device from '@/views/system/device.vue'
import MessageRecords from '@/views/system/message/MessageRecords.vue'
import CustomSer from '@/views/system/CustomSer.vue'
import Static from '@/views/static/CourseBagStatic.vue'
import StaticDaily from '@/views/static/Daily.vue'
import Upload from '@/views/upload/Upload.vue'  // test js 文件上传
import ExplainDocument from '@/views/market/ExplainDocument.vue'
import ImportUserList from '@/views/coursePackage/importUserList.vue'
import UserDetail from '@/views/courseUser/UserDetail.vue'
import Medal from '@/views/medal/Medal.vue'
//视频课程
import Video from '@/views/videoCourse/Video.vue'
import VideoList from '@/views/videoCourse/VideoList.vue'
import VideoBuyLink from '@/views/videoCourse/BuyLink.vue'
import VideoDetail from '@/views/videoCourse/Detail.vue'
import UrlDetail from '@/views/coursePackage/UrlDetail.vue'
import BagTimeStatist from '@/views/coursePackage/BagTimeStatist.vue'
import BagTimeClassStatist from '@/views/coursePackage/BagTimeClassStatist.vue';
// v6兑换码
import cdKey from '@/views/courseCdKey/list.vue'
import cdKeyList from '@/views/courseCdKey/redeemList.vue'
// 课程卡片
import card from '@/views/market/Card.vue'
// 渠道分销管理
import chan from '@/views/channel/distribution.vue'
import chanBindCourse from '@/views/channel/bindCourseBag.vue'
import chanBuyInfo from '@/views/channel/buyInfo.vue'
//头像/头像管理
import header_lists from '@/views/header/lists.vue'
import header_infos from '@/views/header/info.vue'
import header_view from '@/views/header/header.vue'
import header_exchange_list from '@/views/header/exchange_list.vue'
// 音频管理
import audioList from '@/views/audio/List.vue'
import audioCollection from '@/views/audio/Collection.vue'
import audioBuyList from '@/views/audio/BuyList.vue'
import collectionRelationAudio from '@/views/audio/CollectionRelationAudio.vue'
import audioRecords from '@/views/audio/audioRecords.vue'
import audioGroupRecords from '@/views/audio/audioGroupRecords.vue'

//课包绑定学校
import courseBagsBindSchoolList from '@/views/courseBagsBindSchool/bindSchoolList.vue'
import courseBagsBindSchoolAdd from '@/views/courseBagsBindSchool/bindSchoolAdd.vue'

// 教师管理
import teacherList from '@/views/schoolTeacher/teacherList.vue'


//学校账号管理
import schoolAccountList from '@/views/schoolAccount/schoolAccountList.vue'
import schoolList from '@/views/school/list.vue'
import schoolBindingList from '@/views/school/schoolBindingList.vue'
//课程管理（总账号）
import schoolCourseBagsList from '@/views/schoolCourseBags/allList.vue'
import schoolCourseBags from '@/views/schoolCourseBags/courseBags.vue'
import schoolUserList from '@/views/schoolCourseBags/schoolUserList.vue'
//课程管理（老师账号）
import childrenList from '@/views/schoolCourseBags/childrenList.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    // redirect: '/admin.user',
    children: [
      //-------系统
      { path: '/welcome', component: Welcome },
      { path: '/admin.user',redirect: '/admin.user.index'}, // 用户管理
      { path: '/admin.role',redirect: '/admin.role.index'}, // 角色管理
      { path: '/admin.auth',redirect: '/admin.auth.index'}, // 角色管理
      { path: '/admin.user.index', component: User },
      { path: '/admin.auth.index', component: Auth },
      { path: '/admin.role.index', component: Role },

      { path: '/admin.calendar', component: Calendar }, //系统管理-->日历

      { path: '/admin.message', component: Message }, //系统管理-->消息通知
      { path: '/admin.messageRecords', component: MessageRecords }, //系统管理-->消息通知-->发送记录
      { path: '/admin.device', component: Device }, //系统管理-->设备管理

      { path: '/admin.customSer', component: CustomSer }, //系统管理-->客服管理

      //--------------------课程--------------
      { path: '/admin.course.index', component: Course }, // 课程列表
      { path: '/admin.course.content', component: CourseContent }, // 课程内容
      { path: '/admin.course.contentV2', component: CourseContentV2 }, // 课程内容
      { path: '/admin.course.cate', component: CourseCate }, // 课程分类
      { path: '/admin.bag.cate', component: BagCate }, // 课包分类
      { path: '/admin.cdKey', component: cdKey }, // 兑换码
      { path: '/admin.redeemList', component: cdKeyList }, // 兑换码列表

      // 混合课包
      {
        path: '/admin.course.bag',
        component: MixCP
        // component: resolve => require(['@/views/coursePackage/MixCP.vue'],resolve)
      },
      { path: '/admin.course.relatedcourse', component: RelatedC }, // 关联课程
      { path: '/admin.course.themeManage', component: ThemeManage }, // 混合课包-->管理主题
      { path: '/admin.course.buyUrl', component: BuyUrl }, // 混合课包-->生成购买链接
      { path: '/admin.video', component: Video }, // 视频课程
      { path: '/admin.videoList', component: VideoList }, // 视频列表
      { path: '/admin.videoBuyList', component: VideoBuyLink }, // 视频购买链接
      { path: '/admin.detail', component: VideoDetail }, // 视频购买链接

      { path: '/admin.course.schooltt', component: Schooltt }, // 课表管理
      { path: '/admin.course.userSignUp', component: UserSignUp }, // 课表-->报名信息
      { path: '/admin.course.classRecord', component: ClassRecord }, // 课表-->报名信息-->上课记录
      { path: '/admin.course.order', component: COrder }, // 课程订单
      //-----用户管理
      { path: '/admin.courseuser.index', component: CUser }, // 课程用户管理-->用户列表
      { path: '/admin.courseuser.buyRecords', component: BuyRecords }, // 课程用户管理-->用户列表--> 购课记录
      { path: '/admin.courseuser.learnRecord', component: LearnRecord }, // 课程用户管理-->用户列表--> 购课记录-->上课记录
      //-----营销管理
      { path: '/admin.banner', component: Banner }, // banner
      { path: '/admin.alert', component: Alert }, // alert
      { path: '/admin.explain-document', component: ExplainDocument }, // 说明文档
      { path: '/admin.card', component: card }, // 课程卡片
      { path: '/admin.indexAdvert', component: Advert }, // 开屏广告
      //-----数据包分析
      { path: '/admin.course-data', component: Static },
      { path: '/admin.course-data-daily', component: StaticDaily },
      //-------文件上传
      { path: '/admin.upload', component: Upload },
      //-------批量导入记录
      { path: '/admin.course_time.import_user_list', component: ImportUserList },
      //-------互动课程详情
      { path: '/admin.courseuser.userDetail', component: UserDetail },
      //勋章管理
      { path: '/admin.medal', component: Medal },
      //购买链接详情
      { path: '/admin.course.urlDetail', component: UrlDetail },
      //课表 渠道统计
      { path: '/admin.course.bags_time_statist', component: BagTimeStatist },
      //课表 渠道统计
      { path: '/admin.course.bags_time_statist_class', component: BagTimeClassStatist },

      // 渠道分销管理
      { path: '/admin.channel.distribution', component:chan},
      { path: '/admin.channel.bindCourseBag', component:chanBindCourse},
      { path: '/admin.channel.buyInfo', component:chanBuyInfo},
      //头像/头像框管理
      { path: '/admin.header.list', component:header_view},
      { path: '/admin.header.info', component:header_infos},
      { path: '/admin.header.exchange_list', component:header_exchange_list},
      // 音频管理
      { path: '/admin.audio.list', component:audioList},
      { path: '/admin.audio.collection', component:audioCollection},
      { path: '/admin.audio.buyList', component:audioBuyList},
      { path: '/admin.audio.collectionRelationAudio', component:collectionRelationAudio},  //关联音频
      { path: '/admin.audio.audioRecords', component:audioRecords}, //音频收藏/学习记录
      { path: '/admin.audio.audioGroupRecords', component:audioGroupRecords},  //关联音频
      //课包绑定学校
      { path: '/admin.courseBagsBindSchool.courseBagsBindSchoolList', component:courseBagsBindSchoolList},
      { path: '/admin.courseBagsBindSchool.courseBagsBindSchoolAdd', component:courseBagsBindSchoolAdd},
      { path: '/admin.audio.audioGroupRecords', component:audioGroupRecords}, //关联音频
      //教师管理
      { path: '/admin.schoolTeacher.index', component:teacherList},//教师列表
      //学校账号管理
      { path: '/admin.schoolAccount.index', component:schoolAccountList},//学校账号管理
      //学校管理
      { path: '/admin.school.index', component:schoolList},//学校列表
      {path:'/admin.school.schoolBindingList',component: schoolBindingList},//绑定记录
      //课程管理
      {path:'/admin.schoolCourse.allList',component: schoolCourseBagsList},//总账号-课程管理
      {path:'/admin.schoolCourse.schoolCourseBags',component: schoolCourseBags},//总账号-关联课包
      {path:'/admin.schoolCourse.schoolUserList',component: schoolUserList},//总账号-关联课包-绑定学生记录
      {path:'/admin.schoolCourse.list',component: childrenList}//老师账号-关联课包列表
    ]
  },
  {path: '/test', component: Test}
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
