import request from '@/utils/request'

//----------------------------------弹框

export function list(data) {
  return request({
    url: '/admin/InteractCourseScreenAdvert/list',
    method: 'post',
    data
  })
}

export function add(data) {
  return request({
    url: '/admin/InteractCourseScreenAdvert/add',
    method: 'post',
    data
  })
}

export function detail(data) {
  return request({
    url: '/admin/interactCourseAlert/getBannerDetail',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: '/admin/InteractCourseScreenAdvert/edit',
    method: 'post',
    data
  })
}
export function del(data) {
  return request({
    url: '/admin/InteractCourseScreenAdvert/del',
    method: 'post',
    data
  })
}
export function changeStatus(data) {
  return request({
    url: '/admin/InteractCourseScreenAdvert/changeStatus',
    method: 'post',
    data
  })
}