<!--课包购买链接-->
<template>
    <div>
        <el-card>
            <el-row>
                <el-col :span="8">
                    <span style="display: inline-flex;width: 100px;font-size: 18px;">销售:</span>
                    <el-select v-model="search.sale_id" placeholder="请选择" filterable style="width:220px">
                        <el-option value="">全部</el-option>
                        <el-option v-for="(item, index) in salesmanList" :key="index" :label="item.name" :value="item.id" />
                    </el-select>
                </el-col>
                <el-col :span="8">
                    <span style="display: inline-flex;width: 100px;font-size: 18px;">客服:</span>
                    <el-select v-model="search.customer_id" placeholder="请选择" filterable style="width:220px">
                        <el-option value="">全部</el-option>
                        <el-option v-for="(item, index) in customerList" :key="index" :label="item.name" :value="item.id" />
                    </el-select>
                </el-col>
                <el-button type="primary" size="medium" icon="el-icon-search" style="" @click="btnClick()">搜索</el-button>
                <el-button type="warning" icon="el-icon-refresh" size="medium"  style="" @click="resetClick()">重置</el-button>
            </el-row>
            <el-row style="margin-top:10px;">
                <el-button type="success" @click="excelExport">Excel导出</el-button>
            </el-row>
            <div style="height:30px;line-height:30px;font-size:20px;margin:20px 0;">
                <span>课包名称:{{ course_bag_info.course_bag_name }}</span>
            </div>
            <el-row style="margin-top:10px;">
                <el-table :data="list" border stripe>
                    <el-table-column label="销售" prop="sale_name" width="160px"></el-table-column>
                    <el-table-column label="客服" prop="customer_name" width="140px"></el-table-column>
                    <el-table-column label="报名人数" prop="apply_number" width="200px"></el-table-column>
                    <el-table-column label="完课人数" prop="done_number" width="200px"></el-table-column>
                    <el-table-column label="完课率" prop="done_number_p" width="140px"></el-table-column>
                    <el-table-column label="操作" width="280px">
                        <template slot-scope="scope">
                            <el-button type="text" slot="reference" size="mini" @click="jumpCompleteClass(scope.row)"
                                style="margin-left:5px">完课统计</el-button>
                            <!-- </el-popconfirm> -->
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-card>
    </div>
</template>
  
<script>
import { getShortLinkListByBagsTimeStatistics } from '@/api/statistics'
import global_ from '@/utils/global';
export default {
    data() {
        return {
            list: [],
            search: {
                course_bags_time_id: this.$route.query.bagTimeId,
                customer_id: "",
                sale_id: ""
            },
            course_bag_info: "",
            customerList: [],
            salesmanList: []
        }
    },
    created() {
        this.getCustmerList();
        this.getShortLinkListByBagsTimeStatisticsApi();
    },
    methods: {
        async getCustmerList() {
            this.customerList = await global_.getCustmerListByShortLinkStatist(this.search.course_bags_time_id);
            this.salesmanList = await global_.getSaleCustmerListByShortLinkStatist(this.search.course_bags_time_id);
        },
        async getShortLinkListByBagsTimeStatisticsApi() {
            const { data: res } = await getShortLinkListByBagsTimeStatistics(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.list = res.data.list
            this.course_bag_info = res.data.course_bags_info.courseBag
        },
        jumpCompleteClass(row) {
            let query = { bags_time_id: this.search.course_bags_time_id, course_bag_id: this.course_bag_info.id, short_link_id: row.short_link_id, customer_id: row.customer_id, sale_id: row.sale_id }
            this.$router.push({ path: '/admin.course.bags_time_statist_class', query: query })
        },
        btnClick() {
            this.search.pagenum = 1;
            this.getShortLinkListByBagsTimeStatisticsApi();
        },
        resetClick() {
            this.search.customer_id = "";
            this.search.sale_id = "";
            this.search.pagenum = 1;
            this.getShortLinkListByBagsTimeStatisticsApi();
        },
        excelExport() {
            let headers = {}
            headers = {
                '销售': 'sale_name',
                '客服': 'customer_name',
                '报名人数': 'apply_number',
                '完课人数': 'done_number',
                '完课率': 'done_number_p',
            }

            let query = {}
            query.course_bags_time_id = this.search.course_bags_time_id;
            query.customer_id = this.search.customer_id;
            query.sale_id = this.search.sale_id;

            import('@/vendor/Export2Excel').then(async excel => {
                const { data: res } = await getShortLinkListByBagsTimeStatistics(query)
                console.log("res.data.list.data", res.data.list.data)
                const data = this.formatJson(headers, res.data.list)
                excel.export_json_to_excel({
                    header: Object.keys(headers),
                    data,
                    filename: '渠道统计',
                    autoWidth: true,
                    bookType: 'xlsx'
                })
            })
        },
        formatJson(headers, rows) {
            return rows.map(item => {
                return Object.keys(headers).map(key => {
                    return item[headers[key]]
                })
            })
        },
    }
}
</script>