<!-- 渠道分销管理-->
<template>
  <div>
    <el-card>
      <el-row style="margin-top:10px;">
        <el-button type="success" style="width:100px;" @click="add">添加渠道商</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="list" border stripe :row-style="{ height: '50px' }" :cell-style='cellStyle'
          :header-cell-style='rowClass'>
          <el-table-column label="渠道商名称" prop="channel_distributor_name" ></el-table-column>
          <el-table-column label="课程详情图" >
            <template slot-scope="scope">
                <el-button type="text"  @click="showPic(imgDomain + scope.row.channel_distributor_thumb)" size="mini">查看大图
                </el-button>
            </template>
          </el-table-column>
          <el-table-column label="课程链接" prop="link_url"></el-table-column>
          <el-table-column label="状态" prop="status_str" ></el-table-column>
          <el-table-column label="操作" >
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="edit(scope.row)" >编辑</el-button>
              <el-button type="text" size="mini" v-if="scope.row.status ==1" @click="changeStatus(scope.row.id,scope.row.status)">禁用 </el-button>
              <el-button type="text" size="mini" v-if="scope.row.status ==2 " @click="changeStatus(scope.row.id,scope.row.status)">启用 </el-button>
              <el-button type="text" size="mini"  @click="bind(scope.row.id)">绑定课包</el-button>
              <el-button type="text" size="mini"  @click="del(scope.row.id)" v-if="scope.row.channel_course_bags_count<1">删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>

    <!--分页start-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="search.pagenum"
        :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    <!--分页end-->


    <el-dialog :visible.sync="addDialogVisible" :title="title" width="30%" @close="addDialogClosed" :close-on-click-modal="false" >
      <el-form ref="addFormRef"  label-width="100px">
        <el-form-item label="渠道商名称:">
          <el-input v-model="addForm.name" type="text"></el-input>
        </el-form-item>
        <el-form-item label="课程详情图">
          <el-upload class="avatar-uploader" action="#" :show-file-list="false"  :http-request="(file)=> {uploadFile(file,1)}">
            <img v-if="addForm.thumb" :src="imgDomain + addForm.thumb" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="Submit()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>


<script>
import { list , add , edit , changeStatus, del } from '@/api/channel'
import global from '@/utils/global';
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'
export default {
  data() {
    return {
      list: [],
      total: 0,
      addForm:{
        name:"",
        thumb:""
      },
      search:{
        pagenum:1,
        pagesize:10,
      },
      title:"",
      //-------------------------------
      addDialogVisible: false,
      is_edit:0,
     
    }
  },
  created() {
    if(this.$route.query.pagenum){
      this.search.pagenum = parseInt(this.$route.query.pagenum)
    }
    if(this.$route.query.pagesize){
      this.search.pagesize = parseInt(this.$route.query.pagesize)
    }
    this.getList()
  },
  watch: {
  },
  methods: {
    //-----------------------------tabel 文字居中-------------------------------
    rowClass({ row, rowIndex }) {
      return 'text-align:center'
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;padding:0px'
    },
    //--------------------------------------
    async add(){
      this.title = "添加渠道商"
      this.addDialogVisible = true
    },
    edit(data){
      this.addDialogVisible = true
      this.is_edit = 1
      this.title = "编辑渠道商"
      this.addForm.name = data.channel_distributor_name
      this.addForm.thumb = data.channel_distributor_thumb 
      this.addForm.id = data.id
    },
    async changeStatus(id,status){
      const { data: res } = await changeStatus({"id":id,"status":status})
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.$message.success(res.msg)
      this.getList()
    },
    async del(id){
      this.$confirm('确认要删除嘛', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
          const { data: res } = await del({"id":id})
          if (res.status != 0) {
            return this.$message.error(res.msg)
          } 
          this.$message.success(res.msg)
          this.getList()
      }).catch(() => {
        
      })
    },
    async uploadFile(uploadInfo,index){
    const {data:token} =  await getQiniuToken()
    const that = this
    const observer = {
        next(res){
          console.log('next:', res)
          that.percentage = parseInt(res.total.percent.toString().split(".")[0])
        },
        error(err){
          console.log(err)
        },
        complete(res){
          console.log('complete:....')
          that.addForm.thumb = res.key 
          that.show = false  
        }
      }
      this.show = true
      let name = uploadInfo.file.name
      let fileName = global.getFileName(name)
      if(parseInt(fileName) !=NaN){
        name = fileName+"-"+global.randStr(10)+"."+global.getExt(name)
      }
      const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+ name,token,{},{})
      const subscription =  observable.subscribe(observer)
    },
    //-------------------------------渠道列表-----------------------------------
    async getList() {
      const { data: res } = await list()
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.list = res.data.data
      this.total = res.data.total
    },
    showPic(url) {
      this.imagesUrl = [];
      this.imagesUrl.push(url)
      this.$viewerApi({
          images: this.imagesUrl,
      })
    },
    // -------------------------分页-------------------------------------------
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },
    //-------------------
    addDialogClosed() {
       this.title = ""
       this.addForm.name = ""
       this.addForm.thumb = ""
    },
    async Submit() {
      if(this.is_edit == 0){
        const { data: res } = await add(this.addForm)
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
      }else{
        const { data: res } = await edit(this.addForm)
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
      }
      this.addDialogVisible = false
      this.getList()
    },

    bind(id){ //绑定课包
      this.$router.push({ path: '/admin.channel.bindCourseBag', query: { channelId: id ,pagenum:this.search.pagenum,pagesize:this.search.pagesize } })
    }
  }
}
</script>