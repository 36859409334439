<template>
  <div>
    <el-card>
        <el-row class="nav-">
          <el-col :span="6">课程标题: <el-input type="text"  v-model="search.title" prefix-icon="el-icon-search"></el-input></el-col>
          <el-col :span="3" style="margin-left:10px">状态: 
            <el-select v-model="search.type" placeholder="请选择" @change="selectBag(cp_select)" filterable
                style="width:100%">
                <el-option value="">全部</el-option>
                <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id">{{
                  item.name
                }}
                </el-option>
              </el-select>
          </el-col>
          <el-button  type="primary" icon="el-icon-search" @click="searchClick" style="margin-top:14px;margin-left:10px">搜索</el-button>
          <el-button  type="warning" icon="el-icon-refresh" @click="btnClick" style="margin-top:14px;margin-left:10px">重置</el-button>
        </el-row>
        <el-row style="margin-top:10px">
          <el-button type="success"  @click="excelExport" style="float:left;">导出</el-button>
          <!-- <el-button type="primary"  @click="add">新增课程</el-button> -->
          <videoAdd  ref="video_add" :editObj="editObj" @getList="editList"></videoAdd>
        </el-row>
        <el-row style="margin-top:10px;">
            <el-table :data="list" border stripe>
            <el-table-column label="ID"  prop="id"></el-table-column>
            <el-table-column label="视频课标题" prop="video_course_name"></el-table-column>
            <el-table-column label="课程封面" >
              <template slot-scope="scope">
                <el-image :src="imgDomain + scope.row.video_course_thumb" style="width: 30px;height:30px;"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="课程原价" prop="video_course_origin_price"></el-table-column>
            <el-table-column label="课程售价" prop="video_course_price"></el-table-column>
            <el-table-column label="创建时间" prop="created_at"></el-table-column>
            <el-table-column label="状态"  prop="status">
              <template slot-scope="scope" >
                  <span  v-if="scope.row.status ==1">启用</span>
                  <span v-else>禁用</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="240px">
                <template slot-scope="scope" >
                  <el-button type="text" size="mini"  @click="edit(scope.row)">编辑</el-button>
                  <el-button type="text" v-if="scope.row.status == 2" size="mini" @click="changeStatus(scope.row)">启用</el-button>
                  <el-button type="text" v-else-if="scope.row.status == 1" size="mini" @click="changeStatus(scope.row)">禁用</el-button>
                  <el-button type="text"  size="mini" @click="del(scope.row.id)">删除</el-button>
                  <el-button type="text" size="mini" @click="jump(scope.row.id)" >视频列表</el-button>
                  <el-button type="text" size="mini"  @click="jumpBuyLink(scope.row.id,scope.row.video_course_name)">生成购买链接</el-button>
                </template>
            </el-table-column>
          </el-table>

          <!--分页start-->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.pagenum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="search.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          <!--分页end--> 

        </el-row>
      </el-card> 
  </div>
</template>


<script>
import videoAdd from "@/components/Video/add"
import {videoCourseList,changeStatus,del} from '@/api/video'
export default {
  components:{
    // list,
    // excel,
    videoAdd,
    // search
  },
  data(){
    return {
      list:[],
      search:{
        title:"",
        type:"",
        pagenum:1,
        pagesize:10,
      },
      total:0,
      typeList:[{"id":1,"name":"正常"},{"id":2,"name":"禁用"}],
      editObj:{},
    }
  },
  created(){
    this.getList()
  },
  methods:{
    editList(){
      this.getList()
    },
    async getList(){
      const {data:res} = await videoCourseList(this.search)
       if (res.status != 0) {
         return this.$message.error(res.msg)
       }
       this.total = res.data.total
       this.list = res.data.data
    },

    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList(this.search)
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList(this.search)
    },

    searchClick(){
      this.getList()
    },
    
    btnClick() {
      this.search.title = ""
      this.search.type = ""
      this.search.pagenum = 1
      this.search.pagesize = 10
      this.getList()
    },

    edit(data){
      this.$refs.video_add.addDialogVisible = true
      this.$refs.video_add.is_edit = 1
      this.editObj = data
    },
    async del(id){
      this.$confirm("是否要删除", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {data:res} = await del({"id":id})
        if (res.status != 0) {
          return this.$message.error(res.msg)
        } 
        this.$message.success("删除成功!")
        this.getList()
      }).catch(() => {
        
      })
      
    },

    async changeStatus(row){
      const {data:res} =  await changeStatus({id:row.id,status:row.status})
       if(res.status!=0){
        return this.$message.error(res.msg)
      } 
      this.$message.success(res.msg)
      this.getList()
    },

    jump(id){
      this.$router.push({path:'/admin.videoList',query:{id:id}})
    },
    jumpBuyLink(id,title){
      this.$router.push({path:'/admin.videoBuyList',query:{id:id,videoCourseTitle:title}})
    },
   
    excelExport() {
      const headers = {
        'ID':"id",
        '视频课标题': 'video_course_name',
        '课程封面': 'video_course_thumb',
        '课程原价': 'video_course_origin_price',
        '课程售价': 'price_str',
        '创建时间': 'created_at',
        '状态': 'status',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await videoCourseList(this.search)
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '视频课程信息',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.search.pagesize = 10
      })
    },

    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          if(headers[key] == "status"){
            switch(item[headers[key]]){
              case 1:
                return "启用"
                break
              case 2:
                return "禁用"
                break
            }
          }
          return item[headers[key]]
        })
      })
    },
  }
}
</script>