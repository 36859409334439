import request from "@/utils/request";

export function getShortLinkListByBagsTimeStatistics(data) {
  return request({
    url: "/admin/statistics/getShortLinkListByBagsTimeStatistics",
    method: "post",
    data
  });
}



export function getClassStatisticsByBagTimeClassStatistics(data) {
  return request({
    url: "/admin/statistics/getClassStatisticsByBagTimeClassStatistics",
    method: "post",
    data
  });
}
