<template>
    <div>
        <el-card>
            <el-row>
                <el-col :span="8">
                    <span style="display: inline-flex;width: 100px;font-size: 18px;">头像名称:</span>
                    <el-input type="text" prefix-icon="el-icon-search" style="display: inline-flex;width: 250px;"
                        v-model="name">
                    </el-input>
                </el-col>
                <el-button type="primary" size="medium" icon="el-icon-search" style="" @click="search()">搜索</el-button>
            </el-row>
            <el-row style="margin-top:10px;">
                <el-button type="primary" @click="addHeader()">添加头像/头像框</el-button>
            </el-row>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick" style="margin-top: 20px;">
                <el-tab-pane label="头像" name="first">
                    <headerList ref="headerList" @editHeader="editHeader" @goToExchangeList="goToExchangeList"></headerList>
                </el-tab-pane>
                <el-tab-pane label="头像框" name="second">
                    <headerList ref="headerList1" @editHeader="editHeader" @goToExchangeList="goToExchangeList">
                    </headerList>
                </el-tab-pane>
            </el-tabs>
            <headerInfo ref="headerInfo" @getHeaderList="getHeaderList"></headerInfo>
        </el-card>
    </div>
</template>
  
<script>
import headerList from './lists.vue'
import headerInfo from './info.vue'
export default {
    components: {
        headerList: headerList,
        headerInfo: headerInfo,
    },
    data() {
        return {
            activeName: 'first',
            name: "",
            isSearch: false,
        }
    },
    created() {
        this.dealRecordSession();
        this.$nextTick(function () {
            this.getHeaderList();
        })
    },
    methods: {
        getHeaderFrameDataApi(name) {
            this.$refs.headerList1.list = [];
            this.$refs.headerList1.search.name = name;
            this.$refs.headerList1.search.pagenum = parseInt(sessionStorage.getItem('header_list_pagenum')) || 1;
            this.$refs.headerList1.search.pagesize = parseInt(sessionStorage.getItem('header_list_pagesize')) || 10;;
            this.$refs.headerList1.search.header_type = 2;
            this.$refs.headerList1.getHeaderAdminListApi();
        },
        getHeaderDataApi(name) {
            this.$refs.headerList.list = [];
            this.$refs.headerList.search.name = name;
            this.$refs.headerList.search.pagenum = parseInt(sessionStorage.getItem('header_list_pagenum')) || 1;
            this.$refs.headerList.search.pagesize = parseInt(sessionStorage.getItem('header_list_pagesize')) || 10;
            this.$refs.headerList.search.header_type = 1;
            this.$refs.headerList.getHeaderAdminListApi();
        },
        handleClick(tab, event) {
            let name = (this.isSearch) ? this.name : "";
            if (tab.name == "first") {
                this.getHeaderDataApi(name);
            } else {
                this.getHeaderFrameDataApi(name);
            }
        },
        getHeaderList() {
            let name = (this.isSearch) ? this.name : "";
            if (this.activeName == "first") {
                this.getHeaderDataApi(name);
            } else {
                this.getHeaderFrameDataApi(name);
            }
        },
        addHeader() {
            this.$refs.headerInfo.clearinfo();
            this.$refs.headerInfo.title = '添加头像/头像框';
            this.$refs.headerInfo.dislogOpen();
        },
        search() {
            let name = this.name;
            this.isSearch = true;
            this.getHeaderList();
        },
        editHeader(row) {
            this.$refs.headerInfo.title = '编辑头像/头像框';
            this.$refs.headerInfo.setInfo(row);
            this.$refs.headerInfo.dislogOpen();
        },
        goToExchangeList(id) {
            this.$router.push({ path: '/admin.header.exchange_list', query: { header_id: id } })
        },
        dealRecordSession() {
            let header_list_header_type = sessionStorage.getItem('header_list_header_type')
            let header_list_name = sessionStorage.getItem('header_list_name')
            if (header_list_header_type == 1) {
                this.activeName = "first"
            } else if (header_list_header_type == 2) {
                this.activeName = "second"
            }
            if (header_list_name) {
                this.name = header_list_name;
                this.isSearch = true;
            }
        }
    }
}
</script>