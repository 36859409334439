<!--课表管理-->
<template>
  <div>
    <el-card>
      <courseBagsTimeSearchView :kefuList="kefuList" @callCourseTimeListView="callCourseTimeListView"
        @callCourseTimeListViewBySearchValue="callCourseTimeListViewBySearchValue" ref='courseBagsTimeSearchView'>
      </courseBagsTimeSearchView>
      <div class="clear"></div>
      <el-row style="margin-top:10px;">
        <el-button type="primary" @click="openCourseBagsTimeDialog"> 添加课表</el-button>
        <el-button type="success" @click="excelExport">Excel导出</el-button>
      </el-row>
      <courseBagsTimeListView @openEditCourseBagsTimeDialog="openEditCourseBagsTimeDialog" ref="courseBagsTimeListView">
      </courseBagsTimeListView>
      <courseBagsTimeAddOrUpdateView :kefuList="kefuList" ref="courseBagsTimeAddOrUpdateView"
        @callCourseTimeListViewApi="callCourseTimeListViewApi">
      </courseBagsTimeAddOrUpdateView>
    </el-card>
  </div>
</template>


<script>
import { getImportExcel} from "@/api/courseSchoolTime";
import courseBagsTimeAddOrUpdateView from './courseBagsTime/addOrUpdate.vue'
import courseBagsTimeListView from './courseBagsTime/list.vue'
import courseBagsTimeSearchView from './courseBagsTime/search.vue'
import { getTimeCustomerList } from '@/api/customer'
import excelImportOperator from '@/utils/excelImport'
import { saveAs } from 'file-saver';
export default {
  components: {
    courseBagsTimeAddOrUpdateView: courseBagsTimeAddOrUpdateView,
    courseBagsTimeListView: courseBagsTimeListView,
    courseBagsTimeSearchView: courseBagsTimeSearchView
  },
  data() {
    return {
      kefuList: []
    }
  },
  created() {
    this.$nextTick(function () {
      sessionStorage.removeItem('list_search');
      sessionStorage.removeItem('list_search_coursetime');
      this.$refs.courseBagsTimeSearchView.search = this.$refs.courseBagsTimeListView.search
      this.$refs.courseBagsTimeSearchView.initSearchCourseBagList();
      this.$refs.courseBagsTimeAddOrUpdateView.getCoursePackageAll({ course_bag_status: 3, subject_type: 1 })
      this.getKefuList();
      console.log('aaa',this.$refs.courseBagsTimeSearchView.kefuList)
    })
  },
  methods: {

    //-------------删除------------
    confirm() {
      this.visible = false;
      this.$emit('onConfirm');
    },
    async excelExport() {
      let res = await getImportExcel(this.search)
      excelImportOperator.excelExportBlob(res, '课表信息.xlsx')
    },
    async getKefuList() {
      const { data: res } = await getTimeCustomerList({ customer_type: 1, status: 1 })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.kefuList = res.data
    },
    openCourseBagsTimeDialog() {
      this.$refs.courseBagsTimeAddOrUpdateView.openDialog();
    },
    openEditCourseBagsTimeDialog(row) {
      this.$refs.courseBagsTimeAddOrUpdateView.openDialogEdit(row);
    },
    callCourseTimeListView(search) {
      this.callCourseTimeListViewBySearchValue(search);
      this.$refs.courseBagsTimeListView.getCourseTimeList();
    },
    callCourseTimeListViewBySearchValue(search) {
      this.$refs.courseBagsTimeListView.search = search;
    },
    callCourseTimeListViewApi() {
      this.$refs.courseBagsTimeListView.search.pagenum = 1;
      this.$refs.courseBagsTimeListView.search.pagesize = 10;
      this.$refs.courseBagsTimeListView.getCourseTimeList();
    },
  }
}
</script>

<style scoped>
.nav_1 {
  margin-bottom: 10px;
  float: left;
}
</style>