<template>
  <div>
    <el-row style="margin-top:10px;">
      <el-table :data="exerciseList" border stripe>
        <el-table-column label="题目编号" prop="id"></el-table-column>
        <el-table-column label="互动类型" prop="">
          <template slot-scope="scope">
             <span v-if="scope.row.inter_type == 1">归类题</span> 
             <span v-else-if="scope.row.inter_type == 2">排序题</span> 
             <span v-else-if="scope.row.inter_type == 3">配对题</span> 
             <span v-else>问答题</span> 
          </template>
        </el-table-column>
        <el-table-column label="题目标题" prop="title"></el-table-column>
        <!-- <el-table-column label="题目类型" prop="type">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">单选</span>
            <span v-else>多选</span>
          </template>
        </el-table-column> -->
        <el-table-column label="答案数量" prop="num"></el-table-column>
        <el-table-column label="答案类型" prop="option_type">
          <template slot-scope="scope">
            <span v-if="scope.row.option_type == 1">文本</span>
            <span v-else-if="scope.row.option_type == 2">图片</span>
            <span v-else-if="scope.row.option_type == 3">图文结合</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="satus">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">启用</span>
            <span v-else>禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="排序" prop="sort"></el-table-column>
        <el-table-column label="操作" width="240px">
          <template slot-scope="scope">
            <el-button type="text" size="mini" v-if="checkPermission('admin.course.edit')"
              @click="editExercise(scope.row)">编辑</el-button>
            <template>
              <el-button type="text" v-if="scope.row.status == 2" size="mini"
                @click="changeExerciseStatus(scope.row)">启用</el-button>
              <el-button type="text" v-else-if="scope.row.status == 1" size="mini"
                @click="changeExerciseStatus(scope.row)">禁用</el-button>
            </template>
            <el-popconfirm title="确定要删除嘛?" @onConfirm="delClick(scope.row.id)" @confirm="delClick(scope.row.id)">
              <el-button type="text" slot="reference" size="mini">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>


<script>
import { contentDetail, changeExerciseStatus } from '@/api/course'
export default {  
  props:{
             
  },
  data(){
    return {
      courseId: this.$route.query.id,
      gameType: 3,
      exerciseList: [],
      pagenum: 1,
      pagesize: 10,
      total: 0,
      
    }
  },
  created(){
    this.getExerciseList()
  },
  methods: {

    async getExerciseList() {
      const { data: res } = await contentDetail({
        courseId: this.$route.query.id,
        game_type: this.gameType,
      })
      this.exerciseList = res.data
    },

    editExercise(val){
      val.is_edit = 1
      this.$emit("edit",["修改题目",val])
    },
  
    async changeExerciseStatus(row) {
      const status = row.status == 1 ? 2 : 1
      const { data: res } = await changeExerciseStatus({ games_id: row.id, status: status })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success('状态修改成功')
      this.getExerciseList()
    },

    async delClick(id) {
      const { data: res } = await changeExerciseStatus({ games_id: id, status: 3 })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success('删除成功')
      this.getExerciseList()
    },

  }
}
</script>