<!--课程订单-->
<template>
  <div>
    <el-card>
      <div class="nav_1">
        <div class="div-left">
          <div class="d-order-no">兑换标题: </div>
          <div class="div-left">
            <el-input class="input" v-model="search.title"></el-input>
          </div>
        </div>
        <div class="div-left">
          <div class="d-order-no">兑换类型: </div>
          <div class="div-left">
            <el-select v-model="search.type" placeholder="请选择" filterable style="width:100%">
              <el-option v-for="(item, index) in cd_key_type" :key="index" :label="item.name" :value="item.id" />
            </el-select>
          </div>
        </div>
        <div class="div-left">
          <div class="d-order-no">课程名称: </div>
          <div class="div-left">
            <el-input placeholder="请输入课程名称" class="input" v-model="search.course_title"></el-input>
          </div>
        </div>
      </div>
      <div class="nav_1">
        <div class="div-left">
          <div class="d-order-no">状态: </div>
          <div class="div-left">
            <el-select v-model="search.status" placeholder="请选择">
              <el-option v-for="(item, index) in status_list" :label="item.name" :key="index"
                :value="item.id">{{ item.name }}</el-option>
            </el-select>
          </div>
        </div>
        <div class="div-left">
          <el-button type="primary" icon="el-icon-search" @click="btnClick">搜索</el-button>
          <el-button type="warning" icon="el-icon-refresh" @click="reset">重置</el-button>
        </div>
      </div>
      <!---->
      <div class="clear"></div>
      <el-row style="margin-top:10px;">
        <el-button type="success" style="width:100px;" @click="add">新增兑换</el-button>
        <el-button type="success" style="width:100px;" @click="excelExport">Excel导出</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="cdKeyList" border stripe :row-style="{ height: '50px' }" :cell-style='cellStyle'
          :header-cell-style='rowClass'>
          <el-table-column label="兑换ID" prop="id_str" width="190px"></el-table-column>
          <el-table-column label="兑换标题" prop="title" width="140px"></el-table-column>
          <el-table-column label="兑换类型" prop="exchange_type_str" width="150px"></el-table-column>
          <el-table-column label="兑换课程名称" prop="exchange_type_title"></el-table-column>
          <el-table-column label="兑换时间" prop="created_at"></el-table-column>
          <el-table-column label="兑换码数量" prop="has_many_exchange_code_all_count" width="110px"></el-table-column>
          <el-table-column label="已兑换数量" prop="has_many_exchange_code_count" width="160px"></el-table-column>
          <el-table-column label="状态" prop="status_str" width="80px"></el-table-column>
          <el-table-column label="操作" width="200px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="edit(scope.row)" >编辑</el-button>
              <el-button type="text" size="mini" @click="jumpRedeemList(scope.row.id)">兑换码列表</el-button>
              <el-button type="text" size="mini" v-if="scope.row.status ==1" @click="changeStatus(scope.row.id,scope.row.status)">禁用 </el-button>
              <el-button type="text" size="mini" v-if="scope.row.status ==2 " @click="changeStatus(scope.row.id,scope.row.status)">启用 </el-button>
              <el-button type="text" size="mini" v-if="scope.row.has_many_exchange_code_all_count<1" @click="del(scope.row.id)">删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <!--分页start-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="search.pagenum"
        :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      <!--分页end-->

      <!--兑换 start-->
      <el-dialog :title="dialogTitle" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="40%"
        @close="addDialogClosed">
        <el-form ref="addFormRef"  label-width="100px">
          <el-form-item label="兑换标题">
            <el-input v-model="addForm.title" type="text"></el-input>
          </el-form-item>
          <el-form-item label="兑换类型:">
            <el-radio-group v-model="addForm.redeem_type" size="small" :disabled="disabled">
              <el-radio :label="item.id" v-for="item in redeem_type" :key="item.id">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="兑换课程:">
            <el-select v-model="addForm.redeem_course" placeholder="请选择" :disabled="disabled">
              <el-option v-for="(item,index) in redeemCourse" :label="item.name" :key="index" :value="item.id">{{item.name}}</el-option>    
            </el-select>
          </el-form-item>
          <el-form-item label="兑换时间:">
            <div>
              <div>
                <el-radio label="1" v-model="addForm.redeem_time_type" :disabled="disabled">无限制</el-radio>
              </div>
              <div style="margin-top:20px;">
                <el-radio label="2" v-model="addForm.redeem_time_type" :disabled="disabled" >起止时间
                    <div class="block" style="margin-left:160px;margin-top:-25px;">
                      <el-date-picker
                        v-model="addForm.time"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期" :disabled="timeDisabled">
                      </el-date-picker>
                    </div>
                </el-radio>
              </div>
            </div>
          </el-form-item>      
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addSubmit">确认</el-button>
        </span>
      </el-dialog>
      <!--兑换 end-->

    </el-card>
  </div>
</template>

<script>
import { list, redeemCourse,add,edit,changeStatus,del } from '@/api/cdKey'
import global_ from '@/utils/global';
export default {
  data() {
    return {
      courseTypeList:global_.course_order_search_course_type,
      cdKeyList: [],
      total: 0,
      search: {
        pagenum: 1,
        pagesize: 10,
        title: '',
        type: '',
        course_title: '',
        status: '',
      }, 
      addForm:{
        title:"",
        redeem_type:1, //兑换类型
        redeem_course:"",
        redeem_time_type:"1", //兑换时间类型
        time:"",
      },
      cd_key_type: [{ id: 0, name: "全部" }, { id: 1, name: "互动课" },{ id: 2, name: "主题课程" },{ id: 3, name: "视频课" }],
      status_list: [{ id: 0, name: "全部" }, { id: 1, name: "正常" }, { id: 2, name: "禁用" }],
      redeem_type:[  { id: 1, name: "互动课" },{ id: 2, name: "主题课程" },{ id: 3, name: "视频课" }],
      redeemCourse:[],
      value1:"",
      //-----
      dialogTitle:"新增兑换",
      //-------------------------------
      addDialogVisible: false,
      is_edit:0,
      disabled:false,
      timeDisabled:false,
    }
  },
  created() {
    this.getList()
  },
  watch: {
    'addForm.redeem_type':{
        handler(newVal,oldVal){
          if(this.is_edit!=1){
            this.addForm.redeem_course = ""
            this.getRedeemList()
          }else{
            this.getRedeemList()
          }
          
        },
        // immediate:true,
        // deep:true
    },
  },
  methods: {
    async getRedeemList(){
      const { data: res } = await redeemCourse({"type":this.addForm.redeem_type})
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.redeemCourse = res.data
    },
    //-----------------------------tabel 文字居中-------------------------------
    rowClass({ row, rowIndex }) {
      return 'text-align:center'
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;padding:0px'
    },
    //--------------------------------------
    async add(){
      this.getRedeemList()
      this.dialogTitle = "新增兑换"
      this.addDialogVisible = true
    },
    edit(data){
      this.getRedeemList()
      this.addDialogVisible = true
      this.is_edit = 1
      this.addForm.title = data.title
      this.addForm.redeem_type = data.exchange_type //兑换类型
      this.addForm.redeem_course = data.exchange_type_id //兑换课程
      this.addForm.redeem_time_type = data.time_type.toString() //兑换时间类型
      this.dialogTitle = "编辑兑换"
      if(data.start_time!=null){
        let time = []
        time[0] = data.start_time
        time[1] = data.end_time
        this.addForm.time  = time
      }else{
        this.addForm.time = ""
      }
      if(data.has_many_exchange_code_all_count){
        this.disabled = true
        if(this.addForm.redeem_time_type == 1){
          this.timeDisabled = true
        }
      }
      this.addForm.id = data.id
    },
    async changeStatus(id,status){
      const { data: res } = await changeStatus({"id":id,"status":status})
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.getList()
    },
    async del(id){
      this.$confirm('确认要删除嘛', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
          const { data: res } = await del({"id":id})
          if (res.status != 0) {
            return this.$message.error(res.msg)
          } 
          this.$message.success(res.msg)
          this.cdKeyList = res.data.data
          this.total = res.data.total
          this.getList()
      }).catch(() => {
        
      })
    },
    //-------------------------------订单列表-----------------------------------
    async getList() {
      const { data: res } = await list(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.cdKeyList = res.data.data
      this.total = res.data.total
    },
  
    // -------------------------分页-------------------------------------------
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },
    //-------------------
    jumpRedeemList(id){
       this.$router.push({path:'/admin.redeemList',query:{id:id}})
    },
    //------------------------------筛选---------------------------------------
    // timeBlur() {
    //   this.addForm.time = 
    //   this.search.pagenum = 1
    //   this.search.pagesize = 10
    //   this.search.startTime = this.value1[0]
    //   this.search.endTime = this.value1[1]
    // },
    // selectSource(val) {
    //   this.search.pagenum = 1
    //   this.search.pagesize = 10
    //   this.search.source = val
    // },
    btnClick() {
      this.search.pagenum = 1
      this.getList()
    },
    reset() {
      this.search.pagenum = 1
      this.search.pagesize = 10
      this.search.title = ""
      this.search.type = ""
      this.search.course_title = ""
      this.search.status = ""
      this.getList()
    },
    //-----------------------------导出--------------------------
    excelExport() {
      const headers = {
        '兑换ID': 'id_str',
        '兑换标题': 'title',
        '兑换类型': 'exchange_type_str',
        '兑换课程名称': 'exchange_type_title',
        '兑换时间': 'created_at',
        '兑换码数量': 'has_many_exchange_code_all_count',
        '已兑换数量': 'has_many_exchange_code_count',
        '状态': "status_str",
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await list(this.search)
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '课程兑换',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },
    //-----------------------------------
   
    addDialogClosed() {
        this.is_edit = 0
        this.addForm.title = ""
        this.addForm.redeem_type = 1
        this.addForm.redeem_course = ""
        this.addForm.redeem_time_type = "1"
        this.addForm.time = ""
        this.disabled = false
        this.timeDisabled = false
    },
    async addSubmit() {
      if(this.is_edit == 0){
        const { data: res } = await add(this.addForm)
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success("添加成功!")
      }else{
        const { data: res } = await edit(this.addForm)
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
      }
      this.addDialogVisible = false
      this.getList()
    },
  }
}
</script>


<style scoped>
.nav_1 {
  margin-bottom: 10px;
  float: left;
}

.div-left {
  float: left;
  margin-right: 10px;
}

.input {
  width: 220px;
}

.d-order-no {
  float: left;
  margin-right: 15px;
  height: 35px;
  line-height: 35px;
}
</style>