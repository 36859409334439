<template>
    <el-dialog title="批量添加用户" :close-on-click-modal="false" :visible.sync="addUserBatchDialogisible" width="28%"
        @close="closeUserAddBatch">
        <span v-if="addUserBatchDialogisibleStatus == 1">
            <el-form label-width="100px">
                <el-form-item label="下载导入模版">
                    <el-button style="width:100px;" @click="downloadTemplate(imgDomain)">下载模版</el-button>
                </el-form-item>
            </el-form>
            <el-form label-width="100px">
                <el-form-item label="上传导入模版">
                    <el-upload accept=".xlsx,.xls" ref="upload" class="upload-demo"
                        action="https://jsonplaceholder.typicode.com/posts/" :on-change="handleChange" :auto-upload="false"
                        :limit="1" :on-remove="handleRemove">
                        <el-button size="small" type="primary">上传表格</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
        </span>
        <span slot="footer" class="dialog-footer" v-if="addUserBatchDialogisibleStatus == 1">
            <el-button @click="closeUserAddBatch()">取消</el-button>
            <el-button type="primary" @click="submitUserBatch()">确定</el-button>
        </span>

        <span v-if="addUserBatchDialogisibleStatus == 2">
            <h2 style="text-align:center;">导入中，请稍后</h2>
        </span>

        <span v-if="addUserBatchDialogisibleStatus == 3" style="position: relative;">
            <h2 style="text-align:center;">导入完成</h2>
            <h2 style="text-align:center;margin-top: 10px;">可在导入记录中查看异常数据</h2>
            <div style="height: 50px;"></div>
            <el-button style="text-align:center;" class="btn_center" type="primary"
                @click="closeUserAddBatch()">确定</el-button>
        </span>

    </el-dialog>
</template>

<script>
import { addUserBatchCourseBagTime } from '@/api/courseSchoolTime'
export default {
    data() {
        return {
            addUserBatchForm: {
                course_bag_time_id: this.$route.query.bagTimeId,
                file: "",
            },
            addUserBatchDialogisible: false,
            addUserBatchDialogisibleStatus: 1
        }
    },
    created() {
    },
    methods: {
        submitUserBatch() {
            let self = this;
            let uploadFile = this.$refs.upload
            if (this.addUserBatchForm.file == "" || this.addUserBatchForm.file == undefined) {
                this.$message.error("请上传文件");
                return false;
            }
            this.addUserBatchDialogisibleStatus = 2
            addUserBatchCourseBagTime(this.addUserBatchForm).then((res) => {
                let code = res.data.status;
                if (code === 0) {
                    self.$message.success("添加用户成功")
                    self.addUserBatchDialogisibleStatus = 3
                    uploadFile.clearFiles();
                    self.addUserBatchForm.file = "";
                    self.$emit("userSignUpList")
                    // self.userSignUpList(self.search)
                } else {
                    self.addUserBatchDialogisibleStatus = 1
                    self.$message.error(res.data.msg)
                    uploadFile.clearFiles();
                    self.addUserBatchForm.file = "";
                }
            })
        },
        handleChange(file, fileList) {
            if (fileList.length > 0) {
                this.addUserBatchForm.file = fileList[fileList.length - 1]
            }
        },
        closeUserAddBatch() {
            if (this.$refs.upload) {
                this.$refs.upload.clearFiles();
            }
            this.addUserBatchDialogisible = false;
            this.addUserBatchForm.file = "";
        },
        openUserAddBatch() {
            this.addUserBatchDialogisible = true;
            this.addUserBatchDialogisibleStatus = 1;
        },
        downloadTemplate(imgDomain) {
            let url = imgDomain + "导入文件模版.xlsx";
            window.open(url)
        },
        handleRemove() {
            console.log("1314")
            this.clearFile();
        },
        clearFile() {
            let upload_file = this.$refs.upload;
            if (upload_file) {
                upload_file.clearFiles();
            }
            this.addUserBatchForm.file = "";
        }
    }
}
</script>

<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}

.search {
    float: left;
    width: 300px;
    margin-left: 10px;
}

.btn_center {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>