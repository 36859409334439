<template>
  <div>
    <el-tabs
      type="border-card"
      @tab-click="checkOtherTab"
      v-model="activeName"
      :before-leave="leaveTab"
    >
      <el-tab-pane label="翻书挑战" name="1"
        ><contentTab1 v-if="activeName == '1'"  ref="tab1Submit" :activeName="activeName" :nextActiveName="nextActiveName" @activeChange="dealActive"></contentTab1
      ></el-tab-pane>
      <el-tab-pane label="趣味阅读" name="2"
        ><content-tab-2 v-if="activeName == '2'" ref="tab2Submit" @flagChange="changeFatherFlag"></content-tab-2
      ></el-tab-pane>
      <el-tab-pane label="阅读闯关" name="3"
        ><content-tab-3 v-if="activeName == '3'" @flagChange="changeFatherFlag"></content-tab-3
      ></el-tab-pane>
      <el-tab-pane label="自信表达" name="4"
        ><content-tab-4 v-if="activeName == '4'" ref="tab4Submit" @flagChange="changeFatherFlag"></content-tab-4
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>


<script>
import contentTab1 from "./components/ContentTab1.vue";
import contentTab2 from "./components/ContentTab2.vue";
import contentTab3 from "./components/ContentTab3.vue";
import contentTab4 from "./components/ContentTab4.vue";

export default {
  data() {
    return {
      courseId: this.$route.query.id,
      activeName: "1",
      tabFlag: false,
      nextActiveName:'',
      flag2 : false,
    };
  },
  components: {
    contentTab1,
    contentTab2,
    contentTab3,
    contentTab4,
  },
  created() {
   window.sessionStorage.setItem('courseTab',null)
  },
  methods: {
    //------------- tab 切换-----------------
    checkOtherTab(tab, el) {
      
    },
    
    leaveTab(activeName, oldActiveName) { 
      const courseTab = window.sessionStorage.getItem("courseTab")
      if(this.flag2 == true){
        console.log("change"+this.flag2)
        return
      }

      if (courseTab == oldActiveName ) { 
        return new Promise((resolve, reject) => {
          this.$confirm(
            "当前上传图片未保存，是否保存后再切换",
            "系统提示",
            {
              confirmButtonText: "保存",
              cancelButtonText: "不保存",
              cancelButtonClass: "btn-custom-cancel",
              type: "warning",
            }
          )
            .then(async () => { 
              
              // 调用子组件的方法
              if(courseTab == 4){
                this.$refs.tab4Submit.knowClick()
                this.activeName = activeName
                window.sessionStorage.setItem('courseTab',null)
              }else if( courseTab == 2){
                this.$refs.tab2Submit.game2Click()  
                this.activeName = activeName
                window.sessionStorage.setItem('courseTab',null)               
              }else if(courseTab == 1){
                this.nextActiveName = activeName
                
                // 逻辑 加到子组件里面了
                // 子组件中确认要跳转的active  再传递给父 
                const res =  await this.$refs.tab1Submit.interactCourseContentClick()
                       
              }
            })
            .catch((result) => {
                this.activeName = activeName
                window.sessionStorage.setItem('courseTab',null)
            });
        });
      }

    }, // end -- leaveTab

    dealActive(e){
      this.flag2 = e[1]
      if(e[0]){
        this.activeName = e[0]
      }
      
    },
    changeFatherFlag(e){
      this.flag2 = false
    },

  },
};
</script>


<style >
</style>
