<!--课包分类-->
<template>
  <div>
    <el-card>
      <el-row class="nav-">
        <el-col :span="6">课包分类名称: <el-input type="text" v-model="cateName"
            prefix-icon="el-icon-search"></el-input></el-col>
        <el-button type="primary" icon="el-icon-search" @click="btnClick"
          style="margin-top:14px;margin-left:10px">搜索</el-button>
        <el-button type="warning" icon="el-icon-refresh" @click="resetClick"
          style="margin-top:14px;margin-left:10px">重置</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-button type="primary" v-if="checkPermission('admin.courseCate.add')"
          @click="addDialogVisible = true">添加分类</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="cateList" border stripe>
          <el-table-column label="分类编号" prop="id"></el-table-column>
          <el-table-column label="分类名称" prop="name"></el-table-column>
          <el-table-column label="操作" width="240px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" v-if="checkPermission('admin.courseCate.edit')"
                @click="showEditDialog(scope.row)">编辑</el-button>
              <el-popconfirm title="确定要删除嘛?" @onConfirm="delCate(scope.row.id)" @confirm="delCate(scope.row.id)">
                <el-button type="text" slot="reference" size="mini"
                  v-if="checkPermission('admin.courseCate.del')">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="search.pagenum"
        :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>

    <!--添加分类start-->
    <el-dialog title="添加分类" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="30%"
      @close="addDialogClosed">
      <el-form ref="addFormRef">
        <div v-for="(item, index) in addForm" :key="index">
          <el-row>
            <el-col :span="8">
              <el-form-item>
                <el-input placeholder="请输入分类名称" type="text" v-model="item.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-button @click="delCreateCate(index)" type="danger">删除</el-button>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div style="clear:both;"></div>
      <div>
        <el-button @click="cateCreate">+添加</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addCate">确定</el-button>
      </span>
    </el-dialog>
    <!--添加分类end-->

    <!--编辑分类start-->
    <el-dialog title="编辑分类" :close-on-click-modal="false" :visible.sync="editDialogVisible" width="30%"
      @close="editDialogClosed">
      <el-form ref="editFormRef" :model="editForm">
        <el-form-item label="分类名称">
          <el-input placeholder="请输入分类名称" type="text" v-model="editForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editCate">确定</el-button>
      </span>
    </el-dialog>
    <!--编辑分类end-->

  </div>
</template>

<script>
import { getcateList, courseTagAdd, courseTagDel, courseTagEdit } from '@/api/course'
export default {
  data() {
    return {
      cateName: '',
      cateList: [],
      addDialogVisible: false, // 添加
      editDialogVisible: false, // 修改
      addForm: [
      ],
      editForm: {
        'id': '',
        'name': ''
      },
      search: {
        pagenum: 1,
        pagesize: 10,
      },
      total: 0,
    }
  },
  created() {
    this.getCateList()
  },
  methods: {
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getCateList(this.cateName)
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getCateList(this.cateName)
    },
    resetClick() {
      this.search.pagenum = 1;
      this.search.pagesize = 10;
      this.cateName = "";
      this.btnClick();
    },
    btnClick() {
      this.getCateList(this.cateName)
    },
    async getCateList(keyword) {
      const { data: res } = await getcateList({ keyword: keyword, type: 2,page:this.search.pagenum,limit:this.search.pagesize })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.cateList = res.data.list
      this.total = res.data.total
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
      this.addForm = []
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    cateCreate() {
      this.addForm.push({ 'name': '' })
    },
    delCreateCate(index) {
      this.addForm.splice(index, 1)
    },
    //----------------------------------------增删改---------------------------------------------
    async addCate() {
      if (this.addForm.length == 0) {
        return this.$message.error('请点击添加，填写分类')
      }
      if (!this.addForm[0].name) {
        return this.$message.error('分类不为空')
      }

      const arr = [];
      this.addForm.forEach(element => {
        if (element.name != "") {
          arr.push(element.name)
        }
      });

      const { data: res } = await courseTagAdd({ "name": arr, type: 2 })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.addDialogVisible = false
      this.$message.success(res.msg)
      this.getCateList()

    },
    showEditDialog(row) {
      this.editForm.id = row.id
      this.editForm.name = row.name
      this.editDialogVisible = true
    },
    async delCate(id) {
      const { data: res } = await courseTagDel(id)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.getCateList()
    },
    async editCate() {
      const { data: res } = await courseTagEdit(this.editForm.id, this.editForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.getCateList()
      this.editDialogVisible = false
    }
  }
}
</script>