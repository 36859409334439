import request from "@/utils/request";

export function addCustomer(data) {
  return request({
    url: "/admin/customer/add",
    method: "post",
    params: data,
  });
}

export function updateCustomer(data) {
  return request({
    url: "/admin/customer/update",
    method: "post",
    params: data,
  });
}

export function getCustomerList(data) {
  return request({
    url: "/admin/customer/lists",
    method: "post",
    params: data,
  });
}

export function getTimeCustomerList(data) {
  return request({
    url: "/admin/customer/all",
    method: "post",
    params: data,
  });
}

export function deleteCustomer(data) {
  return request({
    url: "/admin/customer/delete",
    method: "post",
    params: data,
  });
}

export function changeCustomerStatus(data) {
  return request({
    url: "/admin/customer/change_status",
    method: "post",
    params: data,
  });
}


export function getOrderCustomerAll(data) {
  return request({
    url: "/admin/customer/order_all",
    method: "post",
    params: data,
  });
}