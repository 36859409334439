<template>
  <div>
    <el-card>
      <el-row class="nav-">
        <el-col :span="6">课程名称: <el-input type="text" v-model="search_content"
            prefix-icon="el-icon-search"></el-input></el-col>
        <el-button type="primary" icon="el-icon-search" @click="btnClick"
          style="margin-top:14px;margin-left:10px">搜索</el-button>
        <el-button type="warning" icon="el-icon-refresh" @click="resetClick"
          style="margin-top:14px;margin-left:10px">重置</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-button type="primary" v-if="checkPermission('admin.course.add')" @click="addCourseClick">添加课程</el-button>
        <el-button type="success" v-if="checkPermission('admin.course.export')" @click="excelExport">Excel导出</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="courseList" border stripe>

          <el-table-column label="课程ID" prop="id"></el-table-column>
          <el-table-column label="课程编号" prop="course_no"></el-table-column>
          <el-table-column label="课程名称" prop="title"></el-table-column>
          <el-table-column label="课程封面" align="center">
            <template slot-scope="scope">
              <el-image :src="imgDomain + scope.row.img" style="width: 30px;height:30px;"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="课程类型" prop="course_cate">
            <template slot-scope="scope">
              <span v-if="scope.row.course_cate == 1">阅读课</span>
              <span v-else-if="scope.row.course_cate == 2">美术课</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="created_at"></el-table-column>
          <el-table-column label="状态" prop="status">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">启用</span>
              <span v-else>禁用</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" v-if="checkPermission('admin.course.edit')"
                @click="showEditDialog(scope.row)">编辑</el-button>
              <el-button type="text" size="mini" v-if="checkPermission('admin.course.games')"
                @click="contentManage(scope.row.id, scope.row.course_cate)">内容管理</el-button>
              <el-button type="text" size="mini" @click="showCS(scope.row.id)"
                v-if="scope.row.course_cate == 1">课程小结</el-button>
              <template v-if="checkPermission('admin.course.changeStatus')">
                <el-button type="text" v-if="scope.row.status == 2" size="mini"
                  @click="changeStatusClick(scope.row)">启用</el-button>
                <el-button type="text" v-else-if="scope.row.status == 1" size="mini"
                  @click="changeStatusClick(scope.row)">禁用</el-button>
              </template>
              <el-popconfirm title="确定要删除嘛?" @onConfirm="delClick(scope.row.id)" @confirm="delClick(scope.row.id)">
                <el-button type="text" slot="reference" size="mini"
                  v-if="checkPermission('admin.course.del') && scope.row.del_hidden == 0">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>

        <!--分页start-->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <!--分页end-->

      </el-row>
    </el-card>

    <!--添加课程start-->
    <el-dialog title="添加课程" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="40%"
      @close="addDialogClosed">
      <el-form ref="addFormRef" :model="addForm" :rules="courseRules" label-width="100px;">
        <el-form-item label="课程名称" prop="title">
          <el-input v-model="addForm.title" maxlength="14"></el-input>
        </el-form-item>
        <div style="width:178px;">
          <el-form-item label="课程封面" v-loading="progressFlag">
            <el-upload class="avatar-uploader" :action="action" :show-file-list="false" :headers="token"
              :http-request="(file) => { uploadFile(file, 0) }">
              <img v-if="addForm.img" :src="imgDomain + addForm.img" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p style="color:red;font-size:8px;height:10px;margin-top:-20px;text-align:center">图片大小不要超过1M</p>
          </el-form-item>
        </div>
        <el-form-item label="课程标签">
          <el-checkbox-group v-model="tagArr" :max="1">
            <el-checkbox v-for="item in tagList" :label="item.id" :key="item.id">{{ item.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="课程类型">
          <el-select v-model="addCTypeSelect" @change="addSelectChange(addCTypeSelect)">
            <el-option v-for="item in courseTypeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addCourse">确定</el-button>
      </span>
    </el-dialog>
    <!--添加课程end-->

    <!--修改课程start-->
    <el-dialog title="编辑课程" :close-on-click-modal="false" :visible.sync="editDialogVisible" width="40%"
      @close="editDialogClosed">
      <el-form ref="editFormRef" :model="editForm">
        <el-form-item label="课程名称" prop="title">
          <el-input v-model="editForm.title" maxlength="14"></el-input>
        </el-form-item>
        <div style="width:178px;">
          <el-form-item label="课程封面" v-loading="progressFlag">
            <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
              :http-request="(file) => { uploadFile(file, 1) }">
              <img v-if="editForm.img" :src="imgDomain + editForm.img" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p style="color:red;font-size:8px;height:10px;margin-top:-20px;text-align:center">图片大小不要超过1M</p>
          </el-form-item>
        </div>
        <el-form-item label="课程标签">
          <el-checkbox-group v-model="tagArr" :max="1">
            <el-checkbox v-for="item in tagList" :label="item.id" :key="item.id">{{ item.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="课程类型">
          <el-select v-model="editCTypeSelect" @change="editSelectChange(editCTypeSelect)" :disabled="disabled">
            <el-option v-for="item in courseTypeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editCourse">确定</el-button>
      </span>
    </el-dialog>
    <!--修改课程end-->

    <!--课程小节start-->
    <el-dialog title="添加课程小结" :close-on-click-modal="false" :visible.sync="csDialogVisible" width="45%"
      @close="csDialogClosed">
      <el-form label-width="100px">
        <el-form-item label="阅读字数:">
          <el-input type="number" v-model="summaryForm.read_number" min="0"></el-input>
        </el-form-item>

        <el-form-item label="专注力数值:">
          <el-input type="number" v-model="summaryForm.type1Num" min="0" max="100"></el-input>
        </el-form-item>
        <el-form-item label="专注力文案:">
          <el-input type="textarea" v-model="summaryForm.type1Text"></el-input>
        </el-form-item>

        <el-form-item label="阅读力数值:">
          <el-input type="number" v-model="summaryForm.type2Num" min="0" max="100"></el-input>
        </el-form-item>
        <el-form-item label="阅读力文案:">
          <el-input type="textarea" v-model="summaryForm.type2Text"></el-input>
        </el-form-item>

        <el-form-item label="理解力数值:">
          <el-input type="number" v-model="summaryForm.type3Num" min="0" max="100"></el-input>
        </el-form-item>
        <el-form-item label="理解力文案:">
          <el-input type="textarea" v-model="summaryForm.type3Text"></el-input>
        </el-form-item>

        <el-form-item label="表达力数值:">
          <el-input type="number" v-model="summaryForm.type4Num" min="0" max="100"></el-input>
        </el-form-item>
        <el-form-item label="表达力文案:">
          <el-input type="textarea" v-model="summaryForm.type4Text"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="csDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addSummary">确定</el-button>
      </span>
    </el-dialog>
    <!--课程小节end-->
  </div>
</template>

<script>
import { list, add, edit, changeStatus, del, getSummary, changeSummary, getCourseTag } from '@/api/course'
import { getQiniuToken } from '@/api/upload'
import * as qiniu from 'qiniu-js'
import global from '@/utils/global'
export default {
  data() {
    return {
      search_content: '',
      queryInfo: {
        pagenum: 1,
        pagesize: 10
      },
      courseList: [],
      total: 0,
      tagList: [], //课程标签
      tag: [], //默认选中
      tagArr: [],
      addDialogVisible: false, // 添加
      editDialogVisible: false, // 修改
      csDialogVisible: false, // 课程小结
      action: this.uploadUrl + this.uploadApi,
      token: { token: window.sessionStorage.getItem("token") },
      //--------------------添加
      addForm: {
        title: '',
        img: '',
        tag: "",
        course_cate: 1,
      },
      courseRules: {
        title: [
          { required: true, trigger: 'blur', message: '请输入课程名' },
        ],
      },
      //----------------------修改
      editForm: {
        id: '',
        title: '',
        img: '',
        tag: "",
        course_cate: 1,
      },
      paySelected: 1,
      // 图片回显
      dialogImageUrl: '',
      dialogVisible: false,
      //
      progressFlag: false,
      summaryForm: {
        courseId: '',
        read_number: '',
        type1Num: 0,
        type1Text: '',
        type2Num: 0,
        type2Text: '',
        type3Num: 0,
        type3Text: '',
        type4Num: 0,
        type4Text: '',
      },
      courseTypeList: [{ id: 1, name: "阅读课" },], //{id:2,name:"美术课"}
      addCTypeSelect: 1,
      editCTypeSelect: 1,
      disabled: true,
    }
  },
  created() {
    this.getCourseList()
  },
  methods: {
    resetClick() {
      this.search_content = "";
      this.btnClick();
    },
    addSelectChange(val) {
      this.addForm.course_cate = val
    },
    editSelectChange(val) {
      this.editForm.course_cate = val
    },
    btnClick() {
      this.queryInfo.pagenum = 1
      this.queryInfo.pagesize = 10
      this.getCourseList()
    },
    // ----------------------------------------------------
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getCourseList(this.queryInfo)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage
      this.getCourseList(this.queryInfo)
    },
    //----------课程标签--------------
    async getTaglist() {
      let obj = { type: 1 }
      const { data: res } = await getCourseTag(obj)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.tagList = res.data
    },
    //-----------------------------导出--------------------------
    excelExport() {
      const headers = {
        '课程ID': 'id',
        '课程编号': 'course_no',
        '课程名称': 'title',
        '课程封面': 'img',
        '购买人数(虚拟)': 'unreal_buy_number',
        '购买人数(实际)': 'real_buy_number',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        const { data: res } = await list({ pagenum: 1, pagesize: this.total, search_content: this.search_content })
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '课程信息表',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },

    //-------------------------------------------------------
    // 课程列表
    async getCourseList(query) {
      const { data: res } = await list({ search_content: this.search_content, pagenum: this.queryInfo.pagenum, pagesize: this.queryInfo.pagesize })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.courseList = res.data.data
      this.total = res.data.total
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
      this.clear()
    },
    clear() {
      this.addForm.img = ''
      this.addForm.tag = ''
      this.editForm.img = ''
      this.editForm.tag = ''
      this.tagArr = [],
        this.disabled = true
    },
    //-----------------------小结-----------------
    async addSummary() {
      if (this.checkSummary() != 1) {
        return
      }
      const { data: res } = await changeSummary(this.summaryForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.csDialogVisible = false
    },
    checkSummaryEmpty() {
      if (this.summaryForm.read_number != '') {
        return -1
      }
      if (this.summaryForm.type1Num != '' && this.summaryForm.type1Text != '') {
        return -1
      }
      if (this.summaryForm.type2Num != '' && this.summaryForm.type2Text != '') {
        return -1
      }
      if (this.summaryForm.type3Num != '' && this.summaryForm.type3Text != '') {
        return -1
      }
      if (this.summaryForm.type4Num != '' && this.summaryForm.type4Text != '') {
        return -1
      }
      return 1
    },
    checkSummary() {
      if (this.summaryForm.read_number < 1) {
        this.$message.error('请填入有效的阅读字数')
        return -1
      }
      if (this.summaryForm.type1Num < 0 || this.summaryForm.type1Num > 100) {
        this.$message.error('数值必须为0-100之间的整数，请调整后保存')
        return -1
      }
      if (this.summaryForm.type2Num < 0 || this.summaryForm.type2Num > 100) {
        this.$message.error('数值必须为0-100之间的整数，请调整后保存')
        return -1
      }
      if (this.summaryForm.type3Num < 0 || this.summaryForm.type3Num > 100) {
        this.$message.error('数值必须为0-100之间的整数，请调整后保存')
        return -1
      }
      if (this.summaryForm.type4Num < 0 || this.summaryForm.type4Num > 100) {
        this.$message.error('数值必须为0-100之间的整数，请调整后保存')
        return -1
      }
      console.log(parseInt(this.summaryForm.type1Num))
      const sum = parseInt(this.summaryForm.type1Num) + parseInt(this.summaryForm.type2Num) + parseInt(this.summaryForm.type3Num) + parseInt(this.summaryForm.type4Num)
      console.log(sum)
      if (sum != 100) {
        this.$message.error('数值总和必须为100，请调整后保存')
        return -1
      }
      return 1
    },
    // -------------------------------------------------添加课程------------------------------------------------------
    addCourseClick() {
      this.getTaglist()
      this.addDialogVisible = true
    },
    async addCourse() {
      // 验证
      if (this.addForm.title == "") {
        return this.$message.error('课程名称必填!')
      }
      if (this.addForm.img == "") {
        return this.$message.error('请上传课程封面!')
      }
      if (this.tagArr.length > 0) {
        this.addForm.tag = this.tagArr.join()
      }
      const { data: res } = await add(this.addForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.addDialogVisible = false
      this.getCourseList()
    },
    //---------------------------------------------------------图片上传----------------------------------------------
    img_limit(val) {
      let type = val.file.type
      let size = val.file.size
      let type_arr = type.split("/")
      let limit = 1   // 1M
      if (type_arr[0] == "image") {
        if (size > 1024 * 1024 * limit) {
          return -1
        }
      }
      return 1
    },
    async uploadFile(uploadInfo, index) {
      if (this.img_limit(uploadInfo) == -1) {
        return this.$message.error("上传图片请小于1M")
      }

      const { data: token } = await getQiniuToken()
      const that = this
      const observer = {
        next(res) {
          console.log('next:', res)
        },
        error(err) {
          console.log(err)
        },
        complete(res) {
          console.log('complete:....')
          if (index == 0) {
            that.addForm.img = res.key
          } else if (index == 1) {
            that.editForm.img = res.key
          }
          that.progressFlag = false
        }
      }
      this.progressFlag = true
      let name = uploadInfo.file.name
      let fileName = global.getFileName(name)
      if (parseInt(fileName) != NaN) {
        name = fileName + "-" + global.randStr(10) + "." + global.getExt(name)
      }
      const observable = qiniu.upload(uploadInfo.file, "interactcourse/" + name, token, {}, {})
      const subscription = observable.subscribe(observer)
    },
    //-------------------------------------------------编辑课程--------------------------------------------------------
    showEditDialog(row) {
      this.getTaglist()
      this.editForm.title = row.title
      this.editForm.img = row.img
      this.editForm.id = row.id
      this.editCTypeSelect = row.course_cate
      if (row.change_course_cate == 0) {
        this.disabled = false
      }
      if (row.tag_id) {
        this.tagArr = row.tag_id.toString().split().map((val) => { return parseInt(val) })
      }
      this.editDialogVisible = true
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
      this.clear()
    },
    async editCourse() {
      // 验证
      if (this.editForm.title == "") {
        return this.$message.error('课程名称必填!')
      }
      if (this.editForm.img == "") {
        return this.$message.error('请上传课程封面!')
      }

      if (this.tagArr.length > 0) {
        this.editForm.tag = this.tagArr.join()
      }

      const { data: res } = await edit(this.editForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }

      this.$message.success(res.msg)
      this.editDialogVisible = false
      this.getCourseList()
    },
    //--------------------------------------修改状态--------------------------------------------------
    async changeStatusClick(row) {
      const { data: res } = await changeStatus({ id: row.id, status: row.status })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.getCourseList()
    },
    //-----------------------------------删除--------------------------------------------
    confirm() {
      this.visible = false;
      this.$emit('onConfirm');
    },
    async delClick(id) {
      const { data: res } = await del({ id: id })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success('已删除')
      this.getCourseList()
    },
    //----------------------------------------------------- 内容管理-----------------------------
    contentManage(id, course_cate) {
      if (course_cate == 1) {
        this.$router.push({ path: '/admin.course.content', query: { id: id } })
      } else if (course_cate == 2) {
        this.$router.push({ path: '/admin.course.contentV2', query: { id: id } })
      }
    },
    async showCS(id) {
      this.summaryForm.courseId = id
      const { data: res } = await getSummary(this.summaryForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.summaryForm.read_number = res.data.read_number
      if (res.data.data) {
        res.data.data.forEach((value) => {
          if (value.game_type == 1) {
            this.summaryForm.type1Num = value.energy_num
            this.summaryForm.type1Text = value.description
          } else if (value.game_type == 2) {
            this.summaryForm.type2Num = value.energy_num
            this.summaryForm.type2Text = value.description
          } else if (value.game_type == 3) {
            this.summaryForm.type3Num = value.energy_num
            this.summaryForm.type3Text = value.description
          } else {
            this.summaryForm.type4Num = value.energy_num
            this.summaryForm.type4Text = value.description
          }
        })
      }
      this.csDialogVisible = true
    },
    csDialogClosed() {
      this.summaryForm.courseId = ''
      this.summaryForm.read_number = '',
        this.summaryForm.type1Num = 0,
        this.summaryForm.type1Text = '',
        this.summaryForm.type2Num = 0,
        this.summaryForm.type2Text = '',
        this.summaryForm.type3Num = 0,
        this.summaryForm.type3Text = '',
        this.summaryForm.type4Num = 0,
        this.summaryForm.type4Text = ''
    }
  }
}
</script>


<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.loadingModal {
  width: 100%;
  margin: 0 auto;
  background: rgba(22, 21, 21, 0.3);
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
}
</style>