import request from '@/utils/request'

//-------------------------------视频课程----------------

export function videoCourseAdd (data) {
  return request({
    url: '/admin/videoCourse/add',
    method: 'post',
    data
  })
}

export function videoCourseList (data) {
  return request({
    url: '/admin/videoCourse/list',
    method: 'post',
    data
  })
}

export function changeStatus (data) {
  return request({
    url: '/admin/videoCourse/changeStatus',
    method: 'post',
    data
  })
}

export function del (data) {
  return request({
    url: '/admin/videoCourse/del',
    method: 'post',
    data
  })
}

//------------------------------------------------------
export function changeVideoStatus (data) {
  return request({
    url: '/admin/video/changeStatus',
    method: 'post',
    data
  })
}


export function videoAdd (data) {
  return request({
    url: '/admin/video/videoAdd',
    method: 'post',
    data
  })
}

export function videoEdit (data) {
  return request({
    url: '/admin/video/videoEdit',
    method: 'post',
    data
  })
}

export function videoList(data) {
  return request({
    url: '/admin/video/list',
    method: 'post',
    data
  })
}

export function videoDel (data) {
  return request({
    url: '/admin/video/del',
    method: 'post',
    data
  })
}

//---------------------------------生成链接
export function createShortUrl(data) {
  return request({
    url: '/admin/VideoShortLink/createVideoShortUrl',
    method: 'post',
    data
  })
}

export function linkList(data) {
  return request({
    url: '/admin/VideoShortLink/list',
    method: 'post',
    data
  })
}

export function buyDetailList(data) {
  return request({
    url: '/admin/VideoShortLink/detail',
    method: 'post',
    data
  })
}