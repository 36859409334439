<!--课程订单-->
<template>
    <div>
        <el-card>
            <el-row style="margin-top:10px;">
                <el-form ref="form">
                    <el-col :span="8">
                        <el-form-item label="后台账号:" label-width="90px">
                            <el-input type="text" v-model="search.admin_name" style="width:300px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="用户端手机号:" label-width="120px">
                            <el-input type="text" v-model="search.user_mobile"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="账号权限:" label-width="120px">
                            <el-select v-model="search.type" placeholder="请选择">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form>
                <el-form ref="form">
                    <el-col :span="5">
                        <el-form-item label="所属学校:" label-width="90px">
                            <el-select v-model="search.school_id" placeholder="请选择">
                                <el-option v-for="item in schoolAllList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-button type="primary" style="width:100px;margin-left:20px" @click="submitSearch">搜索</el-button>
                        <el-button type="warning" icon="el-icon-refresh" style="width:100px;margin-left:20px" @click="resetSearch">重置</el-button>
                    </el-col>
                </el-form>
            </el-row>
            <div class="clear"></div>
            <el-row style="margin-top:10px;">
                <el-button style="width:100px;" @click="callAddUserDialog">添加账号</el-button>
                <el-button type="primary" style="width:100px;" @click="callExcelExport">导出</el-button>
            </el-row>
            <schoolAccountDataVue ref='schoolAccountDataVue' @openSchoolAccountUpdate="openSchoolAccountUpdate" @openSchoolAccountBindPhone="openSchoolAccountBindPhone"></schoolAccountDataVue>
            <schoolAccountAddVue ref='schoolAccountAddVue' @callSchoolAccountList="callSchoolAccountList">
            </schoolAccountAddVue>
            <schoolAccountUpdateVue ref='schoolAccountUpdateVue' @callSchoolAccountList="callSchoolAccountList"></schoolAccountUpdateVue>
            <schoolAccountBindPhoneVue ref='schoolAccountBindPhoneVue' @callSchoolAccountList="callSchoolAccountList"></schoolAccountBindPhoneVue>
        </el-card>
    </div>
</template>
  
<script>

import schoolAccountDataVue from '../schoolAccount/schoolAccountData.vue'
import schoolAccountAddVue from '../schoolAccount/schoolAccountAdd.vue'
import schoolAccountUpdateVue from '../schoolAccount/schoolAccountUpdate.vue'
import schoolAccountBindPhoneVue from '../schoolAccount/schoolAccountBindPhone.vue'

import { getSchoolListAll } from '@/api/bindSchool'
import global_ from '@/utils/global.js'
export default {
    components: {
        schoolAccountDataVue: schoolAccountDataVue,
        schoolAccountAddVue: schoolAccountAddVue,
        schoolAccountUpdateVue: schoolAccountUpdateVue,
        schoolAccountBindPhoneVue:schoolAccountBindPhoneVue
    },
    data() {
        return {
            search: {
                type: 0,
                school_id: 0,
                admin_name: "",
                user_mobile: ""
            },
            options: global_.school_account_search_list,
            schoolAllList: []
        }
    },
    created() {
        this.callSchoolListAllByList();
        this.$nextTick(function () {
            this.$refs.schoolAccountDataVue.callSchoolAccountList();
        })
    },

    methods: {
        resetSearch(){
            this.search.type = 0;
            this.search.school_id = 0;
            this.search.admin_name = "";
            this.search.user_mobile = "";
            this.submitSearch();
        },
        async callSchoolListAllByList() {
            const { data: res } = await getSchoolListAll({})
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.schoolAllList = [...global_.school_list_default, ...res.data]
        },
        submitSearch() {
            this.$refs.schoolAccountDataVue.search.pagenum = 1
            this.$refs.schoolAccountDataVue.search.pagesize = 10
            this.$refs.schoolAccountDataVue.search.type = this.search.type;
            this.$refs.schoolAccountDataVue.search.school_id = this.search.school_id;
            this.$refs.schoolAccountDataVue.search.admin_name = this.search.admin_name;
            this.$refs.schoolAccountDataVue.search.user_mobile = this.search.user_mobile;
            this.$refs.schoolAccountDataVue.callSchoolAccountList();
        },
        callAddUserDialog() {
            this.$refs.schoolAccountAddVue.schoolAccountDialogVisible = true;
        },
        callSchoolAccountList() {
            this.$refs.schoolAccountDataVue.callSchoolAccountList();
        },
        openSchoolAccountUpdate(id) {
            this.$refs.schoolAccountUpdateVue.addForm.admin_id = id;
            this.$refs.schoolAccountUpdateVue.schoolAccountUpdateDialogVisible = true;
        },
        openSchoolAccountBindPhone(id){
            this.$refs.schoolAccountBindPhoneVue.addForm.id = id;
            this.$refs.schoolAccountBindPhoneVue.schoolAccountBindPhoneDialogVisible = true;
        },
        callExcelExport() {
            this.$refs.schoolAccountDataVue.excelExport();
        },
    }
}
</script>
  
  
<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}

.div-left {
    float: left;
    margin-right: 10px;
}

.input {
    width: 220px;
}

.d-order-no {
    float: left;
    margin-right: 15px;
    height: 35px;
    line-height: 35px;
}
</style>