import request from '@/utils/request'

// 渠道

export function list(data) {
  return request({
    url: '/admin/channel/list',
    method: 'post',
    data
  })
}

export function add(data) {
  return request({
    url: '/admin/channel/add',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: '/admin/channel/edit',
    method: 'post',
    data
  })
}

export function changeStatus(data) {
  return request({
    url: '/admin/channel/changeStatus',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: '/admin/channel/del',
    method: 'post',
    data
  })
}
//-----------------------绑定课包
export function bindCourse(data) {
  return request({
    url: '/admin/channel/bindCourse',
    method: 'post',
    data
  })
}

export function getCourseBagType(data) {
  return request({
    url: '/admin/ChannelCourseBag/getCourseBagType',
    method: 'post',
    data
  })
}


export function bList(data) {
  return request({
    url: '/admin/ChannelCourseBag/list',
    method: 'post',
    data
  })
}

export function bAdd(data) {
  return request({
    url: '/admin/ChannelCourseBag/add',
    method: 'post',
    data
  })
}
export function bEdit(data) {
  return request({
    url: '/admin/ChannelCourseBag/edit',
    method: 'post',
    data
  })
}
export function bChangeStatus(data) {
  return request({
    url: '/admin/ChannelCourseBag/changeStatus',
    method: 'post',
    data
  })
}
export function bDel(data) {
  return request({
    url: '/admin/ChannelCourseBag/del',
    method: 'post',
    data
  })
}

//------------------------购买信息
export function buyList(data) {
  return request({
    url: '/admin/ChannelCourseBag/buyInfo',
    method: 'post',
    data
  })
}