<template>
    <span>
        <div style="clear:both"></div>
        <el-dialog title="客服" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="50%">
            <el-table :data="device_list" border stripe :row-style="{ height: '50px' }">
                <el-table-column label="设备类型" prop="device_type" :formatter="dealDeviceType"></el-table-column>
                <el-table-column label="设备机型" prop="device_machine" :formatter="dealDeviceMachine"></el-table-column>
                <el-table-column label="设备版本号" prop="device_version"></el-table-column>
                <el-table-column label="设备品牌" prop="device_brand"></el-table-column>
                <el-table-column label="设备型号" prop="device_machine_version"></el-table-column>
                <el-table-column label="登录次数" prop="counts"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close()">取消</el-button>
                <el-button type="primary" @click="close()">确定</el-button>
            </span>
        </el-dialog>
    </span>
</template>

<script>
import { getUserDevice } from '@/api/interactUser'
export default {
    data() {
        return {
            device_list: [],
            search: {
                user_id: 0
            },
            addDialogVisible:false
        }
    },
    methods: {
        close(){
            this.search.user_id = 0;
            this.addDialogVisible = false;
            this.device_list = [];
        },
        open(user_id){
            this.search.user_id =  user_id;
            this.getUserDeviceList();
            this.addDialogVisible = true;
        },
        async getUserDeviceList(){
            const { data: res } = await getUserDevice(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.device_list = res.data
        },
        dealDeviceType(row) {
            let device_type_string = "";
            switch (row.device_type) {
                case 1:
                    device_type_string = "手机"
                    break;
                case 2:
                    device_type_string = "平板"
                    break;
                default:
                    break;
            }
            return device_type_string;
        },
        dealDeviceMachine(row) {
            let device_machine_string = "";
            switch (row.device_machine) {
                case 1:
                    device_machine_string = "iOS"
                    break;
                case 2:
                    device_machine_string = "安卓"
                    break;
                default:
                    break;
            }
            return device_machine_string;
        }
    }
}
</script>