<template>
  <div style="float:left;margin-left:10px">
    <el-button type="primary"  @click="addBtn">新增视频</el-button>

    <!--添加视频-->
    <el-dialog title="添加视频" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="60%" @close="addDialogClosed">
      <el-form label-width="100px">
        <div v-for="(item,index) in videoList" :key="index" style="border:1px solid black;margin:10px;padding:8px;width:800px;">
          <el-form-item label="*视频名称">
            <el-input v-model="item.title" maxlength="36"></el-input>
          </el-form-item>
          <div style="width:178px;">
            <el-form-item label="*视频上传:" >
              <el-upload class="avatar-uploader my-upload" 
                action="#" 
                :show-file-list="false" 
                :http-request="(file) => { uploadFile(file, 1, index) }"
                
              >
                <div v-if="item.videoUrl" style="height:178px;width:178px;"><span style="line-height:178px">{{item.videoUrl | removeDir | hidden }}</span></div>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-progress type="circle" :percentage="process[index]" v-if="show[index]" class="process"></el-progress>
            </el-form-item>
          </div>
          <el-form-item label="权重">
            <el-input v-model="item.sort"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-button @click="add" v-if="edit">添加视频课程</el-button>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit()">确定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { getQiniuToken } from '@/api/upload'
import global from '@/utils/global'
import * as qiniu from 'qiniu-js'
import { videoAdd ,videoEdit} from '@/api/video'

export default {
  props:{
    editObj:{},
  },
  data(){
    return {
      videoList:[],
      addDialogVisible:false,
      videoId: this.$route.query.id,
      process:[],
      show:[],
      is_edit:0,
      edit:1,
      id:"",
    }
  },
  watch:{
    is_edit(newVal,oldVal){
      if(newVal == 1){
        if(Object.keys(this.editObj).length){
          this.initEdit()
        }
      }
    }
  },
  created(){
    
  },
  methods:{
    initEdit(){
      this.videoList.push({
        title:this.editObj.video_name,
        sort:this.editObj.video_weight,
        videoUrl:this.editObj.video_url
      })
      this.edit = 0
      this.id = this.editObj.id
    },
    addBtn(){
      this.addDialogVisible = true
    },
    add(){
      this.videoList.push({title:"",videoUrl:"",sort:100})
      this.process.push(0)
      this.show.push(false)
    },
    addDialogClosed(){
      this.videoList = []
      this.process = []
      this.show = []
      this.is_edit = 0
      this.edit = 1
      this.id = ""
    },

    async submit(){
      if(this.is_edit == 1){
         const {data:res} = await videoEdit({"videoList":this.videoList,"videoId":this.videoId,"id":this.id})
          if (res.status != 0) {
            return this.$message.error(res.msg)
          }
      }else{
        const {data:res} = await videoAdd({"videoList":this.videoList,"videoId":this.videoId})
         if (res.status != 0) {
            return this.$message.error(res.msg)
          }
      }
     
      this.$message.success("成功!");
      this.addDialogVisible = false
      this.$emit("getVideoList")
    },

     //---文件上传------
    img_limit(val) {
      let type = val.file.type
      let size = val.file.size
      let type_arr = type.split("/")
      let limit = 1   // 1M
      if (type_arr[0] == "image") {
        if (size > 1024 * 1024 * limit) {
          return -1
        }
      }
      return 1
    },
    async uploadFile(uploadInfo, type, index) {
      // if (this.img_limit(uploadInfo) == -1) {
      //   return this.$message.error("上传图片请小于1M")
      // }

      const { data: token } = await getQiniuToken()
      const that = this
      const observer = {
        next(res) {
          that.$set(that.process,index,parseInt(res.total.percent))
          // that.$set(that.process,index,res.total.percent)
        },
        error(err) {console.log(err)},
        complete(res) {
          that.$set(that.videoList[index],"videoUrl",res.key)
          // that.show[index] = false
          that.$set(that.show,index,false)
        }
      }
      let name = uploadInfo.file.name
      let fileName = global.getFileName(name)

      if (parseInt(fileName) != NaN) {
        name = fileName + "-" + global.randStr(10) + "." + global.getExt(name)
      }
      if(type == 4){
        this.show = 1
      } 
      this.show[index] = true
      this.$forceUpdate();
      const observable = qiniu.upload(uploadInfo.file, "interactcourse/" + name, token, {}, {})
      const subscription = observable.subscribe(observer)
    },
    getIndex(key) {
      let start = key.lastIndexOf("\/")
      let end = key.lastIndexOf("-")
      let str = key.substring(start + 1, end)
      if (parseInt(str) == NaN) {
        return -1
      } else {
        return parseInt(str)
      }
    },
  }
}
</script>

<style scoped>
.my-upload{
  position:relative;
}

.process{
  position: absolute;
  margin-top:-165px;
  margin-left:30px;
}
</style>