import request from '@/utils/request'

export function list(data) {
  return request({
    url: '/admin/exchange/list',
    method: 'post',
    data
  })
}

export function redeemCourse(data) {
  return request({
    url: '/admin/exchange/redeemCourse',
    method: 'post',
    data
  })
}
export function add(data) {
  return request({
    url: '/admin/exchange/add',
    method: 'post',
    data
  })
}
export function edit(data) {
  return request({
    url: '/admin/exchange/edit',
    method: 'post',
    data
  })
}
export function changeStatus(data) {
  return request({
    url: '/admin/exchange/changeStatus',
    method: 'post',
    data
  })
}
export function del(data) {
  return request({
    url: '/admin/exchange/del',
    method: 'post',
    data
  })
}
//----
export function redeemList(data) {
  return request({
    url: '/admin/exchangeCode/list',
    method: 'post',
    data
  })
}
export function redeemAdd(data) {
  return request({
    url: '/admin/exchangeCode/add',
    method: 'post',
    data
  })
}
export function changeCodeStatus(data) {
  return request({
    url: '/admin/exchangeCode/changeStatus',
    method: 'post',
    data
  })
}