<template>
  <div>
    <el-card>
        <el-row class="nav-">
          <el-col :span="6">音频名称: <el-input type="text"  v-model="search.title" prefix-icon="el-icon-search"></el-input></el-col>
          <el-col :span="3" style="margin-left:10px">状态: 
            <el-select v-model="search.status" placeholder="请选择"  filterable
                style="width:100%">
                <el-option value="">全部</el-option>
                <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id">{{
                  item.name
                }}
                </el-option>
              </el-select>
          </el-col>
          <el-col :span="3" style="margin-left:10px">所属合集: 
            <el-select v-model="search.collection_id" placeholder="请选择"  filterable
                style="width:100%">
                <el-option value="">全部</el-option>
                <el-option v-for="(item, index) in audioCollectionList" :key="index" :label="item.audio_group_name" :value="item.id">{{
                  item.audio_group_name
                }}
                </el-option>
              </el-select>
          </el-col>
          <el-button  type="primary" icon="el-icon-search" @click="searchClick" style="margin-top:14px;margin-left:10px">搜索</el-button>
          <el-button  type="warning" icon="el-icon-refresh" @click="btnClick" style="margin-top:14px;margin-left:10px">重置</el-button>
        </el-row>
        <el-row style="margin-top:10px">
           <el-button type="primary"  @click="addAudio" style="float:left;">添加音频</el-button>
          <el-button type="success"  @click="excelExport" >导出</el-button>
        </el-row>
        <el-row style="margin-top:10px;">
            <el-table :data="list" border stripe>
            <el-table-column label="ID"  prop="audio_no"></el-table-column>
            <el-table-column label="音频名称" prop="audio_name"></el-table-column>
            <el-table-column label="音频封面" >
              <template slot-scope="scope">
                <el-image :src="imgDomain + scope.row.audio_thumb" style="width: 30px;height:30px;"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="所属合集" prop="audio_group_name"></el-table-column>
            <el-table-column label="实际收藏数" prop="real_collect_num"></el-table-column>
            <el-table-column label="虚拟收藏数" prop="audio_virtual_collect_number"></el-table-column>
            <el-table-column label="实际播放数" prop="real_play_num"></el-table-column>
            <el-table-column label="虚拟播放数" prop="audio_virtual_play_number"></el-table-column>
            <el-table-column label="实际分享数" prop="real_share_num"></el-table-column>
            <el-table-column label="状态"  prop="audio_status_str"> </el-table-column>
            <el-table-column label="操作" width="240px">
                <template slot-scope="scope" >
                  <el-button type="text" size="mini"  @click="edit(scope.row)">编辑</el-button>
                  <el-button type="text" v-if="scope.row.audio_status == 2" size="mini" @click="changeStatus(scope.row)">启用</el-button>
                  <el-button type="text" v-else-if="scope.row.audio_status == 1" size="mini" @click="changeStatus(scope.row)">禁用</el-button>
                  <el-button type="text"  size="mini" @click="del(scope.row.id)" v-if="scope.row.audio_group_buy_num<1">删除</el-button>
                  <el-button type="text" size="mini" @click="jump(scope.row.id)" >收藏/播放记录</el-button>
                </template>
            </el-table-column>
          </el-table>

          <!--分页start-->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.pagenum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="search.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          <!--分页end--> 
        </el-row>

        <!--添加-->
        <el-dialog title="添加音频" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="50%"
          @close="addDialogClosed">
          <el-form ref="addFormRef"  label-width="120px">
            <el-form-item label="音频名称:">
              <el-input v-model="addForm.title" type="text" maxlength="30"></el-input>
            </el-form-item>
            <div style="width:178px;">
              <el-form-item label="音频封面:"  >
                <el-upload class="avatar-uploader" 
                    action="#"
                    :show-file-list="false"     
                    :http-request="(file)=> {uploadFile(file,1,1)}"
                >
                  <img v-if="addForm.cover_img" :src="imgDomain + addForm.cover_img" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <p>1:1</p>
              </el-form-item>
            </div>
            <div style="width:178px;">
              <el-form-item label="音频上传:"  >
                <el-upload class="avatar-uploader" 
                    action="#"
                    :show-file-list="false"     
                    :http-request="(file)=> {uploadFile(file,2,1)}"
                >
                  <div v-if="addForm.audio" style="width:178px;height:35px">{{ addForm.audio| removeDir |hidden }}</div>
                  <div v-else style="width:178px;height:35px">上传</div> 
                </el-upload>
              </el-form-item>
            </div>
            <el-form-item label="虚拟数据:">
              <el-col>
                虚拟收藏量<el-input  v-model="addForm.virtual_collect" type="text" ></el-input>
              </el-col>
              <el-col>
                虚拟播放量<el-input  v-model="addForm.virtual_play" type="text" ></el-input>
              </el-col>
            </el-form-item> 
            
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="addSubmit">保存</el-button>
          </span>
        </el-dialog>

         <!--编辑--> 
         <el-dialog title="编辑音频" :close-on-click-modal="false" :visible.sync="editDialogVisible" width="50%"
          @close="editDialogClosed">
          <el-form ref="addFormRef"  label-width="120px">
            <el-form-item label="音频名称">
              <el-input v-model="editForm.title" type="text" maxlength="30"></el-input>
            </el-form-item>
            <div style="width:178px;">
              <el-form-item label="音频封面:"  >
                <el-upload class="avatar-uploader" 
                    action="#"
                    :show-file-list="false"     
                    :http-request="(file)=> {uploadFile(file,1,2)}"
                >
                  <img v-if="editForm.cover_img" :src="imgDomain + editForm.cover_img" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <!-- <p>1:1</p> -->
              </el-form-item>
            </div>
            <div style="width:178px;">
              <el-form-item label="音频上传:"  >
                <el-upload class="avatar-uploader" 
                    action="#"
                    :show-file-list="false"     
                    :http-request="(file)=> {uploadFile(file,2,2)}"
                >
                   <div v-if="editForm.audio" style="width:178px;height:35px">{{ editForm.audio| removeDir |hidden }}</div>
                  <div v-else style="width:178px;height:35px">上传</div> 
                </el-upload>
              </el-form-item>
            </div>
            <el-form-item label="虚拟数据:">
              <el-col>
                虚拟收藏量<el-input  v-model="editForm.virtual_collect" type="text" ></el-input>
              </el-col>
              <el-col>
                虚拟播放量<el-input  v-model="editForm.virtual_play" type="text" ></el-input>
              </el-col>
            </el-form-item> 
            
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="editSubmit">保存</el-button>
          </span>
        </el-dialog>

      </el-card> 
  </div>
</template>


<script>

import {list,add,edit,changeStatus,del,conllectionList} from '@/api/audio'
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'
import global from '@/utils/global'
export default {
  components:{
  },
  data(){
    return {
      list:[],
      audioCollectionList:[],
      search:{
        title:"",
        status:"",
        collection_id:"",
        pagenum:1,
        pagesize:10,
      },
      addForm:{
        title:"",
        cover_img:"",
        audio:"",
        virtual_collect:"",
        virtual_play:"",
      },
      editForm:{
        title:"",
        cover_img:"",
        audio:"",
        virtual_collect:"",
        virtual_play:"",
        id:"",
      },
      total:0,
      typeList:[{"id":1,"name":"正常"},{"id":2,"name":"禁用"}],
      addDialogVisible:false,
      editDialogVisible:false
    }
  },
  filters: {
    removeDir(value) {
      if(Object.keys(value).length==0) return value
      let site = value.lastIndexOf("\/");
      return value.substring(site+1, value.length)
    },
    hidden(value){
      if(Object.keys(value).length==0) return value
      let end = value.lastIndexOf("-")
      if(end>20){
        end = 20
      }
      return value.substring(0, end)
    },
    tranNum(value) {
      if(Object.keys(value).length==0) return value
      if (value == 1){
        return 'A'
      }else if(value ==2){
        return 'B'
      }else if(value ==3){
        return 'C'
      }else if(value ==4){
        return 'D'
      }
    },
  },
  created(){
    this.getList()
    this.getAudioCollectionList()
  },
  methods:{
    editList(){
      this.getList()
    },
    async getAudioCollectionList(){
      const {data:res} = await conllectionList({type:2})
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.audioCollectionList = res.data
    },
    async getList(){
      const {data:res} = await list(this.search)
       if (res.status != 0) {
         return this.$message.error(res.msg)
       }
       this.total = res.data.total
       this.list = res.data.data
    },

    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },

    addAudio(){
      this.addDialogVisible = true
    },
    async addSubmit(){
      const {data:res} = await add(this.addForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.getList()
      this.addDialogVisible = false
    },
    addDialogClosed(){
      this.addDialogVisible = false
      this.clear()
    },
    edit(row){
      this.editDialogVisible = true
      this.editForm.title = row.audio_name
      this.editForm.cover_img = row.audio_thumb
      this.editForm.audio = row.audio_url
      this.editForm.virtual_collect = row.audio_virtual_collect_number
      this.editForm.virtual_play = row.audio_virtual_play_number
      this.editForm.id = row.id
    },
    async editSubmit(){
      const {data:res} = await edit(this.editForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.getList()
      this.editDialogVisible = false
    },
    editDialogClosed(){
      this.editDialogVisible = false
      this.clear()
    },
    clear(){
      this.addForm.title=""
      this.addForm.cover_img=""
      this.addForm.audio=""
      this.addForm.virtual_collect=""
      this.addForm.virtual_play=""
      this.editForm.title=""
      this.editForm.cover_img=""
      this.editForm.audio=""
      this.editForm.virtual_collect=""
      this.editForm.virtual_play=""
    },
    searchClick(){
      this.getList()
    },
    
    btnClick() {
      this.search.title = ""
      this.search.type = ""
      this.search.status = ""
      this.search.collection_id = ""
      this.search.pagenum = 1
      this.search.pagesize = 10
      this.getList()
    },

    async del(id){
      this.$confirm("是否要删除", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {data:res} = await del({"id":id})
        if (res.status != 0) {
          return this.$message.error(res.msg)
        } 
        this.$message.success("删除成功!")
        this.getList()
      }).catch(() => {
      }) 
    },

    async uploadFile(uploadInfo,index,type){
      const {data:token} =  await getQiniuToken()
      const that = this
      const observer = {
          next(res){
            that.percentage = parseInt(res.total.percent.toString().split(".")[0])
          },
          error(err){
            console.log(err)
          },
          complete(res){
            if(index == 1){
              if(type==1){
                that.addForm.cover_img = res.key 
              }else{
                that.editForm.cover_img = res.key 
              }
            }else if(index== 2){
              if(type == 1){
                that.addForm.audio = res.key
              }else{
                that.editForm.audio = res.key
              } 
            }
            that.show = false  
          }
      }
      let name = uploadInfo.file.name
      let fileName = global.getFileName(name)
      if(parseInt(fileName) !=NaN){
        name = fileName+ "-" + global.randStr(10) +"."+ global.getExt(name)
      }
      const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+ name,token,{},{})
      const subscription =  observable.subscribe(observer)
    },

    async changeStatus(row){
      const {data:res} =  await changeStatus({id:row.id,status:row.audio_status})
       if(res.status!=0){
        return this.$message.error(res.msg)
      } 
      this.$message.success(res.msg)
      this.getList()
    },

    jump(id){
      this.$router.push({path:'/admin.audio.audioRecords',query:{id:id}})
    },
    
    excelExport() {
      const headers = {
        'ID':"audio_no",
        '音频名称': 'audio_name',
        '音频封面': 'audio_thumb',
        '所属合集': 'audio_group_name',
        '实际收藏数': 'real_collect_num',
        '虚拟收藏数': 'audio_virtual_collect_number',
        '实际播放数': 'real_play_num',
        '虚拟播放数': 'audio_virtual_play_number',
        '实际分享数': 'real_share_num',
        '状态': 'audio_status_str',
      }
      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await list(this.search)
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '音频信息',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.search.pagesize = 10
      })
    },

    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },
  }
}
</script>