<!---------------------课程用户------------------------------->
<!----------------------------------------------------------->
<template>
  <div>
    <el-card>
      <div class="nav_1">
        <div style="float:left;">
          <div style="float:left;margin-right:5px;height:40px;line-height:40px;" >用户搜索: </div>
          <div  style="float:left;">
            <el-input placeholder="输入用户名或手机号" v-model="search.keyword" ></el-input>
          </div>
          <div style="float:left;margin-left:10px">
            <el-button  type="primary" icon="el-icon-search" @click="btnClick" >搜索</el-button>
            <el-button  type="warning" icon="el-icon-refresh" @click="reset" >重置</el-button>
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <el-row style="margin-top:10px;">
        <el-button type="success" @click="excelExport()">Excel导出</el-button>
        <el-button type="success" @click="excelExport1()">成长星非零用户导出</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
          <el-table :data="list" 
          border stripe 
          :row-style="{height:'45px'}" 
          :cell-style='cellStyle' 
          :header-cell-style='rowClass'
          >
          <el-table-column label="用户id" prop="id"></el-table-column>
          <el-table-column label="用户头像" align="center">
              <template slot-scope="scope">
                <el-image v-if="scope.row.headimgurl" :src="scope.row.headimgurl" style="width: 30px;height:30px;"></el-image>
              </template>
            </el-table-column>
          <el-table-column label="用户昵称" prop="nickname"></el-table-column>
          <el-table-column label="手机号" prop="mobile_phone" width="108px"></el-table-column>
          <el-table-column label="宝宝性别" >
            <template slot-scope="scope">
              <span v-if="scope.row.boby_sex == 1">男</span>
              <span v-else-if="scope.row.boby_sex == 2">女</span>
            </template>
          </el-table-column>
          <el-table-column label="宝宝生日" prop="boby_birth" ></el-table-column>
          <el-table-column label="宝宝学年" prop="boby_school_age_string"></el-table-column>
          <el-table-column label="主要陪伴亲属" prop="boby_relatives_id_string"></el-table-column>
          <el-table-column label="是否借阅用户" prop="">
            <template slot-scope="scope">
              <span v-if="scope.row.is_borrow_user == 1">是</span>
              <span v-else>否</span>
            </template>
          </el-table-column>
          <el-table-column label="累计星星数量" prop="course_star" :render-header="renderHeader" width="160px">
          </el-table-column>
          <el-table-column label="累计完成课程" prop="course_complete"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope" >
                <span  v-if="scope.row.status ==1">启用</span>
                <span v-else style="color:red">禁用</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240px">
            <template slot-scope="scope" >
              <el-button type="text" size="mini" @click="jumpUserDetail(scope.row.id)" >用户详情</el-button>
              <el-button type="text" size="mini" @click="jump(scope.row.id)" >购课记录</el-button>
              <el-button  type="text" slot="reference" size="mini" v-if="scope.row.status ==2" @click="changeSta(scope.row.id,scope.row.status)">启用</el-button>
              <el-button  type="text" slot="reference" size="mini" v-else @click="changeSta(scope.row.id,scope.row.status)">禁用</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--分页start-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="search.pagenum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="search.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        <!--分页end-->
      </el-row>
    </el-card>  
  </div>
</template>


<script>
import {list, changeStatus ,userExport} from '@/api/interactUser'
export default {
 data (){
  return {
    list:[],
    search:{
      pagenum: 1,
      pagesize: 10,
      keyword:"",
      star:0,
      starSort:-1,
    },
    total: 0,
  }
 },
 created() {
    this.getUserList()
 },
 methods: {
  jumpUserDetail(user_id){
    this.$router.push({path:'/admin.courseuser.userDetail',query:{user_id:user_id}})
  },
  //-----------------------------tabel 文字居中-------------------------------
   rowClass({row, rowIndex}){
    return 'text-align:center'
  },
  cellStyle({row, column, rowIndex, columnIndex}){
    return 'text-align:center;padding:0px' 
  },
  //-------------------
  async getUserList(){
    const {data:res} = await list(this.search)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.list = res.data.data
    this.total = res.data.total
  },
  // 星星排序
  renderHeader () {
    return (
      <div>
        累计星星数量
        <el-button  type="text" size='mini' on-click={()=>this.starSort()}>
          <span class='el-icon-sort'></span>
        </el-button>
      </div>
    )
  },
  starSort(){
    if(this.search.starSort == -1){
      this.search.starSort = 0
    }else{
      this.search.starSort = !this.search.starSort
    }
    this.getUserList() 
  },
  //
  btnClick(){
    this.search.pagenum = 1
    this.search.pagesize = 10
    this.getUserList()
  },
  reset(){
    this.search.pagenum = 1
    this.search.pagesize = 10
    this.search.keyword = ""
    this.search.star = 0
    this.search.starSort = -1
    this.getUserList()
  },
  // 禁用启用
  async changeSta(id,status){
    const {data:res} = await changeStatus({user_id:id,status:status})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.$message.success('ok')
    this.getUserList()
  },
  //----------------------------跳转: 购课记录-----------------------------
  jump(id){  
    this.$router.push({path:'/admin.courseuser.buyRecords',query:{user_id:id}})
  },
   // -------------------------分页-------------------------------------------
  handleSizeChange(newSize) {
    this.search.pagesize = newSize
    this.getUserList()
  },
  handleCurrentChange(newPage) {
    this.search.pagenum = newPage
    this.getUserList()
  },
  //-----------------------------excelExport------
  async excelExport1(){
    var FileSaver = require('file-saver');
    this.search.pagenum = 1
    this.search.pagesize = this.total
    this.search.star = 1
    let res = await userExport(this.search)
    console.log("res",res)
    const { headers, data } = res
    let blob = new Blob([data], {
      type: headers["content-type"] 
    })
    FileSaver.saveAs(blob, "用户信息表.xlsx")
    this.search.pagesize = 10
    this.search.star = 0
    this.search.starSort = -1
  }, 
  async excelExport(){
    var FileSaver = require('file-saver');
    this.search.pagenum = 1
    this.search.pagesize = this.total
    let res = await userExport(this.search)
    const { headers, data } = res
    let blob = new Blob([data], {
      type: headers["content-type"]  
    })
    FileSaver.saveAs(blob, "用户信息表.xlsx")
    this.search.pagesize = 10
    this.search.starSort = -1
  },
 } 
}
</script>

<style scoped>
.nav_1{
  margin-bottom: 10px;
  float:left;
}
</style>