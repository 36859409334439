import request from '@/utils/request'

export function addHeader(data) {
  return request({
    url: "/admin/header/create",
    method: "post",
    data,
  });
}

export function updateHeader(data) {
  return request({
    url: "/admin/header/update",
    method: "post",
    data,
  });
}

export function deleteHeader(data) {
  return request({
    url: "/admin/header/delete",
    method: "post",
    data,
  });
}

export function changeStatusHeader(data) {
  return request({
    url: "/admin/header/changeStatus",
    method: "post",
    data,
  });
}

export function getAdminHeaderList(data) {
  return request({
    url: "/admin/header/lists",
    method: "post",
    data,
  });
}


export function getAdminHeaderExchangeList(data) {
  return request({
    url: "/admin/header/get_exchange_list",
    method: "post",
    data,
  });
}