<!--课包购买链接-->
<template>
    <div>
        <el-card>
            <el-row>
                <el-col :span="8">
                    <span style="display: inline-flex;width: 100px;font-size: 18px;">搜索课表:</span>
                    <el-input type="text" prefix-icon="el-icon-search" v-model="search.keyword"
                        style="display: inline-flex;width: 250px;">
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <span style="display: inline-flex;width: 100px;font-size: 18px;">搜索用户:</span>
                    <el-input type="text" prefix-icon="el-icon-search" placeholder="请输入用户名或手机号搜索" v-model="search.name_keyword"
                        style="display: inline-flex;width: 250px;">
                    </el-input>
                </el-col>
                <el-button type="primary" size="medium" icon="el-icon-search" @click="btnClick" style="">搜索</el-button>
                <el-button type="warning" icon="el-icon-refresh" size="medium"  style="" @click="resetClick">重置</el-button>
            </el-row>
            <el-row style="margin-top:10px;">
                <el-button type="success"  @click="excelExport">Excel导出</el-button>
            </el-row>
            <div style="height:30px;line-height:30px;font-size:20px;margin:5px 0;">
                <span>报名人数:{{ apply_count }}</span>
            </div>
            <el-row style="margin-top:10px;">
                <el-table :data="list" border stripe>
                    <el-table-column label="课表编号" prop="courseBagTime.course_bag_time_no" width="160px"></el-table-column>
                    <el-table-column label="课表名称" prop="bags_times_title" width="140px"></el-table-column>
                    <el-table-column label="课表开课时间" prop="bags_start_time" width="200px"></el-table-column>
                    <el-table-column label="用户名" prop="user_name" width="200px"></el-table-column>
                    <el-table-column label="手机号" prop="user_mobile" width="140px"></el-table-column>
                    <el-table-column label="报名时间" prop="created_at" width="140px"></el-table-column>
                    <el-table-column label="状态" width="140px">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status == 1"> 正常</span>
                            <span v-else-if="scope.row.status == 2"> 已退款</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>
  
<script>
import { getListByShortLink } from '@/api/userSignUp'
export default {
    data() {
        return {
            list: [],
            name: "",
            search: {
                pagenum: 1,
                pagesize: 10,
                keyword: "",
                course_bag_id: this.$route.query.course_bag_id,
                short_link_id: this.$route.query.short_link_id,
                name_keyword:"",
            },
            total: 0,
            apply_count: 0,
        }
    },
    created() {
        this.getListByShortLinkApi();
    },
    methods: {
        async getListByShortLinkApi() {
            console.log("search", this.search)
            const { data: res } = await getListByShortLink(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.list = res.data.data
            this.total = res.data.total
            this.apply_count = res.data.apply_count
        },
        resetClick() {
            this.search.keyword = ''
            this.search.pagenum = 1;
            this.search.pagesize = 10;
            this.getListByShortLinkApi();
        },
        btnClick() {
            this.search.pagenum = 1;
            this.getListByShortLinkApi();
        },
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
            this.getListByShortLinkApi()
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
            this.getListByShortLinkApi()
        },
        excelExport() {
            const headers = {
                '课表编号': 'course_bag_time_no',
                '课表名称': 'bags_times_title',
                '课表开课时间': 'bags_start_time',
                '用户名': 'user_name',
                '手机号': 'user_mobile',
                '报名时间': 'created_at',
                '状态': 'status_name',
            }
            import('@/vendor/Export2Excel').then(async excel => {
                this.search.pagesize = this.total
                const { data: res } = await getListByShortLink(this.search)
                const data = this.formatJson(headers, this.formData(res.data.data))
                excel.export_json_to_excel({
                    header: Object.keys(headers),
                    data,
                    filename: '报名信息表',
                    autoWidth: true,
                    bookType: 'xlsx'
                })
                this.search.pagesize = 10
            })
        },
        formatJson(headers, rows) {
            return rows.map(item => {
                return Object.keys(headers).map(key => {
                    return item[headers[key]]
                })
            })
        },
        formData(data) {
            const arr = []
            for (let i in data) {
                let status_name = ""
                if (data[i].status == 1) {
                    status_name = '正常'
                } else if (data[i].status == 2) {
                    status_name = '退款'
                }
                arr.push({
                    course_bag_time_no: data[i].courseBagTime.course_bag_time_no,
                    bags_times_title: data[i].bags_times_title,
                    bags_start_time: data[i].bags_start_time,
                    user_name: data[i].user_name,
                    user_mobile: data[i].user_mobile,
                    status_name: status_name,
                    created_at:data[i].created_at
                })
            }
            return arr
        },
    }
}
</script>