<template>
    <span>
        <div style="clear:both"></div>
        <div class="nav_1">
            <div style="clear:both;height: 30px;">
                <div style="float:left;width:400px;">
                    <div style="float:left;margin-right:5px;height:40px;line-height:40px;">课程包名称: </div>
                    <div style="float:left;width:320px;">
                        <el-select v-model="cp_select" placeholder="请选择" @change="selectBag(cp_select)" filterable
                            style="width:100%">
                            <el-option value="">--置空--</el-option>
                            <el-option v-for="(item, index) in searchCpList" :key="index" :label="item.course_bag_name"
                                :value="item.id">{{
                                    item.course_bag_name
                                }}
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!--日期start-->
                <div style="float:left;margin-left:15px;height:40px;line-height:40px;">开课时间: </div>
                <div style="float:left;margin-left:20px;">
                    <div class="block">
                        <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
                            @blur="timeBlur" value-format="yyyy-MM-dd" end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
            </div>
        </div>
        <div class="clear" style="margin-top: 30px;">
            <div style="float:left;margin-left:15px;height:40px;line-height:40px;">状态: </div>
            <div style="float:left;margin-left:20px;">
                <div class="block">
                    <el-select v-model="search.course_bag_time_status" placeholder="请选择" style="width:100%">
                        <el-option v-for="(item, index) in statusList" :key="index" :label="item.name" :value="item.id">{{
                            item.course_bag_name
                        }}
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div style="float:left;margin-left:15px;height:40px;line-height:40px;">所属客服: </div>
            <div style="float:left;margin-left:20px;">
                <div class="block">
                    <el-select v-model="search.customer_id" placeholder="请选择" filterable style="width:100%">
                        <el-option value="">全部</el-option>
                        <el-option v-for="(item, index) in kefuList" :key="index" :label="item.name" :value="item.id" />
                    </el-select>
                </div>
            </div>

            <div style="float:left;margin-left:15px;height:40px;line-height:40px;">解锁类型: </div>
            <div style="float:left;margin-left:20px;">
                <div class="block">
                    <el-select v-model="search.lock_type" placeholder="请选择" filterable style="width:100%">
                        <el-option v-for="(item, index) in lockTypeList" :key="index" :label="item.name" :value="item.id" />
                    </el-select>
                </div>
            </div>


            <div style="float:left;margin-left:20px;">
                <el-button type="primary" icon="el-icon-search" @click="btnClick">搜索</el-button>
                <el-button type="warning" icon="el-icon-refresh" @click="reset">重置</el-button>
            </div>

        </div>

    </span>
</template>

<script>

import global_ from '@/utils/global.js'
import { getCourseBagAll } from '@/api/coursePackage'

export default {
    props:{
        kefuList:{
            Type:Array,
            default:[]
        }
    },
    data() {
        return {
            statusList: global_.course_bag_time_status_list,
            lockTypeList: global_.course_bag_time_lock_type_List,
            searchCpList: [],
            cp_select: [],
            value1: [],
            search: {
                course_bag_id: '',
                start_date: '',
                end_date: '',
                pagenum: 1,
                pagesize: 10,
                course_bag_time_status: "",
                customer_id: "",
                lock_type: 0
            },
        }
    },
    created(){
        let list_search = sessionStorage.getItem('list_search') || "";
        if(list_search != "" && list_search != null && list_search != undefined){
            let list_search_obj = JSON.parse(list_search);
            this.cp_select = list_search_obj.course_bag_id || '';
            this.value1[0] = list_search_obj.start_date
            this.value1[1] = list_search_obj.end_date
        }
    },
    methods: {
        async initSearchCourseBagList() {
            const { data: res } = await getCourseBagAll({ course_bag_status: 3 })
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.searchCpList = res.data
        },
        selectBag(value) {
            this.search.course_bag_id = value
            this.search.pagenum = 1
            this.$emit('callCourseTimeListView',this.search)
        },
        timeBlur() {
            this.search.start_date = this.value1[0]
            this.search.end_date = this.value1[1]
        },
        btnClick() {
            this.search.pagenum = 1
            this.search.pagesize = 10
            this.$emit('callCourseTimeListView',this.search)
        },
        reset() {
            this.value1 = []
            this.search.start_date = ""
            this.search.end_date = ""
            this.search.course_bag_id = ""
            this.search.customer_id = "";
            this.search.course_bag_time_status = "";
            this.cp_select = []
            this.$emit('callCourseTimeListView',this.search)
        },
    }
}
</script>