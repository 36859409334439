<template>
    <div>
        <el-row style="margin-top:10px;">
            <el-table :data="customerList" border stripe :row-style="{ height: '50px' }" :cell-style='cellStyle'
                :header-cell-style='rowClass'>
                <el-table-column label="ID" prop="id"></el-table-column>
                <el-table-column label="客服姓名" prop="name"></el-table-column>
                <el-table-column label="企业微信号" prop="work_wechat_no"></el-table-column>
                <el-table-column label="企微二维码" prop="bags_title">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="scope.row.work_wechat_qrcode"
                            @click="showPic(imgDomain + scope.row.work_wechat_qrcode)" size="mini">查看
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column label="企微绑定手机号" prop="mobile_phone"></el-table-column>
                <el-table-column label="所属职位" prop="customer_type" :formatter="dealCustomerPosition"></el-table-column>
                <el-table-column label="状态" prop="status">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">正常</span>
                        <span v-else>禁用</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="140px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">
                            <el-button type="text" @click="changeStatus(scope.row.id)" size="mini">禁用</el-button>
                            <template>
                                <el-button type="text" @click="deleteKf(scope.row.id)" size="mini">删除</el-button>
                            </template>
                        </span>
                        <span v-if="scope.row.status == 2">
                            <el-button type="text" @click="changeStatus(scope.row.id)" size="mini">启用</el-button>
                            <template>
                                <el-button type="text" @click="deleteKf(scope.row.id)" size="mini">删除</el-button>
                            </template>
                        </span>
                        <el-button style="margin-left:5px" type="text" @click="editShow(scope.row)"
                            size="mini">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>

        <!--分页start-->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="search.pagenum"
            :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
        <!--分页end-->
        <customerAddOrEditView @refresh_list="refresh_list" ref="customerAddView" />
    </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import customerAddOrEditView from "@/components/CustomSer/addOrEdit.vue";
import { getCustomerList, deleteCustomer, changeCustomerStatus } from '@/api/customer'
export default {
    components: {
        customerAddOrEditView,
    },
    data() {
        return {
            customerList: [],
            total: 0,
            search: {
                pagenum: 1,
                pagesize: 10,
                status: '',
            },
            position: [{ id: 1, name: "课程客服" }, { id: 3, name: "销售" }, { id: 2, name: "联系客服" }, { id: 4, name: "课程小结客服" }]
        }
    },
    created() {

    },
    methods: {
        dealCustomerPosition(row) {
            let customer_type = row.customer_type;
            let customer_type_array = customer_type.split(",");
            let customer_type_name_array = [];
            for (let i in this.position) {
                let value = this.position[i];
                for (let j in customer_type_array) {
                    let value2 = customer_type_array[j];
                    if (parseInt(value.id) == parseInt(value2)) {
                        customer_type_name_array.push(value.name)
                    }
                }
            }
            return customer_type_name_array.join(',');
        },
        refresh_list() {
            this.getCustomerListApi({});
        },
        editShow(customer_info) {
            this.$refs.customerAddView.clear();
            this.$refs.customerAddView.addDialogVisible = true;
            this.$refs.customerAddView.setCustomerInfoData(customer_info)
        },
        showPic(url) {
            this.imagesUrl = [];
            this.imagesUrl.push(url)
            this.$viewerApi({
                images: this.imagesUrl,
            })
        },
        async getCustomerListApi(query) {
            const { data: res } = await getCustomerList(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.customerList = res.data.data
            this.total = res.data.total
        },
        rowClass({ row, rowIndex }) {
            return 'text-align:center'
        },
        cellStyle({ row, column, rowIndex, columnIndex }) {
            return 'text-align:center;padding:0px'
        },
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
            this.getCustomerListApi();
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
            this.getCustomerListApi();
        },
        changeStatus(id) {
            const self = this;
            changeCustomerStatus({ id: id }).then((res) => {
                let code = res.data.status;
                if (code === 0) {
                    self.$message.success("操作成功")
                    self.getCustomerListApi({})
                } else {
                    self.$message.error(res.data.msg)
                }
            })
        },
        deleteKf(id) {
            const self = this;
            this.$confirm('确认要删除嘛', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data: res } = await deleteCustomer({ id: id })
                if (res.status != 0) {
                    return this.$message.error(res.msg)
                }
                this.search.pagenum = 1
                this.getCustomerListApi()
                this.$message.success('删除成功')
            }).catch(() => {

            })
        },
    }
}
</script>