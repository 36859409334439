<!--购课记录-->
<template>
    <div>
        <el-card>
            <el-row style="margin-top:0px;">
                <h1>累计获得{{ sum }}个勋章</h1>
                <el-table :data="userMedalList" border stripe :row-style="{ height: '45px' }" :cell-style='cellStyle'
                    :header-cell-style='rowClass'>
                    <el-table-column label="勋章名称" prop="name"></el-table-column>
                    <el-table-column label="获取时间" prop="created_at"></el-table-column>
                </el-table>
                <!--分页start-->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
                <!--分页end-->
            </el-row>
        </el-card>
    </div>
</template>
  
  
<script>
import { getUserMedal } from '@/api/interactUser'
export default {
    data() {
        return {
            userMedalList: [
                {
                    name: "开启翻翻之旅",
                    time: "2022.2.14 14:22:12"
                },
                {
                    name: "开启翻翻之旅",
                    time: "2022.2.14 14:22:12"
                }
            ],
            sum: 12,
            search: {
                pagenum: 1,
                pagesize: 10,
                user_id: this.$route.query.user_id,
            },
            total: 0,
        }
    },
    created() {
    },
    methods: {
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
        },
        rowClass({ row, rowIndex }) {
            return 'text-align:center'
        },
        cellStyle({ row, column, rowIndex, columnIndex }) {
            return 'text-align:center;padding:0px'
        },
        async getUserMedalApi() {
            const { data: res } = await getUserMedal(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.userMedalList = res.data.data
            this.total = res.data.total
            this.sum = res.data.total
        },
    }
}
</script>
  
<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}

.search {
    float: left;
    width: 300px;
    margin-left: 10px;
}
</style>