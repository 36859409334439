import request from '@/utils/request'

//老师列表
export function list(data){
  return request({
    url: 'admin/SchoolTeacher/getList',
    method: 'post',
    data
  })
}

//添加老师
export function addTeacher(data){
  return request({
    url: 'admin/SchoolTeacher/addTeacher',
    method: 'post',
    data
  })
}
//修改老师
export function editTeacher(data){
  return request({
    url: 'admin/SchoolTeacher/editTeacher',
    method: 'post',
    data
  })
}
//绑定用户端账号
export function bindingUser(data){
  return request({
    url: 'admin/SchoolTeacher/bindingUser',
    method: 'post',
    data
  })
}

// 禁用/启用
export function changeStatus(data) {
  return request({
    url: '/admin/SchoolTeacher/changeStatus',
    method: 'post',
    data
  })
}
//解除绑定
export function secureBinding(data){
  return request({
    url: 'admin/SchoolTeacher/takeSecureBinding',
    method: 'post',
    data
  })
}
