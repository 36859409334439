<!--归类-->
<template>
  <div>
      <el-form-item label="题目:" prop="title">
        <el-input v-model="addForm.title" placeholder="* 题目字数最多45个字" style="margin-left:28px;width:80%" maxlength="45"></el-input>
      </el-form-item>
      <el-form-item label="题目语音:">
        <el-upload class="avatar-uploader" action="#" :show-file-list="false"
          :http-request="(file) => { uploadFile(file, 0, 0) }">
          <div v-if="addForm.audio" style="width:178px;height:35px">{{ addForm.audio | removeDir | hidden }}</div>
          <div v-else style="width:178px;height:35px">上传</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="内容数量:">
        <el-select v-model="addForm.contentNum" placeholder="请选择" @change="selectContentNum(addForm.contentNum)">
          <el-option v-for="(item,index) in content_num_arr" :key="index" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容类型:">
        <el-select  v-model="addForm.contentType" placeholder="请选择" @change="selectContentType(addForm.contentType)">
          <el-option v-for="item in content_type" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div style="color:red">*内容文字限制为1-5个字，图片尺寸比例建议1:1</div>
        <!--文字-->
        <div v-if="addForm.contentType == 1">
          <div v-for="index of addForm.contentNum" :key="index">
            <el-row>
              <el-col :span="2">
                <el-checkbox :label="index" v-model="addForm.contentCheckbox[index - 1]">内容{{index}}</el-checkbox>
              </el-col>
              <el-col :span="12">
                <el-input v-model="addForm.contentText[index - 1]" type="text" style="width:96%" minlength="1" maxlength="5"></el-input>
              </el-col>
              <el-col :span="5">
                <el-form-item>
                  <el-upload class="avatar-uploader" action="#" :show-file-list="false"
                    :http-request="(file) => { uploadFile(file, 1, index) }">
                    <div v-if="addForm.contentAudio[index - 1]">
                      <div v-if=" addForm.contentAudio[index - 1].url" style="width:178px;height:35px">{{
                        addForm.contentAudio[index - 1].url | removeDir | hidden
                      }}</div>
                      <div v-else style="width:178px;height:35px">上传</div>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </div> 
        </div>
        <!--图片-->
        <div v-else-if="addForm.contentType == 2" style="float:left">
          <div v-for="index of addForm.contentNum" :key="index" style="float:left">
            <el-row >
              <el-col >
                <el-checkbox :label="index" v-model="addForm.contentCheckbox[index - 1]">内容{{index}}</el-checkbox>
              </el-col>
              <el-col :span="5">
                <el-form-item>
                  <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    style="width:130px;height:130px"
                    :http-request="(file)=> {uploadFile(file,2,index)}"              
                  >
                  <div v-if="addForm.contentImg[index-1]">
                    <img
                      v-if="addForm.contentImg[index - 1].url"
                      :src="imgDomain + addForm.contentImg[index - 1].url"
                      class="avatar"
                      style="width:130px;height:130px"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon" style="width:130px;height:130px"></i>
                  </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form-item>
      <div style="footer-div" >
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogHidden">取消</el-button>
          <el-button type="primary" @click="addSubmit">确定</el-button>
        </span>
      </div>
  </div>
</template>


<script>
import global from '@/utils/global.js'
import { getQiniuToken } from '@/api/upload'
import * as qiniu from 'qiniu-js'

import { contentAdd } from '@/api/course'

export default {  
  props:{
    inter_type:{},
    courseId:{},
    game_type:{},
    editObj:{}
  },
  created(){
    if(this.editObj.is_edit == 1){
      this.editInit()
      this.editTemp = this.editObj
    }else{
      this.selectContentNum(4) 
    }
  },
  watch:{
    addForm:{
      handler(newVal,oldVal){
        if(this.editObj.is_edit == 1){
            let flag = 0
            if(this.editTemp.title!=newVal.title){
              flag = 1
            }
            if(this.editTemp.audio != newVal.audio){ 
              flag = 1
            }
            if(this.editTemp.num!=newVal.contentNum){ // 内容数量
              flag = 1
            }
            if(this.editTemp.option_type!=newVal.contentType){
              flag = 1
            }
            for(let i=0;i<this.editTemp.num;i++){
              if(this.editTemp.option_type == 1){
                if( this.editTemp.contentText[i] != newVal.contentText[i] || this.editTemp.contentAudio[i] != newVal.contentAudio[i].url ){
                  flag = 1
                  break
                }
              }else if(this.editTemp.option_type == 2){
                if( this.editTemp.contentImg[i] != newVal.contentImg[i] ){
                  flag = 1
                  break
                }
              }
            } 
            // if(flag == 1){
            //   window.sessionStorage.setItem("interType",1)
            // }else{
            //   window.sessionStorage.setItem("interType",null)
            // }
        }else{
         
          if(newVal.title!="" || newVal.audio!="" || newVal.contentNum!=4 || newVal.contentType!= 1 || newVal.contentCheckbox.length!=4 || newVal.contentText.length!=4 || newVal.contentAudio.length!=4 || newVal.contentImg.length!=4){
             window.sessionStorage.setItem("interType",1)
          }else{
            let flag = 0
            for(let i=0;i<newVal.contentNum;i++){
              if(newVal.contentCheckbox[i]!=false){
                flag = 1
                break
              }
              if(newVal.contentText[i]!=""){
                flag = 1
                break
              }
              if(Object.keys(newVal.contentAudio[i]).length != 0){
                flag = 1
                break
              }
              if(Object.keys(newVal.contentImg[i]).length!=0){
                flag = 1
                break
              }
            }
            if(flag == 1){
              window.sessionStorage.setItem("interType",1)
            }else{
              window.sessionStorage.setItem("interType",null)
            }
          }
        }
      },
      deep:true,
    }
  },
  data(){
    return {
      addForm: {
        id: -1,
        title: '',
        audio: '',
        inter_type: this.inter_type,
        courseId: this.$route.query.id,
        game_type:3,
        contentNum: 4,
        contentType: 1,
        contentCheckbox:[],
        contentText:[],
        contentAudio:[],
        contentImg:[],
      },
      content_num_arr:[4,5,6,7,8,9,10],
      content_type:[{id:1,name:"文字"},{id:2,name:"图片"}],
      content:[],
      editTemp:{},
    }
  },
  filters: {
    removeDir(value) {
      if(Object.keys(value).length==0) return value
      let site = value.lastIndexOf("\/");
      return value.substring(site+1, value.length)
    },
    hidden(value){
      if(Object.keys(value).length==0) return value
      let end = value.lastIndexOf("-")
      if(end>20){
        end = 20
      }
      return value.substring(0, end)
    },
    tranNum(value) {
      if(Object.keys(value).length==0) return value
      if (value == 1){
        return 'A'
      }else if(value ==2){
        return 'B'
      }else if(value ==3){
        return 'C'
      }else if(value ==4){
        return 'D'
      }
    },
  },
  methods:{

    editInit(){
      this.addForm.inter_type = parseInt(this.editObj.inter_type)
      this.addForm.title = this.editObj.title
      this.addForm.audio = this.editObj.audio
      this.addForm.contentNum = this.editObj.num?this.editObj.num:this.addForm.contentNum
      this.addForm.contentType = this.editObj.option_type
      for(let i=0;i<this.editObj.num;i++){
        if(this.editObj.contentCheckbox[i] == false || this.editObj.contentCheckbox[i] == 'false'){
          this.addForm.contentCheckbox[i] = false
        }else{
          this.addForm.contentCheckbox[i] = true
        }
        if(this.editObj.option_type == 1){
          this.addForm.contentText[i] =  this.editObj.contentText[i]
          this.addForm.contentAudio[i] = {url:this.editObj.contentAudio[i]} 
        }else{
          this.addForm.contentImg[i] = {url:this.editObj.contentImg[i]}
        } 
      }
      this.addForm.id = this.editObj.id
    },

    async uploadFile(uploadInfo, type, index) {
      const { data: token } = await getQiniuToken()
      const that = this
      const observer = {
        next(res) {},
        error(err) {console.log(err)},
        complete(res) {
          if(type == 1){ // 文字-音频
            that.$set(that.addForm.contentAudio, index - 1, { url: res.key })
          }else if(type == 2){
            that.$set(that.addForm.contentImg, index - 1, { url: res.key })
          }else if(type == 0){ // 题目语音
            that.addForm.audio = res.key
          }
        }
      }

      let name = uploadInfo.file.name
      let fileName = global.getFileName(name)
      if (parseInt(fileName) != NaN) {
        name = fileName + "-" + global.randStr(20) + "." + global.getExt(name)
      }
      const observable = qiniu.upload(uploadInfo.file, "interactcourse/" + name, token, {}, {})
      const subscription = observable.subscribe(observer)
    },
    // 内容数量
    selectContentNum(num) {
      this.addForm.contentNum = num
      this.changeAddForm(num)
    },
    // 内容类型
    selectContentType(type) {
      this.addForm.contentType = type
      this.changeAddForm(this.addForm.contentNum)
    },
    changeAddForm(num){
      this.addForm.contentCheckbox = []
      this.addForm.contentText = []
      this.addForm.contentAudio = []
      this.addForm.contentImg = []
      for(let i=0;i<num;i++){
        this.addForm.contentCheckbox.push(false)
        this.addForm.contentText.push("")
        this.addForm.contentAudio.push({})
        this.addForm.contentImg.push({})
      }
    },
    //----------------
    addDialogClosed() {
      this.clear()
    },
    clear() {
      this.addForm.title = ''
      this.addForm.audio = ''
      this.addForm.contentNum = 4
      this.addForm.contentType = 4
      this.addForm.contentCheckbox = []
      this.addForm.contentText = []
      this.addForm.contentAudio = []
      this.addForm.contentImg = []
    },
    //-------------------------
    async addSubmit() {
      
      if (this.checkForm() != 1) {
        return
      }
      const { data: res } = await contentAdd(this.addForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)

      this.$emit("updateList",false)
      this.clear()
      window.sessionStorage.setItem("interType",null)
      window.sessionStorage.setItem("oldInterType",null)
      window.sessionStorage.setItem("oldEditObj",null)
    },
    checkForm() {
      if (this.addForm.title == '') {
        return this.$message.error({ 'message': '请输入题目', duration: 1000 })
      }
      if (this.addForm.audio == "") {
        return this.$message.error({ 'message': '请上传题目语音', duration: 1000 })
      }
     
      if (this.addForm.contentNum == "") {
        return this.$message.error({ 'message': '内容数量必选', duration: 1000 })
      }
      if (this.addForm.contentType == "") {
        return this.$message.error({ 'message': '内容类型必选', duration: 1000 })
      }

      if (this.addForm.contentCheckbox.length < 1) {
        return this.$message.error({ 'message': '请勾选正确答案!!', duration: 1000 })
      }

      let tempFlag = 0
      for (let i = 0; i < this.addForm.contentNum; i++) {
          if(this.addForm.contentCheckbox[i] == true){
            tempFlag = 1
          }
      }
      if(tempFlag == 0){
          return this.$message.error({ 'message': '请勾选正确答案!!', duration: 1000 })
      }
  
      if (this.addForm.contentType == 1) { //文本
        for (let i = 0; i < this.addForm.contentNum; i++) {
          if (this.addForm.contentText[i] == "") {
            return this.$message.error({ 'message': '请填写答案选项内容', duration: 1000 })
          }

          // 音频
          if (!this.addForm.contentAudio[i].url) {
            return this.$message.error({ 'message': '请上传音频', duration: 1000 })
          } else {
            if (this.addForm.contentAudio[i].url == "") {
              return this.$message.error({ 'message': '请上传音频', duration: 1000 })
            }
          }
        }
      } else if (this.addForm.contentType == 2) {   // 图片
        for (let i = 0; i < this.addForm.contentNum; i++) {
          if (this.addForm.contentImg[i].url == undefined || this.addForm.contentImg[i].url == "") {
            return this.$message.error({ 'message': '请上传答案图片', duration: 1000 })
          }
        }
      }
      return 1
    },
    dialogHidden(){
      this.clear()
      this.$emit("dialogHidden")
    }
  }
}
</script>

<style scoped>
.footer-div{
   position: relative;
   height:40px;
}
.dialog-footer {
  position:absolute;
  right:5px;
  bottom:10px;
}


</style>