export function excelExportBlob(res, excel_name) {
    var FileSaver = require('file-saver');
    const { headers, data } = res
    let blob = new Blob([data], {
        type: headers["content-type"] // 使用来自`PHP` 输出时的`headers` 中已经指定的类型
    })
    FileSaver.saveAs(blob, excel_name)
}

export function exportExcel(headers, data, filename) {
    import('@/vendor/Export2Excel').then(async excel => {
        excel.export_json_to_excel({
            header: Object.keys(headers),
            data,
            filename: filename,
            autoWidth: true,
            bookType: 'xlsx'
        })
    })
}

export default {
    excelExportBlob,
    exportExcel
}