<!--课程订单-->
<template>
  <div>
    <el-card>
      <div class="nav_1">
        <div class="div-left">
          <div class="d-order-no">兑换码: </div>
          <div class="div-left">
            <el-input class="input" v-model="search.cdKey"></el-input>
          </div>
        </div>
        <div class="div-left">
          <div class="d-order-no">生成时间: </div>
          <div style="float:left;margin-left:20px;">
            <div class="block">
              <el-date-picker v-model="search.created_time" value-format="yyyy-MM-dd HH:mm:ss" type="daterange"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="div-left">
          <div class="d-order-no">兑换时间: </div>
          <div style="float:left;margin-left:20px;">
            <div class="block">
              <el-date-picker  v-model="search.redeem_time" value-format="yyyy-MM-dd HH:mm:ss" type="daterange"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="nav_1">
        <div class="div-left">
          <div class="d-order-no">兑换状态: </div>
          <div class="div-left">
            <el-select v-model="search.redeem_status" placeholder="请选择" @change="selectRedeem(search.redeem_status)">
              <el-option v-for="(item, index) in status_list" :label="item.name" :key="index"
                :value="item.id">{{ item.name }}</el-option>
            </el-select>
          </div>
        </div>
        <div class="div-left">
          <div class="d-order-no">兑换人: </div>
          <div class="div-left">
            <el-input class="input" v-model="search.keyword" placeholder="输入用户名或者手机号"></el-input>
          </div>
        </div>
        <div class="div-left">
          <div class="d-order-no">兑换状态: </div>
          <div class="div-left">
            <el-select v-model="search.status" placeholder="请选择" @change="selectStatus(search.status)">
              <el-option v-for="(item, index) in status_list2" :label="item.name" :key="index"
                :value="item.id">{{ item.name }}</el-option>
            </el-select>
          </div>
        </div>
        <div class="div-left">
          <el-button type="primary" icon="el-icon-search" @click="btnClick">搜索</el-button>
          <el-button type="warning" icon="el-icon-refresh" @click="reset">重置</el-button>
        </div>
      </div>
      <!---->
      <div class="clear"></div>
      <el-row style="margin-top:10px;">
        <el-button type="success" style="width:100px;" @click="add">生成兑换码</el-button>
        <el-button type="success" style="width:100px;" @click="excelExport">导出</el-button>
      </el-row>
      <div class="clear"></div>
      <el-row style="margin-top:10px;">
        <div>
          <span style="font-size:20px">兑换码总数量:{{total}}</span> &nbsp;&nbsp;&nbsp;&nbsp;
          <span style="font-size:20px">已兑换数量:{{redeemNum}}</span>
        </div>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="cdKeyList" border stripe :row-style="{ height: '50px' }" :cell-style='cellStyle'
          :header-cell-style='rowClass'>
          <el-table-column label="兑换码ID" prop="id" ></el-table-column>
          <el-table-column label="兑换码" prop="code" ></el-table-column>
          <el-table-column label="生成时间" prop="created_at"></el-table-column>
          <el-table-column label="兑换状态" prop="exchange_status_str"></el-table-column>
          <el-table-column label="兑换时间" prop="exchange_time"></el-table-column>
          <el-table-column label="兑换用户名称" prop="user_name" ></el-table-column>
          <el-table-column label="兑换用户手机号" prop="user_mobile" width="160px"></el-table-column>
          <el-table-column label="状态" width="80px" prop="status_str"></el-table-column>
          <el-table-column label="操作" width="140px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" v-if="scope.row.status ==1" @click="changeStatus(scope.row.id,scope.row.status)">禁用 </el-button>
              <el-button type="text" size="mini" v-if="scope.row.status ==2 " @click="changeStatus(scope.row.id,scope.row.status)">启用 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <!--分页start-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="search.pagenum"
        :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      <!--分页end-->

      <!--兑换 start-->
      <el-dialog :title="dialogTitle" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="40%"
        @close="addDialogClosed">
        <el-form ref="addFormRef"  label-width="110px">
          <el-form-item label="生成兑换码数量">
            <el-input v-model="addForm.num" type="text"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addSubmit">确认</el-button>
        </span>
      </el-dialog>
      <!--兑换 end-->

    </el-card>
  </div>
</template>

<script>
import { redeemList,redeemAdd,changeCodeStatus } from '@/api/cdKey'
import global_ from '@/utils/global';
export default {
  data() {
    return {
      id: this.$route.query.id,
      cdKeyList: [],
      total: 0,
      redeemNum:0,
      search: {
        pagenum: 1,
        pagesize: 10,
        cdKey: '', // 兑换码
        created_time: '', //生成时间
        redeem_time: '', // 兑换时间
        redeem_status:"", // 兑换状态
        status: '', // 状态
        keyword:"" // 
      }, 
      addForm:{
       num:"",
       exchange_id:""
      },
      status_list2: [{ id: 0, name: "全部" }, { id: 1, name: "正常" }, { id: 2, name: "禁用" }],
      status_list: [{ id: 0, name: "全部" },  { id: 1, name: "未兑换" },{ id: 2, name: "已兑换" }],
      //-----
      dialogTitle:"新增兑换",
      //-------------------------------
      addDialogVisible: false,
    }
  },
  created() {
    this.getList()
  },
  watch: {
    value1(newData) {
      if (newData == null) {
        this.value1 = ''
        this.search.startTime = ''
        this.search.endTime = ''
      }
    }
  },
  methods: {
   
    //-----------------------------tabel 文字居中-------------------------------
    rowClass({ row, rowIndex }) {
      return 'text-align:center'
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;padding:0px'
    },
    //--------------------------------------
    async add(){
      this.addForm.exchange_id = this.id
      this.addDialogVisible = true
    },
    //-------------------------------订单列表-----------------------------------
    async getList() {
      this.search.exchange_id = this.id
      const { data: res } = await redeemList(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.cdKeyList = res.data.data
      this.total = res.data.total
      this.redeemNum = res.data.redeemNum
    },
    async changeStatus(id,status){
      const { data: res } = await changeCodeStatus({"id":id,"status":status})
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.$message.success(res.msg)
      this.getList()
    },
    // -------------------------分页-------------------------------------------
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },
    //-------------------
    jumpRedeemList(id){
       this.$router.push({path:'/admin.redeemList',query:{id:id}})
    },
    //------------------------------筛选---------------------------------------
    btnClick() {
      this.search.pagenum = 1
      this.getList()
    },
    reset() {
      this.search.pagenum = 1
      this.search.pagesize = 10
      this.search.cdKey = ""
      this.search.created_time = ""
      this.search.redeem_time = ""
      this.search.redeem_status = ""
      this.search.status = ""
      this.search.keyword = ""
      this.getList()
    },
    selectRedeem(val){
      this.search.redeem_status = val
    },
    selectStatus(val){
      this.search.status = val
    },
    //-----------------------------导出--------------------------
    excelExport() {
      const headers = {
        '兑换码ID': 'id',
        '兑换码': 'code',
        '生成时间': 'created_at',
        '兑换状态': 'exchange_status_str',
        '兑换时间': 'exchange_time',
        '兑换用户名称': 'user_name',
        '兑换用户手机号': 'user_mobile',
        '状态': 'status_str',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await redeemList(this.search)
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '兑换码',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },
    //-----------------------------------
    addDialogClosed() {
     this.addForm.num = ""
    },
    async addSubmit() {
      const { data: res } = await redeemAdd(this.addForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.addDialogVisible = false
      this.$message.success(res.msg)
      this.getList()
    },
  }
}
</script>


<style scoped>
.nav_1 {
  margin-bottom: 10px;
  float: left;
}

.div-left {
  float: left;
  margin-right: 10px;
}

.input {
  width: 220px;
}

.d-order-no {
  float: left;
  margin-right: 15px;
  height: 35px;
  line-height: 35px;
}
</style>