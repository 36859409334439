<template>
    <div>
        <div class="nav_1">
            <div class="div-left">
                <div class="d-order-no">购买用户: </div>
                <div class="div-left">
                    <el-input placeholder="支持搜索用户名或手机号" class="input" v-model="search.userInfo"></el-input>
                </div>
            </div>
            <div class="div-left">
                <!--日期start-->
                <div style="float:left;height:40px;line-height:40px;">购课时间: </div>
                <div style="float:left;margin-left:20px;">
                    <div class="block">
                        <el-date-picker @blur="timeBlur" v-model="value1" value-format="yyyy-MM-dd HH:mm:ss"
                            type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
                <!--日期end-->
            </div>
        </div>
        <div class="div-left">
            <el-button type="primary" icon="el-icon-search" @click="btnClick">搜索</el-button>
            <el-button type="warning" icon="el-icon-refresh" @click="reset">重置</el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        search: {
            Type: Array,
            default: []
        }
    },
    data() {
        return {
            value1: ''
        }
    },
    created() {
    },
    watch: {
        value1(newData) {
            if (newData == null) {
                this.value1 = ''
                this.search.startTime = ''
                this.search.endTime = ''
            }
        }
    },

    methods: {
        timeBlur() {
            this.search.pagenum = 1
            this.search.pagesize = 10
            this.search.startTime = this.value1[0]
            this.search.endTime = this.value1[1]
            this.$emit('callOrderListApi', this.search)
        },
        btnClick() {
            this.search.pagenum = 1
            this.search.pagesize = 10
            this.$emit('callOrderListApi', this.search)
        },
        reset() {
            this.search.userInfo = ""
            this.search.startTime = ""
            this.search.endTime = ""
            this.value1 = ""
            this.$emit('callOrderListApi', this.search)
        },
    }
}
</script>

<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}

.div-left {
    float: left;
    margin-right: 10px;
}

.input {
    width: 220px;
}

.d-order-no {
    float: left;
    margin-right: 15px;
    height: 35px;
    line-height: 35px;
}
</style>