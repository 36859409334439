<template>
    <span>
        <el-row style="margin-top:10px;">
            <el-button type="success" style="width:100px;" @click="excelExport">Excel导出</el-button>
        </el-row>
        <div style="clear:both"></div>
        <el-row style="margin-top:10px;">
            <el-table :data="orderList" border stripe :row-style="{ height: '50px' }">
                <el-table-column label="订单id" prop="order_no" width="190px"></el-table-column>
                <el-table-column label="用户名" prop="user_name" width="140px"></el-table-column>
                <el-table-column label="手机号" prop="user_mobile" width="150px"></el-table-column>
                <el-table-column label="音频合集名" prop="bags_title"></el-table-column>
                <el-table-column label="购买时间" prop="pay_time" width="160px"></el-table-column>
                <el-table-column label="支付金额" prop="pay_price" width="110px"></el-table-column>
                <el-table-column label="支付方式" width="80px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.pay_type == 1">免费领取</span>
                        <span v-else-if="scope.row.pay_type == 2">微信</span>
                        <span v-else-if="scope.row.pay_type == 3">支付宝</span>
                        <span v-else-if="scope.row.pay_type == 4">小程序</span>
                        <span v-else-if="scope.row.pay_type == 5">兑换码兑换</span>
                    </template>
                </el-table-column>
                <el-table-column label="支付状态" width="80px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.order_status == 0">待支付</span>
                        <span v-else-if="scope.row.order_status == 1 && scope.row.exchange_code_id == 0">已支付</span>
                        <span v-else-if="scope.row.order_status == 2">已取消</span>
                        <span v-else-if="scope.row.order_status == 3">已退款</span>
                        <span v-else-if="scope.row.order_status == 4">退款处理中</span>
                        <span v-else-if="scope.row.order_status == 1 && scope.row.exchange_code_id > 0">兑换成功</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="140px">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" @click="refund(scope.row.id)"
                            v-if="scope.row.order_status == 1 && scope.row.exchange_code_id == 0">退款</el-button>
                        <el-button type="text" size="mini" @click="showLog(scope.row.id)"
                            v-if="scope.row.order_status == 3 || scope.row.order_status == 4">退款日志</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-row>
    </span>
</template>

<script>
import { list } from '@/api/courseOrder'
import excelImportOperator from '@/utils/excelImport'
export default {
    data() {
        return {
            orderList: [],
            total: 0,
            search: {
                pagenum: 1,
                pagesize: 10,
                userInfo: '',
                startTime: '',
                endTime: '',
                product_type: 3
            },
        }
    },
    created() {
    },
    methods: {
        async getOrderList() {
            const { data: res } = await list(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.orderList = res.data.data
            this.total = res.data.total
        },
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
            this.getOrderList(this.search)
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
            this.getOrderList(this.search)
        },
        async excelExport() {
            const headers = {
                '订单id': 'order_no',
                '用户名': 'user_name',
                '手机号': 'user_mobile',
                '音频合集名': 'bags_title',
                '购课时间': 'pay_time',
                '购课金额': 'pay_price',
                '支付方式': 'pay_type',
            }
            const { data: res } = await list(this.formatExcelSearch())
            const data = this.formatJson(headers, res.data.data)
            excelImportOperator.exportExcel(headers, data, '音频已购信息')
        },
        formatJson(headers, rows) {
            return rows.map(item => {
                return Object.keys(headers).map(key => {
                    if (headers[key] == "pay_type") {
                        switch (item[headers[key]]) {
                            case 1:
                                return "免费领取"
                                break
                            case 2:
                                return "微信"
                                break
                            case 3:
                                return "支付宝"
                                break
                            case 4:
                                return "小程序"
                                break
                            case 5:
                                return "兑换码兑换"
                                break
                        }
                    }
                    if (headers[key] == "order_status") {
                        if (item["exchange_code_id"] > 0 && item['order_status'] == 1) {
                            return "兑换成功";
                        }
                        switch (item[headers[key]]) {
                            case 0:
                                return "待支付"
                                break
                            case 1:
                                return "已支付"
                                break
                            case 2:
                                return "已取消"
                                break
                            case 3:
                                return "已退款"
                                break
                            case 4:
                                return "退款处理中"
                                break
                        }
                    }
                    return item[headers[key]]
                })
            })
        },
        formatExcelSearch() {
            let search = {}
            search.pagenum = 1;
            search.pagesize = this.total;
            search.userInfo = this.search.userInfo;
            search.startTime = this.search.startTime;
            search.endTime = this.search.endTime;
            search.product_type = this.search.product_type;
            return search;
        },
        refund(order_id) {
            this.$emit('callOrderListRefundDialogValue', order_id)
        },
        showLog(order_id){
            this.$emit('callOrderRefundRecord',order_id)
        }
    }
}
</script>