import request from '@/utils/request'

//获取所有学校数据
export function getAllSchool(data){
  return request({
    url: 'admin/School/getAllSchool',
    method: 'post',
    data
  })
}
//获取学校列表
export function list(data){
  return request({
    url: 'admin/School/getList',
    method: 'post',
    data
  })
}
//处理学校状态
export function changeStatus(data){
  return request({
    url: 'admin/School/changeStatus',
    method: 'post',
    data
  })
}
//添加学校
export function addSchool(data){
  return request({
    url: 'admin/School/addSchool',
    method: 'post',
    data
  })
}
//修改学校
export function editSchool(data){
  return request({
    url: 'admin/School/editSchool',
    method: 'post',
    data
  })
}
//删除
export function delSchool(data){
  return request({
    url: 'admin/School/delSchool',
    method: 'post',
    data
  })
}
//绑定记录
export function getUserInfoBySchoolId(data){
  return request({
    url: 'admin/School/getUserInfoBySchoolId',
    method: 'post',
    data
  })
}
