<template>
  <div>
    <el-card>
        <el-row class="nav-">
          <el-col :span="6">合集名称: <el-input type="text"  v-model="search.title" prefix-icon="el-icon-search"></el-input></el-col>
          <el-col :span="3" style="margin-left:10px">状态: 
            <el-select v-model="search.status" placeholder="请选择"  filterable
                style="width:100%">
                <!-- <el-option value="">全部</el-option> -->
                <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id">{{
                  item.name
                }}
                </el-option>
              </el-select>
          </el-col>
          <el-col :span="3" style="margin-left:10px">音频关联: 
            <el-select v-model="search.relation_audio_id" placeholder="请选择"  filterable style="width:100%">
                <el-option value="">全部</el-option>
                <el-option v-for="(item, index) in audioRelationList" :key="index" :label="item.audio_name" :value="item.id">{{item.audio_name}}</el-option>
              </el-select>
          </el-col>
          <el-button  type="primary" icon="el-icon-search" @click="searchClick" style="margin-top:14px;margin-left:10px">搜索</el-button>
          <el-button  type="warning" icon="el-icon-refresh" @click="btnClick" style="margin-top:14px;margin-left:10px">重置</el-button>
        </el-row>
        <el-row style="margin-top:10px">
           <el-button type="primary"  @click="add" style="float:left;">添加合集</el-button>
          <el-button type="success"  @click="excelExport" >导出</el-button>
        </el-row>
        <el-row style="margin-top:10px;">
            <el-table :data="list" border stripe>
            <el-table-column label="ID"  prop="audio_group_no"></el-table-column>
            <el-table-column label="合集名称" prop="audio_group_name"></el-table-column>
            <el-table-column label="合集封面" >
              <template slot-scope="scope">
                <el-image :src="imgDomain + scope.row.audio_group_thumb" style="width: 30px;height:30px;"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="合集标语" prop="audio_group_slogan"></el-table-column>
            <el-table-column label="合集简介" prop="audio_group_intro" width="150px">
              <template slot-scope="scope">
                <span v-if="scope.row.audio_group_intro.length< 20">{{scope.row.audio_group_intro}}</span>
                <span v-else>
                  <p v-if="showField">{{scope.row.audio_group_intro}}<el-button type="text" @click="changeShowField()">收起</el-button></p>
                  <p v-else>{{scope.row.audio_group_intro.substring(0,13)}}...<el-button type="text" @click="changeShowField()">查看全部</el-button></p>      
                </span>
              </template>
            </el-table-column>
            <el-table-column label="合集价格" prop="audio_group_price"></el-table-column>
            <el-table-column label="关联音频数" prop="relation_audio_num"></el-table-column>
            <el-table-column label="实际收藏数" prop="real_collect_num"></el-table-column>
            <el-table-column label="虚拟收藏数" prop="audio_group_virtual_collect_number"></el-table-column>
            <el-table-column label="实际播放量" prop="group_audio_real_play_num"></el-table-column>
            <el-table-column label="虚拟播放量" prop="audio_group_virtual_play_number"></el-table-column>
            <el-table-column label="实际分享数" prop="real_share_num"></el-table-column>
            <el-table-column label="权重" prop="audio_group_weight"></el-table-column>
            <el-table-column label="状态"  prop="audio_group_status_str"></el-table-column>
            <el-table-column label="操作" width="240px">
                <template slot-scope="scope" >
                  <el-button type="text" size="mini"  @click="edit(scope.row)">编辑</el-button>
                  <el-button type="text" v-if="scope.row.audio_group_status == 2" size="mini" @click="changeStatus(scope.row)">启用</el-button>
                  <el-button type="text" v-else-if="scope.row.audio_group_status == 1" size="mini" @click="changeStatus(scope.row)">禁用</el-button>
                  <el-button type="text"  size="mini" @click="jump(scope.row.id,scope.row.audio_group_name)">关联音频</el-button>
                  <el-button type="text"  size="mini" @click="jumpRecords(scope.row.id)">收藏记录</el-button>
                  <el-button type="text"  size="mini" @click="del(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
          </el-table>

          <!--分页start-->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.pagenum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="search.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          <!--分页end--> 
        </el-row>

        <!--添加-->
        <el-dialog title="添加合集" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="50%"
          @close="addDialogClosed">
          <el-form ref="addFormRef"  label-width="120px">
            <el-form-item label="合集名称:">
              <el-input v-model="addForm.title" type="text" maxlength="14"></el-input>
            </el-form-item>
            <div style="width:178px;">
              <el-form-item label="合集封面:"  >
                <el-upload class="avatar-uploader" 
                    action="#"
                    :show-file-list="false"     
                    :http-request="(file)=> {uploadFile(file,1,1)}"
                >
                  <img v-if="addForm.cover_img" :src="imgDomain + addForm.cover_img" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <p>1:1</p>
              </el-form-item>
            </div>
            <el-form-item label="合集标语:">
              <el-input v-model="addForm.slogan" type="text" maxlength="18"></el-input>
            </el-form-item>
            <el-form-item label="合集简介:">
              <el-input v-model="addForm.description" type="textarea" maxlength="70"></el-input>
            </el-form-item>
            <el-form-item label="合集价格:">
              <el-input v-model="addForm.price" type="number" ></el-input>
            </el-form-item>
            <el-form-item label="显示数据:">
              <el-col>
                虚拟收藏量<el-input  v-model="addForm.virtual_collect" type="text" ></el-input>
              </el-col>
              <el-col>
                虚拟播放量<el-input  v-model="addForm.virtual_play" type="text" ></el-input>
              </el-col>
            </el-form-item> 
            <el-form-item label="合集权重:">
              <el-input v-model="addForm.sort" type="number" ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="addSubmit">保存</el-button>
          </span>
        </el-dialog>

         <!--编辑--> 
         <el-dialog title="编辑音频" :close-on-click-modal="false" :visible.sync="editDialogVisible" width="50%"
          @close="editDialogClosed">
          <el-form ref="addFormRef"  label-width="120px">
            <el-form-item label="合集名称:">
              <el-input v-model="editForm.title" type="text" maxlength="14"></el-input>
            </el-form-item>
            <div style="width:178px;">
              <el-form-item label="合集封面:"  >
                <el-upload class="avatar-uploader" 
                    action="#"
                    :show-file-list="false"     
                    :http-request="(file)=> {uploadFile(file,1,1)}"
                >
                  <img v-if="editForm.cover_img" :src="imgDomain + editForm.cover_img" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <p>1:1</p>
              </el-form-item>
            </div>
            <el-form-item label="合集标语:">
              <el-input v-model="editForm.slogan" type="text" maxlength="18"></el-input>
            </el-form-item>
            <el-form-item label="合集简介:">
              <el-input v-model="editForm.description" type="textarea" maxlength="70"></el-input>
            </el-form-item>
            <el-form-item label="合集价格:">
              <el-input v-model="editForm.price" type="number"></el-input>
            </el-form-item>
            <el-form-item label="显示数据:">
              <el-col>
                虚拟收藏量<el-input  v-model="editForm.virtual_collect" type="text" ></el-input>
              </el-col>
              <el-col>
                虚拟播放量<el-input  v-model="editForm.virtual_play" type="text" ></el-input>
              </el-col>
            </el-form-item> 
            <el-form-item label="合集权重:">
              <el-input v-model="editForm.sort" type="number" ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="editSubmit">保存</el-button>
          </span>
        </el-dialog>

      </el-card> 
  </div>
</template>


<script>

import { conllectionList, collectionAdd, collectionEdit, collectionDel , collectionChangeStatus, list } from '@/api/audio'
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'
import global from '@/utils/global'
export default {
  components:{
  },
  data(){
    return {
      list:[],
      audioRelationList:[],
      showField:false,
      search:{
        title:"",
        status:"",
        relation_audio_id:"",
        pagenum:1,
        pagesize:10,
      },
      addForm:{
        title:"",
        cover_img:"",
        slogan:"",
        description:"",
        price:"",
        virtual_collect:"",
        virtual_play:"",
        sort:100,
      },
      editForm:{
        title:"",
        cover_img:"",
        slogan:"",
        description:"",
        price:"",
        virtual_collect:"",
        virtual_play:"",
        sort:100,
        id:""
      },
      total:0,
      typeList:[{"id":"","name":"全部"},{"id":1,"name":"启用"},{"id":2,"name":"禁用"}],
      editObj:{},
      addDialogVisible:false,
      editDialogVisible:false
    }
  },
  created(){
    this.getList()
    this.getAudioRelationList()
  },
  methods:{
    // 关联课程
    async getAudioRelationList(){
      const {data:res} = await list({type:2})
       if (res.status != 0) {
         return this.$message.error(res.msg)
       }
       this.audioRelationList = res.data
    },
    changeShowField(){
      this.showField = !this.showField
    },
    async getList(){
      const {data:res} = await conllectionList(this.search)
       if (res.status != 0) {
         return this.$message.error(res.msg)
       }
       this.total = res.data.total
       this.list = res.data.data
    },

    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },

    add(){
      this.addDialogVisible = true
    },
    addDialogClosed(){
      this.addDialogVisible = false
      this.clear()
    },
    async addSubmit(){
      const {data:res} = await collectionAdd(this.addForm)
       if (res.status != 0) {
         return this.$message.error(res.msg)
       }
       this.$message.success(res.msg)
       this.getList()
       this.addDialogVisible = false
    },
    edit(row){
      console.log(row)
      this.editForm.title = row.audio_group_name
      this.editForm.cover_img = row.audio_group_thumb
      this.editForm.slogan = row.audio_group_slogan
      this.editForm.description = row.audio_group_intro
      this.editForm.price = row.audio_group_price
      this.editForm.virtual_collect = row.audio_group_virtual_collect_number
      this.editForm.virtual_play = row.audio_group_virtual_play_number
      this.editForm.sort = row.audio_group_weight
      this.editForm.id = row.id
      this.editDialogVisible = true
    },
    editDialogClosed(){
      this.editDialogVisible = false
      this.clear()
    },
    async editSubmit(){
      const {data:res} = await collectionEdit(this.editForm)
       if (res.status != 0) {
         return this.$message.error(res.msg)
       }
       this.$message.success(res.msg)
       this.getList()
       this.editDialogVisible = false
    },
    clear(){
      this.addForm.title=""
      this.addForm.cover_img=""
      this.addForm.slogan=""
      this.addForm.description=""
      this.addForm.price=""
      this.addForm.sort=""
      this.addForm.virtual_collect=""
      this.addForm.virtual_play=""
      this.editForm.title=""
      this.editForm.cover_img=""
      this.editForm.slogan=""
      this.editForm.description=""
      this.editForm.price=""
      this.editForm.sort=""
      this.editForm.virtual_collect=""
      this.editForm.virtual_play=""
      this.editForm.id = ""
    },
    searchClick(){
      this.getList()
    },
    
    btnClick() {
      this.search.title = ""
      this.search.status = ""
      this.search.relation_audio_id = ""
      this.search.pagenum = 1
      this.search.pagesize = 10
      this.getList()
    },

    async del(id){
      this.$confirm("是否要删除", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {data:res} = await collectionDel({"id":id})
        if (res.status != 0) {
          return this.$message.error(res.msg)
        } 
        this.$message.success("删除成功!")
        this.getList()
      }).catch(() => {
      }) 
    },

    async uploadFile(uploadInfo,index,type){
      const {data:token} =  await getQiniuToken()
      const that = this
      const observer = {
          next(res){
            that.percentage = parseInt(res.total.percent.toString().split(".")[0])
          },
          error(err){
            console.log(err)
          },
          complete(res){
            if(index == 1){
              if(type==1){
                that.addForm.cover_img = res.key 
              }else{
                that.editForm.cover_img = res.key 
              }
            }
          }
      }
      let name = uploadInfo.file.name
      let fileName = global.getFileName(name)
      if(parseInt(fileName) !=NaN){
        name = fileName+ "-" + global.randStr(10) +"."+ global.getExt(name)
      }
      const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+ name,token,{},{})
      const subscription =  observable.subscribe(observer)
    },

    async changeStatus(row){
      const {data:res} =  await collectionChangeStatus({id:row.id,status:row.audio_group_status})
      if(res.status!=0){
        return this.$message.error(res.msg)
      } 
      this.$message.success(res.msg)
      this.getList()
    },

    jump(id,name){
      // 关联音频
      this.$router.push({path:'/admin.audio.collectionRelationAudio',query:{id:id,name:name}})
    },
    jumpRecords(id){
      this.$router.push({path:'/admin.audio.audioGroupRecords',query:{id:id}})
    },
   
    excelExport() {
      const headers = {
        'ID':"audio_group_no",
        '合集名称': 'audio_group_name',
        '合集封面': 'audio_group_thumb',
        '合集标语': 'audio_group_slogan',
        '合集简介': 'audio_group_intro',
        '合集价格': 'audio_group_price',
        '关联音频数': 'relation_audio_num',
        '实际收藏数': 'real_collect_num',
        '虚拟收藏数': 'audio_group_virtual_collect_number',
        '实际播放数': 'group_audio_real_play_num',
        '虚拟播放数': 'audio_group_virtual_play_number',
        '实际分享数': 'real_share_num',
        '权重': 'audio_group_weight',
        '状态': 'audio_group_status_str',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await conllectionList(this.search)
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '音频合集信息',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.search.pagesize = 10
      })
    },

    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },
  }
}
</script>