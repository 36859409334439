import request from '@/utils/request'

//---------------------------------------后台rbac user----------------

export function login (data) {
  return request({
    url: '/admin/login/login',
    method: 'post',
    data
  })
}

// 用户权限
export function userMenuAuth() {
  return request({
    url: '/admin/user/userMenuAuth',
    method: 'post'
  })
}

// 用户列表
export function userList(data){
  return request({
    url: '/admin/user/userList',
    method: 'post',
    data
  })
}

// 添加用户
export function userAdd(data){
  return request({
    url: '/admin/user/userAdd',
    method: 'post',
    data
  })
}

// 修改用户
export function userEdit(data){
  return request({
    url: '/admin/user/userEdit',
    method: 'post',
    data
  })
}

export function userDel(data){
  return request({
    url: '/admin/user/userDel',
    method: 'post',
    data
  })
}

// 根据id查找用户
export function userById(data){
  return request({
    url: '/admin/user/userById',
    method: 'post',
    data
  })
}

// 分配角色
export function allotRole(data){
  return request({
    url: '/admin/user/allotRole',
    method: 'post',
    data
  })
}
