<!--老师管理-->
<template>
  <div>
    <!--面包屑start-->
    <el-card>
      <div class="nav_1">
        <div class="div-left">
          <div class="d-order-no">后台账号:</div>
          <div class="div-left">
            <el-input class="input" v-model="queryInfo.username"></el-input>
          </div>
        </div>
        <div class="div-left">
          <div class="d-order-no">用户端账号:</div>
          <div class="div-left">
            <el-input class="input" v-model="queryInfo.usermobile"></el-input>
          </div>
        </div>
        <div class="div-left">
          <el-button type="primary" icon="el-icon-search" @click="btnClick"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="reset"
            >重置</el-button
          >
        </div>
      </div>
      <div class="clear"></div>
      <el-row>
        <el-col>
          <el-button type="primary" @click="addDialogVisible = true"
            >添加用户</el-button
          >
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-table :data="userList" border stripe>
          <el-table-column type="index"></el-table-column>
          <el-table-column label="后台账户" prop="username"></el-table-column>
          <el-table-column label="用户端id" prop="user_id"></el-table-column>
          <el-table-column
            label="用户端昵称"
            prop="user_name"
          ></el-table-column>
          <el-table-column
            label="用户端手机号"
            prop="user_mobile"
          ></el-table-column>
          <el-table-column label="老师名称" prop="nick_name"></el-table-column>
          <el-table-column
            label="创建时间"
            prop="create_time"
          ></el-table-column>
          <el-table-column label="操作" width="180px">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="editTeacher(scope.row)"
              >编辑</el-button>
              <el-button type="text" v-if="scope.row.user_id ==0" size="mini" @click="addBindingUserModel(scope.row.id)">绑定账号</el-button>
              <el-button type="text"  v-else size="mini" @click="changeSecureBinding(scope.row.id)" >解除绑定</el-button>
                <el-button type="text" v-if="scope.row.status == 2" size="mini" @click="changeStatusClick(scope.row)">启用</el-button>
                <el-button type="text" v-else-if="scope.row.status == 1" size="mini" @click="changeStatusClick(scope.row)">禁用</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页start-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <!--分页end-->
      </el-row>
    </el-card>
    <!--面包屑end-->
    <!--添加用户start-->
    <el-dialog
      title="添加用户"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="70px"
      >
        <el-form-item label="账号" prop="username">
          <el-input v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password"  v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item label="老师姓名" prop="nick_name">
          <el-input v-model="addForm.nick_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addUser">确定</el-button>
      </span>
    </el-dialog>
    <!--添加用户end-->
     <!--绑定账号start-->
     <el-dialog
      title="关联账号"
      :visible.sync="addBindingVisible"
      width="50%"
      @close="addBindingClosed"
    >
      <el-form
        :model="bindingForm"
        :rules="addBindingFormRules"
        ref="addBindingFormRef"
        label-width="70px"
      >

        <el-form-item label="用户端手机号" prop="mobile">
          <el-input v-model="bindingForm.mobile"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addBindingVisible = false">取消</el-button>
        <el-button type="primary" @click="addBindingUser">确定</el-button>
      </span>
    </el-dialog>
    <!--绑定账号end-->
    <!--修改用户start-->
    <el-dialog
      title="修改用户"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="70px"
      >
        <el-form-item label="账号" prop="username">
          <el-input disabled v-model="editForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="editForm.password" ></el-input>
        </el-form-item>
        <el-form-item label="老师姓名" prop="nick_name">
          <el-input v-model="editForm.nick_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!--修改用户end-->
  </div>
</template>

<script>
import {list, addTeacher, editTeacher,changeStatus, bindingUser, secureBinding} from "@/api/schoolTeacher";
import {del, edit} from "@/api/audio";
export default {
  data() {
    return {
      userList: [],
      total: 0,
      addDialogVisible: false, //添加用户对话框
      editDialogVisible: false, //修改用户对话框
      addBindingVisible: false, //添加绑定用户
      admin_user_id:0,
      queryInfo: {
        pagenum: 1,
        pagesize: 10,
        username: "",
        usermobile: "",
      },
      addForm: {
        username: "",
        password: "",
        nick_name:"",
        // operate_id: window.sessionStorage.getItem('admin_id'),
      },
      bindingForm: {
        mobile: "",
      },
      editForm: {
        username: "",
        nick_name: "",
        password: "",
        id:0,
      },
      userInfo: {},
      addFormRules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "账号的长度在3~10个字符之间",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "用户名的长度在6~15个字符之间",
            trigger: "blur",
          },
        ],
      },
      // 修改表单验证
      editFormRules: {},
      // 绑定账号验证
      addBindingFormRules: {
        mobile:[ { required: true, message: "请输入手机号", trigger: "blur" },]
      },
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    //--------------------------------------获取老师列表--------------------------------------------------
    async getUserList() {
      const { data: res } = await list(this.queryInfo);
      if (res.status != 0) {
        return this.$message.error("获取老师列表失败");
      }
      this.userList = res.data.data;
      this.total = res.data.total;
    }, // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getUserList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getUserList();
    },
    //--------------------------------------添加老师账号--------------------------------------------------
    async addUser() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await addTeacher(this.addForm);
        if (res.status != 0) {
          return this.$message.error(res.msg);
        }
        this.$message.success(res.msg);
        this.addDialogVisible = false;
        this.getUserList();
      });
    },
    addDialogClosed() {
      // 关闭对话框，重置表单
      this.$refs.addFormRef.resetFields();
    },
    //--------------------------------------搜索--------------------------------------------------
    btnClick() {
      this.queryInfo.pagenum = 1;
      this.getUserList();
    },
    reset() {
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = 10;
      this.queryInfo.username = "";
      this.queryInfo.usermobile = "";
      this.getUserList();
    },
    //--------------------------------------绑定账号--------------------------------------------------
      addBindingUser() {
      this.$refs.addBindingFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await bindingUser({'mobile':this.bindingForm.mobile,admin_id:this.admin_user_id});
        if (res.status != 0) {
          return this.$message.error(res.msg);
        }
        this.$message.success(res.msg);
        this.addBindingVisible = false;
        this.getUserList();
      });
    },
    addBindingUserModel(id){
      this.addBindingVisible = true;
      this.admin_user_id=id;
    },
    addBindingClosed() {
      // 关闭对话框，重置表单
      this.$refs.addBindingFormRef.resetFields();
    },
    //--------------------------------------修改状态--------------------------------------------------
    async changeStatusClick(row){
      const {data:res} =  await changeStatus({id:row.id,status:row.status})
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.getUserList()
    },
    //--------------------------------------解除绑定--------------------------------------------------
    async changeSecureBinding(id){
      this.$confirm("确定要解除绑定吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {data:res} =  await secureBinding({id:id})
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.getUserList()
      }).catch(() => {
      })
    },
    //--------------------------------------修改账号--------------------------------------------------
    async editTeacher(row){
      this.editDialogVisible = true
      this.editForm.nick_name = row.nick_name
      this.editForm.id = row.id
      this.editForm.username = row.username
    },
    async editSubmit(){
      const {data:res} = await editTeacher(this.editForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.getUserList()
      this.editDialogVisible = false
    },
    editDialogClosed(){
      //this.editDialogVisible = false
      this.$refs.editFormRef.resetFields()

    },
  },
};
</script>

<style scoped>
.nav_1 {
  margin-bottom: 10px;
  float: left;
}

.div-left {
  float: left;
  margin-right: 10px;
}

.input {
  width: 220px;
}

.d-order-no {
  float: left;
  margin-right: 15px;
  height: 35px;
  line-height: 35px;
}
</style>
