<!--------------------- 设备管理------------------------------->
<!----------------------------------------------------------->
<template>
  <div>
    <el-card>
      <div class="nav_1">
        <div style="float:left;">
          <div style="float:left;margin-right:5px;height:40px;line-height:40px;" >用户搜索: </div>
          <div  style="float:left;">
            <el-input placeholder="输入用户名或手机号" v-model="search.keyword" ></el-input>
          </div>
          <div style="float:left;margin-left:10px">
            <el-button  type="primary" icon="el-icon-search" @click="btnClick" >搜索</el-button>
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <div style="float:left;">
        <div style="float:left;">用户登录 <el-button @click="typeSelect(1)" :type="sel1">全部</el-button><el-button @click="typeSelect(2)" :type="sel2">最近一个月</el-button></div>
        <div style="float:left;margin-left:20px;">用户上课 <el-button @click="typeSelect(3)" :type="sel3">全部</el-button><el-button @click="typeSelect(4)" :type="sel4">最近一个月</el-button></div>
        <div style="float:left;margin-left:10px">
          <el-button  type="warning" icon="el-icon-refresh" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="clear"></div>
      <el-row style="margin-top:10px;">
        <el-button type="success" @click="excelExport">Excel导出</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
          <el-table :data="list" 
          border stripe 
          :row-style="{height:'45px'}" 
          :cell-style='cellStyle' 
          :header-cell-style='rowClass'
          >
          <el-table-column label="用户id" prop="user_id"></el-table-column>
          <el-table-column label="用户昵称" prop="nickname"></el-table-column>
          <el-table-column label="手机号" prop="mobile_phone" width="108px"></el-table-column>
          <el-table-column label="购买课程数量" prop="buy_num" ></el-table-column>
          <el-table-column label="最近上课时间" prop="last_learn_time"></el-table-column>
          <el-table-column label="最近登录时间" prop="last_login_time"></el-table-column>
          <el-table-column label="最近登陆设备类型" prop="device_type_name"></el-table-column>
          <el-table-column label="最近登录设备" prop="device"></el-table-column>
          <el-table-column label="常用设备类型" prop="common_device_type_name"></el-table-column>
          <el-table-column label="常用设备" prop="common_use_device"></el-table-column>
          <el-table-column label="设备汇总" width="240px">
            <template slot-scope="scope" >
              <el-button type="text" size="mini" @click="show(scope.row.user_id)" >查看登录设备</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--分页start-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="search.pagenum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="search.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        <!--分页end-->
      </el-row>
    </el-card> 

      <userDeviceList ref="userDeviceList"></userDeviceList>
  </div>
</template>


<script>
import {list,excelImport} from '@/api/device'
import userDeviceList from "@/components/UserDevice/device.vue";
export default {
 data (){
  return {
    list:[],
    search:{
      pagenum: 1,
      pagesize: 10,
      keyword:"",
      type:"",
      type1:"",
    },
    total: 0,
    deviceDialogVisible:false,
    sel1:"success",
    sel2:"",
    sel3:"success",
    sel4:"",
  }
 },
  components:{
    userDeviceList
 },
 created() {
    this.getList()
 },
 methods: {
  //-----------------------------tabel 文字居中-------------------------------
   rowClass({row, rowIndex}){
    return 'text-align:center'
  },
  cellStyle({row, column, rowIndex, columnIndex}){
    return 'text-align:center;padding:0px' 
  },
  //-------------------
  async getList(){
    const {data:res} = await list(this.search)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.list = res.data.data
    this.total = res.data.total
  },
  //
  btnClick(){
    this.search.pagenum = 1
    this.search.pagesize = 10
    this.getList()
  },
  typeSelect(val){
    switch(val){
      case 1:
        this.sel1 = "success"
        this.sel2 = ""
        this.search.type = ""
        break
      case 2:
        this.sel2 = "success"
        this.sel1 = ""
         this.search.type = 1
        break
      case 3:
        this.sel3 = "success"
        this.sel4 = ""
        this.search.type1 = ""
        break
      case 4:
        this.sel3 = ""
        this.sel4 = "success"
        this.search.type1 = 1
        break
    }
    this.getList()
  },  
  reset(){
    this.search.type = ""
    this.search.type1 = ""
    this.sel1 = "success"
    this.sel2 = ""
    this.sel3 = "success"
    this.sel4 = ""
    this.search.keyword = ""
    this.getList()
  },
   //------------
   show(id){
    this.$refs.userDeviceList.addDialogVisible = true
    this.$refs.userDeviceList.search.user_id = id
    this.$refs.userDeviceList.getUserDeviceList()
   },
   // -------------------------分页-------------------------------------------
  handleSizeChange(newSize) {
    this.search.pagesize = newSize
    this.getList()
  },
  handleCurrentChange(newPage) {
    this.search.pagenum = newPage
    this.getList()
  },
  //-----------------------------excelExport------
  async excelExport(){
    var FileSaver = require('file-saver');
      this.search.pagenum = 1
      this.search.pagesize = this.total
      let res = await excelImport(this.search)
      // console.log("res",res)
      const { headers, data } = res
      let blob = new Blob([data], {
        type: headers["content-type"] // 使用来自`PHP` 输出时的`headers` 中已经指定的类型
      })
      FileSaver.saveAs(blob, "用户设备信息.xlsx")
      this.search.pagesize = 10
    },
    formatJson(headers, rows) {
      console.log(rows)
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },

 } 
}
</script>

<style scoped>
.nav_1{
  margin-bottom: 10px;
  float:left;
}
</style>