<!--用户报名信息-->
<template>
  <div>
    <el-card>
      <div class="nav_1">
        <div style="float:left;">
          <div style="float:left;margin-right:5px;height:40px;line-height:40px;">报名用户: </div>
          <div class="search"><el-input type="text" style="width:100%" v-model="addForm.keyword" @blur="blur()"
              prefix-icon="el-icon-search"></el-input></div>
        </div>
        <div style="float:left;margin-left:15px;height:40px;line-height:40px;">报名状态: </div>
        <div style="float:left;margin-left:20px;">
          <div class="block">
            <el-select v-model="addForm.apply_status" @change="blur()" placeholder="请选择" filterable style="width:100%">
              <el-option v-for="(item, index) in apply_status_list" :key="index" :label="item.name" :value="item.id" />
            </el-select>
          </div>
        </div>
        <div style="float:left;margin-left:15px;height:40px;line-height:40px;">上课状态: </div>
        <div style="float:left;margin-left:20px;">
          <div class="block">
            <el-select v-model="addForm.have_class_status" @change="blur()" placeholder="请选择" filterable
              style="width:100%">
              <el-option v-for="(item, index) in have_class_stauts_list" :key="index" :label="item.name"
                :value="item.id" />
            </el-select>
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <el-row style="margin-top:10px;">
        <el-button type="danger" style="width:100px;" @click="back">返回课表</el-button>
        <el-button type="success" style="width:100px;" @click="excelExport">Excel导出</el-button>
        <el-button style="width:100px;" @click="showDialog('addUserView')">添加用户</el-button>
        <el-button style="width:150px;" @click="showDialog('addBatchUserView')">批量添加用户</el-button>
        <el-button style="width:150px;" @click="goToImportUser()">批量添加记录</el-button>

      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="list" border stripe :row-style="{ height: '45px' }" :cell-style='cellStyle'
          :header-cell-style='rowClass'>
          <el-table-column label="用户名" prop="user_name"></el-table-column>
          <el-table-column label="手机号" prop="user_mobile"></el-table-column>
          <el-table-column label="报名状态" prop="sign_status_string"></el-table-column>
          <el-table-column label="上课进度(已上/已解锁/总课程)" prop="p" v-if="lock_type == 1"></el-table-column>
          <el-table-column label="上课进度(已上/总课程)" prop="p" v-if="lock_type == 2"></el-table-column>
          <el-table-column label="上课状态" prop="class_status"></el-table-column>
          <el-table-column label="首次课程完成时间" prop="done_time"></el-table-column>
          <el-table-column label="所属客服" prop="customer_name"></el-table-column>
          <el-table-column label="售卖人" prop="sale_name"></el-table-column>

          <el-table-column label="操作" width="240px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="jump(scope.row)"
                v-if="scope.row.sign_status == 1">上课记录</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--分页start-->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="addForm.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="addForm.pagesize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <!--分页end-->
      </el-row>
    </el-card>

    <addUser ref="addUserView" @userSignUpList="userSignUpList"></addUser>
    <addBatchUser ref="addBatchUserView" @userSignUpList="userSignUpList"></addBatchUser>
  </div>
</template>


<script>
import { getSignUpInfoList } from '@/api/courseSchoolTime'
import global_ from '@/utils/global.js'
import AddUser from '@/components/ImportUser/addUser.vue'
import AddBatchUser from '@/components/ImportUser/addBatchUser.vue'
export default {
  components: {
    addUser: AddUser,
    addBatchUser: AddBatchUser
  },
  data() {
    return {
      apply_status_list: global_.user_signup_apply_status,
      have_class_stauts_list: global_.user_signup_have_class_status,
      bagTimeId: this.$route.query.bagTimeId, // 课表id
      list: [],
      addDialogVisible: false,
      addForm: {
        keyword: '',
        bag_times_id: this.$route.query.bagTimeId,
        pagesize: 10,
        pagenum: 1,
        have_class_status: "0",
        apply_status: "0"
      },
      total: 0,
      lock_type: this.$route.query.lock_type
    }
  },
  created() {
    this.userSignUpList()
  },
  methods: {
    showDialog(name) {
      if (name == "addUserView") {
        this.$refs.addUserView.addUserDialogOpen();
      } else {
        this.$refs.addBatchUserView.openUserAddBatch();
      }
    },
    goToImportUser() {
      this.$router.push({ path: '/admin.course_time.import_user_list', query: { course_bag_time_id: this.bagTimeId } })
    },
    //-----------------------------tabel 文字居中-------------------------------
    rowClass({ row, rowIndex }) {
      return 'text-align:center'
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;padding:0px'
    },
    //-------------------------------------------------------------------------
    addcp() {
      this.addDialogVisible = true
    },
    //-------------------列表----------
    async userSignUpList() {
      const { data: res } = await getSignUpInfoList(this.addForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.list = this.formData(res.data.data)
      this.total = res.data.total

    },
    formData(data) {
      const arr = []
      for (let i in data) {
        let p = ""
        if (this.lock_type == 1) {
          p = data[i].progress + '/' + data[i].deblock + '/' + data[i].sum
        } else if (this.lock_type == 2) {
          p = data[i].progress + '/' + data[i].sum
        }
        arr.push({
          app_user_id: data[i].app_user_id,
          bags_id: data[i].bags_id,
          progress: data[i].progress,
          sum: data[i].sum,
          user_name: data[i].user_name,
          user_mobile: data[i].user_mobile,
          p: p,
          sign_status_string: data[i].sign_status_string,
          sign_status: data[i].sign_status,
          course_bags_cate: data[i].course_bags_cate,
          customer_name: data[i].customer_name,
          sale_name: data[i].sale_name,
          class_status: data[i].class_status,
          done_time: data[i].done_time
        })
      }
      return arr
    },
    // -------------------------分页-------------------------------------------
    handleSizeChange(newSize) {
      this.addForm.pagesize = newSize
      this.userSignUpList(this.search)
    },
    handleCurrentChange(newPage) {
      this.addForm.pagenum = newPage
      this.userSignUpList(this.search)
    },
    //------------
    blur() {
      this.userSignUpList()
    },
    //----------------------------跳转: 用户上课记录-----------------------------
    jump(row) {
      console.log(row)
      this.$router.push({ path: '/admin.course.classRecord', query: { bags_id: row.bags_id, app_user_id: row.app_user_id, bagTimeId: this.$route.query.bagTimeId, course_bags_cate: row.course_bags_cate } })
    },
    back() {
      let list_search_coursetime = sessionStorage.getItem('list_search_coursetime');
      sessionStorage.setItem('list_search',list_search_coursetime);
      this.$router.push({ path: '/admin.course.schooltt', query: {} })
    },
    //-----------------------------导出--------------------------
    excelExport() {
      const headers = {
        '用户名': 'user_name',
        '手机号': 'user_mobile',
        '上课进度': 'p',
      }
      import('@/vendor/Export2Excel').then(async excel => {
        this.addForm.pagenum = 1
        this.addForm.pagesize = this.total
        const { data: res } = await getSignUpInfoList(this.addForm)
        const data = this.formatJson(headers, this.formData(res.data.data))

        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '报名信息表',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },
  }
}
</script>

<style scoped>
.nav_1 {
  margin-bottom: 10px;
  float: left;
}

.search {
  float: left;
  width: 300px;
  margin-left: 10px;
}
</style>