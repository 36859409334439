<template>
    <span>
        <div style="clear:both"></div>
        <el-row style="margin-top:10px;">
            <el-table :data="courseTime" border stripe :row-style="{ height: '45px' }" :cell-style="{ padding: '0px' }">
                <el-table-column label="课表编号" prop="course_bag_time_no"></el-table-column>
                <el-table-column label="课表名称" prop="course_bag_time_name"></el-table-column>
                <el-table-column label="课程包名称" prop="course_bag_name"></el-table-column>
                <el-table-column label="解锁类型" prop="lock_type" :formatter="dealLockType"></el-table-column>
                <el-table-column label="开课时间" prop="course_bag_open_time"></el-table-column>
                <el-table-column label="负责客服" prop="name"></el-table-column>
                <el-table-column label="报名人数" prop="user_sign_up_count"></el-table-column>
                <el-table-column label="完课人数" prop="done_class_number"></el-table-column>
                <el-table-column label="完课率" prop="done_class_number_p"></el-table-column>
                <el-table-column label="状态" prop="status_name"> </el-table-column>
                <el-table-column label="开课日期" prop="open_class_day_name"></el-table-column>
                <el-table-column label="是否排除节假日" prop="holiday_setting_name" width="120px"></el-table-column>
                <el-table-column label="操作" width="280px">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" @click="editCourseBagsTime(scope.row)"
                            v-if="(scope.row.course_bag_time_status == 1) || (scope.row.lock_type == 2 && scope.row.user_sign_up_count == 0)">编辑</el-button>
                        <el-button type="text" size="mini" @click="advanceCourseBagsTime(scope.row)"
                            v-if="scope.row.course_bag_time_status == 1 && scope.row.lock_type == 1">提前开课</el-button>
                        <el-button type="text" size="mini" @click="jumpUserInfo(scope.row)">报名信息</el-button>
                        <el-button type="text" slot="reference" size="mini"
                            v-if="(scope.row.lock_type == 1 && scope.row.course_bag_time_status == 1 && scope.row.user_sign_up_count == 0) || (scope.row.lock_type == 2 && scope.row.user_sign_up_count == 0)"
                            style="margin-left:5px" @click="delTimeTable(scope.row.id)">删除</el-button>
                        <span v-if="scope.row.lock_type == 2">
                            <el-button type="text" slot="reference" size="mini"
                                v-if="scope.row.is_display == 1 && scope.row.lock_type == 2" style="margin-left:5px"
                                @click="changeDisplay(scope.row)">下架</el-button>
                            <el-button type="text" slot="reference" size="mini"
                                v-if="scope.row.is_display == 2 && scope.row.lock_type == 2" style="margin-left:5px"
                                @click="changeDisplay(scope.row)">上架</el-button>
                        </span>
                        <el-button type="text" slot="reference" size="mini" style="margin-left:5px"
                            @click="jumpBagsTimeStatist(scope.row.id)"
                            v-if="((scope.row.course_bag_time_status == 2 || scope.row.course_bag_time_status == 3) && scope.row.lock_type == 1) || (scope.row.lock_type == 2)">渠道统计</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-row>
    </span>
</template>

<script>
import { list, advanceCourseTime, deleteTimeTable, changeDisplay } from '@/api/courseSchoolTime'
export default {
    data() {
        return {
            courseTime: [],
            total: 0,
            search: {
                course_bag_id: '',
                start_date: '',
                end_date: '',
                pagenum: 1,
                pagesize: 10,
                course_bag_time_status: "",
                customer_id: "",
                lock_type: 0
            },
        }
    },
    created() {
        let list_search = sessionStorage.getItem('list_search') || "";
        if(list_search != "" && list_search != null && list_search != undefined){
            this.search= JSON.parse(list_search);
        }
        this.getCourseTimeList();
    },
    methods: {
        jumpBagsTimeStatist(id) {
            this.$router.push({ path: '/admin.course.bags_time_statist', query: { bagTimeId: id } })
        },
        async changeDisplay(row) {
            let is_display = row.is_display
            const { data: res } = await changeDisplay({ id: row.id })
            if (res.status != 0) {
                this.$message.error(res.msg)
            } else {
                let message = (is_display == 1) ? '下架成功' : '上架成功';
                this.$message.success(message);
            }
            this.getCourseTimeList()
        },
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
            this.getCourseTimeList()
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
            this.getCourseTimeList()
        },
        editCourseBagsTime(row) {
            this.$emit('openEditCourseBagsTimeDialog', row)
        },
        async delTimeTable(id) {
            let self = this;
            this.$confirm('确认要删除嘛', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data: res } = await deleteTimeTable({ course_bag_time_id: id })
                if (res.status != 0) {
                    return self.$message.error(res.msg)
                }
                self.getCourseTimeList()
                self.$message.success('删除成功')
            }).catch(() => {
                console.log("课表取消删除...")
            })
        },
        jumpUserInfo(row) {
            sessionStorage.setItem('list_search_coursetime',JSON.stringify(this.search));
            this.$router.push({ path: '/admin.course.userSignUp', query: { bagTimeId: row.id,lock_type: row.lock_type} })
        },
        async getCourseTimeList() {
            const { data: res } = await list(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.courseTime = res.data.data
            this.total = res.data.total
        },
        async advanceCourseBagsTime(row) {
            const self = this;
            let title = "当前课程开课时间为" + row.course_bag_open_time + "，若提前开课，已购买用户将可立即上课，是否提前开课？";
            this.$confirm(title, '提示', {
                confirmButtonText: '确认提前',
                cancelButtonText: '暂不提前',
                type: 'warning'
            }).then(() => {
                let advanceApi = {};
                advanceApi.course_bag_time_id = row.id;
                advanceCourseTime(advanceApi).then((res) => {
                    let code = res.data.status;
                    if (code === 0) {
                        self.$message.success("提前开课成功")
                        self.addDialogVisible = false;
                        self.getCourseTimeList()
                        self.courseTimeOperator = "";
                    } else {
                        self.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {
            });
        },
        dealLockType(row, column) {
            let lock_type_name = "";
            switch (row.lock_type) {
                case 1:
                    lock_type_name = "按期解锁"
                    break;
                case 2:
                    lock_type_name = "无需解锁";
                    break;

                default:
                    break;
            }
            return lock_type_name;
        },
    }
}
</script>