<template>
    <span>
        <div style="clear:both"></div>
        <el-dialog title="添加课表" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="40%"
            @close="addDialogClosed">
            <el-form label-width="120px">
                <el-form-item label="课表名称">
                    <el-input v-model="courseTimeData.time_name"></el-input>
                </el-form-item>
                <el-form-item label="解锁类型">
                    <el-select v-model="courseTimeData.lock_type" placeholder="请选择" @change="changeLockType"
                        :disabled="disabled">
                        <el-option v-for="(item, index) in courseBagATimeLockType" :key="index" :label="item.name"
                            :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="选择课程包">
                    <el-select filterable v-model="courseTimeData.course_bag_id" placeholder="请选择" :disabled="disabled">
                        <el-option v-for="(item, index) in cpList" :key="index" :label="item.course_bag_name"
                            :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="开课时间" v-if="courseTimeData.lock_type == 1">
                    <div class="block">
                        <el-date-picker value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="courseTimeData.open_time"
                            type="date" placeholder="选择日期">
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item label="上课天数" v-if="courseTimeData.lock_type == 1">
                    <template>
                        <el-checkbox-group v-model="courseTimeData.open_class_day">
                            <el-checkbox :label="item.id" v-for="item in openClassDay" :key="item.id">{{ item.name
                            }}</el-checkbox>
                        </el-checkbox-group>
                    </template>
                </el-form-item>
                <el-form-item label="是否排除节假日" v-if="courseTimeData.lock_type == 1" style="width:400px">
                    <template>
                        <el-radio label="1" v-model="courseTimeData.holiday_setting">排除</el-radio>
                        <el-radio label="2" v-model="courseTimeData.holiday_setting">不排除</el-radio>
                    </template>
                </el-form-item>
                <el-form-item label="选择客服">
                    <el-select v-model="courseTimeData.customer_id" placeholder="请选择">
                        <el-option v-for="(item, index) in kefuList" :key="index" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogClosed()">取消</el-button>
                <el-button type="primary" @click="submitCourseSchoolTime()">确定</el-button>
            </span>
        </el-dialog>
    </span>
</template>

<script>
import global_ from '@/utils/global.js'
import { addCourseTime,updateCourseTime} from "@/api/courseSchoolTime";
import { getCourseBagAll } from '@/api/coursePackage'
export default {
    props:{
        kefuList:{
            Type:Array,
            default:[]
        }
    },
    data() {
        return {
            addDialogVisible: false,
            disabled: false,
            courseTimeData: this.getCourseBagsTimeData(),
            statusList: global_.course_bag_time_status_list,
            lockTypeList: global_.course_bag_time_lock_type_List,
            courseBagATimeLockType: global_.course_bag_add_time_lock_type_List,
            cpList: [],
            courseTimeOperator: "",
            openClassDay: global_.course_bags_time_open_class_day_list
        }
    },
    methods: {
        openDialogEdit(row) {
            this.setCourseBagsTimeData(row)
        },
        openDialog() {
            this.courseTimeData = this.getCourseBagsTimeData();
            this.addDialogVisible = true
            this.courseTimeOperator = "add";
            if (this.courseTimeData.lock_type == 1) {
                this.getCoursePackageAll({ course_bag_status: 3, subject_type: 1 });
            } else if (this.courseTimeData.lock_type == 2) {
                this.getCoursePackageAll({ course_bag_status: 3, subject_type: 2 });
            }
        },
        addDialogClosed() {
            if (this.addDialogVisible == true) {
                this.cpList = []
                this.disabled = false
                this.addDialogVisible = false;
                this.courseTimeData = this.getCourseBagsTimeData()
            }
        },
        async submitCourseSchoolTime() {
            this.subjectCourseBagsTime();
        },
        changeLockType(value) {
            this.courseTimeData.course_bag_id = "";
            if (value == 1) {
                this.getCoursePackageAll({ course_bag_status: 3, subject_type: 1 });
            } else if (value == 2) {
                this.getCoursePackageAll({ course_bag_status: 3, subject_type: 2 });
            }
        },
        async getCoursePackageAll(condition) {
            const { data: res } = await getCourseBagAll(condition)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.cpList = res.data
        },
        async addCourseBagsTimeApi(courseApiData) {
            addCourseTime(courseApiData).then((res) => {
                let code = res.data.status;
                if (code === 0) {
                    this.$message.success("添加课表成功")
                    this.addDialogVisible = false;
                    this.$emit('callCourseTimeListViewApi')
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },

        async updateCourseBagsTimeApi(courseApiData) {
            if (courseApiData.course_bag_time_id) {
                updateCourseTime(courseApiData).then((res) => {
                    let code = res.data.status;
                    if (code === 0) {
                        this.$message.success("更新课表成功")
                        this.addDialogVisible = false;
                        this.$emit('callCourseTimeListViewApi')
                        this.courseTimeOperator = "";
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },

        async dealCourseBagsTime() {
            let courseApiData = await this.formatCourseBagsTimeData(this.courseTimeData);
            console.log('courseApiData', courseApiData)
            switch (this.courseTimeOperator) {
                case 'add':
                    this.addCourseBagsTimeApi(courseApiData);
                    break;
                case 'edit':
                    this.updateCourseBagsTimeApi(courseApiData);
                    break;
            }
        },

        async subjectCourseBagsTime() {
            try {
                await this.validateCourseBagsTimeData(this.courseTimeData);
                await this.dealCourseBagsTime();
            } catch (error) {
                let error_result = JSON.parse(error.message);
                if (error_result.code == 1000) this.$message.error(error_result.msg)
            }
        },

        async validateCourseBagsTimeData(course_bags_time_data) {
            if (global_.isEmpty(course_bags_time_data.course_bag_id)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请选择课程包' }))
            }
            if (global_.isEmpty(course_bags_time_data.time_name)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请输入课表名称' }))
            }
            if (global_.isEmpty(course_bags_time_data.open_time) && course_bags_time_data.lock_type == 1) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请选择开课时间' }))
            }
            if (global_.isEmpty(course_bags_time_data.customer_id)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请选择客服' }))
            }
            if (global_.isEmpty(course_bags_time_data.open_class_day) && course_bags_time_data.lock_type == 1 && course_bags_time_data.open_class_day.length <= 0) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请选择上课天数' }))
            }
            if (global_.isEmpty(course_bags_time_data.holiday_setting) && course_bags_time_data.lock_type == 1) {
                throw new Error(JSON.stringify({ code: 1000, msg: '请选择是否排除节假日课天数' }))
            };
        },

        async formatCourseBagsTimeData(data) {
            let coursebagsTimeApiData = {};
            if (parseInt(data.course_bag_time_id) > 0) coursebagsTimeApiData.course_bag_time_id = data.course_bag_time_id
            coursebagsTimeApiData.course_bag_id = data.course_bag_id;
            coursebagsTimeApiData.time_name = data.time_name;
            coursebagsTimeApiData.open_time = data.open_time;
            coursebagsTimeApiData.lock_type = data.lock_type;
            coursebagsTimeApiData.customer_id = data.customer_id;
            switch (data.lock_type) {
                case 1:
                    coursebagsTimeApiData.open_class_day = data.open_class_day.join(',');
                    coursebagsTimeApiData.holiday_setting = data.holiday_setting;
                    break;
            }
            return coursebagsTimeApiData
        },

        setCourseBagsTimeData(row) {
            console.log("row", row)
            this.courseTimeData.course_bag_time_id = row.id;
            this.courseTimeData.course_bag_id = row.course_bag_id;
            this.courseTimeData.time_name = row.course_bag_time_name;
            this.courseTimeData.open_time = (row.course_bag_open_time == null || row.course_bag_open_time == '/') ? "" : row.course_bag_open_time
            this.courseTimeData.customer_id = row.customer_id
            this.courseTimeData.lock_type = row.lock_type;
            this.courseTimeOperator = "edit";
            this.addDialogVisible = true;
            if (row.user_sign_up_count > 0) this.disabled = true
            if (row.lock_type == 1) this.getCoursePackageAll({ course_bag_status: 3, subject_type: 1 });
            if (row.lock_type == 2) this.getCoursePackageAll({ course_bag_status: 3, subject_type: 2 });
            //todo 2023-06-26 翟晓平 增加开课日期以及节假日配置
            this.courseTimeData.holiday_setting = row.holiday_setting.toString();
            this.courseTimeData.open_class_day = (row.open_class_day != '' && row.open_class_day != null && row.open_class_day != undefined) ? row.open_class_day.split(',').map(element => {
                return parseInt(element)
            }) : [];
        },
        getCourseBagsTimeData() {
            let courseBagsTimeData = {}
            courseBagsTimeData.course_bag_time_id = 0
            courseBagsTimeData.course_bag_id = ""
            courseBagsTimeData.time_name = ""
            courseBagsTimeData.open_time = ""
            courseBagsTimeData.customer_id = ''
            courseBagsTimeData.lock_type = 1
            courseBagsTimeData.open_class_day = []
            courseBagsTimeData.holiday_setting = 0
            return courseBagsTimeData;
        }
    }
}
</script>