import { getOrderCustomerAll } from "@/api/customer";

const course_game_q_w_list = [
  { id: 1, name: "文本题目" },
  { id: 2, name: "图文题目" },
];

//课程游戏，阅读闯关，题目类型=文本，回答选项
const course_game_q_w_answer_type_list_text = [
  { id: "1", name: "文字" },
  { id: "2", name: "图片" },
  { id: "3", name: "图文结合" },
];

//课程游戏，阅读闯关，题目类型=图文，回答选项
const course_game_q_w_answer_type_list_pic = [{ id: "1", name: "文字" }];

//课表-》状态
const course_bag_time_status_list = [
  { id: "", name: "全部" },
  { id: "3", name: "已结课" },
  { id: "2", name: "已开课" },
  { id: "1", name: "报名中" },
];

//课表管理-》报名信息-》报名状态
const user_signup_apply_status = [
  { id: "0", name: "全部" },
  { id: "1", name: "正常" },
  { id: "2", name: "已退课" },
];

//课表管理-》报名信息-》上课状态
const user_signup_have_class_status = [
  { id: "0", name: "全部" },
  { id: "2", name: "已完成" },
  { id: "1", name: "未完成" },
  { id: "3", name: "未开始" },
];

const import_user_list_status = [
  { id: 0, name: "全部" },
  { id: 2, name: "部分异常" },
  { id: 1, name: "正常" },
];

function getStringLength(string) {
  let len = 0;
  let zhongwen = new RegExp("[\u4E00-\u9FA5]+");
  let yingwen = new RegExp("[A-Za-z]+");
  let shuzi = new RegExp("[0-9]+");
  for (var i = 0; i < string.length; i++) {
    let value = string.charAt(i);
    console.log("value", value);
    if (zhongwen.test(value)) {
      console.log("1");
      len += 1;
    } else if (yingwen.test(value)) {
      len += 1;
    } else if (shuzi.test(value)) {
      len += 0.5;
    }
  }
  return len;
}

function isEmpty(value) {
  if (value == "" || value == undefined || value == null) {
    return true;
  } else {
    return false;
  }
}

async function getCustmerList() {
  const { data: res } = await getOrderCustomerAll({
    customer_type: 1,
  });
  if (res.status != 0) {
    return this.$message.error(res.msg);
  }
  return res.data;
}

async function getSaleCustmerList() {
  const { data: res } = await getOrderCustomerAll({
    customer_type: 3,
  });
  if (res.status != 0) {
    return this.$message.error(res.msg);
  }
  return res.data;
}

async function getCustmerListByShortLinkStatist(bags_time_id) {
  const { data: res } = await getOrderCustomerAll({
    customer_type: 1,
    course_bags_time_id: bags_time_id,
  });
  if (res.status != 0) {
    return this.$message.error(res.msg);
  }
  return res.data;
}

async function getSaleCustmerListByShortLinkStatist(bags_time_id) {
  const { data: res } = await getOrderCustomerAll({
    customer_type: 3,
    course_bags_time_id: bags_time_id,
  });
  if (res.status != 0) {
    return this.$message.error(res.msg);
  }
  return res.data;
}

const course_bags_homepage_type = [
  { id: "1", name: "体验课程" },
  { id: "2", name: "阅读培养" },
  { id: "3", name: "兴趣养成" },
  { id: "4", name: "主题课程" },
];

const course_bag_time_lock_type_List = [
  { id: 0, name: "全部" },
  { id: 1, name: "按期解锁" },
  { id: 2, name: "无需解锁" },
];

const course_bag_add_time_lock_type_List = [
  { id: 1, name: "按期解锁" },
  { id: 2, name: "无需解锁" },
];

const course_bag_time_class_statisct = [
  { id: 0, name: "全部" },
  { id: 1, name: "已解锁" },
  { id: 2, name: "未解锁" },
];

//课程订单 课程类型 查询
const course_order_search_course_type = [
  { id: 0, name: "全部" },
  { id: 1, name: "阅读课" },
  { id: 2, name: "美术课" },
  { id: 3, name: "视频课" },
];

//----------文件上传-------
function randStr(e) {
  let str = "abacdefghijklmn123456789QWERTYUIOPASDFGHJKLZXCVBNM";
  let n = "";
  for (let i = 0; i < e; i++) {
    n += str.charAt(Math.floor(Math.random() * str.length));
  }
  return n;
}
function getFileName(val) {
  let end = val.lastIndexOf(".");
  // ***(**)**(***)
  // let str = val.substring(0, end).split(" ").join("");
  let str = val.substring(0, end);
  let flag = 0;
  let newStr = "";
  let arr = [
    "@",
    "(",
    ")",
    "（",
    "）",
    "$",
    "#",
    "%",
    "^",
    "<",
    ">",
    "<<",
    ">>",
    "《",
    "》",
    " ",
    "：",
    ":",
  ];
  // for (let i = 0; i < end; i++) {
  //   if (str[i] == "(") {
  //     flag = 1;
  //   }
  //   if (flag == 0) {
  //     newStr += str[i];
  //   }
  //   if (str[i] == ")") {
  //     flag = 0;
  //   }
  // }

  for (let i = 0; i < end; i++) {
    if (arr.indexOf(str[i], 0) == -1) {
      newStr += str[i];
    }
  }
  return newStr;
}

function getExt(val) {
  return val.substring(val.lastIndexOf(".") + 1);
}

const order_list_pay_type = [
  { id: 0, name: "全部" },
  { id: 3, name: "支付宝" },
  { id: 2, name: "微信" },
  { id: 1, name: "免费领取" },
  { id: 4, name: "兑换码兑换" },
];

const course_bags_time_open_class_day_list = [
  { id: 1, name: "星期一" },
  { id: 2, name: "星期二" },
  { id: 3, name: "星期三" },
  { id: 4, name: "星期四" },
  { id: 5, name: "星期五" },
  { id: 6, name: "星期六" },
  { id: 7, name: "星期日" },
];


const pickerOptions = {
  disabledDate(time) {
    return (time.getDay() === 6) || (time.getDay() === 0)
  },
  shortcuts: [{
    text: '最近一周',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    }
  }]
}

const pickerOptions0 = {
  disabledDate(time) {
    return time.getTime() < Date.now() - 8.64e7;//如果没有后面的-8.64e7就是不可以选择今天的 
  }
}

const school_account_search_list = [
  {
    value: 0,
    label: '全部'
  },
  {
    value: 1,
    label: '总账号'
  },
  {
    value: 2,
    label: '教师账号'
  }
];

const school_list_default = [
  {
    id: 0,
    name: "全部"
  }
];

function course_bags_img_limit() {
  let type = val.file.type
  let size = val.file.size
  let type_arr = type.split("/")
  let limit = 2   // 1M
  if (t == 2) {
    limit = 2
  }
  if (type_arr[0] == "image") {
    if (size > 1024 * 1024 * limit) {
      return -1
    }
  }
  return 1
}

export default {
  course_game_q_w_list,
  course_game_q_w_answer_type_list_text,
  course_game_q_w_answer_type_list_pic,
  course_bag_time_status_list,
  user_signup_apply_status,
  user_signup_have_class_status,
  import_user_list_status,
  getStringLength,
  isEmpty,
  getCustmerList,
  getSaleCustmerList,
  randStr, //随机字符串
  getFileName, // 文件名
  getExt, // 扩展名,
  course_bags_homepage_type,
  course_bag_time_lock_type_List,
  course_bag_add_time_lock_type_List,
  course_bag_time_class_statisct,
  getCustmerListByShortLinkStatist,
  getSaleCustmerListByShortLinkStatist,
  course_order_search_course_type,
  order_list_pay_type,
  course_bags_time_open_class_day_list,
  pickerOptions,
  pickerOptions0,
  school_account_search_list,
  school_list_default,
  course_bags_img_limit
};
