<!--课包购买链接-->
<template>
    <div>
        <el-card>
            <el-row>
                <el-col :span="8">
                    <span style="display: inline-flex;width: 100px;font-size: 18px;">课程名称:</span>
                    <el-input type="text" prefix-icon="el-icon-search" v-model="search.keyword"
                        style="display: inline-flex;width: 250px;">
                    </el-input>
                </el-col>
                <el-col :span="7" v-if="lock_type == 1">
                    <span style="display: inline-flex;width: 100px;font-size: 18px;">解锁状态:</span>
                    <el-select v-model="search.lock_type" placeholder="请选择">
                        <el-option v-for="(item, index) in lock_type_list" :label="item.name" :key="index"
                            :value="item.id">{{
                                item.name }}</el-option>
                    </el-select>
                </el-col>
                <el-button type="primary" size="medium" icon="el-icon-search" @click="btnClick()" style="">搜索</el-button>
                <el-button type="warning" icon="el-icon-refresh" size="medium" style="" @click="resetClick()">重置</el-button>
            </el-row>
            <el-row style="margin-top:10px;">
                <el-button type="success" @click="excelExport">Excel导出</el-button>
            </el-row>
            <div style="height:30px;line-height:30px;font-size:20px;margin:20px 0;">
                <span style="width: 250px;display: inline-flex;">销售:{{ sale_name }}</span>
                <span style="width: 250px;display: inline-flex;">客服:{{ customer_name }}</span>
                <span style="width: 250px;display: inline-flex;">报名人数:{{ apply_number }}</span>
            </div>
            <el-row style="margin-top:10px;">
                <el-table :data="list" border stripe>
                    <el-table-column label="课程名称" prop="course_name" width="240px"></el-table-column>
                    <el-table-column v-if="lock_type == 1" label="解锁时间" prop="course_time" width="140px"></el-table-column>
                    <el-table-column v-if="lock_type == 1" label="解锁状态" prop="lock" width="200px"></el-table-column>
                    <el-table-column label="完课人数" prop="user_learn_record_done_count" width="200px"></el-table-column>
                    <el-table-column label="完课率" prop="done_number_p" width="140px"></el-table-column>
                </el-table>
            </el-row>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>
  
<script>
import { getClassStatisticsByBagTimeClassStatistics } from '@/api/statistics'
import global_ from '@/utils/global';
export default {
    data() {
        return {
            list: [],
            search: {
                bags_time_id: this.$route.query.bags_time_id,
                customer_id: this.$route.query.customer_id,
                sale_id: this.$route.query.sale_id,
                short_link_id: this.$route.query.short_link_id,
                course_bag_id: this.$route.query.course_bag_id,
                keyword: "",
                lock_type: 0,
                pagenum: 1,
                pagesize: 10,
            },
            customer_name: "",
            sale_name: "",
            apply_number: "",
            lock_type_list: global_.course_bag_time_class_statisct,
            lock_type: 0,
            total: 0,
        }
    },
    created() {
        this.getClassStatisticsByBagTimeClassStatisticsApi();
    },
    methods: {
        async getClassStatisticsByBagTimeClassStatisticsApi() {
            const { data: res } = await getClassStatisticsByBagTimeClassStatistics(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.list = res.data.list.data
            this.customer_name = res.data.customer_name
            this.sale_name = res.data.sale_name
            this.apply_number = res.data.apply_number
            this.lock_type = res.data.bags_time_lock_type;
            this.total = res.data.list.total
        },
        btnClick() {
            this.search.pagenum = 1;
            this.getClassStatisticsByBagTimeClassStatisticsApi();
        },
        resetClick(){
            console.log("123123")
           this.search.keyword = "";
           this.search.lock_type = 0;
           this.search.pagenum = 1;
           this.getClassStatisticsByBagTimeClassStatisticsApi();
        },
        excelExport() {
            let headers = {}
            if (this.lock_type == 1) {
                headers = {
                    '课程名称': 'course_name',
                    '解锁时间': 'course_time',
                    '解锁状态': 'lock',
                    '完课人数': 'user_learn_record_done_count',
                    '完课率': 'done_number_p',
                }
            } else if (this.lock_type == 2) {
                headers = {
                    '课程名称': 'course_name',
                    '完课人数': 'user_learn_record_done_count',
                    '完课率': 'done_number_p',
                }
            };

            let query = {}
            query.bags_time_id = this.search.bags_time_id;
            query.customer_id = this.search.customer_id;
            query.sale_id = this.search.sale_id;
            query.short_link_id = this.search.short_link_id;
            query.course_bag_id = this.search.course_bag_id;
            query.keyword = this.search.keyword;
            query.lock_type = this.search.lock_type;
            query.pagenum = 1;
            query.pagesize = this.total;

            import('@/vendor/Export2Excel').then(async excel => {
                const { data: res } = await getClassStatisticsByBagTimeClassStatistics(query)
                console.log("res.data.list.data",res.data.list.data)
                const data = this.formatJson(headers, res.data.list.data)
                excel.export_json_to_excel({
                    header: Object.keys(headers),
                    data,
                    filename: '课程渠道统计',
                    autoWidth: true,
                    bookType: 'xlsx'
                })
            })
        },
        formatJson(headers, rows) {
            return rows.map(item => {
                return Object.keys(headers).map(key => {
                    return item[headers[key]]
                })
            })
        },
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
            this.getClassStatisticsByBagTimeClassStatisticsApi()
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
            this.getClassStatisticsByBagTimeClassStatisticsApi()
        },
    }
}
</script>