<template>
  <div>
    <!--面包屑start-->
    <el-card>
      <el-row class="nav-">
        <el-col :span="3" style="margin-left:10px">选择学校:
          <el-select v-model="search.search_school_id" placeholder="请选择"  filterable
                     style="width:100%">
            <el-option value="" label="全部">全部</el-option>
            <el-option v-for="(item, index) in allSchool" :key="index" :label="item.name" :value="item.id">{{
                item.name
              }}
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3" style="margin-left:10px">状态:
          <el-select v-model="search.status" placeholder="请选择"  filterable
                     style="width:100%">
            <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id">{{
                item.name
              }}
            </el-option>
          </el-select>
        </el-col>

        <el-button  type="primary" icon="el-icon-search" @click="btnClick" style="margin-top:14px;margin-left:10px">搜索</el-button>
        <el-button  type="warning" icon="el-icon-refresh" @click="reset" style="margin-top:14px;margin-left:10px">重置</el-button>
      </el-row>
      <el-row  style="margin-top:10px">
        <el-col>
          <el-button type="primary" @click="addBagsToSchool()"
          >添加</el-button>
          <el-button type="success" @click="excelExport" >导出</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-table :data="list" border stripe>
          <el-table-column label="课包ID" prop="bags_id"></el-table-column>
          <el-table-column label="课包名称" prop="bags_name"></el-table-column>
          <el-table-column label="课包所属阶段" prop="bags_rank_text"></el-table-column>
          <el-table-column label="课包所属班级" prop="class_name"></el-table-column>
          <el-table-column label="学籍码" prop="code"></el-table-column>
          <el-table-column label="关联老师" prop="teacher_msg"></el-table-column>
          <el-table-column label="绑定学生数" prop="user_num"></el-table-column>
          <el-table-column label="状态" prop="status_msg"></el-table-column>
          <el-table-column label="操作" width="180px">
            <template slot-scope="scope">
<!--              <el-button type="text" size="mini" @click="editSchoolBags(scope.row)">编辑</el-button>-->
              <el-button type="text" v-if="scope.row.status == 2 " size="mini" @click="changeStatusClick(scope.row)">启用</el-button>
              <el-button type="text" v-else-if="scope.row.status == 1 " size="mini" @click="changeStatusClick(scope.row)">禁用</el-button>
<!--              <el-button type="text" size="mini"  @click="del(scope.row.id)" v-if="scope.row.user_num == 0">删除</el-button>-->
              <el-button type="text" size="mini" @click="bindingClick(scope.row)">学生绑定记录</el-button>

            </template>
          </el-table-column>
        </el-table>
        <!--分页start-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="search.pagenum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="search.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <!--分页end-->
      </el-row>
    </el-card>
    <!--面包屑end-->
    <!--添加-->
    <el-dialog :close-on-click-modal='false' title="添加课包"  :visible.sync="addDialogVisible" width="50%"
               @close="addDialogClosed">
      <el-form   :rules="addFormRules" :model="addForm"
                 ref="addFormRef" label-width="120px">
        <el-form-item label="选择课包:" prop="add_bags_id">
          <el-select v-model="add_bags_id" @change="addBagsId(add_bags_id)">
            <el-option v-for="item in schoolBags" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属阶段:" prop="add_bags_rank">
          <el-select v-model="add_bags_rank" @change="addBagsRank(add_bags_rank)">
            <el-option v-for="item in bags_rank_arr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属班级:"  prop="class_name">
          <el-input v-model="addForm.class_name" type="text"></el-input>
        </el-form-item>
        <el-form-item label="学籍码:" prop="code">
          <el-input v-model="addForm.code"   type="text" maxlength="8"></el-input>
          <label class="placeholder-text">* 学籍码由8位字母和数字组成</label>
        </el-form-item>
        <el-form-item label="关联老师:" prop="add_teacher_id">
          <el-checkbox-group v-model="add_teacher_id">
            <el-checkbox
              v-for="item in teacher_arr"
              :label="item.id"
              :key="item.id"
            >{{ item.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="addSubmit">保存</el-button>
          </span>
    </el-dialog>
    <!--添加end-->
    <!--编辑-->
    <el-dialog :close-on-click-modal='false' title="修改课包"  @close="editDialogClosed" :visible.sync="editDialogVisible" width="50%">
      <el-form   :rules="editFormRules" :model="editForm"
                 ref="editFormRef" label-width="120px">
        <el-form-item label="选择课包:" prop="edit_bags_id">
          <el-select v-model="edit_bags_id" @change="editBagsId(edit_bags_id)">
            <el-option v-for="item in schoolBags" :label="item.name" :value="item.id" :key="item.id" :disabled="item.disable"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属阶段:" prop="edit_bags_rank">
          <el-select v-model="edit_bags_rank" @change="editBagsRank(edit_bags_rank)">
            <el-option v-for="item in bags_rank_arr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属班级:"  prop="class_name">
          <el-input v-model="editForm.class_name" type="text"></el-input>
        </el-form-item>
        <el-form-item label="学籍码:" prop="code">
          <el-input v-model="editForm.code"  :disabled="disabled" type="text" ></el-input>
          <label class="placeholder-text">* 学籍码由8位字母和数字组成</label>
        </el-form-item>
        <el-form-item label="关联老师:" prop="edit_teacher_id">
          <el-checkbox-group v-model="edit_teacher_id">
            <el-checkbox
              v-for="item in teacher_arr"
              :label="item.id"
              :key="item.id"
            >{{ item.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogClosed">取消</el-button>
            <el-button type="primary" @click="editSubmit">保存</el-button>
          </span>
    </el-dialog>
    <!--编辑end-->
  </div>
</template>
<script >
import {getAllSchool} from "@/api/school";
import {
  getCourseBagsBySchoolId,
  changeStatusToBags,
  addBagsToSchool,
  getAllBagsBySchool,
  getAllTeacher, editBagsToSchool,delSchoolBags
} from "@/api/schoolCourseBags";
import {getCourseTag} from "@/api/course";
import {addTeacher} from "@/api/schoolTeacher";
export default {
  data() {
    return {
      list: [],
      search: {
        search_school_id: '',
        school_id:window.sessionStorage.getItem('school_id'),
        status: '',
        pagenum: 1,
        pagesize: 10,
      },
      allSchool: [],
      typeList: [{"id": '', "name": "全部"},{"id": 1, "name": "启用"}, {"id": 2, "name": "禁用"}],
      total: 0,
      addDialogVisible: false,
      editDialogVisible: false,
      addForm:{
        class_name:'',
        code:'',
        school_id:window.sessionStorage.getItem('school_id'),
        relation_teacher_id:'',
        bags_id:'',
        bags_rank:'',
      },
      school_id:window.sessionStorage.getItem('school_id'),
      add_bags_rank:'',
      bags_rank_arr:[{"id": 1, "name": "小班"}, {"id": 2, "name": "中班"},{"id": 3, "name": "大班"}],
      add_teacher_id:[],
      teacher_arr:[],
      schoolBags:[],
      add_bags_id:'',
      addFormRules: {
        bags_id: [
          {required: true, message: "请选择课包", trigger: "blur"},
        ],
        bags_rank: [
          {required: true, message: "请选择所属阶段", trigger: "blur"},
        ],
        class_name: [
          {required: true, message: "请输入班级", trigger: "blur"},
        ],
        relation_teacher_id: [
          {required: true, message: "请选择老师", trigger: "blur"},
        ],
        code:[
          { required: true, message: '请输入学籍码' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8}$/, message: '请输入由8位字母和数字组成的学籍码' }
        ],
      },
      editForm:{
        class_name:'',
        code:'',
        relation_teacher_id:'',
        bags_id:'',
        bags_rank:'',
        id:'',
      },
      edit_bags_id:'',
      edit_teacher_id:[],
      edit_bags_rank:'',
      disabled:true,
      editFormRules: {
        bags_id: [
          {required: true, message: "请选择课包", trigger: "blur"},
        ],
        bags_rank: [
          {required: true, message: "请选择所属阶段", trigger: "blur"},
        ],
        class_name: [
          {required: true, message: "请输入班级", trigger: "blur"},
        ],
        relation_teacher_id: [
          {required: true, message: "请选择老师", trigger: "blur"},
        ],
        code:[
          { required: true, message: '请输入学籍码' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8}$/, message: '请输入由8位字母和数字组成的学籍码' }
        ],
      },
    }
  },
  created(){
    let list_search = sessionStorage.getItem('list_search_schoolCourseBags') || "";
    if(list_search != "" && list_search != null && list_search != undefined){
      this.search= JSON.parse(list_search);
    }
    this.getList()
    this.getAllSchool()
  },
  methods: {
    //--------------------------------------获取所有学校数据--------------------------------------------------
    async getAllSchool(){
      const {data:res} = await getAllSchool({school_id:this.search.school_id})
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.allSchool = res.data
    },
    //--------------------------------------获取课包列表--------------------------------------------------
    async getList(){
      const {data:res} = await getCourseBagsBySchoolId(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.total = res.data.total
      this.list = res.data.data
    },
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },
    //--------------------------------------搜索--------------------------------------------------
    btnClick() {
      this.search.pagenum = 1;
      this.getList();
    },
    reset() {
      this.search.pagenum = 1;
      this.search.pagesize = 10;
      this.search.search_school_id = '';
      this.search.school_id = 0;
      this.search.status = '';
      this.getList();
    },
    //--------------------------------------修改状态--------------------------------------------------
    async changeStatusClick(row){
      const {data:res} =  await changeStatusToBags({id:row.id,status:row.status})
      if(res.status!=0){
        return this.$message.error(res.msg);
      }
      this.$message.success(res.msg);
      this.getList();
    },
    //--------------------------------------删除学校--------------------------------------------------
    async del(id){
      this.$confirm("是否要删除", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {data:res} = await delSchoolBags({"id":id})
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success("删除成功!")
        this.getList()
      }).catch(() => {
      })
    },
//--------------------------------------导出学校--------------------------------------------------
    excelExport() {
      const headers = {
        '课包ID':"id",
        '课包名称': 'bags_name',
        '课包所属阶段': 'bags_rank_text',
        '课包所属班级': 'class_name',
        '学籍码': 'code',
        '关联老师': 'teacher_msg',
        '绑定学生数': 'user_num',
        '状态': 'status_msg',
      }
      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await getCourseBagsBySchoolId(this.search)
        if (!res.data.data) {
          return this.$message.error('暂无数据导出')
        }
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '关联的课包信息',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.search.pagesize = 10
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },
    //--------------------------------------添加学校-------------------------------------------------
    addBagsToSchool(){
      this.getSchoolBindingBags();
      this.getSchoolTeacher();
      this.addDialogVisible = true
    },
    async addSubmit(){

      if(this.add_teacher_id.length>0){
        this.addForm.relation_teacher_id = this.add_teacher_id.join()
      }
      this.$refs.addFormRef.validate( async (valid) => {
        if (!valid) return;
        const { data: res } = await addBagsToSchool(this.addForm);
        if (res.status != 0) {
          return this.$message.error(res.msg);
        }
        this.$message.success(res.msg);
        this.addDialogVisible = false;
        this.getList()
        this.clear()
      });
    },
    addDialogClosed(){
      this.addDialogVisible = false
      this.clear()
    },
    addBagsId(val){
      this.addForm.bags_id = val
    },
    addBagsRank(val){
      this.addForm.bags_rank = val
    },
    //--------------------------------------编辑学校-------------------------------------------------
    editSchoolBags(row){
      this.getSchoolBindingBags();
      this.getSchoolTeacher();
      this.editForm.class_name=row.class_name
      this.editForm.code=row.code
      if(row.relation_teacher_id){
        this.edit_teacher_id = row.relation_teacher_id.toString().split(',').map((val)=>{return parseInt(val)} )
      }
      console.log(this.edit_teacher_id);
      this.edit_bags_id=row.bags_id
      this.editForm.bags_id=row.bags_id
      this.editForm.bags_rank=row.bags_rank
      this.edit_bags_rank=row.bags_rank
      this.editForm.id=row.id
      if(row.user_num ==0){
        this.disabled = false
      }
      this.editDialogVisible = true
    },
    async editSubmit(){

      if(this.edit_teacher_id.length>0){
        this.editForm.relation_teacher_id = this.edit_teacher_id.join()
      }
      this.$refs.editFormRef.validate( async (valid) => {
        if (!valid) return;
        const { data: res } = await editBagsToSchool(this.editForm);
        if (res.status != 0) {
          return this.$message.error(res.msg);
        }
        this.$message.success(res.msg);
        this.editDialogVisible = false;
        this.getList()
        this.clear()
      });
    },
    editDialogClosed(){
      this.editDialogVisible = false
      this.clear()
    },
    clear(){
      this.addForm.class_name=""
      this.addForm.code=""
      this.addForm.relation_teacher_id=''
      this.addForm.bags_id=""
      this.addForm.bags_rank=""
      this.editForm.class_name=""
      this.editForm.code=""
      this.editForm.relation_teacher_id=''
      this.editForm.bags_id=""
      this.editForm.bags_rank=""
      this.add_teacher_id=[]
      this.add_bags_rank=''
      this.add_bags_id=''
      this.edit_teacher_id=[]
      this.edit_bags_rank=''
      this.edit_bags_id=''
      this.disabled=true
    },
    editBagsId(val){
      this.editForm.bags_id = val
    },
    editBagsRank(val){
      this.editForm.bags_rank = val
    },
    //----------学校绑定的课包--------------
    async getSchoolBindingBags(){
      const {data:res} = await getAllBagsBySchool({school_id:this.school_id})
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.schoolBags = res.data
    },
    //----------学校绑定的老师--------------
    async getSchoolTeacher(){
      const {data:res} = await getAllTeacher({school_id:this.school_id})
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.teacher_arr = res.data
    },
    //--------------------------------------学生绑定记录--------------------------------------------------
    bindingClick(row){
      sessionStorage.setItem('list_search_schoolCourseBags',JSON.stringify(this.search));
      this.$router.push({ path: '/admin.schoolCourse.schoolUserList', query: { id: row.id,bags_id:row.bags_id} })
    },
  }
}
</script>
