<!--课表管理-->
<template>
    <div>
        <el-card>
            <!--日期end-->
            <el-row style="margin-top:10px;">
                <el-form>
                    <el-col :span="24">
                        <el-form-item label="添加状态" label-position="left" label-width="100">
                            <el-select v-model="search.status" @change="getImportUserList()" >
                                <el-option v-for="(item, index) in statusList" :key="index" :label="item.name"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <el-row style="margin-top:10px;">
                <el-table :data="importUserList" border stripe :row-style="{ height: '45px' }"
                    :cell-style="{ padding: '0px' }">
                    <el-table-column label="表格名称" prop="name"></el-table-column>
                    <el-table-column label="添加时间" prop="created_at"></el-table-column>
                    <el-table-column label="添加状态" prop="status" :formatter="getStatusName"></el-table-column>
                    <el-table-column label="异常内容" prop="content" :formatter="getContent"></el-table-column>

                </el-table>
                <el-pagination style="margin-top:20px;" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]"
                    :page-size="search.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>
  
  
<script>
import { getImportUser } from '@/api/courseSchoolTime'
import global_ from '@/utils/global.js'
export default {
    data() {
        return {
            total: 0,
            search: {
                course_bag_time_id: this.$route.query.course_bag_time_id,
                pagenum: 1,
                pagesize: 10,
                status: 0,
            },
            importUserList: [],
            statusList: global_.import_user_list_status
        }
    },
    created() {
        this.getImportUserList();
    },
    methods: {
        getContent(row, column) {
            let string = "";
            let content = JSON.parse(row.content)
            if (content.hasOwnProperty('have_buy_user_mobile_phone')) {
                if (content.have_buy_user_mobile_phone.length > 0) {
                    string += "已购买课程:" + content.have_buy_user_mobile_phone.join(",") + "\n";
                }
            }
            if (content.hasOwnProperty('yichang_phone')) {
                if (content.yichang_phone.length > 0) {
                    string += "异常手机号:" + content.yichang_phone.join(",") + "\n";
                }
            }

            return string;
        },
        getStatusName(row, column) {
            let status_name = "";
            switch (row.status) {
                case "1":
                    status_name = "正常"
                    break;
                case "2":
                    status_name = "部分异常";
                    break;

                default:
                    break;
            }
            return status_name;
        },
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
            this.getCourseTimeList(this.search)
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
            this.getCourseTimeList(this.search)
        },
        async getImportUserList() {
            const { data: res } = await getImportUser(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.importUserList = res.data.data
            this.total = res.data.total
        },
    }
}
</script>
  
<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}
</style>