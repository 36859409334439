import request from "@/utils/request";

//--------------课表------------------
//--------------课表------------------

export function list(data) {
  return request({
    url: "/admin/courseTime/lists",
    method: "post",
    params: data,
  });
}

export function addCourseTime(data) {
  return request({
    url: "/admin/courseTime/add",
    method: "post",
    params: data,
    timeout:5*60*1000,
  });
}

export function updateCourseTime(data) {
  return request({
    url: "/admin/courseTime/update",
    method: "post",
    params: data,
  });
}

export function advanceCourseTime(data) {
  return request({
    url: "/admin/courseTime/advance",
    method: "post",
    params: data,
  });
}

// 课表删除
export function deleteTimeTable(data) {
  return request({
    url: "/admin/courseTime/delete",
    method: "post",
    params: data,
  });
}

// 报名信息
export function getSignUpInfoList(data) {
  return request({
    url: "/admin/courseTime/getSignUpInfoList",
    method: "post",
    params: data,
  });
}

// 用户学习记录
export function timeLearnRecordsOfUser(data) {
  return request({
    url: "/admin/courseTime/timeLearnRecordsOfUser",
    method: "post",
    params: data,
  });
}

// 客服list
export function customList(data) {
  return request({
    url: "/admin/courseTime/timeLearnRecordsOfUser",
    method: "post",
    params: data,
  });
}

export function addUserCourseBagTime(data) {
  return request({
    url: "/admin/courseTime/addUser",
    method: "post",
    params: data,
  });
}

export function addUserBatchCourseBagTime(data) {
  let headers = {
    "Content-Type": "multipart/form-data",
    Token: window.sessionStorage.getItem("token"),
  };
  return request({
    url: "/admin/courseTime/addUserBatch",
    method: "post",
    data: data,
    headers: headers,
  });
}


export function getImportUser(data) {
  return request({
    url: "/admin/courseTime/getImportList",
    method: "post",
    params: data,
  });
}

export function getImportExcel(data) {
  return request({
    url: "/admin/courseTime/importExcel",
    method: "post",
    params: data,
    responseType:"blob"
  });
}

export function changeDisplay(data) {
  return request({
    url: "/admin/courseTime/isDisplay",
    method: "post",
    data: data
  });
}