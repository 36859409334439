<!--购课记录-->
<template>
  <div>
    <el-card>
      <el-row style="margin-top:10px;">
        <!-- <el-button type="danger" style="width:100px;" @click="back">返回课表</el-button> -->
      </el-row>
      <el-row style="margin-top:10px;">
          <el-table :data="buyList" 
          border stripe 
          :row-style="{height:'45px'}" 
          :cell-style='cellStyle' 
          :header-cell-style='rowClass'
          >
          <el-table-column label="课程包名称" prop="bags_title"></el-table-column>
          <el-table-column label="购课金额(元)" prop="pay_price"></el-table-column>
          <el-table-column label="购课时间" prop="pay_time"></el-table-column>
          <el-table-column label="购课方式" >
            <template slot-scope="scope">
              <span v-if="scope.row.pay_type == 1">免费领取</span>
              <span v-else-if="scope.row.pay_type == 2">微信</span>
              <span v-else-if="scope.row.pay_type == 3">支付宝</span>
              <span v-else-if="scope.row.pay_type == 4">小程序</span>
              <span v-else-if="scope.row.source == 2">外部订单 {{scope.row.source_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="课程进度" >
            <template slot-scope="scope">
              <span v-if="scope.row.course_bag_time_status == 1">报名中</span>
              <span v-else-if="scope.row.course_bag_time_status == 2">已开课</span>
              <span v-else-if="scope.row.course_bag_time_status == 3">已结课</span>
            </template>
          </el-table-column>
          <el-table-column label="上课进度(已上/已解锁/总课程)" prop="p_string"></el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope" >
              <el-button type="text" size="mini" @click="jump(scope.row)">上课记录</el-button>            
            </template>
          </el-table-column>
        </el-table>

      <!--分页start-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="search.pagenum"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="search.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      <!--分页end--> 
      </el-row>
    </el-card>  
  </div>
</template>


<script>
import {buyList } from '@/api/interactUser'
export default {
 data (){
  return { 
    buyList:[],
    search:{
      pagenum: 1,
      pagesize: 10,
      user_id: this.$route.query.user_id, 
    },
    total: 0,  
  }
 },
 created() {
    this.getList()
 },
 methods: {
  //-----------------------------tabel 文字居中-------------------------------
   rowClass({row, rowIndex}){
    return 'text-align:center'
  },
  cellStyle({row, column, rowIndex, columnIndex}){
    return 'text-align:center;padding:0px' 
  },
  //-------------------------------------------------------------------------
  addcp(){
    this.addDialogVisible = true
  },
  //-------------------列表----------
  async getList(){
    const {data:res} = await buyList(this.search)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.buyList = res.data.data
    // this.list = this.formData(res.data.data)
    this.total = res.data.total

  },
  formData(data){
    const arr =[]
    for( let i in data){
       arr.push({
        app_user_id:data[i].app_user_id,
        bags_id:data[i].bags_id,
        progress:data[i].progress,
        sum:data[i].sum,
        user_name:data[i].user_name,
        user_mobile:data[i].user_mobile,
        p:data[i].progress+'/'+ data[i].sum
        })
    }
    return arr
  },
  // -------------------------分页-------------------------------------------
  handleSizeChange(newSize) {
    this.addForm.pagesize = newSize
    this.userSignUpList(this.search)
  },
  handleCurrentChange(newPage) {
    this.addForm.pagenum = newPage
    this.userSignUpList(this.search)
  },
  //----------------------------跳转: 用户上课记录-----------------------------
  jump(row){  
    this.$router.push({path:'/admin.courseuser.learnRecord',query:{bags_id:row.bags_id , app_user_id:row.app_user_id,bagTimeId: row.bags_times_id, course_bags_cate:row.course_bags_cate}})
  }, 
 } 
}
</script>

<style scoped>
.nav_1{
  margin-bottom: 10px;
  float:left;
}
.search{
  float:left;
  width:300px;
  margin-left:10px;
}
</style>