<template>
    <span>
        <div style="clear:both"></div>
        <el-row style="margin-top:10px;">
            <el-table :data="list" border stripe :row-style="{ height: '50px' }">
                <el-table-column label="后台账号" prop="username" width="190px"></el-table-column>
                <el-table-column label="用户端手机号" prop="user_mobile" width="250px"></el-table-column>
                <el-table-column label="所属学校" prop="school_name" width="250px"></el-table-column>
                <el-table-column label="账号权限" width="250px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 1">总账号</span>
                        <span v-if="scope.row.type == 2">教师账号</span>
                        <span v-if="scope.row.type == 3">总账号,教师账号</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="250px">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" @click="callSchoolAccountDialog(scope.row.id)">修改账号</el-button>
                        <el-button type="text" size="mini" v-if="scope.row.user_mobile == ''"
                            @click="callSchoolAccountBindPhoneDialog(scope.row.id)">关联账号</el-button>
                        <el-button type="text" size="mini" v-if="scope.row.user_mobile != ''"
                            @click="callCannelPhoneSchoolAccount(scope.row.id)">取消关联</el-button>
                        <el-button type="text" size="mini" @click="callChangeStatusSchoolAccount(scope.row.id)"
                            v-if="scope.row.status == 1">禁用</el-button>
                        <el-button type="text" size="mini" @click="callChangeStatusSchoolAccount(scope.row.id)"
                            v-if="scope.row.status == 2">启用</el-button>
                        <el-button type="text" size="mini" @click="callDeleteSchoolAccountId(scope.row.id)"
                            v-if="scope.row.relation_school_teacher == false">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-row>
    </span>
</template>

<script>
import { getSchoolAccountList, changeStatusBySchoolAccount, cannelPhoneBySchoolAccount, deleteBySchoolAccount } from '@/api/schoolAccount'
import excelImportOperator from '@/utils/excelImport'
export default {
    data() {
        return {
            list: [],
            total: 0,
            search: {
                pagenum: 1,
                pagesize: 10,
                type: 0,
                school_id: 0,
                admin_name: "",
                user_mobile: "",
                insert_source: 2
            },
        }
    },
    created() {
    },
    methods: {
        async callSchoolAccountList() {
            const { data: res } = await getSchoolAccountList(this.search)
            console.log(res)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.list = res.data.data
            this.total = res.data.total
        },
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
            this.callSchoolAccountList()
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
            this.callSchoolAccountList()
        },
        async callDeleteSchoolAccountId(id) {
            var self = this;
            this.$confirm("是否要删除", '提示', {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data: res } = await deleteBySchoolAccount({ admin_id: id })
                if (res.status != 0) {
                    return self.$message.error(res.msg)
                }
                self.callSchoolAccountList()
            }).catch(() => {
                console.log("课包取消删除")
            })

        },
        async callChangeStatusSchoolAccount(id) {
            const { data: res } = await changeStatusBySchoolAccount({ id: id })
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.callSchoolAccountList()
        },
        callSchoolAccountDialog(id) {
            this.$emit('openSchoolAccountUpdate', id)
        },
        callSchoolAccountBindPhoneDialog(id) {
            this.$emit('openSchoolAccountBindPhone', id)
        },
        async callCannelPhoneSchoolAccount(id) {
            const { data: res } = await cannelPhoneBySchoolAccount({ id: id })
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.callSchoolAccountList()
        },
        async excelExport() {
            const headers = {
                '后台账号': 'username',
                '用户端手机号': 'user_mobile',
                '所属学校': 'school_name',
                '账号权限': 'type',
            }
            const { data: res } = await getSchoolAccountList(this.formatExcelSearch())
            if (!res.data.data) {
                return this.$message.error('暂无数据导出')
            }
            const data = this.formatJson(headers, res.data.data)
            excelImportOperator.exportExcel(headers, data, '学校账号信息')
        },
        formatJson(headers, rows) {
            return rows.map(item => {
                return Object.keys(headers).map(key => {
                    switch (headers[key]) {
                        case 'type':
                            switch (item[headers[key]]) {
                                case 1:
                                    return "总账号";
                                    break;
                                case 2:
                                    return "教师账号";
                                    break;
                                case 3:
                                    return "总账号,教师账号";
                                    break;
                                default:
                                    return "";
                                    break;
                            }
                            break;
                        default:
                            return item[headers[key]]
                    }

                })
            })
        },
        formatExcelSearch() {
            let search = {}
            search.pagenum = 1;
            search.pagesize = this.total;
            search.type = this.search.type;
            search.school_id = this.search.school_id;
            search.admin_name = this.search.admin_name;
            search.user_mobile = this.search.user_mobile;
            return search;
        },
    }
}
</script>
