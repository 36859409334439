<template>
    <div>
        <el-form ref="form" label-width="100px">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="用户id：" label-width="150px;">{{ userInfo.id }}</el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="用户头像：" label-width="150px;">
                        <el-image v-if="userInfo.headimgurl" :src="userInfo.headimgurl"
                            style="width: 30px;height:30px;"></el-image>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="用户昵称：" label-width="150px;">{{ userInfo.nickname }}</el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="手机号：" label-width="150px;">{{ userInfo.mobile_phone }}</el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="宝宝性别：" label-width="150px;">{{ userInfo.boby_sex_string }}</el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="宝宝生日：" label-width="150px;">{{ userInfo.boby_birth }}</el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="宝宝学年：" label-width="150px;">{{ userInfo.boby_school_age_string }}</el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="主要陪伴亲属：" label-width="150px;">{{ userInfo.boby_relatives_id_string
                    }}</el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="是否借阅用户：" label-width="150px;">{{ userInfo.is_borrow_user_string
                    }}</el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="累计星星数量：" label-width="150px;">{{ userInfo.course_star }}</el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="累计完成课程：" label-width="150px;">{{ userInfo.course_complete }}</el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="状态：" label-width="150px;">{{ userInfo.status | dealUserStatus }}</el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
  
<script>
import { getUserDetail } from '@/api/interactUser'
export default {
    data() {
        return {
            search: {
                user_id: this.$route.query.user_id
            },
            userInfo: {}
        }
    },
    created() {
        this.getUserInfo();
    },
    methods: {
        async getUserInfo() {
            const { data: res } = await getUserDetail(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.userInfo = res.data
        }
    },
    filters: {
        dealUserStatus(status) {
            let user_status_string = "";
            switch (status) {
                case 1:
                    user_status_string = "正常";
                    break;
                case 2:
                    user_status_string = "禁用";
                    break;
            }
            return user_status_string;
        }
    }
}
</script>