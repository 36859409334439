<!--------------------互动游戏------------------------>
<!--------------------------------------------------->
<template>
  <div >
    <el-form>
      <el-form-item label="游戏类型">
        <el-select
          v-model="selectedGameType"
          placeholder="请选择"
          @change="selectChange(selectedGameType)"
        >
          <el-option
            v-for="item in gameTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-------------------------------------------------1--------------------------------------------------->
      <!-------------------------------------------------1--------------------------------------------------->
      <template v-if="selectedGameType == 1">
        <el-form-item label="结尾引导语音:">
          <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="(file)=> {uploadFile(file,1,-3)}"
              >
            <div v-if="game1Audio" style="width:178px;height:35px">{{ game1Audio | removeDir | hidden }}</div>
            <div v-else style="width:178px;height:35px">上传</div>
          </el-upload>
          <el-progress  v-if="show_end_audio[0]" :percentage="percentage_end_audio[0]" class="line"></el-progress>
        </el-form-item>
        <el-form-item label="书名文字">
          <el-input v-model="game1Text" style="width:40%"></el-input>
        </el-form-item>
        <el-form-item label="文字音频">
          <div style="color: red">(请务必按书名文字顺序依次上传音频)</div>
          <div v-for="(item, index) in fileList1" :key="index">
            <el-row>
              <div style="float: left">
                <el-form-item >
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="(file)=> {uploadFile(file,1,index)}"
                >
                  <template v-if="fileList1[index]">
                    <div
                      v-if="fileList1[index]"
                      style="height: 40px; width: 200px"
                    >
                      {{ fileList1[index].url | removeDir | hidden}}
                    </div>
                    <i
                      v-else
                      class="el-icon-plus avatar-uploader-icon"
                      style="height: 40px; width: 200px"
                    ></i>
                  </template>
                  <template v-else>
                    <i
                      class="el-icon-plus avatar-uploader-icon"
                      style="height: 40px; width: 200px"
                    ></i>
                  </template>
                </el-upload>
                <el-progress  v-if="fileListProgressFlagArr1[index]" :percentage="percentage[index]" class="line"></el-progress>
                </el-form-item>
              </div>
              <div style="float: left; margin-left: 10px">
                <el-button @click="delCreateYP(index)" type="danger"
                  >删除</el-button
                >
              </div>
            </el-row>
          </div>
          <div style="clear: both"></div>
          <div>
            <el-button @click="ypCreate">+添加</el-button>
          </div>
        </el-form-item>
      </template>
      <!--------------------------------------------2-------------------------------------------------------->
      <!--------------------------------------------2-------------------------------------------------------->
      <template v-else-if="selectedGameType == 2">
        <el-form-item label="结尾引导语音:">
          <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="(file)=> {uploadFile(file,2,-3)}"
              >
            <div v-if="game2Audio" style="width:178px;height:35px">{{ game2Audio | removeDir |hidden }}</div>
            <div v-else style="width:178px;height:35px">上传</div>
          </el-upload>
          <el-progress  v-if="show_end_audio[1]" :percentage="percentage_end_audio[1]" class="line"></el-progress>
        </el-form-item>
        <div>上传连连看完整图（尺寸：300x300px）</div>
        <div style="margin-top: 10px; margin-bottom: 10px; width:178px;">
          <el-form-item>
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :http-request="(file)=> {uploadFile(file,2,-1)}"
          >
            <img
              v-if="fileList2_origin"
              :src="imgDomain + fileList2_origin"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <el-progress v-if="fileListProgressFlagArr[0]" :percentage="percentage1" class="progress"></el-progress>
          </el-form-item>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
        <div>上传图片尺寸为1：1（正方形），建议尺寸80x80px</div>
        <div
          style="float: left; margin: 15px"
          v-for="index of 6"
          :key="index"
        >
        <el-form-item>
          <el-upload
            class="avatar-uploader"
            width="150px"
            action="#"
            :show-file-list="false"
            :http-request="(file)=> {uploadFile(file,2, index)}"
          >
            <img v-if="fileList2[index - 1]" :src="imgDomain + fileList2[index - 1].url" class="avatar"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <el-progress  v-if="fileListProgressFlagArr1[index-1]" :percentage="percentage1" class="progress"></el-progress>
        </el-form-item>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
          
        </div>
      </template>
      <!--------------------------------------3-------------------------------------------------------------->
      <!--------------------------------------3-------------------------------------------------------------->
      <template v-else-if="selectedGameType == 3">
        <el-form-item label="结尾引导语音:">
          <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="(file)=> {uploadFile(file,3, -3)}"
              >
            <div v-if="game3Audio" style="width:178px;height:35px">{{ game3Audio | removeDir |hidden }}</div>
            <div v-else style="width:178px;height:35px">上传</div>
          </el-upload>
          <el-progress  v-if="show_end_audio[2]" :percentage="percentage_end_audio[2]" class="line"></el-progress>
        </el-form-item>
        <div>
          上传图片尺寸为1：1（正方形），建议尺寸300x300px，并勾选图片不同区域
        </div>
        <div style="float: left; margin-top: 20px; height: 200px">
          <div style="float: left">
            <el-form-item>
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="(file)=> {uploadFile(file,3, -1)}"
            >
              <img
                v-if="fileList3_origin"
                :src="imgDomain + fileList3_origin"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-progress  v-if="fileListProgressFlagArr[0]" :percentage="percentage1" class="progress"></el-progress>
            </el-form-item>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
            <div style="text-align: center">原图</div>
            <div>
              <el-progress
                v-if="fileListProgressFlagArr1[0]"
                :percentage="loadProgress"
                style="width: 200px"
              ></el-progress>
            </div>  
          </div>
          <div style="float: left; margin-left: 50px">
            <el-form-item >
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="(file)=> {uploadFile(file,3, -2)}"
            >
            
              <img
                v-if="fileList3_error"
                :src="imgDomain + fileList3_error"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-progress  v-if="fileListProgressFlagArr[1]" :percentage="percentage1" class="progress"></el-progress>
            </el-form-item>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
            <div style="text-align: center">对比图</div>
            
          </div>
          <div style="float: left; margin-left: 50px">
            <img :src="imgDomain + img3" alt="" width="178" height="178" />
            <img v-if="fileList3_origin" :src="imgDomain + fileList3_origin" class="origin-img" />
            <div style="text-align: center">分布图</div>
          </div>
        </div>
        <div class="clear"></div>
        <div style="margin-top: 50px; float: left">
          <div style="margin-bottom: 10px">
            勾选不同区域(目前仅支持3个不同区域)
          </div>
          <template>
            <el-checkbox-group v-model="checkedChunks" :max="3">
              <el-checkbox
                v-for="city in chunks"
                :label="city"
                :key="city"
                >{{ city }}</el-checkbox
              >
            </el-checkbox-group>
          </template>
        </div>
      </template>
      <!------------------------------------------------------4---------------------------------------------->
      <!------------------------------------------------------4---------------------------------------------->
      <template v-else-if="selectedGameType == 4">
        <el-form-item label="结尾引导语音:">
          <el-upload
              class="avatar-uploader"
              action="#"  
              :show-file-list="false"
              :http-request="(file)=> {uploadFile(file,4, -3)}"
              >
            <div v-if="game4Audio" style="width:178px;height:35px">{{ game4Audio | removeDir |hidden }}</div>
            <div v-else style="width:178px;height:35px">上传</div>
          </el-upload>
          <el-progress  v-if="show_end_audio[3]" :percentage="percentage_end_audio[3]" class="line"></el-progress>
        </el-form-item>
        <el-form-item label="拼图数量">
          <el-select
            v-model="ptNum"
            placeholder="请选择"
            @change="selectPtNum(ptNum)"
          >
            <el-option value="9">9</el-option>
          </el-select>
        </el-form-item>
        <div><h3>上传拼图原图(尺寸: 300x300px)</h3></div>
        <div style="float: left; margin-top: 20px; height: 200px">
          <div style="float: left">
            <el-form-item >
              <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="(file)=> {uploadFile(file,4, -1)}"
              >
                <img
                  v-if="fileList4_origin"
                  :src="imgDomain + fileList4_origin"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-progress  v-if="fileListProgressFlagArr[0]" :percentage="percentage1" class="progress"></el-progress>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </div>
          <div style="float: left; margin-left: 50px">
            <img :src="imgDomain + img4" alt="" width="178" height="178" />
            <div style="text-align: center">拼图示意图</div>
          </div>
        </div>
        <div class="clear"></div>
        <div><h3>上传拼图块</h3></div>
        <div style="width: 700px">
          <div
            style="float: left; margin: 15px"
            v-for="index of ptNum"
            :key="index"
          >
          <el-form-item >
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="(file)=> {uploadFile(file,4, index)}"
            >
              <img
                v-if="fileList4[index - 1]"
                :src="imgDomain + fileList4[index - 1].url"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-progress  v-if="fileListProgressFlagArr1[index-1]" :percentage="percentage1" class="progress"></el-progress>
          </el-form-item>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </div>
      </template>
    </el-form>
    <div class="clear"></div>
    <div style="position: relative; height: 40px">
      <el-button style="position: absolute; right: 90px" @click="back" >取消</el-button>
      <el-button
        type="primary"
        style="position: absolute; right: 10px"
        @click="interactCourseContentClick"
        >确定</el-button
      >
    </div>
  </div>
</template>


<script>
import { gameCate, contentDetail, contentAdd } from "@/api/course";
import {getQiniuToken} from '@/api/upload'
import global from '@/utils/global'
import * as qiniu from 'qiniu-js'
export default {
  data() {
    return {
      courseId: this.$route.query.id,
      game_type: 1, // 大分类
      action: this.uploadUrl + "/admin/upload/upload",
      token: { token: window.sessionStorage.getItem("token") },
      gameTypeList: [],
      selectedGameType: "", // 默认选中游戏类型
      otherGameType: 0, // 其他下拉框类型是否有值
      addForm: {
        cate_id: "",
        type1Text: "",
        game_type: 1,
      },
      flag: 0,
      flag2: 0,
      flag4: 0,
      isEdit: 0,
      fileListlimit: "",
      //------------------------1.音频----------------------------------------------------------------
      fileList1: [], //爱唱歌的当当
      game1Text: "", // 书名
      addFlag1: 0,
      game1Audio:'',
      //-----------------------------------2.连连看----------------------------------------------------
      fileList2_origin: "",
      fileList2: [],
      addFlag2: 0,
      game2Audio:'',
      // -------------------------3.找茬------------------------------
      // 多选框
      chunks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      checkedChunks: [],
      fileList3_origin: "",
      fileList3_error: "",
      fileList3: [],
      img3: "interactcourse/20221019171444.jpg",
      img4: "interactcourse/20221019171453.jpg",
      game3Audio:'',
      //--------------------------4.拼图--------------------------------
      ptNum: "",
      fileList4_origin: "",
      fileList4: [],
      addFlag4: 0,
      game4Audio:'',
      //------------图片回显--------------------
      dialogImageUrl: "",
      dialogVisible: false,
      //------------进度条----------------
      loadProgress: 0,
      fileListProgressFlagArr1: [],
      fileListProgressFlagArr: [],
      percentage:[],
      percentage1:0,
      //----------辅助监听------
      is_edit1: 0,
      temp1:'',
      temp1List:[],
      temp1Audio: '',
      is_edit3:0,
      temp2Audio:'',
      //---2
      temp2_origin:'',
      temp2List:[],
      is_edit2: 0,
      //---3
      temp3checkedChunks: [],
      tempFlag3: 0,
      temp3_origin:'',
      temp3_error:'',
      is_edit3: 0,
      temp3Audio:'',
      //---4
      is_edit4:0,
      temp4Audio:'',
      temp4Num:'',
      temp4_origin:'',
      temp4List:[],

      page3Status:false,
      //---进度条
      show_end_audio:[false,false,false,false], // 结尾引导语音
      percentage_end_audio:[0,0,0,0]
    };
  },
  created() {
    this.getGameCate(); // 游戏类型
    this.getContentDetail();
  },
  //-----------------------
  props :{
    activeName: String,
    nextActiveName: String,
  },
  //-----------------------------监听-----------------------------
  //-----------------------------监听-----------------------------
  watch: {
    //----1的书名文字---
    game1Text: {
      handler(newName,oldName){
        if(this.is_edit1==1){
          // 编辑时: 和原先值比对
          if(newName != this.temp1){
            window.sessionStorage.setItem('courseTab',1)
          }else{
            window.sessionStorage.setItem('courseTab',null)
          }
        }else{
          // 添加时：和空比对
          if(newName!=''){
            window.sessionStorage.setItem('courseTab',1)
          }else{
            window.sessionStorage.setItem('courseTab',null)
          }
        }
      },
      deep:true
    },
    //----1.音频------
    fileList1: {
      handler(newName,oldName){
        if(this.is_edit1==1){
          if(this.temp1List.length != newName.length){
            window.sessionStorage.setItem('courseTab',1)
          }else{
            let flag = 0
            for(let i=0;i<this.temp1List.length;i++){
              if(this.temp1List[i].url != this.fileList1[i].url){
                flag = 1
                break
              }
            }
            if(flag == 1){
               window.sessionStorage.setItem('courseTab',1)
            }else{
               window.sessionStorage.setItem('courseTab',null)
            }
          }
        }else{ // 添加时
          if(newName.length>0){
            for(let i in newName){
              if(newName[i].url!=""){
                window.sessionStorage.setItem('courseTab',1)
                break
              }
            }
          }else{
            if(this.game1Text==''){
              window.sessionStorage.setItem('courseTab',null)
            }  
          }
        }
      },
      deep:true
    },
    //--------------引导语音1
    game1Audio: {
      handler(val){
        if(this.is_edit1==1){
          if(val != this.temp1Audio){
            window.sessionStorage.setItem('courseTab',1)
          }else{
            window.sessionStorage.setItem('courseTab',null)
          }
        }else{
          if(val){
            window.sessionStorage.setItem('courseTab',1)
          }else{
            window.sessionStorage.setItem('courseTab',null)
          }
        }
      },
      deep:true
    },
    //--------------引导语音2
    game2Audio: {
      handler(val){
        if(this.is_edit2==1){
          if(val != this.temp2Audio){
            window.sessionStorage.setItem('courseTab',1)
          }else{
            window.sessionStorage.setItem('courseTab',null)
          }
        }else{
          if(val){
            window.sessionStorage.setItem('courseTab',1)
          }else{
            window.sessionStorage.setItem('courseTab',null)
          }
        }
      },
      deep:true
    },
     //--------------引导语音3
    game3Audio: {
      handler(val){
        if(this.is_edit3==1){
          if(val != this.temp3Audio){
            window.sessionStorage.setItem('courseTab',1)
          }else{
            window.sessionStorage.setItem('courseTab',null)
          }
        }else{
          if(val){
            window.sessionStorage.setItem('courseTab',1)
          }else{
            window.sessionStorage.setItem('courseTab',null)
          }
        }
      },
      deep:true
    },
  //--------------引导语音4
  game4Audio: {
    handler(val){
      if(this.is_edit4==1){
        if(val != this.temp4Audio){
          window.sessionStorage.setItem('courseTab',1)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }
      }else{
        if(val){
          window.sessionStorage.setItem('courseTab',1)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }
      }
    },
    deep:true
  },
  //--------------------------2----------------------
  fileList2_origin: {
    handler(newName,oldName){
      if(this.is_edit2==1){
        // 编辑时: 和原先值比对
        if(newName != this.temp2_origin){
          window.sessionStorage.setItem('courseTab',1)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }
      }else{
        // 添加时：和空比对
        if(newName!=''){
          window.sessionStorage.setItem('courseTab',1)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }
      }
    }
  },
  fileList2: {
    handler(newName,oldName){
      if(this.is_edit2==1){
        if(this.temp2List.length != newName.length){
          window.sessionStorage.setItem('courseTab',1)
        }else{
          let flag = 0
          for(let i=0;i<this.temp2List.length;i++){
            if(this.temp2List[i].url != this.fileList2[i].url){
              flag = 1
              break
            }
          }
          if(flag == 1){
              window.sessionStorage.setItem('courseTab',1)
          }else{
              window.sessionStorage.setItem('courseTab',null)
          }
        }
      }else{ // 添加时
        if(newName.length>0){
          for(let i in newName){
            if(newName[i].url!=""){
              window.sessionStorage.setItem('courseTab',1)
              break
            }
          }
        }
      }
    },
    deep:true
  },    
  //---------------------3----------
  fileList3_origin: {
    handler(newName,oldName){
      if(this.is_edit3==1){
        // 编辑时: 和原先值比对
        if(newName != this.temp3_origin){
          window.sessionStorage.setItem('courseTab',1)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }
      }else{
        // 添加时：和空比对
        if(newName!=''){
          window.sessionStorage.setItem('courseTab',1)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }
      }
    },
    deep:true
  },
  fileList3_error: {
    handler(newName,oldName){
      if(this.is_edit3==1){
        // 编辑时: 和原先值比对
        if(newName != this.temp3_error){
          window.sessionStorage.setItem('courseTab',1)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }
      }else{
        // 添加时：和空比对
        if(newName!=''){
          window.sessionStorage.setItem('courseTab',1)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }
      }
    },
    deep:true
  },
  checkedChunks: {
    handler(newName,oldName){   
      if(this.is_edit3 == 1){
        if(this.checkedChunks.length!=this.temp3checkedChunks.length){
          window.sessionStorage.setItem('courseTab',1)
        }else{    
          for(let i=0;i<this.temp3checkedChunks.length;i++){
            if(this.temp3checkedChunks[i]!=this.checkedChunks[i]){
              this.tempFlag3 = 1
            }
          }
          if(this.tempFlag3 == 1){
              window.sessionStorage.setItem('courseTab',1)
          }else{
              window.sessionStorage.setItem('courseTab',null)
          }
          this.tempFlag3 = 0
        }
      }else{
        if(this.checkedChunks.length>0){
          window.sessionStorage.setItem('courseTab',1)
        }else{
          if(window.sessionStorage.getItem('courseTab')!=1)
            window.sessionStorage.setItem('courseTab',null)
        }
      }
    },
    deep:true
  },
  //-----------------4---------
  ptNum: {
    handler(newName,oldName){
      if(this.is_edit4==1){
        // 编辑时: 和原先值比对
        if(newName != this.temp4Num){
          window.sessionStorage.setItem('courseTab',1)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }
      }else{
        // 添加时：和空比对
        if(newName!=''){
          window.sessionStorage.setItem('courseTab',1)
        }else{
          window.sessionStorage.setItem('courseTab',null)
        }
      }
    },
    deep:true
  },  
  fileList4_origin: {
      handler(newName,oldName){
        if(this.is_edit4==1){
          // 编辑时: 和原先值比对
          if(newName != this.temp4_origin){
            window.sessionStorage.setItem('courseTab',1)
          }else{
            window.sessionStorage.setItem('courseTab',null)
          }
        }else{
          // 添加时：和空比对
          if(newName!=''){
            window.sessionStorage.setItem('courseTab',1)
          }else{
            window.sessionStorage.setItem('courseTab',null)
          }
        }
      },
      deep:true
    },
    fileList4: {
      handler(newName,oldName){
        if(this.is_edit4==1){
          if(this.temp4List.length != newName.length){
            window.sessionStorage.setItem('courseTab',1)
          }else{
            let flag = 0
            for(let i=0;i<this.temp4List.length;i++){
              if(this.temp4List[i].url != this.fileList4[i].url){
                flag = 1
                break
              }
            }
            if(flag == 1){
               window.sessionStorage.setItem('courseTab',1)
            }else{
               window.sessionStorage.setItem('courseTab',null)
            }
          }
        }else{ // 添加时
          if(newName.length>0){
            console.log("newName",newName)
             for(let i in newName){
              if(newName[i].url!=""){
                window.sessionStorage.setItem('courseTab',1)
                break
              }
            }
          }
        }
      },
      deep:true
    },  
  },//end_watch
  //---------------------end watch------------------------------

  //---------------------过滤器-----------------------------
  filters: {
    removeDir(value) {
      let site = value.lastIndexOf("\/");
      return value.substring(site+1, value.length)
    },
    hidden(value){
      let end = value.lastIndexOf("-")
      if(end>20){
        end = 20
      }
      return value.substring(0, end)
    },
  },
  
  //-----------------------方法-----------------------------
  methods: {
    //------------------改变父组件的active值----------------------------
     changeActive(num){
      this.$emit('activeChange', num)
    },
    //---------------------------------------------------------------
    async getGameCate() {
      const { data: res } = await gameCate();
      if (res.status != 0) {
        return this.$message.error(res.msg);
      }
      this.gameTypeList = res.data;
    },
    //--------------------new---------------------
    delCreateYP(index) {
      this.fileList1.splice(index, 1);
    },
    ypCreate() {
      this.fileListProgressFlagArr1.push(false);
      this.percentage.push(0);
      this.fileList1.push({ url: "" });
    },
    //------------------课程游戏详情-------------------------------------------
    changeEditStatus(type){
      switch(type){
        case 1:
          this.is_edit1 = 1
          this.is_edit2 = 0
          this.is_edit3 = 0
          this.is_edit4 = 0
          break;
        case 2:
          this.is_edit1 = 0
          this.is_edit2 = 1
          this.is_edit3 = 0
          this.is_edit4 = 0
          break;
        case 3:
          this.is_edit1 = 0
          this.is_edit2 = 0  
          this.is_edit3 = 1
          this.is_edit4 = 0
          break;
        case 4:
          this.is_edit1 = 0
          this.is_edit2 = 0
          this.is_edit3 = 0
          this.is_edit4 = 1
          break;
      }
    },
    async getContentDetail() {  
      const { data: res } = await contentDetail({
        courseId: this.$route.query.id,
        game_type: this.game_type,
      });

      if (res.status != 0) {
        return this.$message.error(res.msg);
      }

      this.selectedGameType = res.data.cate_id;
      if (res.data.cate_id == 1) {
        this.addForm.type1Text = res.data.name;
        this.game1Text = res.data.name;
        this.fileList1 = res.data.audio;
        this.temp1 = res.data.name
        this.changeEditStatus(1)
        this.game1Audio = res.data.game1Audio
        this.temp1Audio = res.data.game1Audio
        // 深浅拷贝
        res.data.audio.forEach((element)=>{
          this.temp1List.push(element)
        })
      }
      
      if (res.data.cate_id == 2) {
        this.fileList2_origin = res.data.origin_image;
        this.fileList2 = res.data.fileList;
        this.game2Audio = res.data.game2Audio
        this.temp2Audio = res.data.game2Audio
        this.temp2_origin = res.data.origin_image
         // 深浅拷贝
        res.data.fileList.forEach((element)=>{
          this.temp2List.push(element)
        })
        this.changeEditStatus(2)
      }

      if (res.data.cate_id == 3) {
        this.fileList3_origin = res.data.origin_images;
        this.fileList3_error = res.data.error_images;
        this.checkedChunks = res.data.error_number;
        this.changeEditStatus(3)
        this.temp3checkedChunks = res.data.error_number
        this.game3Audio = res.data.game3Audio
        this.temp3Audio = res.data.game3Audio
        this.temp3_origin = res.data.origin_images
        this.temp3_error = res.data.error_images
      }

      if (res.data.cate_id == 4) {
        this.fileList4_origin = res.data.origin_image;
        this.fileList4 = res.data.fileList;
        this.ptNum = parseInt(res.data.ptNum);
        this.game4Audio = res.data.game4Audio
        this.temp4Audio = res.data.game4Audio
        this.temp4Num = this.ptNum
        this.temp4_origin = res.data.origin_image
        res.data.fileList.forEach((element)=>{
          this.temp4List.push(element)
        })
        this.changeEditStatus(4)
      }
      this.$forceUpdate();
    },
    //-----------------------------------------------
    back() {
      this.$router.push("/admin.course.index"); // 取消则跳回 list
       window.sessionStorage.setItem('courseTab',null)
    },
    selectChange(type) {  // 切换游戏类型
      //下拉菜单
      this.selectedGameType = type;
      if( type ==2 ){
          this.fileListProgressFlagArr.push(false);
          for(let i=0;i<6;i++){
            this.fileListProgressFlagArr1.push(false);
            this.percentage.push(0);
          }
      }else if(type == 3){
         for(let i=0;i<2;i++){
            this.fileListProgressFlagArr.push(false);
          }
      }else if(type == 4){
        this.fileListProgressFlagArr.push(false);
      }else if(type ==1){

      }
    },
    selectPtNum(ptNum) {
      this.ptNum = parseInt(ptNum);
      this.fileListProgressFlagArr1 = []
      this.percentage = []
      for(let i=0;i<this.ptNum;i++){
        this.fileListProgressFlagArr1.push(false);
        this.percentage.push(0);
      }
    },
    //---------------------------------文件上传---------------------------------------
    //---------------------------------文件上传---------------------------------------
    async uploadFile(uploadInfo,type,index){
      const {data:token} = await getQiniuToken()
      const that = this
      const observer = {
          next(res){
            const percentage = parseInt(res.total.percent.toString().split(".")[0])
            if(index == -3){
              that.$set(that.percentage_end_audio,type-1,percentage)
            }else{
              if(type == 1){
                that.$set(that.percentage, index, percentage)
              }else {
                that.percentage1 = percentage
              }
            }
          },
          error(err){
            console.log(err)
            this.$message.error(err)
          },
          complete(res){
            if(type == 1){
              if(index == -3){ // 引导语音
                that.game1Audio = res.key
                that.$set(that.show_end_audio, type-1, false)
              }else{
                that.$set(that.fileList1,index,{url:res.key})
                that.$set(that.fileListProgressFlagArr1,index,false)
              }
              that.selectedGameType = 1
            }else if(type == 2){
              if(index == -3){ // 引导语音
                that.game2Audio = res.key
                that.$set(that.show_end_audio, type-1, false)
              }else if(index == -1){  //
                that.fileList2_origin = res.key
                that.$set(that.fileListProgressFlagArr,0,false)
              }else {
                that.$set(that.fileList2,index-1,{url:res.key})
                that.$set(that.fileListProgressFlagArr1,index-1,false)
              }
            }else if(type ==3){
              if(index == -3){
                that.game3Audio = res.key
                that.$set(that.show_end_audio, type-1, false)
              }else if(index == -1){
                that.fileList3_origin = res.key
                that.$set(that.fileListProgressFlagArr,0, false)
              }else if(index == -2){
                that.fileList3_error = res.key
                that.$set(that.fileListProgressFlagArr,1, false)
              }
            }else if(type == 4){
              if(index == -3){
                that.game4Audio = res.key
                that.$set(that.show_end_audio, type-1, false)
              }else if(index == -1){
                that.fileList4_origin = res.key
                that.$set(that.fileListProgressFlagArr,0,false)
              }else{
                that.$set(that.fileList4, index-1,{url:res.key})
                that.$set(that.fileListProgressFlagArr1,index-1,false)
              }
            }
          }
      }
    
     
      if(index == -3){
        this.$set(this.show_end_audio,type-1,true)
      }else{
        if(type ==1){
           this.$set(this.fileListProgressFlagArr1,index,true)
        }else if(type ==2){
           if(index == -1){
            this.$set(this.fileListProgressFlagArr,0,true)
           }else{
            this.$set(this.fileListProgressFlagArr1,index-1,true)
           }
        }else if(type ==3){
          if(index == -1){
            this.$set(this.fileListProgressFlagArr,0,true)
          }else{
            this.$set(this.fileListProgressFlagArr,1,true)
          }
        }else if(type == 4){
          if(index == -1){
            this.$set(this.fileListProgressFlagArr,0,true)
          }else{
            this.$set(this.fileListProgressFlagArr1, index-1, true)
          }
        }
      }

      // 数字命名
      let name = uploadInfo.file.name
      let fileName = global.getFileName(name)
      if(parseInt(fileName) !=NaN){
        name = fileName+"-"+ global.randStr(10) +"."+ global.getExt(name)
      }
      
      
      const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+name, token,{},{})
      const subscription =  observable.subscribe(observer)
    },
    // randStr(e){
    //   let str = "abacdefghijklmn123456789QWERTYUIOPASDFGHJKLZXCVBNM"
    //   let n = "";
    //   for(let i=0;i<e;i++){
    //     n += str.charAt(Math.floor(Math.random()*str.length))
    //   }
    //   return n
    // },
    // getFileName(val){
    //   let end = val.lastIndexOf(".")
    //   return  val.substring(0,end).split(' ').join('')
    // },
    // getExt(val){
    //   return val.substring(val.lastIndexOf(".")+1)
    // },
    //-----------------------------------------------------------------------确认----------------------------------------------------
    interactCourseContentClick() {
      var that = this
      if (!this.selectedGameType) {
        return this.$message.error("请选择游戏类型");
      }

      if (this.selectedGameType == 1) {
        this.otherSelectHasValue();
        if (this.otherGameType == 1) {
          this.$confirm(
            "当前已有保存的游戏内容，是否要覆盖当前内容",
            "系统提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              cancelButtonClass: "btn-custom-cancel",
              type: "warning",
            }
          )
            .then(() => {
              this.otherGameType = 0;
              this.type1Text();
              this.isEdit = 1
            })
            .catch(function () {
              console.log("取消成功！");
            });
        } else {
          this.type1Text();
        }
      }

      if (this.selectedGameType == 2) {
        this.otherSelectHasValue();
        if (this.otherGameType == 1) {
          this.$confirm(
            "当前已有保存的游戏内容，是否要覆盖当前内容",
            "系统提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              cancelButtonClass: "btn-custom-cancel",
              type: "warning",
            }
          )
            .then(() => {
              this.otherGameType = 0;
              this.type2();
              this.isEdit = 1
            })
            .catch(function () {
              console.log("取消成功！");
            });
        } else {
          this.type2();
        }
      }

      if (this.selectedGameType == 3) {
        this.otherSelectHasValue();
        if (this.otherGameType == 1) {
          this.$confirm(
            "当前已有保存的游戏内容，是否要覆盖当前内容",
            "系统提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              cancelButtonClass: "btn-custom-cancel",
              type: "warning",
            }
          )
            .then(() => {
              this.otherGameType = 0;
              this.type3();
              this.isEdit = 1
            })
            .catch(function () {
              console.log("取消成功！");
            });
        } else {
          this.type3();
        }
      }

      if (this.selectedGameType == 4) {
        this.otherSelectHasValue();
        if (this.otherGameType == 1) {
          this.$confirm(
            "当前已有保存的游戏内容，是否要覆盖当前内容",
            "系统提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              cancelButtonClass: "btn-custom-cancel",
              type: "warning",
            }
          )
            .then(() => {
              this.otherGameType = 0;
              this.type4();
              this.isEdit = 1
            })
            .catch(function () {
              console.log("取消成功！");
            });
        } else {
          this.type4();
        }
      }
      this.isEdit = 0  
    },

    async type1Text() {
      //-----------------------------------游戏1------------------------------------------

      if (!this.game1Text) {
        return this.$message.error("请输入书名文字");
      }
      if (!this.game1Audio) {
        return this.$message.error("请上传引导语音");
      }
      if (this.game1Text.length > 8 || this.game1Text.length < 4) {
        return this.$message.error("书名的文字一般在4-8个字!");
      }
      if (this.fileList1.length > this.game1Text.length) {
        return this.$message.error("音频文件超量");
      }
      for (let i = 0; i < this.fileList1.length; i++) {
        if (this.fileList1[i] == undefined) {
          this.addFlag1 = 1;
        }
        if (this.fileList1[i].url == '') {
          this.addFlag1 = 1;
        }
      }

      
      if (this.fileList1.length < this.game1Text.length || this.addFlag1 == 1) {
        this.$confirm("文字与音频未一一对应，是否要保存内容", "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning",
        })
          .then(async () => {
            this.addFlag1 = 0
            this.addForm.fileList = this.fileList1
            this.addForm.type1Text = this.game1Text
            this.addForm.game1Audio = this.game1Audio
            this.addForm.courseId = this.courseId
            this.addForm.cate_id = this.selectedGameType

            const { data: res } = await contentAdd(this.addForm);
            if (res.status != 0) {
              return this.$message.error(res.msg);
            }
            this.$message.success(res.msg);
            // this.$router.push({ path: "/admin.course.index" });
            window.sessionStorage.setItem("courseTab",null)
            this.changeActive([this.nextActiveName,true])
            this.clear(1)
          })
          .catch(() => {
            this.addFlag1 = 0;
            console.log("取消成功！");
          });
      } else {
        this.addForm.fileList = this.fileList1
        this.addForm.type1Text = this.game1Text
        this.addForm.courseId = this.courseId
        this.addForm.game1Audio = this.game1Audio
        this.addForm.cate_id = this.selectedGameType

        const { data: res } = await contentAdd(this.addForm);
        if (res.status != 0) {
          return this.$message.error(res.msg);
        }
        window.sessionStorage.setItem("courseTab",null)
        this.$message.success(res.msg);
        this.clear(1)
      }
      
    },
    async type2() {
      //-----------------------------------------游戏2------------------------------------------

      if (this.fileList2_origin == "") {
        this.addFlag2 = 1;
      }

      for (let i = 0; i < 6; i++) {
        if (this.fileList2[i] == undefined) {
          this.addFlag2 = 1;
        }
      }

      if (this.addFlag2 == 1) {
        this.$confirm("当前还有图片未上传，是否要保存内容", "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning",
        })
          .then(async () => {
            this.addFlag2 = 0;
            this.addForm.fileList = this.fileList2;
            this.addForm.origin = this.fileList2_origin;
            this.addForm.courseId = this.courseId;
            this.addForm.game2Audio = this.game2Audio
            this.addForm.cate_id = this.selectedGameType;

            const { data: res } = await contentAdd(this.addForm);
            if (res.status != 0) {
              return this.$message.error(res.msg);
            }
            this.$message.success(res.msg);
           
            window.sessionStorage.setItem("courseTab",null)
            this.changeActive([this.nextActiveName,true])
            this.clear(2)
          })
          .catch(() => {
            this.addFlag2 = 0;
            console.log("取消成功！");
          });
      } else {
        this.addForm.fileList = this.fileList2;
        this.addForm.origin = this.fileList2_origin;
        this.addForm.courseId = this.courseId;
        this.addForm.game2Audio = this.game2Audio
        this.addForm.cate_id = this.selectedGameType;

        const { data: res } = await contentAdd(this.addForm);
        if (res.status != 0) {
          return this.$message.error(res.msg);
        }
        this.$message.success(res.msg);
  
        window.sessionStorage.setItem("courseTab",null)
        this.changeActive([this.nextActiveName,true])
        this.clear(2)
      }
    },
    async type3() {
      //-------------------------------游戏3----------------------------------------
      if (this.checkedChunks.length == 0) { 
        return this.$message.error("请勾选不同区域");
      }
      if (this.checkedChunks.length < 3) {
        return this.$message.error("勾选不同区域必须为3个，请全部勾选在保存");
      }
      if (this.checkedChunks.length > 3) {
        return this.$message.error("最多选择3个不同区域");
      }

      if ( this.fileList3_error == "" || this.fileList3_origin == "") {
        this.$confirm("当前还有图片未上传，是否要保存内容", "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning",
        })
          .then(async () => {
            this.addForm.origin = this.fileList3_origin;
            this.addForm.error_iamge = this.fileList3_error;
            this.addForm.error_number = this.checkedChunks;
            this.addForm.courseId = this.courseId;
            this.addForm.game3Audio = this.game3Audio
            this.addForm.cate_id = this.selectedGameType;
            const { data: res } = await contentAdd(this.addForm);
            if (res.status != 0) {
              return this.$message.error(res.msg);
            }
            this.$message.success(res.msg);

           
            window.sessionStorage.setItem("courseTab",null)
            this.changeActive([this.nextActiveName,true])
            this.clear(3)
          })
          .catch(() => {
            console.log("取消成功！");
          });
      } else {
        this.addForm.origin = this.fileList3_origin;
        this.addForm.error_iamge = this.fileList3_error;
        this.addForm.error_number = this.checkedChunks;
        this.addForm.courseId = this.courseId;
        this.addForm.game3Audio = this.game3Audio
        this.addForm.cate_id = this.selectedGameType;
        const { data: res } = await contentAdd(this.addForm);
        if (res.status != 0) {
          return this.$message.error(res.msg);
        }
        window.sessionStorage.setItem("courseTab",null)
        this.$message.success(res.msg);
        // this.$router.push({ path: "/admin.course.index" });
        this.clear(3)
      }
      return 1
    },
    async type4() {
      //-------------------------------游戏4-------------------------------------------

      if (!this.ptNum) {
        return this.$message.error("请选择拼图数量");
      }

      if (this.fileList4_origin == undefined) {
        this.addFlag4 = 1;
      }
     
      for (let i = 0; i < this.ptNum; i++) {
        if (this.fileList4[i] == undefined) {
          this.addFlag4 = 1;
        }
      }
   
      if (this.addFlag4 == 1) {
        this.$confirm("当前还有图片未上传，是否要保存内容", "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning",
        })
          .then(async () => {
            this.addFlag4 = 0;
            this.addForm.origin = this.fileList4_origin;
            this.addForm.fileList = this.fileList4;
            this.addForm.courseId = this.courseId;
            this.addForm.cate_id = this.selectedGameType;
            this.addForm.game4Audio = this.game4Audio
            this.addForm.num = this.ptNum;
            const { data: res } = await contentAdd(this.addForm);
            if (res.status != 0) {
              return this.$message.error(res.msg);
            }
            this.$message.success(res.msg);
  
            window.sessionStorage.setItem("courseTab",null)
            this.changeActive([this.nextActiveName,true])
            this.clear(4)
          })
          .catch(() => {
            this.addFlag4 = 0;
            console.log("取消成功！");
          });
      } else {
        this.addForm.origin = this.fileList4_origin;
        this.addForm.fileList = this.fileList4;
        this.addForm.courseId = this.courseId;
        this.addForm.game4Audio = this.game4Audio
        this.addForm.cate_id = this.selectedGameType;
        this.addForm.num = this.ptNum;
        const { data: res } = await contentAdd(this.addForm);
        if (res.status != 0) {
          return this.$message.error(res.msg);
        }
        this.$message.success(res.msg);
        // this.$router.push({ path: "/admin.course.index" });
        window.sessionStorage.setItem("courseTab",null)
        this.changeActive([this.nextActiveName,true])
        this.clear(4)
      }
    },

    //-----------------clear 覆盖成功之后 清理其他游戏类型的数据---------------
    clear(num) {
      if(num == 1){ // 清除2,3,4的数据
        this.fileList2_origin = ""
        this.fileList2 = []
        this.game2Audio = ''
        this.game3Audio = ''
        this.game4Audio = ''
        this.addFlag2 = 0
        this.checkedChunks = []
        this.fileList3_origin = ''
        this.fileList3_origin= ''
        this.fileList3 = []
        this.ptNum = ''
        this.fileList4_origin = ""
        this.fileList4= []
        this.addFlag4 = 0
      }else if(num == 2){
        this.fileList1 = []
        this.game1Text = ''
        this.addForm.type1Text = ''
        this.addFlag1 = 0
        this.checkedChunks = []
        this.fileList3_origin = ''
        this.fileList3_origin= ''
        this.fileList3 = []
        this.ptNum = ''
        this.fileList4_origin = ""
        this.fileList4= []
        this.addFlag4 = 0
        this.game1Audio = ''
        this.game3Audio = ''
        this.game4Audio = ''
      }else if(num ==3){
        this.fileList1 = []
        this.game1Text = ''
        this.addForm.type1Text = ''
        this.addFlag1 = 0
        this.fileList2_origin = ""
        this.fileList2 = []
        this.addFlag2 = 0
        this.ptNum = ''
        this.fileList4_origin = ""
        this.fileList4= []
        this.addFlag4 = 0
        this.game2Audio = ''
        this.game1Audio = ''
        this.game4Audio = ''
      }else if(num == 4){
        this.fileList1 = []
        this.game1Text = ''
        this.addForm.type1Text = ''
        this.addFlag1 = 0
        this.fileList2_origin = ""
        this.fileList2 = []
        this.addFlag2 = 0
        this.checkedChunks = []
        this.fileList3_origin = ''
        this.fileList3_origin= ''
        this.fileList3 = []
        this.game2Audio = ''
        this.game3Audio = ''
        this.game1Audio = ''
      }
      
    },
    otherSelectHasValue() {
      // 判断是否要覆盖
      if (this.selectedGameType == 1) {
        if (
          this.fileList2_origin != "" ||
          this.fileList2.length != 0 ||
          this.fileList3_origin != "" ||
          this.fileList3_error != "" ||
          this.checkedChunks.length != 0 ||
          this.fileList4_origin != "" ||
          this.fileList4.length != 0 ||
          this.ptNum != "" ||
          this.game2Audio != '' ||
          this.game3Audio != '' ||
          this.game4Audio != ''
        ) {
          this.otherGameType = 1;
        }
      }
      if (this.selectedGameType == 2) {
        if (
          this.game1Text != "" ||
          this.fileList1.length != 0 ||
          this.fileList3_origin != "" ||
          this.fileList3_error != "" ||
          this.checkedChunks.length != 0 ||
          this.fileList4_origin != "" ||
          this.fileList4.length != 0 ||
          this.ptNum != "" ||
          this.game4Audio != '' ||
          this.game3Audio != '' ||
          this.game1Audio != ''
        ) {
          this.otherGameType = 1;
        }
      }
      if (this.selectedGameType == 3) {
        if (
          this.fileList2_origin != "" ||
          this.fileList2.length != 0 ||
          this.game1Text != "" ||
          this.fileList1.length != 0 ||
          this.fileList4_origin != "" ||
          this.fileList4.length != 0 ||
          this.ptNum != "" || 
          this.game2Audio != '' ||
          this.game4Audio != '' ||
          this.game1Audio != ''
        ) {
          this.otherGameType = 1;
        }
      }
      if (this.selectedGameType == 4) {
        if (
          this.game1Text != "" ||
          this.fileList1.length != 0 ||
          this.fileList2_origin != "" ||
          this.fileList2.length != 0 ||
          this.fileList3_origin != "" ||
          this.fileList3_error != "" ||
          this.checkedChunks.length != 0 ||
          this.game2Audio != '' ||
          this.game3Audio != '' ||
          this.game1Audio != ''
        ) {
          this.otherGameType = 1;
        }
      }
    },
  }, //end methods
};
</script>


<style >
.clear {
  clear: both;
}
.disabled .el-upload--picture-card {
  display: none;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-icon-plus:before {
  line-height: 178px;
}
.progress{
 position: absolute;
 margin-left:26px;
 margin-top:-162px;
}
.line {
  width: 200px;
}
.origin-img{
  /* margin-top: -180px; */
  width: 178px;
  height: 178px;
  display: block;
  margin-top:-181px;
  opacity: 50%;
}
</style>
