<!--课程包数据分析-->
<template>
  <div>
    <el-card>
      <div class="nav_1">
        <div class="div-left">
          <div class="d-order-no">课程包名称: </div>
          <div class="div-left">
            <el-input class="input" v-model="search.keywords"></el-input>
          </div>
          <div class="div-left">
            <el-button type="primary" icon="el-icon-search" @click="btnClick">搜索</el-button>
            <el-button type="warning" icon="el-icon-refresh" @click="resetClick">重置</el-button>
          </div>
        </div>
      </div>
      <!---->
      <div class="clear"></div>
      <el-row style="margin-top:10px;">
        <el-button type="success" style="width:100px;" @click="excelExport">Excel导出</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="list" border stripe :row-style="{ height: '50px' }" :cell-style='cellStyle'
          :header-cell-style='rowClass'>
          <el-table-column label="课程包编号" prop="id"></el-table-column>
          <el-table-column label="课程包名称" prop="course_bag_name"></el-table-column>
          <el-table-column label="查看课包总次数" prop="click_num">
            <template slot-scope="scope">
              <span v-if="scope.row.click_num">{{ scope.row.click_num }}</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column label="点击购买总次数" prop="buy_click_num">
            <template slot-scope="scope">
              <span v-if="scope.row.buy_click_num">{{ scope.row.buy_click_num }}</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column label="购买总数量" prop="pay_num">
            <template slot-scope="scope">
              <span v-if="scope.row.pay_num">{{ scope.row.pay_num }}</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column label="退款总数量" prop="refund_num">
            <template slot-scope="scope">
              <span v-if="scope.row.refund_num">{{ scope.row.refund_num }}</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="jump(scope.row.id, scope.row.course_bag_name)">查看日数据</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <!--分页start-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="search.pagenum"
        :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      <!--分页end-->

    </el-card>
  </div>
</template>

<script>
import { list } from '@/api/static'
export default {
  data() {
    return {
      list: [],
      total: 0,
      search: {
        keywords: '',
        pagesize: 10,
        pagenum: 1,
      },
      addDialogVisible: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    resetClick()  {
      this.search.keywords = "";
      this.search.pagenum = 1;
      this.search.pagesize = 10;
      this.btnClick();
    },
    //-----------------------------tabel 文字居中-------------------------------
    rowClass({ row, rowIndex }) {
      return 'text-align:center'
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;padding:0px'
    },
    //-------------------------------列表-----------------------------------
    async getList() {
      const { data: res } = await list(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.list = res.data.data
      this.total = res.data.total
    },
    // -------------------------分页-------------------------------------------
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },
    //
    async changeSta(id, status) {
      const { data: res } = await changeStatus({ mes_id: id, status: status })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success('ok')
      this.getList()
    },
    //------------------------------筛选---------------------------------------
    btnClick() {
      this.search.pagenum = 1;
      this.search.pagesize = 10;
      this.getList()
    },
    //---------发送记录
    jump(id, name) {
      this.$router.push({ path: '/admin.course-data-daily', query: { bags_id: id, course_bag_name: name } })
    },
    //-----------------------------excelExport------
    excelExport() {
      const headers = {
        '课程包编号': 'id',
        '课程包名称': 'course_bag_name',
        '查看课包总次数': 'click_num',
        '点击购买总次数': 'buy_click_num',
        '购买总数量': 'pay_num',
        '退款总数量': 'refund_num',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagesize = this.total
        const { data: res } = await list(this.search)
        this.search.pagesize = 10
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '课程包数据分析',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },

  }
}
</script>


<style scoped>
.nav_1 {
  margin-bottom: 10px;
  float: left;
}

.div-left {
  float: left;
  margin-right: 10px;
}

.input {
  width: 220px;
}

.d-order-no {
  float: left;
  margin-right: 15px;
  height: 35px;
  line-height: 35px;
}
</style>