<template>
    <span>
        <div style="clear:both"></div>
        <el-dialog title="客服" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="35%">
            <el-form ref="addFormRef" label-width="100px">
                <el-form-item label="所属职位:">
                    <el-checkbox-group v-model="customerData.position">
                        <el-checkbox v-for="item in position" :label="item.id" :key="item.id">{{ item.name }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="客服姓名:">
                    <el-input v-model="customerData.name"></el-input>
                </el-form-item>
                <el-form-item label="客服微信号:">
                    <el-input v-model="customerData.work_wechat_no"></el-input><span
                        style="color:red">*若勾选客服，必须添加与企微对应的微信号</span>
                </el-form-item>
                <el-form-item label="客服手机号:">
                    <el-input v-model="customerData.mobile_phone"></el-input><span
                        style="color:red">*若勾选客服，必须添加与企微对应的手机号</span>
                </el-form-item>
                <div style="width:178px;">
                    <el-form-item label="微信二维码:">
                        <el-upload class="avatar-uploader" action="#" :show-file-list="false"
                            :http-request="(file) => { uploadFile(file, 1) }">
                            <img v-if="customerData.work_wechat_qrcode" :src="imgDomain + customerData.work_wechat_qrcode"
                                class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </div>
                <div style="margin-left:90px"><span style="color:red">*若勾选客服，必须添加微信二维码</span></div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="addSubmit">保存</el-button>
            </span>
        </el-dialog>
    </span>
</template>

<script>
import { addCustomer, updateCustomer } from '@/api/customer'
import { getQiniuToken } from '@/api/upload'
import * as qiniu from 'qiniu-js'
import global from '@/utils/global'
export default {
    data() {
        return {
            progress: false,
            action: this.uploadUrl + '/admin/upload/upload',
            token: { token: window.sessionStorage.getItem("token") },
            customerData: {
                id: 0,
                name: "",
                mobile_phone: "",
                work_wechat_no: "",
                work_wechat_qrcode: "",
                position: [],
                customer_type: ""
            },
            addDialogVisible: false,
            position: [{ id: 1, name: "课程客服" }, { id: 3, name: "销售" }, { id: 2, name: "联系客服" }, { id: 4, name: "课程小结客服" }]
        }
    },
    methods: {
        setCustomerInfoData(customer_info) {
            let customer_arr = customer_info.customer_type.split(",")
            customer_arr.map((el) => {
                this.customerData.position.push(parseInt(el))
            })
            this.customerData.id = customer_info.id
            this.customerData.name = customer_info.name
            this.customerData.work_wechat_no = customer_info.work_wechat_no
            this.customerData.mobile_phone = customer_info.mobile_phone
            this.customerData.work_wechat_qrcode = customer_info.work_wechat_qrcode
        },
        handleAvatarSuccess(res, file) {  // 图片上传成功
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            if (res.data.index == 1) {
                this.customerData.work_wechat_qrcode = res.data.url
            }
        },
        addSubmit() {
            const self = this;
            let flag = false
            for (let i in this.customerData.position) {
                if (this.customerData.position[i] == 1) {
                    flag = 1
                }
            }
            if (this.customerData.position.length < 1) {
                return this.$message.error("请选择所属职位!")
            }

            if (this.customerData.name == "" || this.customerData.name == undefined) {
                this.$message.error("请输入客服名称")
                return
            }

            if (this.customerData.mobile_phone == "" || this.customerData.mobile_phone == undefined) {
                this.$message.error("请输入手机号")
                return
            }

            if (flag == 1) {
                if (this.customerData.work_wechat_no == "" || this.customerData.work_wechat_no == undefined) {
                    this.$message.error("请输入客服微信号")
                    return
                }

                if (this.customerData.work_wechat_qrcode == "" || this.customerData.work_wechat_qrcode == undefined) {
                    this.$message.error("请上传企微二维码")
                    return
                }
            }

            this.customerData.customer_type = this.customerData.position.toString()
            if (this.customerData.id == 0) {
                addCustomer(this.customerData).then((res) => {
                    let code = res.data.status;
                    if (code === 0) {
                        self.$message.success("添加客服成功")
                        self.addDialogVisible = false;
                        self.$emit("refresh_list")
                        self.clear()
                    } else {
                        self.$message.error(res.data.msg)
                    }
                })
            } else {
                updateCustomer(this.customerData).then((res) => {
                    let code = res.data.status;
                    if (code === 0) {
                        self.$message.success("编辑客服成功")
                        self.addDialogVisible = false;
                        self.$emit("refresh_list")
                        self.clear()
                    } else {
                        self.$message.error(res.data.msg)
                    }
                })
            }
        },
        clear() {
            this.customerData.id = 0;
            this.customerData.name = ""
            this.customerData.mobile_phone = ""
            this.customerData.work_wechat_no = ""
            this.customerData.work_wechat_qrcode = ""
            this.customerData.position = []
            this.customerData.customer_type = "";
        },
        async uploadFile(uploadInfo, index) {
            const { data: token } = await getQiniuToken()
            const that = this
            const observer = {
                next(res) {
                    console.log('next:', res)
                },
                error(err) {
                    console.log(err)
                },
                complete(res) {
                    console.log('complete:....')
                    that.customerData.work_wechat_qrcode = res.key
                }
            }

            let name = uploadInfo.file.name
            let fileName = global.getFileName(name)
            name = global.randStr(15) + "." + global.getExt(name)

            const observable = qiniu.upload(uploadInfo.file, "interactcourse/" + name, token, {}, {})
            const subscription = observable.subscribe(observer)
        },
    }
}
</script>


<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}

.div-left {
    float: left;
    margin-right: 10px;
}

.input {
    width: 220px;
}

.d-order-no {
    float: left;
    margin-right: 15px;
    height: 35px;
    line-height: 35px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>