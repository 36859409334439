<!--课包的绑定学生记录-->
<template>
  <div>
    <el-card>
      <div class="nav_1">
        <div style="float:left;">
          <div style="float:left;margin-right:5px;height:40px;line-height:40px;">搜索用户: </div>
          <div class="search"><el-input type="text" prefix="支持模糊搜索，输入用户名或者手机号" style="width:100%" v-model="search.search_content" @blur="blur()"
                                        prefix-icon="el-icon-search"></el-input></div>
        </div>
      </div>
      <div class="clear"></div>
      <el-row style="margin-top:10px;">
<!--        <el-button type="danger" style="width:100px;" @click="back">返回关联课包</el-button>-->
        <el-button type="success" style="width:100px;" @click="excelExport">Excel导出</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="list" border stripe :row-style="{ height: '45px' }">
          <el-table-column label="用户ID" prop="user_id"></el-table-column>
          <el-table-column label="用户名" prop="user_name"></el-table-column>
          <el-table-column label="手机号" prop="user_mobile"></el-table-column>
          <el-table-column label="上课进度(已完成/总课程)" prop="learn_msg"></el-table-column>
          <el-table-column label="操作" width="240px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="jump(scope.row)">上课记录</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--分页start-->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
                       layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <!--分页end-->
      </el-row>
    </el-card>

  </div>
</template>
<script>
import {getUserInfoBySchoolBags} from "@/api/schoolCourseBags";

export default {
  data() {
    return {
      search: {
        bags_id: this.$route.query.bags_id, // 课包id
        bags_relation_school_id: this.$route.query.id, // 学校关联课包id bags_relation_school_id
        search_content: '',
        pagesize: 10,
        pagenum: 1,
      },
      total: 0,
      list: [],
    }
  },
  created() {
    this.userSignUpList()
  },
  methods: {
    //-------------------列表----------
    async userSignUpList() {
      const { data: res } = await getUserInfoBySchoolBags(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.list = res.data.data
      this.total = res.data.total

    },
    // -------------------------分页-------------------------------------------
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.userSignUpList(this.search)
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.userSignUpList(this.search)
    },
    //----------------------------搜索-----------------------------------
    blur() {
      this.userSignUpList()
    },
    //----------------------------跳转: 用户上课记录-----------------------------
    jump(row) {
      console.log(row)
       this.$router.push({ path: '/admin.course.classRecord', query: { bags_id: row.bags_id, app_user_id: row.user_id, bagTimeId: row.bags_time_id, course_bags_cate: row.course_bags_cate } })
    },
    back() {
      this.$router.push({ path: '/admin.schoolCourse.schoolCourseBags', query: {} })
    },
    //-----------------------------导出--------------------------
    excelExport() {
      const headers = {
        '用户ID': 'user_id',
        '用户名': 'user_name',
        '手机号': 'user_mobile',
        '上课进度': 'learn_msg',
      }
      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await getUserInfoBySchoolBags(this.search)
        if (!res.data.data) {
          return this.$message.error('暂无数据导出')
        }
        const data = this.formatJson(headers, res.data.data)

        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '绑定学生记录表',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },
  }
}
</script>
