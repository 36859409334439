<!--课包购买链接-->
<template>
  <div>
    <el-card>
      <el-row style="margin-top:10px;">
        <!-- <el-button type="danger" @click="back">返回课包</el-button> -->
        <el-button type="primary" @click="add">生成链接</el-button>
      </el-row>
      <div style="height:30px;line-height:30px;font-size:20px;margin:5px 0;">
        <span>{{ bag_name }}</span>
      </div>
      <div class="div-left">
        <!--日期start-->
        <div style="float:left;height:40px;line-height:40px;">报名时间: </div>
        <div style="float:left;margin-left:20px;">
          <div class="block">
            <el-date-picker @blur="timeBlur" v-model="value1" value-format="yyyy-MM-dd HH:mm:ss" type="daterange"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <!--日期end-->
        <div class="div-left">
          <el-button type="primary" icon="el-icon-search" @click="btnClick">搜索</el-button>
          <el-button type="warning" icon="el-icon-refresh" @click="reset">重置</el-button>
        </div>
      </div>
      <el-row style="margin-top:10px;">
        <el-table :data="list" border stripe>
          <el-table-column label="短链id" prop="id" width="160px"></el-table-column>
          <el-table-column label="生成时间" prop="created_at" width="160px"></el-table-column>
          <el-table-column label="购买价格" prop="price_str" width="140px"></el-table-column>
          <el-table-column label="报名人数" prop="apply_count" width="200px"></el-table-column>
          <el-table-column label="短链接" prop="short_url" width="200px"></el-table-column>
          <el-table-column label="原链接" prop="url" width="200px"></el-table-column>
          <el-table-column label="链接状态" prop="link-status" width="100px">
            <template slot-scope="scope">
              <span v-if="scope.row.link_status == 1">正常</span>
              <span v-else>失效</span>
            </template>
          </el-table-column>
          <el-table-column label="所属客服" prop="name" width="140px"></el-table-column>
          <el-table-column label="售卖人" prop="sale_name" width="140px"></el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="jumpDetail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--分页start-->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <!--分页end-->

        <!--添加start-->
        <el-dialog title="生成购买链接" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="50%"
          @close="addDialogClosed">
          <el-form ref="addFormRef" label-width="100px">
            <el-form-item label="购买价格:" v-if="addForm.pay_type == 2">
              <div>
                <div>
                  <el-radio :label="1" v-model="addForm.type" label-width="100px">原&nbsp;&nbsp;价&nbsp;&nbsp;
                  </el-radio>
                </div>
                <div>
                  <el-radio :label="2" v-model="addForm.type">自定义
                    <el-input v-model="addForm.price" type="number" style="margin-left:40px;">
                    </el-input>元
                  </el-radio>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="所属客服:">
              <el-select v-model="addForm.customer_id">
                <el-option v-for="item in customerList" :label="item.name" :value="item.id" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="售卖人:">
              <el-select v-model="salesman" @change="selectVal(salesman)">
                <el-option v-for="item in salesmanList" :label="item.name" :value="item.id" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <div>
                <el-button @click="createUrl">生成链接</el-button>
              </div>
            </el-form-item>

            <el-form-item label="">
              <el-input type="textarea" v-model="url"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">关闭</el-button>
          </span>
        </el-dialog>
        <!--添加end-->

      </el-row>
    </el-card>
  </div>
</template>

<script>
import { createShortUrl, linkList } from '@/api/coursePackage'
import { getTimeCustomerList } from '@/api/customer'
export default {
  data() {
    return {
      bag_name: this.$route.query.bag_name,
      addForm: {
        course_bag_id: this.$route.query.course_bag_id, // 课包id
        type: 1,
        price: '',
        origin_price: this.$route.query.price, //原价
        pay_type: this.$route.query.pay_type,
        customer_id: '',
        salesman_id: '',
        salesman_name: '',
        salesman_phone: '',
      },
      list: [],
      addDialogVisible: false,
      editDialogVisible: false,
      url: '',
      customerList: [],
      salesmanList: [],
      salesman: "",
      total: 0,
      search: {
        pagenum: 1,
        pagesize: 10,
        course_bag_id: this.$route.query.course_bag_id, // 课包i
        apply_start_time: "",
        apply_end_time: "",
      },
      value1: '',
    }
  },
  created() {
    let page = sessionStorage.getItem("page");
    let pagesize = sessionStorage.getItem("pagesize");
    if (parseInt(page) > 0 && page != null && page != undefined) {
      this.search.pagenum = parseInt(page);
      sessionStorage.removeItem("page")
    }
    if (parseInt(pagesize) > 0 && pagesize != null && pagesize != undefined) {
      this.search.pagesize = parseInt(pagesize);
      sessionStorage.removeItem("pagesize")
    }
    this.getList()
  },
  methods: {
    jumpDetail(row) {
      sessionStorage.setItem('page', this.search.pagenum)
      sessionStorage.setItem('pagesize', this.search.pagesize)
      this.$router.push({ path: '/admin.course.urlDetail', query: { short_link_id: row.id, course_bag_id: this.addForm.course_bag_id } })
    },
    //----------------------------列表-----------------------------------
    async getList() {
      const { data: res } = await linkList(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.list = res.data.data
      this.total = res.data.total
    },
    async getCustomerList() {
      const { data: res } = await getTimeCustomerList({ customer_type: 1, status: 1 })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.customerList = res.data
    },
    async getSalesmanList() {
      const { data: res } = await getTimeCustomerList({ customer_type: 3, status: 1 })
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.salesmanList = res.data
    },
    //-------------------------------- 生成链接--------------------------------
    add() {
      this.addDialogVisible = true
      this.getCustomerList()
      this.getSalesmanList()
    },
    addDialogClosed() {
      this.addForm.subject_name = ''
      this.salesman = ""
      this.url = ""
      this.addForm.customer_id = ""
      this.getList()
    },
    async createUrl() {
      if (this.addForm.type == 2) {
        if (this.addForm.price == "") {
          return this.$message.error('请填写自定义价格')
        }
      }
      if (this.addForm.customerSelected == "") {
        return this.$message.error("请选择客服")
      }
      if (this.addForm.salesmanSelected == "") {
        return this.$message.error("请选择售卖人")
      }

      if (this.addForm.salesman_id == "") {
        return this.$message.error("参数有误")
      }
      const { data: res } = await createShortUrl(this.addForm)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.url = res.data.url
    },
    //---------------- 跳转到 课包----------------------
    back() {
      this.$router.push({ path: '/admin.course.mixCPackage', query: {} })
    },
    selectVal(val) {
      const that = this
      this.salesmanList.filter((el) => {
        if (el.id == val) {
          that.addForm.salesman_id = el.id
          that.addForm.salesman_name = el.name
          that.addForm.salesman_phone = el.mobile_phone
        }
      })

    },
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },
    timeBlur() {
      this.search.pagenum = 1
      this.search.pagesize = 10
      this.search.apply_start_time = this.value1[0]
      this.search.apply_end_time = this.value1[1]
    },
    btnClick() {
      this.getList()
    },
    reset() {
      this.search.apply_end_time = "";
      this.search.apply_start_time = "";
      this.value1 = "";
      this.getList();
    }
  }
}
</script>