<template>
  <div>
    <el-card>
        <el-row class="nav-">
          <el-col :span="6">音频名称: <el-input type="text"  v-model="search.title" prefix-icon="el-icon-search"></el-input></el-col>
          <el-button  type="primary" icon="el-icon-search" @click="searchClick" style="margin-top:14px;margin-left:10px">搜索</el-button>
          <el-button  type="warning" icon="el-icon-refresh" @click="btnClick" style="margin-top:14px;margin-left:10px">重置</el-button>
        </el-row>
        <el-row style="margin-top:10px">
          <el-button type="primary"  @click="addAudio" style="float:left;">添加音频</el-button>
        </el-row>
        <el-row>
          <div style="float:left;margin-top:10px;">
            <div style="float:left;font-size:20px;"><span>合集名称:{{collection_name}}</span></div>
            <div style="float:left;font-size:20px;margin-left:20px;"><span>关联音频数量:{{relation_audio_num}}</span></div>
          </div>
        </el-row>
        <el-row style="margin-top:10px;">
            <el-table :data="list" border stripe>
            <el-table-column label="音频ID"  prop="audio_no"></el-table-column>
            <el-table-column label="音频名称" prop="audio_name"></el-table-column>
            <el-table-column label="权重" prop="relevance_weight"></el-table-column>
            <el-table-column label="操作" width="240px">
                <template slot-scope="scope" >
                  <el-button type="text" size="mini"  @click="edit(scope.row)">编辑权重</el-button>
                  <el-button type="text"  size="mini" @click="audioRelationDel1(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
          </el-table>

          <!--分页start-->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.pagenum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="search.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          <!--分页end--> 
        </el-row>

        <!-------------------关联课程------------------------->
        <el-dialog title="关联课程" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="70%" @close="addDialogClosed" >
          <div style="float:left;width:100%">
            <!--未关联部分-->
            <div style="width:70%;float:left;border-right:1px solid #ccc">
              <div>
                <el-form label-width="100px" >
                  <el-form-item label="音频搜索">
                    <el-input type="text"  v-model="dialogTitle" style="width:40%" placeholder="输入音频编号或名称" prefix-icon="el-icon-search"></el-input>
                    <el-button type="text" style="margin-left:10px;" @click="dialogSearch()">搜索</el-button>
                    <el-button type="text" @click="dialogReset()">重置</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <div >
                <div style="width:95%;height:500px;overflow-y:scroll">
                  <el-table
                  ref="table" 
                  :row-style="{height:'45px'}" 
                  :cell-style="{padding:'0px'}"
                  :data="couldBeRelaList" border  
                  @selection-change="handleSelectionChange"
                  >
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <!-- <el-table-column label="id" prop="id"></el-table-column> -->
                    <el-table-column label="音频ID" prop="audio_no"></el-table-column>
                    <el-table-column label="音频名称" prop="audio_name">
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
              <!-- 关联-->
            <div style="float:left;width:30%;height:500px;overflow-y:scroll">
              <div class="r-div-son" v-for="(item,index) in relationRight" :key="index"> 
                <span v-if="item.audio_name.length<26">{{item.audio_name}}</span>
                <span v-else>{{item.audio_name.substring(0,23)}}...</span>
                <img src="@/assets/common/delete.png" alt="" class="delete"  @click="del(index)"/>
              </div>          
            </div>
          </div>
          <div class="clear" style="height: 10px;"></div>
          <div style="width:100%;margin-top:20px;height: 50px;">
            <div style="width:200px;float: right;">
              <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="addSubmit()">确定</el-button>
              </span>
            </div>
          </div>  
          <div class="clear" style="height: 10px;"></div>
        </el-dialog>
        <!--关联-->

        <el-dialog
          :visible.sync="sortDialogVisible"
          title="修改权重" width="25%" @close="sortDialogClosed" >
          <el-form  label-width="100px">
            <el-form-item style="margin:0 auto" label="权重值" >
              <el-input v-model="editForm.weight" type="number" ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="sortDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="sortSubmit()">确 定</el-button>
          </span>
        </el-dialog>

      </el-card> 
  </div>
</template>


<script>

import { audioRelationList,couldBeRelaList,relationAddAudio,editSort,audioRelationDel } from '@/api/audio'
export default {
  components:{
  },
  data(){
    return {
      collection_name:this.$route.query.name,
      relation_audio_num:"",
      list:[],
      couldBeRelaList:[],
      collection_id: this.$route.query.id,
      search:{
        title:"",
        collection_id: this.$route.query.id,
        pagenum:1,
        pagesize:10,
      },
      dialogTitle:"",
      total:0,
      typeList:[{"id":1,"name":"正常"},{"id":2,"name":"禁用"}],
      addDialogVisible:false,
      relationRight:[],
      multipleSelection:[],
      sortDialogVisible:false,
      editForm:{
        weight:"",
        audio_group_id:"",
        audio_id:""
      }
      
    }
  },
  created(){
    this.getList()
  },
  methods:{
    async getList(){
      const {data:res} = await audioRelationList(this.search)
       if (res.status != 0) {
         return this.$message.error(res.msg)
       }
       this.relation_audio_num = res.data.total
       this.total = res.data.total
       this.list = res.data.data
    },
    async getCouldBeRelaList(){
      const {data:res} = await couldBeRelaList({title:this.dialogTitle})
       if (res.status != 0) {
         return this.$message.error(res.msg)
       }
       this.couldBeRelaList = res.data
    },

    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList(this.search)
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList(this.search)
    },

  handleSelectionChange(val){
    this.multipleSelection = val
    this.multipleSelection.forEach((val)=>{
      this.couldBeRelaList.forEach((cVal,index)=>{
        if(cVal.id == val.id){
          this.couldBeRelaList.splice(index,1)
          const item = {
            'id': val.id,
            'audio_no': val.audio_no,
            'audio_name':val.audio_name
          }
          // 过滤： 课程重复添加
          let ret = 0
          this.relationRight.forEach((eml)=>{
            if(eml.id == item.id){
              ret = -1
            }
          })
          if(ret != -1){
            this.relationRight.unshift(item)
          }      
        }
      })
    })
    this.$refs.table.clearSelection()
  },
  del(index){
    const item = {
      'audio_no':this.relationRight[index].audio_no,
      'id':this.relationRight[index].id,
      'audio_name':this.relationRight[index].audio_name
    }
    this.couldBeRelaList.unshift(item)
    this.relationRight.splice(index,1)  
  },

  addAudio(){
    this.getCouldBeRelaList()
    this.addDialogVisible = true
  },
  addDialogClosed(){
    this.clear()
    this.addDialogVisible = false
  },
  clear(){
    this.relationRight = []
  },
  searchClick(){
    this.getList()
  },
  
  btnClick() {
    this.search.title = ""
    this.search.type = ""
    this.search.collection_id = ""
    this.search.pagenum = 1
    this.search.pagesize = 10
    this.getList()
  },

  edit(data){
    console.log(data)
    this.editForm.weight = data.relevance_weight
    this.editForm.audio_group_id = data.audio_group_id
    this.editForm.audio_id = data.audio_id
    this.sortDialogVisible = true
  },
  async sortSubmit(){
    const {data:res} = await editSort(this.editForm)
    if (res.status != 0) {
      return this.$message.error(res.msg)
    }
    this.$message.success(res.msg)
    this.getList()
    this.sortDialogVisible = false
  },
  sortDialogClosed(){
    this.sortDialogVisible
  },

  async audioRelationDel1(id){
    this.$confirm("是否要删除", '提示', {
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      console.log(id)
      const {data:res} = await audioRelationDel({id:id})
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.$message.success("删除成功!")
      this.getList()
    }).catch(() => {
    }) 
  },

  async uploadFile(uploadInfo,index,type){
    const {data:token} =  await getQiniuToken()
    const that = this
    const observer = {
        next(res){
          that.percentage = parseInt(res.total.percent.toString().split(".")[0])
        },
        error(err){
          console.log(err)
        },
        complete(res){
          if(index == 1){
            if(type==1){
              that.addForm.cover_img = res.key 
            }else{
              that.editForm.cover_img = res.key 
            }
            
          }else if(index== 2){
            if(type == 1){
              that.addForm.audio = res.key
            }else{
              that.addForm.audio = res.key
            } 
          }
          that.show = false  
        }
    }
    let name = uploadInfo.file.name
    let fileName = global.getFileName(name)
    if(parseInt(fileName) !=NaN){
      name = fileName+ "-" + global.randStr(10) +"."+ global.getExt(name)
    }
    const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+ name,token,{},{})
    const subscription =  observable.subscribe(observer)
  },

  async changeStatus(row){
    const {data:res} =  await changeStatus({id:row.id,status:row.status})
      if(res.status!=0){
      return this.$message.error(res.msg)
    } 
    this.$message.success(res.msg)
    this.getList()
  },

  jump(id){
    this.$router.push({path:'/admin.videoList',query:{id:id}})
  },
  jumpBuyLink(id,title){
    this.$router.push({path:'/admin.videoBuyList',query:{id:id,videoCourseTitle:title}})
  },
  async addSubmit(){
    const idArr = []
    this.relationRight.forEach(val=>{
      idArr.unshift(val.id)
    })
    const relation_audio_ids = idArr.join(",")
    const {data:res} =  await relationAddAudio({audio_group_id:this.collection_id,audio_ids:relation_audio_ids})
      if(res.status!=0){
      return this.$message.error(res.msg)
    } 
    this.$message.success(res.msg)
    this.addDialogVisible = false
    this.getList()
    
  },
  dialogSearch(){
    this.couldBeRelaList = []
    this.relationRight = []
    this.getCouldBeRelaList()
  },
  dialogReset(){
    this.couldBeRelaList = []
    this.relationRight = []
    this.dialogTitle = ""
    this.getCouldBeRelaList()
  },
   
  }
}
</script>

<style scoped>

.r-div-son{
  border:1px solid #ccc;
  height:33px; 
  border-radius:20px;
  line-height: 33px;
  margin: 5px 0 5px 10px;
  width:95%;
}
.r-div-son span {
  margin-left:10px;
}

.clear{
  clear:both;
}

.delete {
  width:28px;
  height: 28px;
  color:red;
  position: absolute;
  right: 2px;
  z-index: 100;
  margin-right: 45px;
}
</style>