<template>
    <el-dialog title="关联学校" :close-on-click-modal="false" :visible.sync="bindSchoolDialogVisible" width="40%"
        @close="closeBindSchoolDialog">
        <el-form ref="addFormRef" label-width="100px">
            <el-form-item label="绑定学校:">
                <el-select filterable v-model="optionsSelect" multiple style="margin-left: 20px;width:200px" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeBindSchoolDialog">取消</el-button>
            <el-button type="primary" @click="addSubmit()">确认</el-button>
        </span>
    </el-dialog>
</template>


<script>
import global_ from '@/utils/global.js'
import { getSchoolListAll, createBindSchoolBatch } from '@/api/bindSchool'
export default {
    data() {
        return {
            bindSchoolDialogVisible: false,
            addForm: {
                bags_id: this.$route.query.course_bags_id,
                batch_school_id: ""
            },
            options: [],
            optionsSelect: [],
        }
    },
    created() {
        this.callSchoolListAll();
    },
    methods: {
        async callSchoolListAll() {
            const { data: res } = await getSchoolListAll({ filter_course_bags_id: this.addForm.bags_id,status:1 })
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.options = res.data;
        },
        async addSubmit() {
            try {
                await this.validateFormSubmit();
                await this.callBindSchoolData();
            } catch (error) {
                console.log(error)
                let error_result = JSON.parse(error.message);
                if (error_result.code == 1000) this.$message.error(error_result.msg)
            }
        },
        closeBindSchoolDialog() {
            this.bindSchoolDialogVisible = false;
            this.clearData();
        },
        validateFormSubmit() {
            if (this.optionsSelect.length <= 0) throw new Error(JSON.stringify({ code: 1000, msg: '请选择学校' }))
            if (global_.isEmpty(this.addForm.bags_id)) {
                throw new Error(JSON.stringify({ code: 1000, msg: '缺少关联课包' }))
            }
        },
        clearData() {
            this.optionsSelect = []
            this.addForm.batch_school_id = "";
        },
        async callBindSchoolData() {
            this.addForm.batch_school_id = this.optionsSelect.join(",")
            const { data: res } = await createBindSchoolBatch(this.addForm)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.closeBindSchoolDialog();
            this.$emit("callBindSchoolList")
        }
    }
}
</script>