<template>
  <div>
    <add-button @dialogChange="addExercise"></add-button>
    <list @edit="edit" ref="list"></list>
    <content-dialog v-if="show" @destoryDialog="destoryDialog" :title="title" :editObj="editObj" ref="add_dialog" @updateList="updateList"></content-dialog>
  </div>
</template>

<script>
import addButton from '@/components/ContentTab3/addButton'
import List from '@/components/ContentTab3/list'
import contentDialog from '@/components/ContentTab3/dialog'

export default {
  components:{
    addButton,
    List,
    contentDialog
  },
  data() {
    return {
      title:"添加题目",
      editObj:{},
      item: Date.now(),
      show:false
    }
  },
  created() {
    this.changeFatherFlag()
  },
  methods: {
    // ----------改变父组件的值----------- 
    changeFatherFlag() {
      this.$emit('flagChange', false)
    },
    addExercise(value){
      this.show = true
    },
    edit(val){ 
      this.title = val[0]
      this.editObj = val[1]
      this.show = true
    },
    destoryDialog(){
      console.log('销毁。。。')
      this.title = "添加题目"
      this.editObj = {}
      this.show = false
      window.sessionStorage.setItem("oldEditObj",null)
      window.sessionStorage.setItem("oldInterType",null)
      window.sessionStorage.setItem('interType',null)
    },
    updateList(){
      this.$refs.list.getExerciseList()
    },
    showchange(){
      this.show = false
    }
  }
}
</script>

<style scoped>

</style>