import request from '@/utils/request'


export function getSchoolAccountList(data) {
    return request({
        url: "/admin/schoolAccount/lists",
        method: "post",
        data
    });
}

export function changeStatusBySchoolAccount(data) {
    return request({
        url: "/admin/schoolAccount/changeStatus",
        method: "post",
        data,
    });
}

export function bindPhoneBySchoolAccount(data) {
    return request({
        url: "/admin/schoolAccount/bindPhone",
        method: "post",
        data,
    });
}

export function cannelPhoneBySchoolAccount(data) {
    return request({
        url: "/admin/schoolAccount/cannelPhone",
        method: "post",
        data,
    });
}

export function addAccountBySchoolAccount(data) {
    return request({
        url: "/admin/schoolAccount/addUser",
        method: "post",
        data,
    });
}

export function updateBySchoolAccount(data) {
    return request({
        url: "/admin/schoolAccount/update",
        method: "post",
        data,
    });
}

export function deleteBySchoolAccount(data) {
    return request({
        url: "/admin/schoolAccount/delete",
        method: "post",
        data,
    });
}