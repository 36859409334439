<!-- 绑定课包 -->
<template>
  <div>
    <el-card>
      <el-row style="margin-top:10px;">
        <el-button type="success" style="width:100px;" @click="add">关联课包</el-button>
        <el-button type="success" style="width:100px;" @click="excelExport">导出</el-button>
        <el-button  style="width:100px;float:right;margin-right:10px" @click="back()">返回</el-button>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-table :data="list" border stripe :row-style="{ height: '50px' }" :cell-style='cellStyle'
          :header-cell-style='rowClass'>
          <el-table-column label="渠道课程包id" prop="id_str" ></el-table-column>
          <el-table-column label="渠道课程包名称" prop="course_bags_name"></el-table-column>
          <el-table-column label="关联课程包id" prop="relation_course_bags_id"></el-table-column>
          <el-table-column label="关联课程包名称" prop="relation_course_bags_name"></el-table-column>
          <el-table-column label="购买价格" prop="price"></el-table-column>
          <el-table-column label="实际已购数" prop="have_buy_record_count"></el-table-column>
          <el-table-column label="状态" prop="status_str" ></el-table-column>
          <el-table-column label="操作" >
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="edit(scope.row)" >编辑</el-button>
              <el-button type="text" size="mini" v-if="scope.row.status ==1" @click="changeStatus(scope.row.id,scope.row.status)">禁用 </el-button>
              <el-button type="text" size="mini" v-if="scope.row.status ==2 " @click="changeStatus(scope.row.id,scope.row.status)">启用 </el-button>
              <el-button type="text" size="mini"  @click="buyInfo(scope.row.id,scope.row.course_bags_name,scope.row.relation_course_bags_id)">购买信息</el-button>
              <el-button type="text" size="mini"  @click="del(scope.row.id)" v-if="scope.row.buy_records_count<1">删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>

    <!--分页start-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="search.pagenum"
        :page-sizes="[10, 20, 30, 50]" :page-size="search.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    <!--分页end-->


    <el-dialog :visible.sync="addDialogVisible" :title="title" width="30%" @close="addDialogClosed" :close-on-click-modal="false">
      <el-form ref="addFormRef"  label-width="100px">
        <el-form-item label="课程包名称:">
          <el-input v-model="addForm.name" type="text"></el-input>
        </el-form-item>
        <el-form-item label="课包类型:">
          <el-radio-group v-model="type" size="small" >
            <el-radio :label="item.id" v-for="item in redeem_type" :key="item.id">{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="关联课程包:">
          <el-select v-model="addForm.courseBag" placeholder="请选择" filterable @change="changeVal(addForm.courseBag)">
            <el-option v-for="(item,index) in courseList" :label="item.name" :key="index" :value="item.id">{{item.name}}</el-option>    
          </el-select>
        </el-form-item>
        <el-form-item label="购买价格:">
          <el-input v-model="addForm.price" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="Submit()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>


<script>
import { bList , bAdd , bEdit , bChangeStatus, bDel, bindCourse,getCourseBagType } from '@/api/channel'
import {  redeemCourse } from '@/api/cdKey'
import global_ from '@/utils/global';
export default {
  data() {
    return {
      channelId: this.$route.query.channelId,
      list: [],
      courseList:[],
      total: 0,
      redeem_type:[  { id: 1, name: "互动课" },{ id: 2, name: "主题课程" }],
      addForm:{
        channelId: this.$route.query.channelId,
        name:"",
        courseBag:"",
        price:"",
        courseBagName:"", //关联课包名称
      },
      search:{
        pagenum:1,
        pagesize:10,
        channelId: this.$route.query.channelId,
      },
      title:"",
      //-------------------------------
      addDialogVisible: false,
      is_edit:0,
      type:"",
      editTemp:0,
    }
  },
  created() {
    if(this.$route.query.pagenum){
      this.search.pagenum = parseInt(this.$route.query.pagenum)
    }
    if(this.$route.query.pagesize){
      this.search.pagesize = parseInt(this.$route.query.pagesize)
    }
    if(this.$route.query.channelId){
      this.search.channelId = parseInt(this.$route.query.channelId)
    }
    this.getList()
  },
  watch: {
    'type':{
      handler(newVal,oldVal){
        if(this.is_edit!=1){
          this.addForm.courseBag = ""
          this.getRedeemList()
        }else{
          if(this.editTemp > 0){
            this.addForm.courseBag = ""
          }
          this.getRedeemList()
          this.editTemp ++  
        }
      },
      immediate:true,
      deep:true
    },
  },
  methods: {
    async getRedeemList(){
      const { data: res } = await redeemCourse({"type":this.type})
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.courseList = res.data
    },
    //-----------------------------tabel 文字居中-------------------------------
    rowClass({ row, rowIndex }) {
      return 'text-align:center'
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;padding:0px'
    },
    //--------------------------------------
    async add(){
      this.getRedeemList()
      this.title = "添加课程"
      this.type = 1
      this.addDialogVisible = true
    },
    async getCourseBagType(id){
      const { data: res } = await getCourseBagType({"bag_id":id})
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.type = res.data.type
    },
    edit(data){
      // console.log(data)
      this.getCourseBagType(data.relation_course_bags_id)
      this.getRedeemList()
      this.addDialogVisible = true
      this.is_edit = 1
      this.title = "编辑课包"
      this.addForm.name = data.course_bags_name
      this.addForm.courseBag = data.relation_course_bags_id
      this.addForm.courseBagName = data.relation_course_bags_name.toString()
      this.addForm.price = data.price
      this.addForm.id = data.id
      if(this.type == 1){
        this.editTemp ++
      }
    },
    async changeStatus(id,status){
      const { data: res } = await bChangeStatus({"id":id,"status":status})
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.$message.success(res.msg)
      this.getList()
    },
    async del(id){
      this.$confirm('确认要删除嘛', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
          const { data: res } = await bDel({"id":id})
          if (res.status != 0) {
            return this.$message.error(res.msg)
          } 
          this.$message.success(res.msg)
          this.getList()
      }).catch(() => {
        
      })
    },
    changeVal(id){
      this.courseList.filter((item,index)=>{
        if(item.id == id){
          this.addForm.courseBagName =  item.name
        }
      })   
      
    },
    //-------------------------------渠道列表-----------------------------------
    async getList() {
      const { data: res } = await bList(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.list = res.data.data
      this.total = res.data.total
    },
    async bindCourseList() {
      const { data: res } = await bindCourse()
      if (res.status != 0) {
        return this.$message.error(res.msg)
      } 
      this.courseList = res.data
    },
    showPic(url) {
      this.imagesUrl = [];
      this.imagesUrl.push(url)
      this.$viewerApi({
          images: this.imagesUrl,
      })
    },
    //-----------------------------导出--------------------------
    excelExport() {
      const headers = {
        '渠道课程包id': 'id',
        '渠道课程包名称': 'course_bags_name',
        '关联课程包id': 'relation_course_bags_id',
        '关联课程包名称': 'relation_course_bags_name',
        '购买价格': 'price',
        '实际已购数':'have_buy_record_count',
        '状态': 'status_str',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await bList(this.search)
        const data = this.formatJson(headers, res.data.data)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '渠道商课程包',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },
    // -------------------------分页-------------------------------------------
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getList()
    },
    //-------------------
    addDialogClosed() {
       this.title = ""
       this.addForm.name = ""
       this.addForm.courseBag = ""
       this.addForm.price = ""
       this.addForm.id = ""
       this.is_edit = 0
       this.editTemp = 0
       this.type = ""
    },
    async Submit() {
      if(this.is_edit == 0){
        const { data: res } = await bAdd(this.addForm)
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
      }else{
        const { data: res } = await bEdit(this.addForm)
        if (res.status != 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
      }
      this.addDialogVisible = false
      this.getList()
    },
    buyInfo(id,course_bags_name,relation_course_bags_id){
      this.$router.push({ path: '/admin.channel.buyInfo', query: { id:id,course_bags_name:course_bags_name,relation_course_bags_id: relation_course_bags_id,channelId: this.$route.query.channelId} })
    },
    back(){
      this.$router.push({ path: '/admin.channel.distribution', query: { pagenum:this.$route.query.pagenum, pagesize:this.$route.query.pagesize } })
    }
  }
}
</script>