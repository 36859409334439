<!--banner 广告-->
<template>
<div>
  <el-card>
    <div class="nav_1">
      <div class="div-left">
        <div class="d-order-no">Banner标题: </div>
        <div  class="div-left">
          <el-input class="input"  v-model="search.keyword"></el-input>
        </div>
      </div>
      <div class="div-left">
        <div class="d-order-no">状态: </div>
        <div  class="div-left">
         <el-select v-model="search.status" placeholder="请选择" >
            <el-option v-for="(item,index) in statusList" :label="item.name" :key="index" :value="item.id">{{item.name}}</el-option>    
          </el-select>
        </div>
      </div>
      <div class="div-left">
        <el-button  type="primary" icon="el-icon-search" @click="btnClick" >搜索</el-button>
        <el-button  type="warning" icon="el-icon-refresh" @click="reset" >重置</el-button>
      </div>
    </div>
    <!---->
    <div class="clear"></div>
    <el-row style="margin-top:10px;">
      <el-button type="primary" style="width:100px;" @click="addBanner">添加banner</el-button>
    </el-row>
    <el-row style="margin-top:10px;">
      <el-table :data="list" 
        border stripe 
        :row-style="{height:'50px'}" 
        :cell-style='cellStyle' 
        :header-cell-style='rowClass'
        >
        <el-table-column label="编号" prop="id"></el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-image :src="imgDomain + scope.row.img" style="width: 30px;height:30px;"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="展示页面" >
          <span>首页</span>
        </el-table-column>
        <el-table-column label="展示开始时间" prop="start_time"></el-table-column>
        <el-table-column label="展示结束时间" prop="end_time"></el-table-column>
        <el-table-column label="跳转链接" prop="click_url"></el-table-column>
        <el-table-column label="权重" prop="sort" width="50px"></el-table-column>
        <el-table-column label="状态" prop="show_str"></el-table-column>
         <el-table-column label="操作" width="240px">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="edit(scope.row)" >编辑</el-button>
              <template>
                <el-button type="text"  size="mini" v-if="scope.row.status == 2" @click="changeSta(scope.row.id,scope.row.status)">启用</el-button>
                <el-button type="text"  size="mini" v-else-if="scope.row.status == 1" @click="changeSta(scope.row.id,scope.row.status)">禁用</el-button>
              </template>
              <el-button type="text" slot="reference" size="mini" style="margin-left:10px" @click="del(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
      </el-table>
    </el-row>

    <!--分页start-->
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="search.pagenum"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="search.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination> -->
    <!--分页end-->

    <!--添加banner start-->
    <el-dialog title="Banner" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <el-form ref="addFormRef"   label-width="100px">
        <el-form-item label="广告位标题">
          <el-input v-model="addForm.title" type="text"></el-input>
        </el-form-item>
        <el-form-item label="展示页面:">
          <el-select v-model="addForm.is_page" placeholder="请选择">
            <el-option v-for="(item,index) in showPage" :label="item.name" :key="index" :value="item.id">{{item.name}}</el-option>    
          </el-select>
        </el-form-item>
        <div style="width:178px;">
          <el-form-item label="广告位图片:" >
            <el-upload class="avatar-uploader" 
                :action="action"
                :show-file-list="false"
                :headers="token"
                :on-success="handleAvatarSuccess"
                :data="{dirname:'interactcourse',index:1}"
                :http-request="(file)=> {uploadFile(file,1)}"
            >
              <img v-if="addForm.img" :src="imgDomain + addForm.img" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-progress type="circle" v-if="show" :percentage="percentage" class="progress"></el-progress>
            <div style="width:178px;">(建议尺寸:&nbsp; 19:8)</div>
          </el-form-item>
        </div>
        <el-form-item label="展示时间:">
          <div style="float:left;margin-left:20px;">
          <div class="block">
              <el-date-picker
              @blur="timeBlur"
                v-model="value1"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="点击事件:">
          <div>
            <div>
              <el-radio label="1" v-model="addForm.is_click" >页面跳转 
                <el-input
                  v-model="click_url1" type="textarea" style="margin-left:40px;width:500px">
                </el-input>
              </el-radio>
            </div>
            <div style="text-align:center"><el-button @click="jump()" type="text">跳转链接说明文档</el-button></div>
            <div style="margin-top:20px;">
              <el-radio label="2" v-model="addForm.is_click" >外部链接 <el-input
                  v-model="click_url2" type="textarea" style="margin-left:40px;width:500px"></el-input>
              </el-radio>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="广告位权重:">
          <el-input v-model="addForm.sort" type="number"></el-input>
        </el-form-item>       
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addSubmit">保存</el-button>
      </span>
    </el-dialog>
    <!--添加banner end-->

  </el-card>
</div>
</template>

<script>
import {list,add,edit,del,changeStatus,detail} from '@/api/banner'
import {getQiniuToken} from '@/api/upload'
import * as qiniu from 'qiniu-js'
import global from '@/utils/global'
export default {
 data (){
  return {
    list:[],
    action: this.uploadUrl + '/admin/upload/upload',
    token: { token: window.sessionStorage.getItem("token") },
    addForm: {
      title:'',
      is_page:1,
      start_time:"",
      end_time:"",
      is_click:"1",
      click_url:"",
      sort:100,
      img:""
    },
    total:0,
    click_url1:"",
    click_url2:"",
    showPage: [{"id":1,name:'首页'}],
    statusList:[{id:"",name:"全部"},{id:2,name:"禁用"},{id:4,name:"即将展示"},{id:5,name:"展示中"},{id:6,name:"已过期"}],
    search: {
      keyword:"",
      status:"",
      pagenum:1,
      pagesize:10,
    },
    addDialogVisible: false,
    value1: [],
    is_edit:0,
    percentage:0,
    show:false,
  }
 },
 created(){
   this.getList()
 },
 methods: {
  //-----------------------------tabel 文字居中-------------------------------
  rowClass({row, rowIndex}){
    return 'text-align:center'
  },
  cellStyle({row, column, rowIndex, columnIndex}){
    return 'text-align:center;padding:0px' 
  },
  //----------------添加-----------
  addBanner(){
    this.addDialogVisible = true
  },
  addDialogClosed(){
    this.is_edit = 0
    this.clear()
  },
  async addSubmit(){

    if(this.is_edit == 1){
      if(this.checkForm()!=1 ){
        return
      }
      if(this.addForm.banner_id == ""){
        return this.$message.error("有误")
      }

      const {data:res} = await edit(this.addForm)
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.$message.success('更新成功');
    }else{
       if(this.checkForm()!=1 ){
      return
      }
      const {data:res} = await add(this.addForm)
      if(res.status!=0){
        return this.$message.error(res.msg)
      }
      this.$message.success('添加成功');
    }
   
    this.addDialogVisible = false
    this.getList()
    
  },
  timeBlur(){
    console.log(this.value1)
    this.addForm.start_time = this.value1[0]
    this.addForm.end_time = this.value1[1]
  },
  //---------
  checkForm(){
    if(this.addForm.title == ""){
      return this.$message.error("请写广告标题")
    }
    if(this.addForm.is_page == ""){
      return this.$message.error("请选择展示页面")
    }
    if(this.addForm.img == ""){
      return this.$message.error("请上传广告位图片")
    }
    if(this.addForm.start_time == ""){
      return this.$message.error("展示时间参数有误")
    }
    if(this.addForm.end_time == ""){
      return this.$message.error("展示时间参数有误")
    }
    if(this.addForm.is_click == 1){
      this.addForm.click_url = this.click_url1
    }else{
      this.addForm.click_url = this.click_url2
    }
    // if(this.addForm.click_url == ""){
    //   return this.$message.error("请填写跳转链接")
    // }
    if(this.addForm.sort == ""){
      return this.$message.error("权重不为空")
    }
    return 1
  },
  // -------------------------分页-------------------------------------------
  handleSizeChange(newSize) {
    this.search.pagesize = newSize
    this.getList(this.search)
  },
  handleCurrentChange(newPage) {
    this.search.pagenum = newPage
    this.getList(this.search)
  },
  //-------------------------------列表-----------------------------------
  async getList(){
    const {data:res} = await list(this.search)
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.list = res.data
    // this.total = res.data.total 
  },
  //---详情--
   async getDetail(id){
    const {data:res} = await detail({banner_id:id})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.list = res.data 
  },
  //---------禁用/启用-------
  async changeSta(id,status){
    const {data:res} = await changeStatus({banner_id:id,status:status})
    if(res.status!=0){
      return this.$message.error(res.msg)
    }
    this.getList()
  },
  //-----------删除
  del(id){ 
    this.$confirm("是否要删除", '提示', {
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      const {data:res} = await del({banner_id:id})
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.getList()
      this.$message.success('删除成功!')
    }).catch(()=>{
      console.log("取消删除")
    })
    
  },
  //-------编辑-------
  async edit(row){
    console.log(row)
    this.is_edit = 1
    this.addForm.title = row.title
    this.addForm.is_page = row.is_page
    this.addForm.start_time = row.start_time
    this.addForm.end_time = row.end_time
    this.value1 = [row.start_time,row.end_time]
    this.addForm.is_click = row.is_click.toString()
    this.addForm.img = row.img
    this.addForm.sort = row.sort
    this.addDialogVisible = true
    this.addForm.banner_id = row.id
    if(this.addForm.is_click==1){
      this.click_url1 = row.click_url
    }else if(this.addForm.is_click==2){
      this.click_url2 = row.click_url
    }
  },
  clear(){
    this.is_edit = 0
    this.addForm.title = ""
    this.addForm.is_page = 1
    this.addForm.start_time = ""
    this.addForm.end_time = "row.end_time"
    this.value1 = []
    this.addForm.is_click = "1"
    this.addForm.click_url = ""
    this.addForm.img = ""
    this.addForm.sort = 100
    this.addForm.banner_id = ""
    this.click_url1= ""
    this.click_url2= ""
  },
  //----------------上传-------------
   handleAvatarSuccess(res, file) {  
      if(res.status != 0){
        this.progressFlag = false
        return this.$message.error(res.msg)
      }
      this.addForm.img =  res.data.url    
    },
    async uploadFile(uploadInfo,index){
    const {data:token} =  await getQiniuToken()
    const that = this
    const observer = {
        next(res){
          console.log('next:', res)
          that.percentage = parseInt(res.total.percent.toString().split(".")[0])
        },
        error(err){
          console.log(err)
        },
        complete(res){
          console.log('complete:....')
          that.addForm.img = res.key 
          that.show = false
        }
      }
      this.show = true
      let name = uploadInfo.file.name
      let fileName = global.getFileName(name)
      if(parseInt(fileName) !=NaN){
        name = fileName+"-"+global.randStr(10)+"."+global.getExt(name)
      }
      const observable =  qiniu.upload(uploadInfo.file, "interactcourse/"+ name,token,{},{})
      const subscription =  observable.subscribe(observer)
    },
  //------------------------------筛选---------------------------------------
  //1.订单编号
  btnClick(){
    this.getList()
  },
  reset(){
    this.search.keyword = ""
    this.search.status = ""
    this.getList()
  },
  //---------------------跳转----------
  jump(id){
    let {href} = this.$router.resolve({path:"/admin.explain-document"})
    window.open(href,"_blank")
  }
 }
}
</script>


<style scoped>
.nav_1{
  margin-bottom: 10px;
  float:left;
}
.div-left{
  float:left;
  margin-right: 10px;
}

.input{
  width: 220px;
}

.d-order-no{
  float:left;
  margin-right:15px;
  height:35px;
  line-height:35px;
}

.progress{
 position: absolute;
 margin-left:26px;
 margin-top:-147px;
}

</style>