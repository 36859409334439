<!--课程订单-->
<template>
    <div>
        <el-card>
            <audioOrderListSearchView :search="search1" @callOrderListApi="callOrderListApi" ref='audioOrderListSearchView'>
            </audioOrderListSearchView>
            <div class="clear"></div>
            <audioOrderListView ref='audioOrderListView' @callOrderRefundRecord="callOrderRefundRecord"
                @callOrderListRefundDialogValue="callOrderListRefundDialogValue">
            </audioOrderListView>
            <audioOrderRefundDialogView ref='audioOrderRefundDialogView'
                @callOrderListByRefundSubmit="callOrderListByRefundSubmit"></audioOrderRefundDialogView>
            <audioOrderRefundRecordDialogView ref="audioOrderRefundRecordDialogView"></audioOrderRefundRecordDialogView>
        </el-card>
    </div>
</template>
  
<script>
import audioOrderListSearch from '../order/audioOrderListSearch.vue'
import audioOrderList from '../order/audioOrderList.vue'
import audioOrderRefundDialog from '../order/audioOrderRefundDialog.vue'
import audioOrderRefundRecordDialog from '../order/audioOrderRefundLog.vue'

export default {
    components: {
        audioOrderListSearchView: audioOrderListSearch,
        audioOrderListView: audioOrderList,
        audioOrderRefundDialogView: audioOrderRefundDialog,
        audioOrderRefundRecordDialogView: audioOrderRefundRecordDialog
    },
    data() {
        return {
            search1: [],
        }
    },
    created() {
        this.$nextTick(function () {
            this.search1 = this.$refs.audioOrderListView.search;
            this.$refs.audioOrderListView.getOrderList()
        })
    },

    methods: {
        callOrderListApi(search) {
            this.$refs.audioOrderListView.search = search;
            this.$refs.audioOrderListView.getOrderList()
        },
        callOrderListRefundDialogValue(order_id) {
            this.$refs.audioOrderRefundDialogView.refund_form.order_id = order_id;
            this.$refs.audioOrderRefundDialogView.addRefundDialogVisible = true;
        },
        callOrderRefundRecord(order_id) {
            this.$refs.audioOrderRefundRecordDialogView.order_id = order_id;
            this.$refs.audioOrderRefundRecordDialogView.addRefundRecordDialog = true;
            this.$refs.audioOrderRefundRecordDialogView.getRefundRecordApi()
        },
        callOrderListByRefundSubmit() {
            this.$refs.audioOrderRefundDialogView.closeOrderRefundDialog()
            this.$refs.audioOrderRefundDialogView.clearData();
            this.$refs.audioOrderListView.getOrderList()
        }
    }
}
</script>
  
  
<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}

.div-left {
    float: left;
    margin-right: 10px;
}

.input {
    width: 220px;
}

.d-order-no {
    float: left;
    margin-right: 15px;
    height: 35px;
    line-height: 35px;
}
</style>