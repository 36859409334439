import { render, staticRenderFns } from "./lists.vue?vue&type=template&id=dec0f18a&scoped=true&"
import script from "./lists.vue?vue&type=script&lang=js&"
export * from "./lists.vue?vue&type=script&lang=js&"
import style0 from "./lists.vue?vue&type=style&index=0&id=dec0f18a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dec0f18a",
  null
  
)

export default component.exports