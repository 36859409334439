<template>
  <div>
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="60%"  @close="addDialogClosed">
      <el-form >
        <!-- :inline="true" -->
        <el-form-item label="互动类型:">
          <el-select v-model="inter_type" placeholder="请选择"    
            @change="selectInterType(inter_type)"
           :disabled="diabled">  
            <!-- @click.native="checkContent" -->
            <el-option v-for="item in inter_type_arr" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <component  
          :inter_type="inter_type" 
          :editObj="editObj_" 
          :is="inter_type_arr[inter_type-1].template" 
          :ref="inter_type_arr[inter_type-1].template" 
          @updateList="updateList" @dialogHidden="dialogHidden"
        >
        </component>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import gui_lei from "./components/gui_lei.vue";
import lian_xian from "./components/lian_xian.vue";
import wen_da from "./components/wen_da.vue";
import sort from "./components/sort.vue";
import { add } from '@/api/course';

export default {  
  components:{
    gui_lei,
    lian_xian,
    wen_da,
    sort,
  },
  props: {
    title:{
    default:"添加题目",
   },
   editObj:{}
  },
  created(){
    this.addDialogVisible = true
    window.sessionStorage.setItem("oldInterType",this.tempInterType)
    this.init()
  },
  data() {
    return {
      inter_type: 4,
      inter_type_arr: [
        { id: 1, name: "归类题", template:"gui_lei" },
        { id: 2, name: "排序题", template:"sort"},
        { id: 3, name: "配对题", template:"lian_xian" }, // 连线改名了
        { id: 4, name: "问答题", template:"wen_da" },
      ],
      addDialogVisible: false,
      show:true,
      diabled:false,
      editObj_:this.editObj,
      tempInterType:4,
      is_edit:0,
      help:0,
    }
  },
  methods: {
    init(){
      if(this.editObj_.inter_type){
          this.inter_type = parseInt(this.editObj_.inter_type)
      }
      if(this.editObj_.is_edit == 1){
        this.diabled = true
      }
    },
    checkContent(){ // select 切换前事件
      let interType = window.sessionStorage.getItem("interType")
      if( interType == 1 || interType == 2 || interType == 3 || interType == 4  ){
        this.$confirm(
            "切换后当前配置内容重置，是否切换？",
            "系统提示",
            {
              confirmButtonText: "取消",
              cancelButtonText: "确认",
              cancelButtonClass: "btn-custom-cancel",
              type: "warning",
            }
          ).then(async () => { 
            if(interType == 1){
              // this.$refs.gui_lei.addSubmit()
            }else if(interType == 2){
              // this.$refs.sort.addSubmit()
            }else if(interType == 3){
              // this.$refs.lian_xian.addSubmit()
            }else if(interType == 4){
              // this.$refs.wen_da.addSubmit()
            }
          }).catch((result) => {
            window.sessionStorage.setItem('interType',null)
          })
      }
    },
    selectInterType(inter_type) { 
      let interType = window.sessionStorage.getItem("interType")
      let oldInterType = window.sessionStorage.getItem("oldInterType")

      if( interType == 1 || interType == 2 || interType == 3 || interType == 4 ){
        if(oldInterType == 1){
          let addForm = this.$refs.gui_lei.addForm
          addForm.num = addForm.contentNum
          addForm.option_type = addForm.contentType
          for(let i=0;i<addForm.num;i++){
            if(addForm.option_type == 1){
              addForm.contentAudio[i] = Object.keys(addForm.contentAudio[i]).length!=0?addForm.contentAudio[i].url:""
            }else{
              addForm.contentImg[i] = Object.keys(addForm.contentImg[i]).length!=0?addForm.contentImg[i].url:""
            }     
          }
          window.sessionStorage.setItem("oldEditObj", JSON.stringify( addForm))
        }else if(oldInterType == 2){
          let addForm = this.$refs.sort.addForm
          addForm.num = addForm.contentNum
          addForm.option_type = addForm.contentType
          for(let i=0;i<addForm.num;i++){
            if(addForm.option_type!=1){
              addForm.contentImg[i] = addForm.contentImg[i].url?addForm.contentImg[i].url:""
            }
            if(addForm.option_type==1 || addForm.option_type == 3){
              addForm.contentAudio[i] = addForm.contentAudio[i].url?addForm.contentAudio[i].url:""
            }
          }
          window.sessionStorage.setItem("oldEditObj", JSON.stringify( addForm))
        }else if(oldInterType == 3){
          let addForm = this.$refs.lian_xian.addForm
          addForm.num = addForm.contentNum
          addForm.option_type = addForm.contentType
          for(let i=0;i<addForm.num;i++){
            if(addForm.option_type == 1){
              addForm.contentAudio[i] = addForm.contentImg[i].url?addForm.contentImg[i].url:""
              addForm.contentAudio1[i] = addForm.contentAudio1[i].url?addForm.contentAudio1[i].url:""
            }else if(addForm.option_type == 2){
              addForm.contentImg[i] = addForm.contentImg[i].url?addForm.contentImg[i].url:""
              addForm.contentImg1[i] =  addForm.contentImg1[i].url ?addForm.contentImg1[i].url:""
            }else{
              addForm.contentAudio[i] = addForm.contentImg[i].url?addForm.contentImg[i].url:""
              addForm.contentImg1[i] =  addForm.contentImg1[i].url ?addForm.contentImg1[i].url:""
            }
          }
          window.sessionStorage.setItem("oldEditObj", JSON.stringify( addForm))
        }else if(oldInterType == 4){
          let addForm = this.$refs.wen_da.addForm
          addForm.type = addForm.ex_type
          addForm.option_type = addForm.answerType
          addForm.num = addForm.answerNum?addForm.answerNum:2
          addForm.title_type = addForm.title_type?addForm.title_type:1
          addForm.text = addForm.answerRadioText
          addForm.audioList = addForm.answerRadioAudio?addForm.answerRadioAudio:""
          addForm.img = addForm.answerRadioImg?addForm.answerRadioImg:""
          let temp = []
          for(let i=0;i<addForm.num;i++){
            temp[i] = 'false'
          }
          addForm.check = temp
          window.sessionStorage.setItem("oldEditObj", JSON.stringify( addForm ))
        }

        if(this.inter_type!=interType){
          this.editObj_.is_edit = 0
        }else{
          this.editObj_.is_edit = 1
        }

        // window.sessionStorage.setItem("oldInterType",this.tempInterType)
        this.$confirm(
            "切换后当前配置内容重置，是否切换？",
            "系统提示",
            {
              confirmButtonText: "确认",
              cancelButtonText: "取消",
              cancelButtonClass: "btn-custom-cancel",
              type: "warning",
            }
          ).then( () => { 
            this.inter_type = inter_type
            this.tempInterType = inter_type
            window.sessionStorage.setItem("oldEditObj",null)
            window.sessionStorage.setItem("oldInterType",null)
            window.sessionStorage.setItem('interType',null)
            this.help = 0
          }).catch((result) => {
           
            this.inter_type = parseInt(window.sessionStorage.getItem("oldInterType"))  
            this.editObj_ = JSON.parse(window.sessionStorage.getItem("oldEditObj"))
            this.editObj_.is_edit = 1
            window.sessionStorage.setItem('interType',this.inter_type)
          })
      }else{
        if(this.editObj_.is_edit){
          this.editObj_.is_edit= 0
        }
        this.inter_type = inter_type
        window.sessionStorage.setItem("oldInterType",inter_type)
      }
    },
    updateList(val){
      this.addDialogVisible = val
      this.$emit("updateList")
    },
    //----------------
    dialogHidden(){
      this.show = false
      this.addDialogVisible = false
      this.inter_type = 4
      this.$emit("destoryDialog")
    },
    addDialogClosed() {
      this.$emit("destoryDialog")
    },
  },
};
</script>
