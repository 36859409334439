<template>
    <el-dialog title="添加用户" :close-on-click-modal="false" :visible.sync="addUserDialogisible" width="28%"
        @close="addUserDialogClosed">
        <el-form label-width="100px">
            <el-form-item label="用户手机号">
                <el-input v-model="addUserForm.mobile_phone"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="addUserDialogisible = false">取消</el-button>
            <el-button type="primary" @click="submitUserAddCourseTime()">确定</el-button>
        </span>
    </el-dialog>
</template>


<script>
import { addUserCourseBagTime } from '@/api/courseSchoolTime'
import global_ from '@/utils/global.js'
export default {
    data() {
        return {
            addUserDialogisible: false,
            addUserForm: {
                mobile_phone: "",
                course_bag_time_id: this.$route.query.bagTimeId
            }
        }
    },
    created() {
    },
    methods: {
        addUserDialogClosed() {
            this.addUserDialogisible = false
            this.addUserForm.mobile_phone = ""
        },
        addUserDialogOpen() {
            this.addUserDialogisible = true
            this.addUserForm.mobile_phone = ""
        },
        submitUserAddCourseTime() {
            let self = this;
            addUserCourseBagTime(this.addUserForm).then((res) => {
                let code = res.data.status;
                if (code === 0) {
                    self.$message.success("添加用户成功")
                    self.addUserDialogClosed();
                    self.$emit("userSignUpList")
                } else {
                    self.$message.error(res.data.msg)
                }
            })
        },
    }
}
</script>

<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}

.search {
    float: left;
    width: 300px;
    margin-left: 10px;
}
</style>