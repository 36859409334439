<!--课表管理-->
<template>
    <div>
        <el-card>
            <!--日期end-->
            <el-row style="margin-top:10px;">
                <el-table :data="medal_list" border stripe :row-style="{ height: '45px' }" :cell-style="{ padding: '0px' }">
                    <el-table-column label="勋章id" prop="id"></el-table-column>
                    <el-table-column label="获得途径" prop="path"></el-table-column>
                    <el-table-column label="勋章名称" prop="name"></el-table-column>
                    <el-table-column label="勋章描述" prop="desc"></el-table-column>
                    <el-table-column label="勋章图像">
                        <template slot-scope="scope">
                            <el-image style="width: 50px; height: 50px;margin: 12px auto;" :src="imgDomain + scope.row.thumb"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="勋章时效" :formatter="getTimeType" width="200" align="center"></el-table-column>
                    <el-table-column label="权重" prop="weight" align="center"></el-table-column>
                    <el-table-column label="状态" :formatter="getStatusName"></el-table-column>
                    <el-table-column label="操作" width="240px">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="jump(scope.row)">编辑</el-button>
                            <el-button type="text" size="mini" @click="changeStatusMedal(scope.row)"
                                v-if="scope.row.status == 1">禁用</el-button>
                            <el-button type="text" size="mini" @click="changeStatusMedal(scope.row)"
                                v-if="scope.row.status == 2">启用</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination style="margin-top:20px;" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :current-page="search.pagenum" :page-sizes="[10, 20, 30, 50]"
                    :page-size="search.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-row>
        </el-card>
        <medalDetail ref="medalDetail" @getMedalList="getMedalList"></medalDetail>
    </div>
</template>
  
  
<script>
import { list, changeStatus } from '@/api/medal'
import global_ from '@/utils/global.js'
import MedalDetail from './MedalDetail.vue'
export default {
    components: {
        medalDetail: MedalDetail
    },
    data() {
        return {
            total: 0,
            search: {
                pagenum: 1,
                pagesize: 10,
            },
            medal_list: []
        }
    },
    created() {
        this.getMedalList();
    },
    methods: {
        jump(row) {
            this.$refs.medalDetail.dislogOpen()
            this.$refs.medalDetail.setMedalInfo(row);
        },
        async changeStatusMedal(row) {
            let change_status_hash = {}
            change_status_hash.id = row.id
            const { data: res } = await changeStatus(change_status_hash)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.getMedalList();
        },
        getTimeType(row, column) {
            let string = "";
            let time_type = row.time_type
            if (time_type == 1) {
                string = "永久";
            } else if (time_type == 2) {
                string = row.start_time + " ~ " + row.end_time
            }
            return string;
        },
        getStatusName(row, column) {
            let status_name = "";
            switch (row.status) {
                case 1:
                    status_name = "正常"
                    break;
                case 2:
                    status_name = "禁用";
                    break;

                default:
                    break;
            }
            return status_name;
        },
        handleSizeChange(newSize) {
            this.search.pagesize = newSize
            this.getMedalList(this.search)
        },
        handleCurrentChange(newPage) {
            this.search.pagenum = newPage
            this.getMedalList(this.search)
        },
        async getMedalList() {
            const { data: res } = await list(this.search)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.medal_list = res.data.data
            this.total = res.data.total
        },
    }
}
</script>
  
<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}
</style>