<template>
  <div>
    <!--面包屑start-->
    <el-card>
      <el-row class="nav-">
        <el-col :span="6">搜索用户: <el-input type="text"  v-model="search.search_content" prefix-icon="el-icon-search"></el-input></el-col>
        <el-button  type="primary" icon="el-icon-search" @click="btnClick" style="margin-top:14px;margin-left:10px">搜索</el-button>
        <el-button  type="warning" icon="el-icon-refresh" @click="reset" style="margin-top:14px;margin-left:10px">重置</el-button>
      </el-row>
      <el-row  style="margin-top:10px">
        <el-col>
          <el-button type="success"  @click="excelExport" >导出</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-table :data="userList" border stripe>
          <el-table-column label="用户ID" prop="id"></el-table-column>
          <el-table-column label="用户名称" prop="nickname"></el-table-column>
          <el-table-column label="手机号" prop="mobile_phone"></el-table-column>
          <el-table-column label="绑定时间" prop="binding_created_at"></el-table-column>
          <el-table-column label="当前阶段" prop="bags_rank"></el-table-column>
          <el-table-column label="所属班级" prop="class_name"></el-table-column>
        </el-table>
        <!--分页start-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="search.pagenum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="search.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <!--分页end-->
      </el-row>
    </el-card>
    <!--面包屑end-->
  </div>
</template>
<script >
import {getUserInfoBySchoolId} from "@/api/school";

export default {
    data(){
      return {
        userList:[],
        search:{
          pagenum:1,
          pagesize:10,
          search_content:'',
          id: this.$route.query.id,
        },
        total:0,
      }
    },
  created(){
    this.getUserBindingList()
  },
  methods: {
    //--------------------------------------获取绑定记录列表--------------------------------------------------
    async getUserBindingList(){
      const {data:res} = await getUserInfoBySchoolId(this.search)
      if (res.status != 0) {
        return this.$message.error(res.msg)
      }
      this.total = res.data.total
      this.userList = res.data.data
    },
    handleSizeChange(newSize) {
      this.search.pagesize = newSize
      this.getUserBindingList()
    },
    handleCurrentChange(newPage) {
      this.search.pagenum = newPage
      this.getUserBindingList()
    },
//--------------------------------------搜索--------------------------------------------------
btnClick() {
  this.search.pagenum = 1;
  this.getUserBindingList();
},
reset() {
  this.search.pagenum = 1;
  this.search.pagesize = 10;
  this.search.search_content= '';
  this.getUserBindingList();
},
//--------------------------------------导出--------------------------------------------------

    excelExport() {
      this.submitExport()
    },
    submitExport(){
      const headers = {
        '用户ID':"id",
        '用户名':"nickname",
        '手机号': 'mobile_phone',
        '绑定时间': 'binding_created_at',
        '当前阶段': 'bags_rank',
        '所属班级': 'class_name',
      }

      import('@/vendor/Export2Excel').then(async excel => {
        this.search.pagenum = 1
        this.search.pagesize = this.total
        const { data: res } = await getUserInfoBySchoolId(this.search)
        if (!res.data.data) {
          return this.$message.error('暂无数据导出')
        }
        const data = this.formatJson(headers, res.data.data)

        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '学校绑定的学生记录',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.search.pagesize = 10
      })
    },
    formatJson(headers, rows) {
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]]
        })
      })
    },

  }

}
</script>
