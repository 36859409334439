<!--课程订单-->
<template>
    <div>
        <el-card>
            <el-row style="margin-top:10px;">
                <el-form ref="form" label-width="90px">
                    <el-col :span="6">
                        <el-form-item label="学校名称:">
                            <el-input type="text" v-model="school_name" @blur="searchList"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <div class="clear"></div>
            <el-row style="margin-top:10px;">
                <el-button style="width:100px;" @click="openSchool">关联学校</el-button>
                <el-button type="primary" style="width:100px;" @click="callExcelExport">Excel导出</el-button>
            </el-row>
            <bindSchoolListData ref='bindSchoolListData' @callAddSchoolList="callAddSchoolList"></bindSchoolListData>
            <bindSchoolAdd ref='bindSchoolAdd' @callBindSchoolList="callBindSchoolList"></bindSchoolAdd>
        </el-card>
    </div>
</template>
  
<script>
import bindSchoolListData from '../courseBagsBindSchool/bindSchoolListData.vue'
import bindSchoolAdd from '../courseBagsBindSchool/bindSchoolAdd.vue'

export default {
    components: {
        bindSchoolListData: bindSchoolListData,
        bindSchoolAdd: bindSchoolAdd,
    },
    data() {
        return {
            school_name: "",
            bags_id: this.$route.query.course_bags_id,
        }
    },
    created() {
        this.$nextTick(function () {
            this.$refs.bindSchoolListData.getBindSchoolList();
        })
    },

    methods: {
        openSchool() {
            this.$refs.bindSchoolAdd.bindSchoolDialogVisible = true;
        },
        callExcelExport() {
            this.$refs.bindSchoolListData.excelExport();
        },
        callBindSchoolList(){
            this.callAddSchoolList()
            this.$refs.bindSchoolListData.getBindSchoolList();
        },
        searchList(){
            this.$refs.bindSchoolListData.search.keyword = this.school_name;
            this.$refs.bindSchoolListData.search.pagenum = 1;
            this.$refs.bindSchoolListData.search.pagesize = 10;
            this.$refs.bindSchoolListData.getBindSchoolList();
        },
        callAddSchoolList(){
            this.$refs.bindSchoolAdd.callSchoolListAll();
        }
    }
}
</script>
  
  
<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}

.div-left {
    float: left;
    margin-right: 10px;
}

.input {
    width: 220px;
}

.d-order-no {
    float: left;
    margin-right: 15px;
    height: 35px;
    line-height: 35px;
}
</style>