import request from '@/utils/request'
//总列表
export function list(data) {
  return request({
    url: 'admin/School/getListByAccount',
    method: 'post',
    data
  })
}
//获取学校关联的课包
export function getCourseBagsBySchoolId(data) {
  return request({
    url: 'admin/CourseBagsRelationSchool/getCourseBagsBySchoolId',
    method: 'post',
    data
  })
}
//处理关联课包状态
export function changeStatusToBags(data) {
  return request({
    url: 'admin/CourseBagsRelationSchool/changeStatus',
    method: 'post',
    data
  })
}
//添加关联课包
export function addBagsToSchool(data) {
  return request({
    url: 'admin/CourseBagsRelationSchool/addBagsToSchool',
    method: 'post',
    data
  })
}
//修改关联课包
export function editBagsToSchool(data) {
  return request({
    url: 'admin/CourseBagsRelationSchool/editBagsToSchool',
    method: 'post',
    data
  })
}
//获取学校绑定的课包
export function getAllBagsBySchool(data) {
  return request({
    url: 'admin/CourseBagsBindSchool/getAllBagsBySchool',
    method: 'post',
    data
  })
}
//获取学校绑定的老师
export function getAllTeacher(data) {
  return request({
    url: 'admin/SchoolTeacher/getAllTeacherBySchoolId',
    method: 'post',
    data
  })
}
//删除学校关联的课包
export function delSchoolBags(data) {
  return request({
    url: 'admin/CourseBagsRelationSchool/delSchoolBags',
    method: 'post',
    data
  })
}
//获取课包绑定学生的记录
export function getUserInfoBySchoolBags(data) {
  return request({
    url: 'admin/CourseBagsRelationSchool/getUserInfoBySchoolBags',
    method: 'post',
    data
  })
}


export function getSchoolRealtionExcel(data) {
  return request({
    url: 'admin/CourseBagsRelationSchool/getSchoolRelationBagsExcel',
    method: 'post',
    data
  })
}