<template>
    <el-dialog title="编辑勋章" :close-on-click-modal="false" :visible.sync="medalDetailDislog" width="28%"
        @close="dislogClose">
        <el-form label-width="100px">
            <el-form-item label="勋章名称">
                <el-input v-model="addUserForm.name"></el-input>
                <p style="color:red;font-size:8px;height:30px;">名称限制最多8个字</p>
            </el-form-item>
            <el-form-item label="勋章描述">
                <el-input v-model="addUserForm.desc"></el-input>
                <p style="color:red;font-size:8px;height:30px;">描述限制最多40个字</p>
            </el-form-item>
            <el-form-item label="勋章图片">
                <el-upload class="avatar-uploader" action="#" :show-file-list="false"
                    :http-request="(file) => { uploadFile(file, 1) }">
                    <img v-if="addUserForm.thumb" :src="imgDomain + addUserForm.thumb" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <p style="color:red;font-size:8px;height:30px;">上传圆形图片</p>
            </el-form-item>
            <el-form-item label="权重">
                <el-input v-model="addUserForm.weight"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="medalDetailDislog = false">取消</el-button>
            <el-button type="primary" @click="submitMedal()">确定</el-button>
        </span>
    </el-dialog>
</template>


<script>
import { edit } from '@/api/medal'
import global_ from '@/utils/global.js'
import { getQiniuToken } from '@/api/upload'
import * as qiniu from 'qiniu-js'
export default {
    data() {
        return {
            progress: false,
            action: this.uploadUrl + '/admin/upload/upload',
            token: { token: window.sessionStorage.getItem("token") },
            addUserForm: {
                id: 0,
                name: "",
                desc: "",
                thumb: "",
                weight: ""
            },
            medalDetailDislog: false,
        }
    },
    created() {

    },
    methods: {
        clearMedalInfo() {
            this.addUserForm.id = 0;
            this.addUserForm.name = "";
            this.addUserForm.desc = "";
            this.addUserForm.thumb = "";
            this.addUserForm.weight = "";
        },
        setMedalInfo(row) {
            this.addUserForm.id = row.id;
            this.addUserForm.name = row.name;
            this.addUserForm.desc = row.desc;
            this.addUserForm.thumb = row.thumb;
            this.addUserForm.weight = row.weight;
        },
        dislogOpen() {
            this.medalDetailDislog = true;
        },
        dislogClose() {
            this.medalDetailDislog = false;
        },
        async submitMedal() {
            if (global_.isEmpty(this.addUserForm.name)) {
                this.$message.error("请输入勋章名称")
                return
            } else {
                if (global_.getStringLength(this.addUserForm.name) > 8) {
                    this.$message.error("勋章名称最多8个字")
                    return
                }
            }
            if (this.addUserForm.desc == "" || this.addUserForm.desc == undefined) {
                this.$message.error("请输入勋章描述")
                return
            } else {
                if (global_.getStringLength(this.addUserForm.desc) > 40) {
                    this.$message.error("勋章描述最多40个字")
                    return
                }
            }
            if (this.addUserForm.weight == "" || this.addUserForm.weight == undefined) {
                this.$message.error("请输入权重")
                return
            }
            if (this.addUserForm.thumb == "" || this.addUserForm.thumb == undefined) {
                this.$message.error("请上传图片")
                return
            }
            const { data: res } = await edit(this.addUserForm)
            if (res.status != 0) {
                return this.$message.error(res.msg)
            }
            this.dislogClose();
            this.clearMedalInfo();
            this.$emit("getMedalList")
        },
        img_limit(val) {
            let type = val.file.type
            let size = val.file.size
            let type_arr = type.split("/")
            let limit = 1   // 1M
            if (type_arr[0] == "image") {
                if (size > 1024 * 1024 * limit) {
                    return -1
                }
            }
            return 1
        },
        async uploadFile(uploadInfo, index) {
            if (this.img_limit(uploadInfo) == -1) {
                return this.$message.error("上传图片请小于1M")
            }
            const { data: token } = await getQiniuToken()
            const that = this
            const observer = {
                next(res) {
                    console.log('next:', res)
                },
                error(err) {
                    console.log(err)
                },
                complete(res) {
                    console.log('complete:....')
                    that.addUserForm.thumb = res.key
                }
            }

            let name = uploadInfo.file.name
            let fileName = global_.getFileName(name)
            name = global_.randStr(15) + "." + global_.getExt(name)

            const observable = qiniu.upload(uploadInfo.file, "medal/" + name, token, {}, {})
            const subscription = observable.subscribe(observer)
        },
    }
}
</script>
  
<style scoped>
.nav_1 {
    margin-bottom: 10px;
    float: left;
}
</style>