<template>
  <div>
    <el-row style="margin-top:10px;">
      <el-button type="primary" v-if="checkPermission('admin.course.add')" @click="addExercise">添加题目</el-button>
    </el-row>
  </div>
</template>


<script>
export default {  
  props:{
    // addForm:{},
    // num1:{},
    // is_edit:{}
  },
  created(){
  },
  data(){
    return {
    }
  },
  methods: {
    addExercise() {
      // this.clear()
      this.$emit("dialogChange",true)
    },
    clear() {
      this.addForm.title = ''
      this.addForm.audio = ''
      this.addForm.sort = 100
      this.num1 = ''
      this.addForm.ex_type = '1'
      this.addForm.answerRadio = ''
      this.addForm.answerRadioAudio = []
      this.addForm.answerRadioText = []
      this.addForm.answerRadioImg = []
      this.addForm.answerCheckbox = [false, false, false, false]
      this.addForm.answerNum = ''
      this.addForm.id = -1
      this.is_edit = 0
    },
  }
}
</script>